import Ticket from "@/views/Ticket.vue";
import DepartmentDetails from "../components/DepartmentDetails";
import Colleagues from "@/views/Colleagues.vue";
import AreaManagement from "@/views/AreaManagement.vue";
import AreaDependants from "@/views/AreaDependants.vue";
import EmployeeManagement from "../views/EmployeeManagement";
import AllTeamMembers from "../views/AllTeamMembers";
import UserProfile from "@/views/UserProfile.vue";
import FilterResults from "../views/FilterResults";
import MoreDepartmentTickets from "@/views/MoreDepartmentTickets";
import UserTickets from "@/views/UserTickets";
import Home from "@/views/Home.vue";
import AssignedTickets from "@/components/AssignedTickets";
import ProcessingTickets from "@/components/ProcessingTickets";
import ClosedTickets from "@/components/ClosedTickets";
import SentTickets from "@/components/SentTickets";

export default [
  {
    path: "/",
    name: "Home",
    component: Home,
    meta: {
      auth: true,
      showSideMenu: true,
    },
  },
  {
    path: "/assignedTickets",
    name: "AssignedTickets",
    component: AssignedTickets,
    meta: {
      auth: true,
      showSideMenu: true,
    },
  },
  {
    path: "/processingTickets",
    name: "ProcessingTickets",
    component: ProcessingTickets,
    meta: {
      auth: true,
      showSideMenu: true,
    },
  },
  {
    path: "/closedTickets",
    name: "ClosedTickets",
    component: ClosedTickets,
    meta: {
      auth: true,
      showSideMenu: true,
    },
  },
  {
    path: "/sentTickets",
    name: "SentTickets",
    component: SentTickets,
    meta: {
      auth: true,
      showSideMenu: true,
    },
  },
  {
    path: "/ticket/:id",
    name: "Ticket",
    component: Ticket,
    meta: {
      auth: true,
      showSideMenu: true,
    },
  },
  {
    path: "/my-areas/:id/:sectorId?",
    name: "Department",
    component: DepartmentDetails,
    meta: {
      auth: true,
      showSideMenu: true,
    },
  },
  {
    path: "/showAllTickets/:id/:sectorId?",
    name: "MoreDepartmentTickets",
    component: MoreDepartmentTickets,
    meta: {
      auth: true,
      showSideMenu: true,
    },
  },
  {
    path: "/userTickets/:id/:userName",
    name: "userTickets",
    component: UserTickets,
    meta: {
      auth: true,
      showSideMenu: true,
    },
  },
  {
    path: "/employeeManagement",
    name: "EmployeeManagement",
    component: EmployeeManagement,
    meta: {
      auth: true,
      showSideMenu: true,
    },
  },
  {
    path: "/filterResults/:type",
    name: "FilterResults",
    component: FilterResults,
    meta: {
      auth: true,
      showSideMenu: true,
    },
  },
  {
    path: "/allTeamMembers/:id/:sectorId?",
    name: "AllTeamMembers",
    component: AllTeamMembers,
    meta: {
      auth: true,
      showSideMenu: true,
    },
  },
  {
    path: "/colleagues",
    name: "Colleagues",
    component: Colleagues,
    meta: {
      auth: true,
      showSideMenu: false,
    },
  },
  {
    path: "/areas",
    name: "AreaManagement",
    component: AreaManagement,
    meta: {
      auth: true,
      showSideMenu: true,
    },
  },
  {
    path: "/areas/:id",
    name: "AreaDependants",
    component: AreaDependants,
    meta: {
      auth: true,
      showSideMenu: true,
    },
  },
  {
    path: "/profile",
    name: "UserProfile",
    component: UserProfile,
    meta: {
      auth: true,
      showSideMenu: false,
    },
  },
];
