<template>
    <div>
        <div class="text-right">
            <img
                class="inline-block h-10 cursor-pointer"
                src="@/assets/icons/nuovoTicketFilled.svg"
                @click="$refs.createAreaModal.open()"
            />
        </div>

        <div
            class="mt-6 grid grid-cols-1 sm:grid-cols-1 lg:grid-cols-2 xl:grid-cols-3 2xl:grid-cols-4 gap-x-8 gap-y-5"
        >
            <area-card
                v-for="area in areas"
                :key="area.id"
                :area="area"
                @update="deleteArea(area.id)"
            ></area-card>
        </div>
        <new-area ref="createAreaModal" @area-created="addArea"></new-area>
    </div>
</template>

<script>
import AreaCard from "@/components/AreaCard.vue";
import NewArea from "@/components/NewArea.vue";
import Services from "@/services/index";
import {mapActions} from "vuex";

export default {
    name: "AreaManagement",

    components: {
        AreaCard,
        NewArea,
    },

    data: () => ({
        sectorDialog: false,
        areas: [],
    }),

    created() {
        this.getListArea();
    },

    methods: {
        ...mapActions({setSelected: "mobileMenu/setSelected"}),

        async getListArea() {
            this.setSelected('dependantsOrAreas')
            const response = await Services.getListArea();
            this.areas = response.data.area;
        },

        async createArea(data) {
            const response = await Services.createArea(data);
            return response;
        },

        addArea(area) {
            this.areas.push(area);
        },

        deleteArea(id) {
            const index = this.areas.indexOf(id);
            this.areas.splice(index, 1);
        },
    },
};
</script>
