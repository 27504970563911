var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('Modal',{ref:"forwardTicketModal",on:{"close":_vm.resetValues},scopedSlots:_vm._u([{key:"header",fn:function(ref){
var close = ref.close;
return [_c('div',{staticClass:"flex justify-end px-8 py-5 items-center leading-none"},[_c('h1',{staticClass:"text-3xl mx-auto font-semibold"},[_vm._v("Inoltra ticket")]),_c('span',{staticClass:"cursor-pointer text-orange text-2xl",on:{"click":function($event){$event.preventDefault();return close.apply(null, arguments)}}},[_c('img',{attrs:{"src":require("@/assets/icons/X.svg")}})])])]}},{key:"body",fn:function(){return [_c('div',{staticClass:"flex flex-col p-2 pb-5 px-20"},[_c('select',{directives:[{name:"model",rawName:"v-model",value:(_vm.forwardData.area),expression:"forwardData.area"}],staticClass:"w-full h-10 mx-auto rounded-md pl-4 text-xs sm:text-sm lg:text-lg overflow-auto bg-lightgray focus:outline-none",attrs:{"type":"text","id":"area"},on:{"change":[function($event){var $$selectedVal = Array.prototype.filter.call($event.target.options,function(o){return o.selected}).map(function(o){var val = "_value" in o ? o._value : o.value;return val}); _vm.$set(_vm.forwardData, "area", $event.target.multiple ? $$selectedVal : $$selectedVal[0])},function($event){_vm.getAreaDivision();
          _vm.getAllUsers();}]}},[_c('option',{staticClass:"select-none",attrs:{"disabled":"","selected":"","value":"0"}},[_vm._v("Seleziona area d'interesse *")]),_vm._l((_vm.areas),function(area){return _c('option',{key:area.id,domProps:{"value":area.id}},[_vm._v(_vm._s(area.name))])})],2),_c('select',{directives:[{name:"model",rawName:"v-model",value:(_vm.forwardData.division),expression:"forwardData.division"}],staticClass:"w-full h-10 mx-auto rounded-md my-2 pl-4 text-xs sm:text-sm lg:text-lg overflow-auto bg-lightgray focus:outline-none",attrs:{"disabled":_vm.forwardData.area == 0,"type":"text"},on:{"change":[function($event){var $$selectedVal = Array.prototype.filter.call($event.target.options,function(o){return o.selected}).map(function(o){var val = "_value" in o ? o._value : o.value;return val}); _vm.$set(_vm.forwardData, "division", $event.target.multiple ? $$selectedVal : $$selectedVal[0])},function($event){return _vm.getDivision()}]}},[_c('option',{staticClass:"select-none",attrs:{"selected":"","value":"0"},domProps:{"innerHTML":_vm._s(
            _vm.forwardData.user == 0
              ? 'Seleziona Settore (Opzionale)'
              : 'Seleziona Settore (Obbligatorio)'
          )}}),_vm._l((_vm.divisions),function(division){return _c('option',{key:division.id,domProps:{"value":division.id}},[_vm._v(_vm._s(division.name))])})],2),_c('select',{directives:[{name:"model",rawName:"v-model",value:(_vm.forwardData.user),expression:"forwardData.user"}],staticClass:"w-full h-10 mx-auto rounded-md pl-4 text-xs sm:text-sm lg:text-lg overflow-auto bg-lightgray focus:outline-none",attrs:{"disabled":!_vm.forwardData.area,"type":"text"},on:{"change":[function($event){var $$selectedVal = Array.prototype.filter.call($event.target.options,function(o){return o.selected}).map(function(o){var val = "_value" in o ? o._value : o.value;return val}); _vm.$set(_vm.forwardData, "user", $event.target.multiple ? $$selectedVal : $$selectedVal[0])},function($event){_vm.forwardData.user == 0 ? _vm.getAreaDivision() : _vm.getUserDivisions()}]}},[_c('option',{staticClass:"select-none",attrs:{"selected":"","value":"0"}},[_vm._v("Operatore (Opzionale)")]),_vm._l((_vm.users),function(user){return _c('option',{key:user.id,domProps:{"value":user.id}},[_vm._v(" "+_vm._s(user.user_info.name)+" ")])})],2),_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.forwardData.message),expression:"forwardData.message"}],staticClass:"w-full h-10 mx-auto my-2 pl-4 rounded-md text-xs sm:text-sm lg:text-lg overflow-auto bg-lightgray focus:outline-none",attrs:{"type":"text","placeholder":"Messaggio *","id":"message"},domProps:{"value":(_vm.forwardData.message)},on:{"input":function($event){if($event.target.composing){ return; }_vm.$set(_vm.forwardData, "message", $event.target.value)}}}),_c('button',{class:_vm.loading
            ? 'ticket-btn-disabled rounded-md py-1.5 focus:outline-none'
            : 'ticket-btn rounded-md py-1.5 focus:outline-none',attrs:{"disabled":_vm.loading},domProps:{"innerHTML":_vm._s(_vm.loading ? 'Inoltro...' : 'Inoltra')},on:{"click":_vm.forwardTicket}}),_c('p',{staticClass:"text-red mt-2",attrs:{"id":"error"}})])]},proxy:true}])})}
var staticRenderFns = []

export { render, staticRenderFns }