<template>
  <div class="w-full lg:w-6/12 bg-white lg:bg-transparent mt-2 lg:mt-0 rounded-t-3xl lg:rounded-t-none lg:ml-12">
    <div class="flex hidden lg:flex items-center mt-4 mx-4 lg:mx-0">
      <div class="hidden lg:block 2xl:text-lg lg:text-base text-blue font-bold uppercase">Team members</div>
      <div class="ml-auto text-right w-4/12">
        <span
          class="font-medium text-blue 2xl:text-base lg:text-sm cursor-pointer"
          @click="showMore(), setTicketStatus(2)"
        >
          Di più >
        </span>
      </div>
    </div>
    <div class="flex flex-col w-full h-full mt-4 2xl:h-120 lg:h-100 shadow-md border-t text-base rounded-lg bg-white">
      <div class="border-b mx-4 2xl:text-base lg:text-xs">
        <div class="flex text-center items-center my-2 w-full">
          <div class="font-medium invisible lg:visible w-1/6 text-xs lg:text-base ml-auto">Ruolo</div>
          <div class="font-medium w-1/6 text-xs lg:text-base truncate">Assegnati</div>
          <div class="font-medium w-1/6 text-xs lg:text-base truncate pl-1">In lavorazione</div>
          <div class="font-medium w-1/6 text-xs lg:text-base">Chiusi</div>
        </div>
      </div>
      <VuePerfectScrollbar>
        <div v-if="!usersLoading" class="mx-4">
          <div
            v-for="(user, index) in area.area_rel_user"
            :key="user.id"
            class="flex text-right w-full 2xl:py-4 xl:py-2 items-center"
            :class="
              index < area.area_rel_user.length - 1 ? 'border-b' : 'border-b-0'
            "
          >
            <div class="w-0 xl:w-1/12">
              <img
                v-if="user.user.user_info.picture_url"
                class="rounded-full 2xl:w-10 2xl:h-10 xl:w-7 xl:h-7"
                :src="user.user.user_info.picture_url"
              />
              <div
                v-else
                class="flex place-items-center justify-center rounded-full bg-gray-300 text-white font-bold
                2xl:w-10 2xl:h-10 xl:w-7 xl:h-7 2xl:text-lg lg:text-base"
              >
                {{ user.user.user_info.firstname.slice(0, 1) }}{{ user.user.user_info.lastname.slice(0, 1) }}
              </div>
            </div>
            <span
              v-if="user.user"
              class="2xl:w-auto lg:w-24 w-28 text-left cursor-pointer ml-2 text-xs lg:text-base lg:truncate lg:overflow-ellipsis"
              @click="
                setTicketStatus(null),
                $router.push('/userTickets/' + user.user_id + '/' +user.user.user_info.name)"
            >
              {{ user.user.user_info.name }}
            </span>
            <span class="w-1/6 text-base invisible lg:visible ml-auto text-center">
              <img
                v-if="user.acl.role === 'User'"
                v-title="user.acl.role_it"
                class="mx-auto 2xl:w-4 2xl:h-4 lg:w-3 lg:h-3"
                src="@/assets/icons/dipendente.svg"
              />
              <img
                v-else-if="user.acl.role === 'Area Manager'"
                v-title="user.acl.role_it"
                class="mx-auto 2xl:w-4 2xl:h-4 lg:w-3 lg:h-3"
                src="@/assets/icons/coordinatore.svg"
              />
              <img
                v-else
                v-title="user.acl.role_it"
                class="mx-auto 2xl:w-4 2xl:h-4 lg:w-3 lg:h-3"
                src="@/assets/icons/manager.svg"
              />
            </span>
            <div
              class="w-1/6 text-base items-center text-red font-semibold hover:cursor-pointer "
            >
              <button
                class="flex rounded-full h-7 mx-auto font-semibold text-xs 2xl:text-base lg:text-xs items-center
                justify-center cursor-pointer hover:bg-lightgray focus:outline-none"
                :class="user.count_ticket_assigned.toString().length > 2 ? 'w-10' : 'w-7'"
                @click="
                  setTicketStatus(2),
                    $router.push(
                      '/userTickets/' +
                        user.user_id +
                        '/' +
                        user.user.user_info.name
                    )
                "
              >
                {{ user.count_ticket_assigned }}
              </button>
            </div>
            <div class="w-1/6 text-base text-center text-green font-semibold">
              <button
                class="flex rounded-full h-7 mx-auto font-semibold text-xs 2xl:text-base lg:text-xs items-center justify-center cursor-pointer hover:bg-lightgray focus:outline-none"
                :class="user.count_ticket_in_process.toString().length > 2 ? 'w-10' : 'w-7'"
                @click="
                  setTicketStatus(3),
                    $router.push(
                      '/userTickets/' +
                        user.user_id +
                        '/' +
                        user.user.user_info.name
                    )
                "
              >
                {{ user.count_ticket_in_process }}
              </button>
            </div>
            <div class="w-1/6 text-base text-center text-cyan  font-semibold">
              <span
                class="flex rounded-full h-7 mx-auto font-semibold text-xs 2xl:text-base lg:text-xs items-center justify-center cursor-pointer hover:bg-lightgray focus:outline-none"
                :class="user.count_ticket_closed.toString().length > 2 ? 'w-10' : 'w-7'"
                @click="
                  setTicketStatus(4),
                    $router.push(
                      '/userTickets/' +
                        user.user_id +
                        '/' +
                        user.user.user_info.name
                    )
                "
              >
                {{ user.count_ticket_closed }}
              </span>
            </div>
          </div>
        </div>
        <div v-if="usersLoading" class="2xl:my-64 lg:my-48">
          <Spinner></Spinner>
        </div>
        <div v-else-if="area.area_rel_user.length == 0"
             class="flex 2xl:text-base lg:text-xs justify-center 2xl:my-64 lg:my-48">
          Non sono presenti utenti.
        </div>
      </VuePerfectScrollbar>
    </div>
  </div>
</template>

<script>
import VuePerfectScrollbar from "vue-perfect-scrollbar";
import { mapActions, mapState } from "vuex";
import Services from "../services";
import Spinner from "@/components/Spinner";

export default {
  name: "TeamMembers",
  components: {
    Spinner,
    VuePerfectScrollbar,
  },
  data: () => ({
    area: [],

    usersLoading: false,
  }),
  computed: {
    ...mapState("user", { user: "user" }),
    ...mapState("user", { user: "user", status: "ticketStatus" }),
  },

  mounted() {
    this.getAreaDetails();
  },
  methods: {
    ...mapActions({ setTicketStatus: "user/setTicketStatus" }),

    showMore() {
      if (this.$route.params.sectorId) {
        this.$router.push(
          "/allTeamMembers/" +
            this.$route.params.id +
            "/" +
            this.$route.params.sectorId
        );
      } else {
        this.$router.push("/allTeamMembers/" + this.$route.params.id);
      }
    },

    async getAreaDetails() {
      try {
        this.usersLoading = true;
        if (!this.$route.params.sectorId) {
          const response = await Services.getAreaDetails({
            area: this.$route.params.id,
          });
          this.area = response;
        } else {
          const response = await Services.getAreaDetails({
            area: this.$route.params.id,
            division: this.$route.params.sectorId,
          });
          this.area = response;
        }
      } catch (error) {
        console.log(error.response);
      }
      this.usersLoading = false;
    },
  },
};
</script>

<style scoped></style>
