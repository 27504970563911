<template>
  <Modal ref="descriptionModal">
    <template #header="{close}">
      <div class="relative flex place-items-center pt-6">
        <h1 class="text-3xl mx-auto font-bold text-blue">Descrizione</h1>
        <span
          @click.prevent="close"
          class="absolute cursor-pointer text-orange text-2xl right-8"
        >
          <img src="@/assets/icons/X.svg" />
        </span>
      </div>
    </template>
    <template #body>
      <div
        class="flex flex-col bg-gray-100 border-px border-gray-200 rounded-lg m-5 p-3 text-gray-normal font-medium"
      >
        <p class="text-lg">{{ description }}</p>
        <div class="flex flex-wrap mt-5">
          <a
            v-for="attachment in attachments"
            :key="attachment.id"
            :href="attachment.url"
            target="_blank"
            class="cursor-pointer whitespace-nowrap bg-white py-1.5 px-3 rounded-md border-px border-orange text-orange mr-2 mb-2"
          >
            {{ attachment.filename }}
          </a>
        </div>
      </div>
    </template>
  </Modal>
</template>

<script>
import Modal from "@/components/Modal.vue";

export default {
  name: "DescriptionModal",

  components: {
    Modal,
  },

  props: {
    description: String,
    attachments: Array,
  },

  methods: {
    open() {
      this.$refs.descriptionModal.open();
    },

    close() {
      this.$refs.descriptionModal.close();
    },
  },
};
</script>
