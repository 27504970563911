export default {
  namespaced: true,
  state: {
    tickets: [],
    filters: {},
    lastPage: null,
    priorities: [],
    //isLoading: false da usare per gestire il caricamento della lista con lo spinner
  },
  mutations: {
    SET_TICKETS(state, value) {
      state.tickets = value;
    },
    SET_FILTERS(state, value) {
      state.filters = value;
    },
    SET_LAST_PAGE(state, value) {
      state.lastPage = value;
    },
    SET_PRIORITIES(state, value) {
      state.priorities = value;
    },
  },
  actions: {
    setTickets({ commit }, value) {
      commit("SET_TICKETS", value);
    },
    setFilters({ commit }, value) {
      commit("SET_FILTERS", value);
    },
    setLastPage({ commit }, value) {
      commit("SET_LAST_PAGE", value);
    },
    setPriorities({ commit }, value) {
      commit("SET_PRIORITIES", value);
    },
  },
};
