<template>
  <div>
    <!--
      <TicketStatus></TicketStatus>

      <Priorities
        class="mx-5"
        :title="'Priorità Critiche'"
        :type="'critical'"
      ></Priorities>
      <Priorities :title="'Solleciti'" :type="'reminder'"></Priorities>
    </div>-->
    <div>
      <TicketsList class="lg:px-4 lg:pb-6 rounded-tr-3xl"></TicketsList>
    </div>
  </div>
</template>

<script>
//import Priorities from "../components/Priorities";
import TicketsList from "../components/TicketsList";
import Vue from "vue";
//import TicketStatus from "../components/TicketStatus";

import {mapActions, mapState} from "vuex";

export default {
  name: "Home",

  data: () => ({
    files: [],
    pressedBtn: "",
    ticketsCounter: {
      newTickets: 0,
      assignedTickets: 0,
      processingTickets: 0,
      closedTickets: 0,
    },
  }),

  created() {
    //TicketStatus sarà null quando si fa il refresh della pagina (di default è settato a null nello store)
    if (this.ticketStatus == null) {
      this.setTicketStatus(1);
    }
    this.checkScreenWidth();
    window.addEventListener('resize', () => {
      Vue.prototype.$screen.width = window.innerWidth;
      Vue.prototype.$screen.height = window.innerHeight;
    });
  },

  components: {
    TicketsList,
  },

  computed: {
    ...mapState("user", { tickets: "tickets" }),
    ...mapState("user", { ticketStatus: "ticketStatus"})
  },

  methods: {
    ...mapActions({ setTicketStatus: "user/setTicketStatus" }),
    checkScreenWidth() {
      Vue.prototype.$screen = Vue.observable({
        width: window.innerWidth,
        height: window.innerHeight
      });
    }

  },
};
</script>
