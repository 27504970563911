<template>
  <Modal ref="editDivisionModal">
    <template #header="{close}">
      <div class="flex p-8 justify-end ">
        <h1 class="text-3xl mx-auto font-semibold">Modifica Settore</h1>
        <span
          @click.prevent="close"
          class="cursor-pointer text-orange text-2xl"
        >
          <img src="@/assets/icons/X.svg" />
        </span>
      </div>
    </template>
    <template #body>
      <div class="px-6 lg:px-16 pb-16">
        <div class="rounded-lg">
          <p class="text-blue font-medium mb-2">Nome Settore</p>
          <input
            :disabled="isDeleting || loading"
            class="block mb-3 focus:outline-none h-10 w-full pl-4 text-lg overflow-auto bg-ticket-grey"
            type="text"
            placeholder="Titolo"
            v-model="division.name"
          />
          <p class="text-blue font-medium mb-2">Descrizione</p>
          <input
            :disabled="isDeleting || loading"
            class="block mb-3 focus:outline-none h-10 w-full pl-4 text-lg overflow-auto bg-ticket-grey"
            type="text"
            placeholder="Descrizione (Max 100 Caratteri)"
          />
          <div v-if="user.is_admin">
            <p class="text-blue font-medium mb-2">Area</p>
            <select
              :disabled="isDeleting || loading"
              class="ticket-text-input bg-ticket-gray py-2 w-full"
              v-model="division.area_id"
            >
              <option v-for="area in areas" :key="area.id" :value="area.id">
                {{ area.name }}
              </option>
            </select>
          </div>
          <div v-if="user.is_admin" class="flex mt-8">
            <span class="text-red">Elimina Settore</span>
            <div class="ml-5">
              <div
                class="flex w-14 h-7 m-0 rounded-full p-1
                        cursor-pointer transition duration-500 ease-in-out "
                :class="isDeleting ? 'bg-red justify-end' : 'bg-gray-100'"
                @click="isDeleting = !isDeleting"
              >
                <div class="bg-white w-5 h-5 rounded-full shadow-lg"></div>
              </div>
            </div>
          </div>
        </div>
        <div class="text-right">
          <button
            v-if="isDeleting"
            :disabled="loading"
            class="mt-5 px-3 py-1 focus:outline-none  bg-orange text-lg text-white rounded-md"
            @click="deleteDivision(division.id)"
          >
            Elimina
          </button>
          <button
            v-else
            :disabled="loading"
            class="mt-5 px-3 py-1 focus:outline-none  bg-orange text-lg text-white rounded-md"
            @click="updateDivision"
          >
            Modifica
          </button>
        </div>
        <p class="text-red">{{ error }}</p>
      </div>
    </template>
  </Modal>
</template>

<script>
import { mapState } from "vuex";
import Services from "../services";
import Modal from "./Modal";

export default {
  name: "ModalCreateDependant",
  components: {
    Modal,
  },
  props: {
    division: Object,
  },

  data: () => ({
    loading: false,
    isDeleting: false,
    error: "",
  }),

  computed: {
    ...mapState("user", { user: "user", areas: "areas" }),
  },

  methods: {
    open() {
      this.$refs.editDivisionModal.open();
    },
    close() {
      this.$refs.editDivisionModal.close();
    },

    async updateDivision() {
      let division = {
        id: this.division.id,
        name: this.division.name,
      };

      this.division.area_id ? (division["area"] = this.division.area_id) : null;

      this.loading = true;
      try {
        await Services.updateDivision(division);
        this.close();
        this.$toast.success("Nome del settore modificato", {
          position: "bottom-right",
        });
      } catch (error) {
        this.error = error.response.data.message;
      }
      this.loading = false;
    },

    async deleteDivision(division) {
      this.loading = true;
      try {
        await Services.deleteDivision({ id: [division] });
        this.$emit("delete", null);
        this.close();
        this.$toast.success("Settore eliminato", { position: "bottom-right" });
      } catch (error) {
        this.error = error.response.data.message;
      }
      this.loading = false;
    },
  },
};
</script>

<style scoped></style>
