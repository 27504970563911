<template>
    <Modal
        card-class="w-full p-5 sm:w-5/6 md:w-1/2 xl:w-5/12 rounded-0 overflow-hidden"
        ref="createTicketModal"
    >
        <template #header="{close}">
            <div class="flex justify-end mb-6 items-center leading-none">
                <h1 class="text-3xl mx-auto font-semibold">Apri ticket</h1>
                <span
                    @click="resetValues()"
                    @click.prevent="close"
                    class="cursor-pointer text-orange text-2xl"
                >
          <img src="@/assets/icons/X.svg"/>
        </span>
            </div>
        </template>
        <template #body>
            <OpenTicketContent @closeModal="{close}"></OpenTicketContent>
        </template>
        <prompt-modal ref="errorModal" :title="'Errore'" :confirm="false">
        </prompt-modal>
    </Modal>
</template>

<script>
import Modal from "@/components/Modal";
import PromptModal from "@/components/PromptModal";
import OpenTicketContent from "@/components/OpenTicketContent";

export default {
    name: "OpenTicketModal",

    components: {
        Modal,
        PromptModal,
        OpenTicketContent,
    },

    methods: {
        open() {
            this.$refs.createTicketModal.open();
        },
    },
};
</script>

<style></style>
