import AbstractRequest from './AbstractRequest';
import axios from 'axios';

class AuthRequestClass extends AbstractRequest {
    constructor() {
        super(axios.create(), process.env.VUE_APP_AUTH_API_BASE_URL, 'mv_token');
    }
}

const AuthRequest = new AuthRequestClass();

AuthRequest.interceptResponse(function (response) {
    return response.data;
}, function (error) {
    throw error;
});

export default AuthRequest;