<template>
  <div class="mt-2 lg:mt-0 pl-1 pr-10 lg:px-0">
    <div
      class="flex 2xl:h-8 lg:h-7 h-8 2xl:my-2 lg:my-1 2xl:text-base lg:text-sm cursor-pointer hover:bg-orangedisabled hover:bg-opacity-50 rounded-l-full lg:rounded-l-none rounded-r-full"
      :class="[
        status == 1 && this.$route.path != '/sentTickets'
          ? 'bg-orangedisabled bg-opacity-50 font-bold text-blue'
          : 'font-medium text-gray-normal',
        this.$route.name === 'userTickets' ||
        this.$route.name === 'AllTeamMembers'
          ? 'hidden'
          : '',
      ]"
    >
      <div
        class="flex w-full justify-between"
        @click="
          goToRoot(1);
          setTicketStatus(1);
        "
      >
        <span class="my-auto pl-6">
          Nuovi
        </span>
        <!-- <div
          class="flex w-10 h-6 my-auto mr-2 rounded-full bg-orange text-white"
        >
          <span class="m-auto font-medium">
            Tot. ticket nuovi
          </span>
        </div> -->
      </div>
    </div>
    <div
      class="flex 2xl:h-8 lg:h-7 h-8 2xl:my-2 lg:my-1 2xl:text-base lg:text-sm cursor-pointer hover:bg-orangedisabled hover:bg-opacity-50 rounded-l-full lg:rounded-l-none rounded-r-full"
      :class="
        status == 2 && this.$route.path != '/sentTickets'
          ? 'bg-orangedisabled bg-opacity-50 font-bold text-blue'
          : 'font-medium text-gray-normal'
      "
    >
      <div
        class="flex w-full justify-between"
        @click="
          goToRoot(2);
          setTicketStatus(2);
        "
      >
        <span class="my-auto pl-6">
          Assegnati
        </span>
        <!-- <div
          class="flex w-10 h-6 my-auto mr-2 rounded-full bg-orange text-white"
        >
          <span class="m-auto font-medium">
            Tot. ticket assegnati
          </span>
        </div> -->
      </div>
    </div>
    <div
      class="flex 2xl:h-8 lg:h-7 h-8 2xl:my-2 lg:my-1 2xl:text-base lg:text-sm cursor-pointer hover:bg-orangedisabled hover:bg-opacity-50 rounded-l-full lg:rounded-l-none rounded-r-full"
      :class="
        status == 3 && this.$route.path != '/sentTickets'
          ? 'bg-orangedisabled bg-opacity-50 font-bold text-blue'
          : 'font-medium text-gray-normal'
      "
    >
      <div
        class="flex w-full justify-between"
        @click="
          goToRoot(3);
          setTicketStatus(3);
        "
      >
        <span class="my-auto pl-6">
          In lavorazione
        </span>
        <!-- <div
          class="flex w-10 h-6 my-auto mr-2 rounded-full bg-orange text-white"
        >
          <span class="m-auto font-medium">
            Tot. ticket in lavorazione
          </span>
        </div> -->
      </div>
    </div>
    <div
      class="flex 2xl:h-8 lg:h-7 h-8 2xl:my-2 lg:my-1 2xl:text-base lg:text-sm cursor-pointer hover:bg-orangedisabled hover:bg-opacity-50 rounded-l-full lg:rounded-l-none rounded-r-full"
      :class="
        status == 4 && this.$route.path != '/sentTickets'
          ? 'bg-orangedisabled bg-opacity-50 font-bold text-blue'
          : 'font-medium text-gray-normal'
      "
    >
      <div
        class="flex w-full justify-between"
        @click="
          goToRoot(4);
          setTicketStatus(4);
        "
      >
        <span class=" my-auto pl-6">
          Chiusi
        </span>
        <!-- <div
          class="flex w-10 h-6 my-auto mr-2 rounded-full bg-orange text-white"
        >
          <span class="m-auto font-medium">
            Tot. ticket chiusi
          </span>
        </div> -->
      </div>
    </div>
    <div
      v-if="currentArea === 'I miei' || currentArea === 'Tutti'"
      class="flex 2xl:h-8 lg:h-7 h-8 2xl:my-2 lg:my-1 2xl:text-base lg:text-sm cursor-pointer hover:bg-orangedisabled hover:bg-opacity-50 rounded-l-full lg:rounded-l-none rounded-r-full"
      :class="[
        this.$route.path == '/sentTickets' || status == 5
          ? 'bg-orangedisabled bg-opacity-50 font-bold text-blue'
          : 'font-medium text-gray-normal',
      ]"
    >
      <div
        class="flex w-full justify-between"
        @click="
          goToRoot(5);
          setTicketStatus(5);
        "
      >
        <span class=" my-auto pl-6">
          Inviati
        </span>
        <!-- <div
          class="flex w-10 h-6 my-auto mr-2 rounded-full bg-orange text-white"
        >
          <span class="m-auto font-medium">
            Tot. ticket inviati
          </span>
        </div> -->
      </div>
    </div>
  </div>
</template>

<script>
import { mapState, mapActions } from "vuex";

export default {
  name: "TicketStatus.vue",

  data: () => ({
    //filter: { status: 1 },
    currentState: 0
  }),

  created() {
    if (this.ticketStatus == null && this.$route.name === "Department") {
      this.setTicketStatus(1);
    }
  },

  props: {
    ticketsAmount: {
      type: Object,
      required: false,
    },
  },

  computed: {
    ...mapState("user", { user: "user" }),
    ...mapState("user", { user: "user", status: "ticketStatus" }),
    ...mapState("user", { currentArea: "currentArea" }),
    ...mapState("user", { currentAreaId: "currentAreaId" }),
    ...mapState("user", { currentSectorId: "currentSectorId" }),
    ...mapState("user", { currentSector: "currentSector" }),

    /*ticketStatus: function() {
      return this.$store.state.user.ticketStatus;
    },*/
  },

  methods: {
    ...mapActions({ setTicketStatus: "user/setTicketStatus" }),
      ...mapActions({setSelected: "mobileMenu/setSelected"}),


      goToRoot(status) {

        this.setSelected(null)
      // Se sono coordinatore/manager e clicco su uno status del menu, devo cambiare route coerentemente con l'area
      // selezionata nel menu. Esempio: ho nel menu Area IT e sto vedendo il dettaglio del ticket, poi clicco su assegnati,
      // devo accertarmi di andare su /my-areas/ - Area - IT (e non per esempio sulla home - I miei)

      //if - Controlli che faccio se sono admin o coordinatore relativi all'interazione con il menù
      //Controllo lo stato del menu settato nello store nel caso in cui vado su una delle voci del menu 'Altro'
      //(Gestione dipendenti/aree, in evidenza o solleciti) e da lì torno a cliccare su nuovi, assegnati ecc.
      if (
        this.user.user_area_coordinator_or_manager.length > 0 ||
        this.user.is_admin > 0
      ) {
        if (
          this.$route.name === "MoreDepartmentTickets" ||
          this.$route.name === "userTickets"
        ) {
          this.setTicketStatus(status);
        }
        //Ho come area selezionato Tutti o I miei
        else if (
          (this.currentArea === "I miei" || this.currentArea === "Tutti") &&
          this.currentSector === ""
        ) {
          this.switchTicketStatus(status);
        }
        // Ho area/settore selezionati (Es. Area IT - Backend oppure Area IT e nessun settore)
        else if (this.currentArea != "I miei" || this.currentArea != "Tutti") {
          this.$route.params.id = this.currentAreaId;
          this.$route.params.sectorId = this.currentSectorId;
          if (this.currentSectorId) {
            this.$router.push(
              "/my-areas/" +
                this.$route.params.id +
                "/" +
                this.$route.params.sectorId
            );
          } else {
            this.$router.push("/my-areas/" + this.$route.params.id);
          }
        }
      }

      //else - Controlli che faccio con utente normale (sempre relativi all'interazione con il menù e al cambio di pagina)
      else {
        //Ho come area selezionato Tutti o I miei
        if (this.currentArea === "I miei" || this.currentArea === "Tutti") {
          this.switchTicketStatus(status);
        }
        /*if (
          this.$route.path != "/" &&
          (this.currentArea != "I miei" || this.currentArea != "Tutti")
        ) {
          this.$route.params.id = this.currentAreaId;
          this.$route.params.sectorId = this.currentSectorId;
          this.$router.push("/my-areas/" + this.$route.params.id + "/" + this.$route.params.sectorId
          );
        }*/
      }
    },

    switchTicketStatus(status) {
      if (status === 1) {
        this.$router.replace("/");
      } else if (status === 2) {
        this.$router.replace("/assignedTickets");
      } else if (status === 3) {
        this.$router.push("/processingTickets");
      } else if (status === 4) {
        this.$router.push("/closedTickets");
      } else {
        this.$router.push("/sentTickets");
      }
    },
  },

  watch: {
    $route(to, from) {
      if (to.name === 'Ticket' && from.name === 'SentTickets') {
        this.setTicketStatus(5);
      } else if (to.name === 'Ticket' && from.name === 'userTickets') {
          this.setTicketStatus(5);
      }
    }
  }
};
</script>
