var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('VuePerfectScrollbar',{staticClass:"w-full flex whitespace-nowrap pb-4",attrs:{"settings":{ swipeEasing: true, useBothWheelAxes: true }}},[_c('div',{staticClass:"w-auto inline-block bg-ticket-grey py-1 px-2.5 text-sm rounded-sm cursor-pointer mr-3 border border-gray-300",on:{"click":function($event){return _vm.$refs.createDivision.open()}}},[_c('img',{staticClass:"h-3 mb-0.5 inline-block",attrs:{"src":require("@/assets/icons/Più.svg")}}),_c('p',{staticClass:"inline-block ml-1 mr-2 2xl:mr-0 font-medium"},[_vm._v("Aggiungi settore")])]),_c('div',{staticClass:"rounded-sm cursor-pointer mr-3 py-1 text-sm px-3",class:this.selectedDivision == 0
          ? 'bg-orange text-white'
          : 'ticket-btn-gray-outline',on:{"click":function($event){_vm.selectedDivision = 0;
        _vm.onClickDivision(0);}}},[_vm._v(" Tutti ")]),(_vm.divisions.data)?_c('div',{staticClass:"flex"},_vm._l((_vm.divisions.data.division),function(division){return _c('div',{key:division.id,staticClass:"mr-3 rounded-sm cursor-pointer text-sm flex place-items-center",class:_vm.selectedDivision.id === division.id
            ? 'bg-orange text-white pr-2'
            : 'ticket-btn-gray-outline px-5',on:{"click":function($event){_vm.selectedDivision = division;
          _vm.onClickDivision(division.id);}}},[(_vm.selectedDivision.id == division.id)?_c('div',{directives:[{name:"title",rawName:"v-title",value:('Modifica Settore'),expression:"'Modifica Settore'"}],staticClass:"w-6 hover:bg-white border border-transparent hover:border-orange rounded-l-sm h-full flex place-items-center",on:{"mouseleave":function($event){_vm.hover = false},"mouseenter":function($event){_vm.hover = true}}},[_c('img',{staticClass:"h-4 w-4 ml-2",attrs:{"src":_vm.hover
                ? require('@/assets/icons/edit-orange.svg')
                : require('@/assets/icons/edit.svg')},on:{"click":function($event){$event.stopPropagation();return _vm.$refs.createEditDivision.open()}}})]):_vm._e(),_c('div',{staticClass:"ml-1"},[_vm._v(_vm._s(division.name))])])}),0):_vm._e()]),_c('modal-create-division',{ref:"createDivision",attrs:{"area":_vm.area.area_id},on:{"division-create":_vm.getDivision}}),_c('edit-division-modal',{ref:"createEditDivision",attrs:{"division":_vm.selectedDivision},on:{"delete":_vm.getDivision}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }