var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"w-full lg:w-6/12 bg-white lg:bg-transparent mt-2 lg:mt-0 rounded-t-3xl lg:rounded-t-none lg:ml-12"},[_c('div',{staticClass:"flex hidden lg:flex items-center mt-4 mx-4 lg:mx-0"},[_c('div',{staticClass:"hidden lg:block 2xl:text-lg lg:text-base text-blue font-bold uppercase"},[_vm._v("Team members")]),_c('div',{staticClass:"ml-auto text-right w-4/12"},[_c('span',{staticClass:"font-medium text-blue 2xl:text-base lg:text-sm cursor-pointer",on:{"click":function($event){_vm.showMore(), _vm.setTicketStatus(2)}}},[_vm._v(" Di più > ")])])]),_c('div',{staticClass:"flex flex-col w-full h-full mt-4 2xl:h-120 lg:h-100 shadow-md border-t text-base rounded-lg bg-white"},[_vm._m(0),_c('VuePerfectScrollbar',[(!_vm.usersLoading)?_c('div',{staticClass:"mx-4"},_vm._l((_vm.area.area_rel_user),function(user,index){return _c('div',{key:user.id,staticClass:"flex text-right w-full 2xl:py-4 xl:py-2 items-center",class:index < _vm.area.area_rel_user.length - 1 ? 'border-b' : 'border-b-0'},[_c('div',{staticClass:"w-0 xl:w-1/12"},[(user.user.user_info.picture_url)?_c('img',{staticClass:"rounded-full 2xl:w-10 2xl:h-10 xl:w-7 xl:h-7",attrs:{"src":user.user.user_info.picture_url}}):_c('div',{staticClass:"flex place-items-center justify-center rounded-full bg-gray-300 text-white font-bold\n              2xl:w-10 2xl:h-10 xl:w-7 xl:h-7 2xl:text-lg lg:text-base"},[_vm._v(" "+_vm._s(user.user.user_info.firstname.slice(0, 1))+_vm._s(user.user.user_info.lastname.slice(0, 1))+" ")])]),(user.user)?_c('span',{staticClass:"2xl:w-auto lg:w-24 w-28 text-left cursor-pointer ml-2 text-xs lg:text-base lg:truncate lg:overflow-ellipsis",on:{"click":function($event){_vm.setTicketStatus(null),
              _vm.$router.push('/userTickets/' + user.user_id + '/' +user.user.user_info.name)}}},[_vm._v(" "+_vm._s(user.user.user_info.name)+" ")]):_vm._e(),_c('span',{staticClass:"w-1/6 text-base invisible lg:visible ml-auto text-center"},[(user.acl.role === 'User')?_c('img',{directives:[{name:"title",rawName:"v-title",value:(user.acl.role_it),expression:"user.acl.role_it"}],staticClass:"mx-auto 2xl:w-4 2xl:h-4 lg:w-3 lg:h-3",attrs:{"src":require("@/assets/icons/dipendente.svg")}}):(user.acl.role === 'Area Manager')?_c('img',{directives:[{name:"title",rawName:"v-title",value:(user.acl.role_it),expression:"user.acl.role_it"}],staticClass:"mx-auto 2xl:w-4 2xl:h-4 lg:w-3 lg:h-3",attrs:{"src":require("@/assets/icons/coordinatore.svg")}}):_c('img',{directives:[{name:"title",rawName:"v-title",value:(user.acl.role_it),expression:"user.acl.role_it"}],staticClass:"mx-auto 2xl:w-4 2xl:h-4 lg:w-3 lg:h-3",attrs:{"src":require("@/assets/icons/manager.svg")}})]),_c('div',{staticClass:"w-1/6 text-base items-center text-red font-semibold hover:cursor-pointer "},[_c('button',{staticClass:"flex rounded-full h-7 mx-auto font-semibold text-xs 2xl:text-base lg:text-xs items-center\n              justify-center cursor-pointer hover:bg-lightgray focus:outline-none",class:user.count_ticket_assigned.toString().length > 2 ? 'w-10' : 'w-7',on:{"click":function($event){_vm.setTicketStatus(2),
                  _vm.$router.push(
                    '/userTickets/' +
                      user.user_id +
                      '/' +
                      user.user.user_info.name
                  )}}},[_vm._v(" "+_vm._s(user.count_ticket_assigned)+" ")])]),_c('div',{staticClass:"w-1/6 text-base text-center text-green font-semibold"},[_c('button',{staticClass:"flex rounded-full h-7 mx-auto font-semibold text-xs 2xl:text-base lg:text-xs items-center justify-center cursor-pointer hover:bg-lightgray focus:outline-none",class:user.count_ticket_in_process.toString().length > 2 ? 'w-10' : 'w-7',on:{"click":function($event){_vm.setTicketStatus(3),
                  _vm.$router.push(
                    '/userTickets/' +
                      user.user_id +
                      '/' +
                      user.user.user_info.name
                  )}}},[_vm._v(" "+_vm._s(user.count_ticket_in_process)+" ")])]),_c('div',{staticClass:"w-1/6 text-base text-center text-cyan  font-semibold"},[_c('span',{staticClass:"flex rounded-full h-7 mx-auto font-semibold text-xs 2xl:text-base lg:text-xs items-center justify-center cursor-pointer hover:bg-lightgray focus:outline-none",class:user.count_ticket_closed.toString().length > 2 ? 'w-10' : 'w-7',on:{"click":function($event){_vm.setTicketStatus(4),
                  _vm.$router.push(
                    '/userTickets/' +
                      user.user_id +
                      '/' +
                      user.user.user_info.name
                  )}}},[_vm._v(" "+_vm._s(user.count_ticket_closed)+" ")])])])}),0):_vm._e(),(_vm.usersLoading)?_c('div',{staticClass:"2xl:my-64 lg:my-48"},[_c('Spinner')],1):(_vm.area.area_rel_user.length == 0)?_c('div',{staticClass:"flex 2xl:text-base lg:text-xs justify-center 2xl:my-64 lg:my-48"},[_vm._v(" Non sono presenti utenti. ")]):_vm._e()])],1)])}
var staticRenderFns = [function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"border-b mx-4 2xl:text-base lg:text-xs"},[_c('div',{staticClass:"flex text-center items-center my-2 w-full"},[_c('div',{staticClass:"font-medium invisible lg:visible w-1/6 text-xs lg:text-base ml-auto"},[_vm._v("Ruolo")]),_c('div',{staticClass:"font-medium w-1/6 text-xs lg:text-base truncate"},[_vm._v("Assegnati")]),_c('div',{staticClass:"font-medium w-1/6 text-xs lg:text-base truncate pl-1"},[_vm._v("In lavorazione")]),_c('div',{staticClass:"font-medium w-1/6 text-xs lg:text-base"},[_vm._v("Chiusi")])])])}]

export { render, staticRenderFns }