<template>
  <div class="h-full lg:h-auto">
    <div class="relative lg:hidden overflow-hidden h-full">
      <div
        id="stato-ticket-mobile"
        class="border-4 rounded-3xl overflow-hidden h-full"
        :class="
          ticket.status.status == 'CHIUSO'
            ? 'bg-orange'
            : 'bg-' +
              ticket.priority.color +
              ' bg-opacity-10' +
              ' border-' +
              ticket.priority.color
        "
      >
        <div class="px-4 py-2">
          <div class="flex justify-between">
            <div class="flex place-items-center text-xs xs:text-lg">
              <span
                class="font-bold mr-1"
                :class="
                  ticket.status.status == 'CHIUSO' ? 'text-blue' : 'text-orange'
                "
                >Ticket -</span
              >
              <span
                class="font-bold"
                :class="
                  ticket.status.status == 'CHIUSO' ? 'text-white' : 'text-blue'
                "
                >#{{ ticket.id }}
              </span>
              <img
                v-if="ticket.favorite"
                class="inline-block ml-1 h-3 w-3 sm:h-5 sm:w-5"
                src="@/assets/icons/pin.svg"
              />
              <img
                v-if="ticket.remind && ticket.status.status != 'CHIUSO'"
                class="inline-block ml-1 h-3 w-3 sm:h-5 sm:w-5"
                src="@/assets/icons/sollecita-red.svg"
              />
            </div>
            <span
              class="font-bold whitespace-nowrap text-sm xs:text-xl 2xl:text-2xl"
              :class="
                ticket.status.status == 'CHIUSO' ? 'text-white' : 'text-blue'
              "
            >
              {{ ticket.status.status }}
            </span>
          </div>
          <div class="flex justify-between place-items-center mt-5">
            <div>
              <span class="flex place-items-center w-44">
                <img
                  class="block"
                  :src="
                    ticket.status.status == 'CHIUSO'
                      ? require('@/assets/icons/tags-white.svg')
                      : require('@/assets/icons/tags.svg')
                  "
                />
                <div
                  class="block ml-2 overflow-hidden truncate"
                  v-if="ticket.tags && ticket.tags.length > 0"
                  v-title="ticket.tags"
                >
                  <span
                    v-for="tag in ticket.tags"
                    :key="tag.id"
                    class="font-medium mr-2"
                    :class="
                      ticket.status.status == 'CHIUSO'
                        ? 'text-white'
                        : 'text-blue'
                    "
                    >#{{ tag }}</span
                  >
                </div>

                <span
                  class="font-medium ml-2"
                  :class="
                    ticket.status.status == 'CHIUSO'
                      ? 'text-white'
                      : 'text-blue'
                  "
                  v-else
                >
                  Nessun Tag
                </span>
              </span>
              <input
                v-if="
                  (ticket.status.status != 'CHIUSO' && ticket.self_assign) ||
                    (ticket.status.status != 'CHIUSO' &&
                      ticket.processing_user_id == user.id)
                "
                size="10"
                v-model="tag"
                placeholder="Aggiungi Tag"
                class="text-sm ml-7 mt-1 rounded-md border-px border-gray-300 pl-2 py-0.5 focus:outline-none"
                type="text"
                @keydown.enter="
                  addTag();
                  checkChanges();
                "
              />
            </div>
            <div v-if="ticket.status.status != 'CHIUSO'">
              <div class="flex place-items-center relative">
                <span
                  class="block uppercase text-md xs:text-lg font-medium mr-2"
                  :style="'color: ' + ticket.priority.color"
                  >{{ ticket.priority.priority }}</span
                >
                <img
                  v-if="
                    ticket.self_assign || ticket.processing_user_id == user.id
                  "
                  class="cursor-pointer h-3 w-3"
                  src="@/assets/icons/freccia-giu.svg"
                  @click="showPriorities = !showPriorities"
                />
                <div
                  class="absolute bg-white z-10 border border-gray top-7 w-full p-2"
                  v-if="showPriorities"
                >
                  <p
                    class="cursor-pointer hover:bg-orangedisabled font-medium text-black-special"
                    v-for="priority in priorities"
                    :key="priority.id"
                    @click="
                      checkChanges();
                      showPriorities = false;
                      ticket.priority = priority;
                    "
                  >
                    {{ priority.priority }}
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div
        class="detail-height absolute bg-white w-full top-32 px-4 py-2 rounded-3xl overflow-auto"
      >
        <div
          class="flex mt-4"
          :class="
            ticket.status.status == 'CHIUSO' ? 'justify-end' : 'justify-between'
          "
        >
          <div
            v-if="ticket.status.status != 'CHIUSO'"
            class="relative flex place-items-center w-full"
          >
            <img
              src="@/assets/icons/punti.svg"
              class="h-8 w-8 -ml-3 cursor-pointer"
              @click="toggleActions = !toggleActions"
            />
            <div
              v-if="toggleActions"
              class="absolute flex place-items-center bg-white ml-5 z-10"
            >
              <button
                v-title="'Metti in Evidenza'"
                class="flex-shrink-0 pr-4 cursor-pointer focus:outline-none"
                :disabled="loading"
                @click="ticket.favorite ? removeFavorite() : updateFavorite()"
              >
                <img
                  :src="
                    ticket.favorite
                      ? require('@/assets/icons/pin.svg')
                      : require('@/assets/icons/pin-gray.svg')
                  "
                />
              </button>

              <img
                v-if="ticket.can_remind"
                v-title="'Sollecita'"
                @click="$refs.remindConfirmModal.open()"
                class="mr-6 cursor-pointer h-5 w-5 mt-0.5"
                :src="
                  ticket.remind
                    ? require('@/assets/icons/sollecita-red.svg')
                    : require('@/assets/icons/sollecita-gray.svg')
                "
              />

              <img
                v-if="ticket.can_forward"
                v-title="'Inoltra'"
                class="mr-6 cursor-pointer h-5 w-5"
                src="@/assets/icons/inoltra.svg"
                @click="$refs.forwardTicketModal.open()"
              />
            </div>
          </div>
          <span
            v-if="!toggleActions"
            class="flex flex-row mr-4 whitespace-nowrap items-center text-orange"
          >
            <p class="text-sm font-medium">{{ ticket.area.name }}</p>
            <span class="ml-1" v-if="ticket.division.length > 0">-</span>
            <multiselect
              v-if="
                ticket.status.status != 'CHIUSO' && ticket.from_id != user.id
              "
              class="ml-2 z-0 border border-orange rounded-md text-sm"
              :loading="loading"
              :disabled="loading || ticket.status.status == 'CHIUSO'"
              multiple
              v-model="selectedDivision"
              :options="divisions"
              :hide-selected="true"
              :placeholder="'Assegna Settore'"
              :label="'name'"
              :track-by="'id'"
              :allow-empty="false"
              :selectLabel="''"
              @input="checkChanges"
            >
            </multiselect>
            <span
              class="ml-1 text-blue"
              v-for="division in ticket.division"
              :key="division.id"
              v-else
            >
              {{ division.division.name }}
            </span>
          </span>
        </div>
        <div id="img-mittente" class="flex place-items-center mt-5">
          <img
            v-if="
              ticket.from_user
                ? ticket.from_user.user_info.picture_url
                  ? ticket.from_user.user_info.picture_url
                  : false
                : false
            "
            class="rounded-full h-12 w-12"
            :src="ticket.from_user.user_info.picture_url"
          />
          <div
            v-else
            class="flex h-12 w-12 rounded-full items-center justify-center text-xl font-bold bg-gray-300 text-white"
          >
            {{ ticket.from_firstname.slice(0, 1)
            }}{{ ticket.from_lastname.slice(0, 1) }}
          </div>

          <div class="ml-3">
            <p
              class="font-medium text-sm"
              :class="
                ticket.status.status == 'CHIUSO' ? 'text-blue' : 'text-gray-400'
              "
            >
              Mittente
            </p>
            <p class="font-medium text-lg 2xl:text-md">
              {{ ticket.from_firstname }} {{ ticket.from_lastname }}
            </p>
          </div>
        </div>
        <div class="mt-4">
          <p
            class="font-medium text-sm"
            :class="
              ticket.status.status == 'CHIUSO' ? 'text-blue' : 'text-gray-400'
            "
          >
            Titolo
          </p>
          <p class="font-bold text-lg 2xl:text-base break-words">
            {{ ticket.subject }}
          </p>
        </div>
        <div class="mt-4 flex justify-between">
          <div>
            <p
              class="font-medium text-sm"
              :class="
                ticket.status.status == 'CHIUSO' ? 'text-blue' : 'text-gray-400'
              "
            >
              Data apertura
            </p>
            <p class="capitalize font-medium text-lg 2xl:text-md">
              {{ formatDate(ticket.creation_date) }}
            </p>
          </div>
          <div
            v-if="ticket.processing_date && ticket.status.status != 'CHIUSO'"
          >
            <p
              class="font-medium text-sm"
              :class="
                ticket.status.status == 'CHIUSO' ? 'text-blue' : 'text-gray-400'
              "
            >
              Data presa in carico
            </p>
            <span
              class="capitalize font-medium text-lg 2xl:text-md whitespace-nowrap"
              :class="
                ticket.status.status == 'CHIUSO' ? 'text-white' : 'text-blue'
              "
              >{{ formatDate(ticket.processing_date) }}</span
            >
          </div>
        </div>
        <div v-if="ticket.closing_date && ticket.status.status == 'CHIUSO'">
          <p
            class="font-medium text-sm"
            :class="
              ticket.status.status == 'CHIUSO' ? 'text-blue' : 'text-gray-400'
            "
          >
            Data chiusura
          </p>
          <span
            class="block capitalize transform -translate-y-1 text-lg whitespace-nowrap font-medium"
            :class="
              ticket.status.status == 'CHIUSO' ? 'text-white' : 'text-blue'
            "
            >{{ formatDate(ticket.closing_date) }}</span
          >
        </div>
        <div v-if="ticket['student-status']" class="mt-4">
          <p
            class="font-medium text-sm"
            :class="
              ticket.status.status == 'CHIUSO' ? 'text-blue' : 'text-gray-400'
            "
          >
            Stato Studente
          </p>
          <p class="capitalize font-medium text-lg">
            {{ ticket["student-status"] }}
          </p>
        </div>
        <div id="separator" class="my-5 mx-8 h-0.5 bg-gray-300"></div>
        <div>
          <p class="text-blue font-bold text-lg">Descrizione</p>
          <p class="bg-gray-100 text-blue font-medium text-base my-5 p-2 px-4">
            {{ ticket.text }}
          </p>
        </div>
        <div v-if="ticket.attachments.length > 0" id="attachments">
          <div class="flex place-items-center">
            <img
              class="block float-left h-6 w-6"
              src="@/assets/icons/allegato.svg"
            />
            <p class="text-blue font-bold pb-0.5 ml-1">Allegati</p>
          </div>
          <div
            class="flex flex-wrap bg-gray-100 border-px border-gray-200 rounded-lg my-5 p-3 text-gray-normal font-medium"
          >
            <a
              v-for="attachment in ticket.attachments"
              :key="attachment.id"
              :href="attachment.url"
              target="_blank"
              class="truncate text-xs xs:text-sm cursor-pointer bg-white py-1.5 px-3 rounded-md border-px border-orange text-orange m-1.5"
            >
              {{ attachment.filename }}
            </a>
          </div>
        </div>

        <div id="actions" class="flex flex-wrap justify-center mb-16">
          <multiselect
            v-if="ticket.can_assignable"
            class="w-40 border-px border-orange rounded-md m-2 whitespace-nowrap text-orange"
            v-model="selectedAssignUser"
            :options="processingUsers"
            placeholder="Assegna a..."
            :hide-selected="true"
            :track-by="'id'"
            :custom-label="
              (processingUsers) => {
                return processingUsers.user_info.name;
              }
            "
            :taggable="false"
            :optionHeight="10"
            :maxHeight="300"
            :showLabels="false"
            @input="$refs.assignedTicketModal.open(selectedAssignUser, 2)"
          >
          </multiselect>

          <button
            v-if="
              (ticket.status.status == 'DA ASSEGNARE' ||
                ticket.status.status == 'INOLTRATO A SETTORE') &&
                ticket.self_assign
            "
            :disabled="loading"
            :class="loading ? 'ticket-btn-disabled' : 'ticket-btn'"
            class="rounded-md px-5 py-1 m-2 whitespace-nowrap text-sm 2xl:text-md"
            @click="
              selectedDivision == 0
                ? $refs.assignedTicketModal.open(user, 2)
                : assignTicket({
                    id: ticket.id,
                    user: user.id,
                    status: 2,
                    division: selectedDivision.map((a) => a.id),
                  })
            "
          >
            Assegna a Te
          </button>
          <button
            :disabled="loading"
            v-if="
              ((ticket.status.status == 'DA ASSEGNARE' ||
                ticket.status.status == 'INOLTRATO A SETTORE') &&
                ticket.self_assign) ||
                (ticket.status.status == 'ASSEGNATO' &&
                  ticket.processing_user_id == user.id)
            "
            class="ticket-btn rounded-md px-5 py-1 text-sm m-2"
            @click="
              status = 3;
              ticket.status.status == 'DA ASSEGNARE'
                ? $refs.assignedTicketModal.open(user, status)
                : toProcessing(user.id);
            "
          >
            Gestisci
          </button>

          <button
            v-if="modified"
            class="ticket-btn rounded-md px-5 py-1 text-sm m-2"
            @click="updateTicket"
          >
            Salva Modifiche
          </button>
          <button
            v-if="
              ticket.answers.length > 0 &&
              ticket.processing_user &&
              ticket.status.status != 'CHIUSO'
                ? ticket.processing_user.id == user.id &&
                  ticket.status.status != 'ASSEGNATO'
                : ''
            "
            @click="$refs.promptModal.open()"
            class="ticket-btn-red px-5 mr-5"
          >
            Chiudi
          </button>
          <button
            v-if="ticket.status.status == 'CHIUSO'"
            class="mt-5 ticket-btn py-1 px-3 rounded-md whitespace-nowrap"
            @click="$refs.ticketReopenModal.open()"
          >
            Riapri Ticket
          </button>
        </div>
      </div>
    </div>

    <!-- Desktop -->

    <div
      class="hidden lg:flex flex-col border rounded-lg"
      :class="
        ticket.status.status == 'CHIUSO'
          ? 'bg-orange text-white border-white'
          : 'bg-white border-gray-300'
      "
    >
      <div
        class="flex flex-row justify-between pl-9 py-2 border border-t-0 border-l-0 border-r-0"
        :class="
          ticket.status.status == 'CHIUSO' ? 'border-white' : 'border-gray-300'
        "
      >
        <span>
          <span
            class="font-medium mr-2"
            :class="
              ticket.status.status == 'CHIUSO' ? 'text-blue' : 'text-orange'
            "
            >Ticket</span
          >
          <span
            class="text-2xl font-bold"
            :class="
              ticket.status.status == 'CHIUSO' ? 'text-white' : 'text-blue'
            "
            >#{{ ticket.id }}
            <span
              v-if="ticket.remind == 1"
              class="inline-flex font-medium ml-2 place-items-center text-base"
              :class="
                ticket.status.status == 'CHIUSO' ? 'text-white' : 'text-red'
              "
            >
              <img
                src="@/assets/icons/sollecita-red.svg"
                class="inline h-5 w-5 mr-2"
              />
              Sollecitato in data
              {{ formatDate(ticket.remind_ticket.created_at) }}
            </span>
          </span>
        </span>

        <span
          class="flex flew-row font-medium mr-4 whitespace-nowrap items-center"
          :class="
            ticket.status.status == 'CHIUSO' ? 'text-white' : 'text-orange'
          "
        >
          {{ ticket.area.name }}
          <span class="ml-1" v-if="ticket.division.length > 0">-</span>
          <multiselect
            v-if="ticket.status.status != 'CHIUSO' && ticket.from_id != user.id"
            class="ml-2 border-px border-orange rounded-md"
            :loading="loading"
            :disabled="loading || ticket.status.status == 'CHIUSO'"
            multiple
            v-model="selectedDivision"
            :options="divisions"
            :hide-selected="true"
            :placeholder="'Assegna Settore'"
            :label="'name'"
            :track-by="'id'"
            :allow-empty="false"
            :selectLabel="''"
            @input="checkChanges"
          >
          </multiselect>
          <span
            class="ml-1"
            v-for="division in ticket.division"
            :key="division.id"
            :class="
              ticket.status.status == 'CHIUSO' ? 'text-white' : 'text-blue'
            "
            v-else
          >
            {{ division.division.name }}
          </span>
        </span>
      </div>

      <div class="flex flex-row justify-between">
        <div id="info-ticket" class="py-2 ml-8 mr-2 w-full mb-2">
          <div class="h-full float-left">
            <img
              v-if="
                ticket.from_user
                  ? ticket.from_user.user_info.picture_url
                    ? ticket.from_user.user_info.picture_url
                    : false
                  : false
              "
              class="rounded-full h-12 w-12"
              :src="ticket.from_user.user_info.picture_url"
            />
            <div
              v-else
              class="flex h-12 w-12 rounded-full items-center justify-center text-xl font-bold bg-gray-300 text-white"
            >
              {{ ticket.from_firstname.slice(0, 1)
              }}{{ ticket.from_lastname.slice(0, 1) }}
            </div>
          </div>
          <div class="h-full flex flex-col">
            <div class="grid grid-rows-2 flex-grow">
              <div class="grid grid-cols-4">
                <div class="ml-4">
                  <p
                    class="font-medium text-sm"
                    :class="
                      ticket.status.status == 'CHIUSO'
                        ? 'text-blue'
                        : 'text-gray-400'
                    "
                  >
                    Mittente
                  </p>
                  <p class="font-medium text-sm 2xl:text-md">
                    {{ ticket.from_firstname }} {{ ticket.from_lastname }}
                  </p>
                </div>
                <div v-if="ticket.processing_user" class="ml-4">
                  <p
                    v-if="ticket.processing_user"
                    class="font-medium text-sm"
                    :class="
                      ticket.status.status == 'CHIUSO'
                        ? 'text-blue'
                        : 'text-gray-400'
                    "
                  >
                    Assegnato
                  </p>

                  <p
                    v-if="ticket.processing_user"
                    class="font-medium text-sm 2xl:text-md"
                  >
                    {{ ticket.processing_user.user_info.firstname }}
                    {{ ticket.processing_user.user_info.lastname }}
                  </p>
                </div>
                <div class="ml-4">
                  <p
                    class="font-medium text-sm"
                    :class="
                      ticket.status.status == 'CHIUSO'
                        ? 'text-blue'
                        : 'text-gray-400'
                    "
                  >
                    Data apertura
                  </p>
                  <p class="capitalize font-medium text-sm 2xl:text-md">
                    {{ formatDate(ticket.creation_date) }}
                  </p>
                </div>
                <div v-if="ticket.status.status == 'CHIUSO'">
                  <p
                    class="font-medium text-sm"
                    :class="
                      ticket.status.status == 'CHIUSO'
                        ? 'text-blue'
                        : 'text-gray-400'
                    "
                  >
                    Data presa in carico
                  </p>
                  <span
                    class="capitalize font-medium text-sm 2xl:text-md whitespace-nowrap"
                    :class="
                      ticket.status.status == 'CHIUSO'
                        ? 'text-white'
                        : 'text-blue'
                    "
                    >{{ formatDate(ticket.processing_date) }}</span
                  >
                </div>
                <div v-if="ticket['student-status']" class="ml-4">
                  <p
                    class="font-medium text-sm"
                    :class="
                      ticket.status.status == 'CHIUSO'
                        ? 'text-blue'
                        : 'text-gray-400'
                    "
                  >
                    Stato Studente
                  </p>
                  <p class="capitalize font-medium text-md">
                    {{ ticket["student-status"] }}
                  </p>
                </div>
              </div>
              <div>
                <div class="ml-4">
                  <p
                    class="font-medium text-sm"
                    :class="
                      ticket.status.status == 'CHIUSO'
                        ? 'text-blue'
                        : 'text-gray-400'
                    "
                  >
                    Titolo
                  </p>
                  <p class="font-bold text-sm 2xl:text-base break-words">
                    {{ ticket.subject }}
                  </p>
                </div>
              </div>
            </div>

            <div
              v-if="ticket.status.status != 'CHIUSO'"
              class="flex lg:flex-col xl:flex-row justify-between w-full "
            >
              <div class="flex mt-6 place-items-center">
                <button
                  v-title="'Metti in Evidenza'"
                  class="flex-shrink-0 pr-4 cursor-pointer focus:outline-none"
                  :disabled="loading"
                  @click="ticket.favorite ? removeFavorite() : updateFavorite()"
                >
                  <img
                    :src="
                      ticket.favorite
                        ? require('@/assets/icons/pin.svg')
                        : require('@/assets/icons/pin-gray.svg')
                    "
                  />
                </button>

                <img
                  v-if="ticket.can_remind"
                  v-title="'Sollecita'"
                  @click="$refs.remindConfirmModal.open()"
                  class="mr-6 cursor-pointer h-5 w-5 mt-0.5"
                  :src="
                    ticket.remind
                      ? require('@/assets/icons/sollecita-red.svg')
                      : require('@/assets/icons/sollecita-gray.svg')
                  "
                />

                <img
                  v-if="ticket.can_forward"
                  v-title="'Inoltra'"
                  class="mr-6 cursor-pointer h-5 w-5"
                  src="@/assets/icons/inoltra.svg"
                  @click="$refs.forwardTicketModal.open()"
                />
                <button
                  v-if="ticket.status.status != 'DA ASSEGNARE'"
                  class="ticket-btn rounded-md px-5 mr-5 py-1 text-sm 2xl:text-md"
                  @click="$refs.descriptionModal.open()"
                >
                  Descrizione
                </button>
              </div>

              <div
                v-if="ticket.status.status != 'CHIUSO'"
                class="flex mt-6 mr-3"
              >
                <multiselect
                  v-if="ticket.can_assignable"
                  class="w-30 2xl:w-48 border-px border-orange rounded-md text-orange mr-5 whitespace-nowrap"
                  v-model="selectedAssignUser"
                  :options="processingUsers"
                  placeholder="Assegna a..."
                  :hide-selected="true"
                  :track-by="'id'"
                  :custom-label="
                    (processingUsers) => {
                      return processingUsers.user_info.name;
                    }
                  "
                  :taggable="false"
                  :optionHeight="10"
                  :maxHeight="300"
                  :showLabels="false"
                  @input="$refs.assignedTicketModal.open(selectedAssignUser, 2)"
                >
                </multiselect>
                <div class="flex">
                  <button
                    v-if="
                      (ticket.status.status == 'DA ASSEGNARE' ||
                        ticket.status.status == 'INOLTRATO A SETTORE') &&
                        ticket.self_assign
                    "
                    :disabled="loading"
                    :class="loading ? 'ticket-btn-disabled' : 'ticket-btn'"
                    class="rounded-md mr-5 px-5 py-1 whitespace-nowrap text-sm 2xl:text-md"
                    @click="
                      selectedDivision == 0
                        ? $refs.assignedTicketModal.open(user, 2)
                        : assignTicket({
                            id: ticket.id,
                            user: user.id,
                            status: 2,
                            division: selectedDivision.map((a) => a.id),
                          })
                    "
                  >
                    Assegna a Te
                  </button>
                  <button
                    :disabled="loading"
                    v-if="
                      ((ticket.status.status == 'DA ASSEGNARE' ||
                        ticket.status.status == 'INOLTRATO A SETTORE') &&
                        ticket.self_assign) ||
                        (ticket.status.status == 'ASSEGNATO' &&
                          ticket.processing_user_id == user.id)
                    "
                    class="ticket-btn rounded-md px-5 mr-5 text-sm 2xl:text-md"
                    @click="
                      status = 3;
                      ticket.status.status == 'DA ASSEGNARE'
                        ? $refs.assignedTicketModal.open(user, status)
                        : toProcessing(user.id);
                    "
                  >
                    Gestisci
                  </button>
                </div>

                <button
                  v-if="modified"
                  class="ticket-btn rounded-md px-5 mr-5"
                  @click="updateTicket"
                >
                  Salva Modifiche
                </button>
                <button
                  v-if="
                    ticket.answers.length > 0 && ticket.processing_user
                      ? ticket.processing_user.id == user.id &&
                        ticket.status.status != 'ASSEGNATO'
                      : ''
                  "
                  @click="$refs.promptModal.open()"
                  class="ticket-btn-red px-5 mr-5"
                >
                  Chiudi
                </button>
              </div>
            </div>
            <div v-else>
              <button
                class="ticket-btn-white rounded-md px-3 py-1 ml-3"
                @click="$refs.descriptionModal.open()"
              >
                Descrizione
              </button>
            </div>
          </div>
        </div>

        <div
          id="stato-ticket"
          class="inline-flex rounded-br-lg"
          :class="
            ticket.status.status == 'CHIUSO'
              ? 'bg-orange'
              : 'bg-' + ticket.priority.color + ' bg-opacity-10'
          "
        >
          <div
            class="w-2 float-left"
            :class="ticket.status.status == 'CHIUSO' ? 'bg-white' : ''"
            :style="
              ticket.status.status == 'CHIUSO'
                ? 'background: white'
                : 'background: ' + ticket.priority.color
            "
          ></div>
          <div class="px-8 py-2 mb-2 flex flex-col">
            <p
              class="font-medium whitespace-nowrap text-lg 2xl:text-2xl"
              :class="
                ticket.status.status == 'CHIUSO' ? 'text-white' : 'text-blue'
              "
            >
              {{ ticket.status.status }}
            </p>
            <div v-if="ticket.status.status != 'CHIUSO'">
              <p class="text-gray-400 font-medium text-sm mt-2">Priorità</p>
              <div class="flex relative">
                <span
                  class="block uppercase text-xl transform -translate-y-1 font-medium mr-2"
                  :style="'color: ' + ticket.priority.color"
                  >{{ ticket.priority.priority }}</span
                >
                <img
                  v-if="
                    ticket.self_assign || ticket.processing_user_id == user.id
                  "
                  class="cursor-pointer mt-1.5 h-3 w-3"
                  src="@/assets/icons/freccia-giu.svg"
                  @click="showPriorities = !showPriorities"
                />
                <div
                  class="absolute bg-white z-10 border border-gray top-7 w-full p-2"
                  v-if="showPriorities"
                >
                  <p
                    class="cursor-pointer hover:bg-orangedisabled font-medium text-black-special"
                    v-for="priority in priorities"
                    :key="priority.id"
                    @click="
                      checkChanges();
                      showPriorities = false;
                      ticket.priority = priority;
                    "
                  >
                    {{ priority.priority }}
                  </p>
                </div>
              </div>
            </div>

            <div
              v-if="ticket.processing_date && ticket.status.status != 'CHIUSO'"
            >
              <p
                class="font-medium text-sm"
                :class="
                  ticket.status.status == 'CHIUSO'
                    ? 'text-blue'
                    : 'text-gray-400'
                "
              >
                Data presa in carico
              </p>
              <span
                class="capitalize font-medium text-sm whitespace-nowrap"
                :class="
                  ticket.status.status == 'CHIUSO' ? 'text-white' : 'text-blue'
                "
                >{{ formatDate(ticket.processing_date) }}</span
              >
            </div>

            <div
              v-if="ticket.closing_date && ticket.status.status == 'CHIUSO'"
              class="mt-2"
            >
              <p
                class="font-medium text-sm"
                :class="
                  ticket.status.status == 'CHIUSO'
                    ? 'text-blue'
                    : 'text-gray-400'
                "
              >
                Data chiusura
              </p>
              <span
                class="block capitalize transform -translate-y-1 text-sm whitespace-nowrap font-medium"
                :class="
                  ticket.status.status == 'CHIUSO' ? 'text-white' : 'text-blue'
                "
                >{{ formatDate(ticket.closing_date) }}</span
              >
            </div>
            <div class="inline-flex place-items-center mt-5 text-md sm:text-xl">
              <img
                class="block mr-2"
                :src="
                  ticket.status.status == 'CHIUSO'
                    ? require('@/assets/icons/tags-white.svg')
                    : require('@/assets/icons/tags.svg')
                "
              />
              <div
                class="block w-36 overflow-hidden truncate"
                v-if="ticket.tags && ticket.tags.length > 0"
                v-title="ticket.tags"
              >
                <span
                  v-for="tag in ticket.tags"
                  :key="tag.id"
                  class="font-medium mr-2"
                  :class="
                    ticket.status.status == 'CHIUSO'
                      ? 'text-white'
                      : 'text-blue'
                  "
                  >#{{ tag }}</span
                >
              </div>
              <div
                class="font-medium"
                :class="
                  ticket.status.status == 'CHIUSO' ? 'text-white' : 'text-blue'
                "
                v-else
              >
                Nessun Tag
              </div>
            </div>

            <input
              v-if="
                (ticket.status.status != 'CHIUSO' && ticket.self_assign) ||
                  (ticket.status.status != 'CHIUSO' &&
                    ticket.processing_user_id == user.id)
              "
              v-model="tag"
              placeholder="Aggiungi Tag"
              class="mt-1 rounded-md border-px border-gray-300 pl-2 py-0.5 focus:outline-none"
              type="text"
              @keydown.enter="
                addTag();
                checkChanges();
              "
            />

            <button
              v-if="ticket.status.status == 'CHIUSO'"
              class="mt-5 ticket-btn-white py-1 px-3 rounded-md whitespace-nowrap"
              @click="$refs.ticketReopenModal.open()"
            >
              Riapri Ticket
            </button>
          </div>
        </div>
      </div>
    </div>
    <description-modal
      ref="descriptionModal"
      :description="ticket.text"
      :attachments="ticket.attachments"
    >
    </description-modal>

    <forward-ticket
      ref="forwardTicketModal"
      :ticket_id="ticket.id"
    ></forward-ticket>

    <prompt-modal
      ref="ticketReopenModal"
      :title="'Conferma Riapertura'"
      :message="'Procedendo, il ticket verrà riaperto.'"
      :error="error"
      :loading="loading"
      @action="reopenTicket"
    ></prompt-modal>

    <assigned-ticket-modal
      :user_id="
        this.selectedAssignUser ? this.selectedAssignUser.id : this.user.id
      "
      :ticket_id="this.ticket.id"
      :ticket_area="this.ticket.area.id"
      :ticket_divisions="this.ticket.division"
      :status="this.status"
      ref="assignedTicketModal"
      @success="getTicket"
      @error="$refs.errorModal.open()"
      @dismiss="selectedAssignUser = ''"
    ></assigned-ticket-modal>

    <prompt-modal
      ref="promptModal"
      :title="'Conferma Chiusura'"
      :message="'Sei sicuro di voler chiudere il ticket?'"
      @action="closeTicket"
    ></prompt-modal>

    <prompt-modal
      ref="remindConfirmModal"
      :title="ticket.remind ? 'Annulla Sollecito' : 'Conferma Sollecito'"
      :message="
        ticket.remind
          ? 'Sei sicuro di voler rimuovere il sollecito da questo ticket?'
          : 'Sei sicuro di voler sollecitare questo ticket?'
      "
      @action="remindTicket"
      :loading="loading"
    >
    </prompt-modal>

    <prompt-modal
      ref="saveConfirmModal"
      :title="'Modifiche Salvate'"
      :message="'Le modifiche sono state salvate con successo'"
      :confirm="false"
    >
    </prompt-modal>

    <prompt-modal
      ref="errorModal"
      :title="'Ops...'"
      :message="'Qualcosa è andato storto.'"
      :confirm="false"
    >
    </prompt-modal>
  </div>
</template>

<script>
import { mapState } from "vuex";
import DescriptionModal from "./DescriptionModal.vue";
import PromptModal from "@/components/PromptModal.vue";
import AssignedTicketModal from "@/components/AssignedTicketModal.vue";
import ForwardTicket from "@/components/ForwardTicket.vue";
import Services from "@/services/index";
import moment from "moment";

export default {
  name: "TicketDetail",

  props: {
    ticketProp: Object,
  },

  components: {
    DescriptionModal,
    AssignedTicketModal,
    ForwardTicket,
    PromptModal,
  },

  data: () => ({
    toggleActions: false,
    error: "",
    modified: false,
    priority: null,
    components: { PromptModal },
    tag: "",
    descriptionModal: false,
    updateData: {},
    selectedAssignUser: "",
    divisions: [],
    processingUsers: [],
    loading: false,
    selectedDivision: [],
    showPriorities: false,
    changedTicket: {},
    status: 2,
  }),

  created() {
    this.selectedDivision = this.ticket.division
      .filter((a) => a.division)
      .map((a) => a.division);
    this.updateData.id = this.ticket.id;

    this.getAllAreaDivisions();

    if (!this.ticket.processing_user) {
      this.getProcessingUser();
    }
    this.changedTicket = JSON.parse(JSON.stringify(this.ticket));
  },

  computed: {
    ...mapState("user", { user: "user" }),
    ...mapState("tickets", { priorities: "priorities" }),

    ticket: {
      get: function() {
        return this.ticketProp;
      },
      set: function(newValue) {
        return newValue;
      },
    },
  },

  methods: {
    checkChanges() {
      this.ticket.priority != this.changedTicket.priority
        ? (this.modified = true)
        : (this.modified = false);

      if (this.changedTicket.tags != this.tag) {
        this.modified = true;
      } else if (this.changedTicket.division != this.selectedDivision) {
        this.modified = true;
      } else {
        this.modified = false;
      }
    },

    addTag() {
      this.ticket.tags == null ? (this.ticket.tags = []) : null;
      this.ticket.tags.push(this.tag);
      this.tag = "";
    },

    async getProcessingUser() {
      try {
        const response = await Services.getProcessingUser(this.ticket.id);
        this.processingUsers = response.data.user;
      } catch (error) {
        console.log(error);
      }
    },

    async getAllAreaDivisions() {
      try {
        const response = await Services.getAllAreaDivisions(
          this.ticket.area.id
        );
        this.divisions = response.data.division;
      } catch (error) {
        console.log(error);
      }
    },

    async getUserDivisions() {
      try {
        const response = await Services.getUserDivisions({
          user: this.user.id,
        });
        this.divisions = response;
      } catch (error) {
        console.log(error);
      }
    },

    async assignTicket(data) {
      delete data["division"];
      this.loading = true;
      try {
        await Services.assignTicket(data);
        this.$emit("status_change", null);
        this.getTicket();
      } catch (error) {
        this.$refs.errorModal.open();
        setTimeout(() => {
          this.$refs.errorModal.close();
        }, 2000);
        console.log(error.response);
      }
      this.loading = false;
    },

    async updateTicket(redirect = false) {
      this.loading = true;
      let data = {
        id: this.ticket.id,
        priority: this.ticket.priority.id,
      };

      this.selectedDivision.length > 0
        ? (data["division"] = this.selectedDivision.map((a) => a.id))
        : null;

      this.ticket.tags != null ? (data["tags"] = this.ticket.tags) : null;

      try {
        await Services.updateTicket(data);
        this.$refs.saveConfirmModal.open();
        setTimeout(() => {
          this.$refs.saveConfirmModal.close();
        }, 2000);
        this.modified = false;
        redirect
          ? setTimeout(() => {
              this.$router.push(redirect);
            }, 2000)
          : null;
      } catch (error) {
        this.$refs.errorModal.open();
        setTimeout(() => {
          this.$refs.errorModal.close();
        }, 2000);
        console.log(error.response);
      }

      this.loading = false;
    },

    async getTicket() {
      try {
        const response = await Services.getTicket(this.ticket.id);
        this.ticket = response.data.ticket;
        this.$emit("status_change", null);
      } catch (error) {
        alert("Si è verificato un errore durante il reperimento del ticket.");
      }
    },

    async toProcessing(user) {
      try {
        await Services.assignTicket({
          user: user,
          id: this.ticket.id,
          status: 3,
        });
        this.$emit("status_change", null);
        this.getTicket();
      } catch (error) {
        this.$refs.errorModal.open();
        setTimeout(() => {
          this.$refs.errorModal.close();
        }, 2000);
        console.log(error.response);
      }
    },

    async reopenTicket() {
      this.loading = true;
      try {
        await Services.updateTicket({ id: this.ticket.id, status: 2 });
        this.$refs.ticketReopenModal.close();
        this.getTicket();
      } catch (error) {
        console.log(error);
      }
      this.loading = false;
    },

    async closeTicket() {
      try {
        await Services.updateTicket({ id: this.ticket.id, status: 4 });
        this.$emit("status_change", null);
        this.$refs.promptModal.close();
        this.getTicket();
      } catch (error) {
        console.log(error);
      }
    },

    async updateFavorite() {
      this.loading = true;
      try {
        await Services.updateFavorites({ id: this.ticket.id });
        this.ticket.favorite = 1;
      } catch (error) {
        console.log(error);
      }
      this.loading = false;
    },

    async removeFavorite() {
      this.loading = true;
      try {
        await Services.removeFavorites({ id: [this.ticket.id] });
        this.ticket.favorite = 0;
      } catch (error) {
        console.log(error);
      }
      this.loading = false;
    },

    async remindTicket() {
      this.loading = true;
      try {
        await Services.remindTicket({
          id: this.ticket.id,
          remind: this.ticket.remind ? 0 : 1,
        });
        this.ticket.remind = !this.ticket.remind;
        this.$refs.remindConfirmModal.close();
      } catch (error) {
        console.log(error);
      }
      this.loading = false;
    },

    showDescription() {
      this.descriptionModal = !this.descriptionModal;
    },

    formatDate: function(date) {
      return moment(date, "YYYY/MM/DD HH:mm:ss").format("DD MMM YYYY - HH:mm");
    },
  },
};
</script>

<style scoped>
.stato-height {
  height: calc(100vh - 20rem);
}

.detail-height {
  height: calc(100vh - 16.2rem);
}
</style>

<style>
.multiselect {
  min-height: 28px !important;
  max-height: 28px !important;
}

.multiselect__tag {
  background-color: #fd7f50 !important;
}

.multiselect__tags {
  min-height: 28px !important;
  max-height: 28px !important;
  padding: 0 40px 0 8px !important;
}

.multiselect__tag-icon:hover {
  color: white;
  background: #fd7f50 !important;
}

.multiselect__option--highlight {
  background: #fd7f50 !important;
}

.multiselect--active .multiselect__input {
  width: fit-content !important;
}

@media (max-width: 1536px) {
  .multiselect__tags {
    padding-top: 4px !important;
    padding-left: 3px !important;
  }
}
</style>

<!-- Style Multiselect -->

<style src="vue-multiselect/dist/vue-multiselect.min.css"></style>
