<template>
    <div class="bg-white">
        <!-- visualizzazione della ricerca da lg in su -->
        <div class="hidden lg:block mb-6">
            <img class="inline-block h-6" src="@/assets/icons/cerca.svg"/>
            <select
                class="w-56 ml-3 ticket-text-input py-2"
                v-model="search.area"
                @change="getAllUsers(search)"
            >
                <option value="" disabled>Settore</option>
                <option value="0">Tutti</option>
                <option v-for="area in areas" :key="area.id" :value="area.id">
                    {{ area.name }}
                </option>
            </select>

            <div class="relative inline-block">
                <input
                    type="text"
                    class="ticket-text-input ml-3 py-2"
                    placeholder="Nome, Cognome..."
                    v-model="name"
                    @click="open = true"
                    @keydown.enter="getAllUsers(search)"
                />
            </div>
        </div>
        <!-- visualizzazione della ricerca fino a lg -->
        <div class="flex flex-wrap lg:hidden mb-6 mx-2">
            <div class="text-blue font-medium my-1">Cerca in rubrica</div>
            <select
                class="w-full ticket-text-input py-2 my-1"
                v-model="search.area"
                @change="getAllUsers(search)"
            >
                <option value="" disabled>Settore</option>
                <option value="0">Tutti</option>
                <option v-for="area in areas" :key="area.id" :value="area.id">
                    {{ area.name }}
                </option>
            </select>

            <div class="w-full my-1">
                <input
                    type="text"
                    class="ticket-text-input w-full py-2"
                    placeholder="Nome, Cognome..."
                    v-model="name"
                    @click="open = true"
                    @keydown.enter="getAllUsers(search)"
                />
            </div>
        </div>
        <div v-if="loading">
            <spinner></spinner>
        </div>
        <div
            v-else
            class="grid grid-cols-1 md:grid-cols-2 xl:grid-cols-3 gap-8 gap-x-32 px-10"
        >
            <div
                v-for="user in filteredUsers"
                :key="user.id"
                @click="$refs.createUserModal.open(user)"
            >
                <user-card class="cursor-pointer" :user="user"></user-card>
            </div>
        </div>
        <user-modal ref="createUserModal"></user-modal>
    </div>
</template>

<script>
import UserCard from "@/components/UserCard.vue";
import UserModal from "@/components/UserModal.vue";
import Spinner from "@/components/Spinner.vue";
import Services from "@/services/index";
import {mapState} from "vuex";

export default {
    name: "Colleagues",

    components: {
        UserCard,
        UserModal,
        Spinner,
    },

    data: () => ({
        loading: false,
        open: false,

        users: [],
        name: "",

        search: {
            area: "0",
        },
    }),

    computed: {
        ...mapState("user", {areas: "areas"}),

        filteredUsers: function () {
            if (this.name.length > 0)
                return this.users.filter((a) =>
                    a.user_info.name.toLowerCase().includes(this.name.toLowerCase())
                );
            return this.users;
        },
    },

    created() {
        this.getAllUsers({});
    },

    methods: {
        async getAllUsers(search) {
            this.loading = true;
            this.users = [];
            this.name != "" ? (this.search["name"] = this.name) : null;
            try {
                const response = await Services.getAllUsers(search);
                this.users = response.data.user;
            } catch (error) {
                console.log(error);
            }
            this.loading = false;
            delete this.search["name"];
        },
    },
};
</script>

<style></style>
