<template>
	<div
		class="relative flex rounded-xl bg-white border border-gray-300 shadow-md text-center h-40"
	>
		<img
			v-if="user.user_info.picture_url"
			class="absolute rounded-full transform -translate-x-10 translate-y-10 shadow-orange h-16 w-16 lg:h-20 lg:w-20 ml-2 lg:ml-0"
			:src="user.user_info.picture_url"
		/>
		<div
			v-else
			class="flex absolute items-center justify-center pb-1 rounded-full transform -translate-x-10 translate-y-10 shadow-orange bg-gray-200 text-white font-bold text-4xl h-16 w-16 lg:h-20 lg:w-20 ml-2 lg:ml-0"
		>
			{{ user.user_info.firstname.slice(0, 1)
			}}{{ user.user_info.lastname.slice(0, 1) }}
		</div>

		<div class="m-auto">
			<p
				v-if="user.user_info"
				class="text-blue font-medium text-sm 2xl:text-base"
			>
				{{ user.user_info.name ? user.user_info.name : "" }}
			</p>
			<p v-else class="text-blue font-medium">{{ user.areas[0].name }}</p>
			<div v-if="user.areas.length <= 2">
				<p v-for="area in user.areas" :key="area.id" class="text-orange my-2">
					{{ area.name }}
				</p>
			</div>
			<div v-if="user.areas.length > 2">
				<p class="text-orange my-2">{{ user.areas[0].name }}</p>
				<p class="text-orange my-2">Altri {{ user.areas.length - 1 }}</p>
			</div>
			<p
				v-if="user.user_info"
				class="text-blue font-medium text-sm 2xl:text-md break-all"
			>
				{{ user.user_info.email }}
			</p>
			<p v-else class="text-blue font-medium break-all">{{ user.areas[0].email }}</p>
		</div>
	</div>
</template>

<script>
export default {
	name: "UserCard",

	props: {
		user: Object,
	},
};
</script>

<style></style>
