import Vue from "vue";
import VueRouter from "vue-router";
import cookies from "vue-cookies";
import paths from "./routes";
import Services from "@/services/index";
import store from "@/store";

Vue.use(VueRouter);

const router = new VueRouter({
    scrollBehavior() {
        return {x: 0, y: 0};
    },
    mode: "history",
    base: process.env.BASE_URL,
    linkActiveClass: "active",
    routes: paths,
});

router.beforeEach(async (to, from, next) => {
    const authorized = cookies.get("mv_token");
    if (!authorized) {
        window.location.replace("https://signin.multiversity.click/service/1");
    } else {
        if(!store.state.user.user){
            const response = await Services.getUserSession();
            store.dispatch('user/setUser', response);
        }
        next();
    }
});

export default router;
