<template>
  <div>
    <VuePerfectScrollbar
      class="w-full flex whitespace-nowrap pb-4"
      :settings="{ swipeEasing: true, useBothWheelAxes: true }"
    >
      <div
        class="w-auto inline-block bg-ticket-grey py-1 px-2.5 text-sm rounded-sm cursor-pointer mr-3 border border-gray-300"
        @click="$refs.createDivision.open()"
      >
        <img class="h-3 mb-0.5 inline-block" src="@/assets/icons/Più.svg" />
        <p class="inline-block ml-1 mr-2 2xl:mr-0 font-medium">Aggiungi settore</p>
      </div>
      <div
        @click="
          selectedDivision = 0;
          onClickDivision(0);
        "
        :class="
          this.selectedDivision == 0
            ? 'bg-orange text-white'
            : 'ticket-btn-gray-outline'
        "
        class="rounded-sm cursor-pointer mr-3 py-1 text-sm px-3"
      >
        Tutti
      </div>
      <div class="flex" v-if="divisions.data">
        <div
          v-for="division in divisions.data.division"
          :key="division.id"
          @click="
            selectedDivision = division;
            onClickDivision(division.id);
          "
          :class="
            selectedDivision.id === division.id
              ? 'bg-orange text-white pr-2'
              : 'ticket-btn-gray-outline px-5'
          "
          class="mr-3 rounded-sm cursor-pointer text-sm flex place-items-center"
        >
          <div
            v-if="selectedDivision.id == division.id"
            class="w-6 hover:bg-white border border-transparent hover:border-orange rounded-l-sm h-full flex place-items-center"
            @mouseleave="hover = false"
            @mouseenter="hover = true"
            v-title="'Modifica Settore'"
          >
            <img
              class="h-4 w-4 ml-2"
              :src="
                hover
                  ? require('@/assets/icons/edit-orange.svg')
                  : require('@/assets/icons/edit.svg')
              "
              @click.stop="$refs.createEditDivision.open()"
            />
          </div>
          <div class="ml-1">{{ division.name }}</div>
        </div>
      </div>
    </VuePerfectScrollbar>
    <modal-create-division
      ref="createDivision"
      :area="area.area_id"
      @division-create="getDivision"
    ></modal-create-division>
    <edit-division-modal
      ref="createEditDivision"
      :division="selectedDivision"
      @delete="getDivision"
    >
    </edit-division-modal>
  </div>
</template>

<script>
import Services from "@/services/index";
import ModalCreateDivision from "./ModalCreateDivision";
import EditDivisionModal from "./EditDivisionModal.vue";
import VuePerfectScrollbar from "vue-perfect-scrollbar";

export default {
  name: "DivisionsCard",
  components: {
    ModalCreateDivision,
    EditDivisionModal,
    VuePerfectScrollbar,
  },
  props: {
    area: Object,
  },
  data: () => ({
    divisions: [],
    selectedDivision: 0,
    hover: false,
  }),
  created() {
    this.getDivision();
  },
  methods: {
    async getDivision() {
      this.divisions = await Services.getAreaDivision({
        id: this.area.area_id,
      });
    },

    onClickDivision(division) {
      this.$emit("change-division", division);
    },

    addDivision(division) {
      this.divisions.push(division);
    },

    removeDivision(division) {
      const index = this.divisions.indexOf(division);
      this.divisions.splice(index, 1);
    },
  },
};
</script>
