import Vue from "vue";
import Vuex from "vuex";

import user from "@/store/modules/user";
import tickets from "@/store/modules/tickets";
import mobileMenu from "@/store/modules/mobileMenu";

Vue.use(Vuex);

export default new Vuex.Store({
  modules: {
    user,
    tickets,
    mobileMenu
  },
});
