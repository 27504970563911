<template>
    <div>
        <div
            v-if="!loading"
            class="grid grid-cols-1 sm:grid-cols-2 xl:grid-cols-3 gap-11 border-t-0 pt-5"
        >
            <div
                v-if="division"
                class="all-around-shadow rounded-lg cursor-pointer text-center p-5"
                @mouseenter="toggleAddDependeant()"
                @mouseleave="toggleAddDependeant()"
                @click="$refs.createDependant.open()"
            >
                <div class="2xl:pt-5">
                    <img
                        v-if="addDependant"
                        class="h-16 mx-auto"
                        src="@/assets/icons/nuovoTicketFilled.svg"
                    />
                    <img
                        v-else
                        class="h-16 mx-auto"
                        src="@/assets/icons/nuovoTicketFilledOpacity.svg"
                        @click="$refs.createDependant.open()"
                    />
                </div>
                <p
                    class="pt-5 font-medium"
                    :class="addDependant ? 'text-blue' : 'text-blue text-opacity-50'"
                >
                    Aggiungi nuovo dipendente
                </p>
            </div>
            <div v-for="aru in listUser.area_rel_user" :key="aru.id">
                <div class="all-around-shadow rounded-lg p-5">
                    <div class="relative flex flex-row">
                        <img
                            class="absolute right-1 top-2 h-4 w-4 cursor-pointer"
                            src="@/assets/icons/espandi.svg"
                            @click="getUserDetails(aru.user.id)"
                        />
                        <img
                            v-if="aru.user.user_info.picture_url"
                            class="w-10 h-10 2xl:h-14 2xl:w-14 rounded-full shadow-orange"
                            :src="aru.user.user_info.picture_url"
                        />
                        <div
                            v-else
                            class="flex flex-shrink-0 h-10 w-10 2xl:h-14 2xl:w-14 rounded-full shadow-orange bg-gray-200 text-white font-medium justify-center place-items-center text-xl 2xl:text-3xl"
                        >
                            {{
                                aru.user.user_info.firstname.slice(0, 1)
                            }}{{ aru.user.user_info.lastname.slice(0, 1) }}
                        </div>
                        <div class="ml-5">
							<span
                                class="font-medium text-blue text-md 2xl:text-xl 2xl:whitespace-nowrap break-words"
                            >{{ aru.user.user_info.firstname }}
								{{ aru.user.user_info.lastname }}</span
                            >
                            <p class="font-bold text-orange text-sm 2xl:text-md">
                                {{ aru.acl.role_it }}
                            </p>
                        </div>
                    </div>
                    <div v-if="aru.user" class="text-center mt-2">
                        <p class="text-sm 2xl:text-lg text-blue font-medium">
                            {{ aru.user.user_info.email }}
                        </p>
                    </div>
                    <div class="inline-flex w-full mt-3">
                        <button :disabled="loading" class="ml-auto focus:outline-none">
                            <img
                                class="h-5 w-5"
                                v-title="
									aru.user.division.length > 1 && division
										? 'Rimuovi dal Settore'
										: 'Rimuovi dall\'Area'
								"
                                @click="
									aru.user.division.length > 1
										? division
											? $refs.deleteUserDivisionModal.open()
											: $refs.deleteUserAreaModal.open()
										: $refs.deleteUserAreaModalWarning.open();
									selectedUser = aru.user.id;
								"
                                src="@/assets/icons/elimina.svg"
                            />
                        </button>
                        <button
                            :disabled="loading || aru.acl.level == 0"
                            v-if="aru.set_manager == 1"
                            v-title="'Manager'"
                            class="cursor-pointer ml-5 focus:outline-none"
                            @click="
								selectedUserRole = 'manager';
								selectedUser = aru.user.id;
								updateUserAcl();
							"
                        >
                            <img
                                class="h-5 w-5"
                                :src="
									aru.acl.level == 0
										? require('@/assets/icons/manager.svg')
										: require('@/assets/icons/manager_gray.svg')
								"
                            />
                        </button>
                        <button
                            :disabled="loading || aru.acl.level == 1"
                            v-if="aru.set_coordinator == 1"
                            v-title="'Coordinatore'"
                            class="cursor-pointer ml-5 focus:outline-none"
                            @click="
								selectedUserRole = 'coordinator';
								selectedUser = aru.user.id;
								updateUserAcl();
							"
                        >
                            <img
                                class="h-7 w-7"
                                :src="
									aru.acl.level == 1
										? require('@/assets/icons/coordinatore.svg')
										: require('@/assets/icons/coordinatore-gray.svg')
								"
                            />
                        </button>
                        <button
                            :disabled="loading || aru.acl.level == 2"
                            v-if="aru.set_user == 1"
                            v-title="'Dipendente'"
                            class="cursor-pointer ml-5 focus:outline-none"
                            @click="
								selectedUserRole = 'employee';
								selectedUser = aru.user.id;
								updateUserAcl();
							"
                        >
                            <img
                                class="h-5 w-5"
                                :src="
									aru.acl.level == 2
										? require('@/assets/icons/dipendente.svg')
										: require('@/assets/icons/dipendente-gray.svg')
								"
                            />
                        </button>
                    </div>
                </div>
            </div>
        </div>
        <div class="flex h-44 justify-center place-items-center" v-else>
            <spinner class="mt-5"></spinner>
        </div>

        <prompt-modal
            ref="deleteUserAreaModalWarning"
            :title="'Attenzione'"
            :message="
				'Questo è l\'ultimo settore associato all\'utente. Procedendo, l\'utente verrà rimosso dall\'area.'
			"
            @action="deleteUserArea()"
        ></prompt-modal>

        <prompt-modal
            ref="deleteUserAreaModal"
            :title="'Conferma Eliminazione'"
            :message="'Procedendo, l\'utente verrà rimosso dall\'area.'"
            @action="deleteUserArea()"
        ></prompt-modal>

        <prompt-modal
            ref="deleteUserDivisionModal"
            :title="'Conferma Eliminazione'"
            :message="
				'Sei sicuro di voler rimuovre l\'utente selezionato dal settore?'
			"
            @action="deleteUserDivision()"
        ></prompt-modal>

        <prompt-modal
            ref="updateAclUserModal"
            :title="'Conferma Ruolo'"
            :message="
				'Sei sicuro di voler cambiare ruolo dell\'utente nell\' area selezionata?'
			"
            @action="updateUserAcl()"
        ></prompt-modal>
        <modal-create-dependant
            ref="createDependant"
            :area="area.area.id"
            :division="division"
            :type="'division'"
            @user-create="getCreateDependant"
        ></modal-create-dependant>

        <user-modal ref="userModal"></user-modal>
    </div>
</template>

<script>
import PromptModal from "./PromptModal.vue";
import Services from "@/services/index";
import ModalCreateDependant from "./ModalCreateDependant";
import UserModal from "@/components/UserModal.vue";
import Spinner from "@/components/Spinner.vue";

export default {
    name: "DependantsCard",

    components: {
        ModalCreateDependant,
        PromptModal,
        UserModal,
        Spinner,
    },

    props: {
        area: Object,
        division: Number,
    },

    data() {
        return {
            loading: false,
            addDependant: false,
            listUser: [],
            selectedUser: 0,
            selectedUserRole: "",
        };
    },

    created() {
        this.getAreaManagement();
    },

    methods: {
        toggleAddDependeant() {
            this.addDependant = !this.addDependant;
            this.updateTotalUser();
        },

        async getUserDetails(id) {
            const response = await Services.getUserDetails(id);
            this.$refs.userModal.open(response);
        },

        async getAreaManagement() {
            this.listUser = [];
            this.loading = true;
            try {
                this.listUser = await Services.getAreaManagement({
                    area: this.area.area.id,
                    division: this.division ? this.division : 0,
                });
                this.updateTotalUser();
            } catch (error) {
                console.log(error.response);
            }
            this.loading = false;
        },

        async getCreateDependant() {
            await this.getAreaManagement();
            await this.updateTotalUser();
        },

        async deleteUserArea() {
            await Services.deleteUserArea({
                id: this.area.area.id,
                user: this.selectedUser,
            });
            this.$refs.deleteUserAreaModal.close();
            this.$refs.deleteUserAreaModalWarning.close();
            await this.getAreaManagement();
            await this.updateTotalUser();
        },

        async deleteUserDivision() {
            try {
                await Services.deleteUserDivision({
                    user_division: [{user: this.selectedUser, division: this.division}],
                });
                this.$refs.deleteUserDivisionModal.close();
                this.$toast.success("Utente rimosso dal settore", {
                    position: "bottom-right",
                });
                this.getAreaManagement();
            } catch (error) {
                console.log(error);
            }
        },

        async updateUserAcl() {
            this.loading = true;
            try {
                await Services.updateUserAcl({
                    area: this.area.area.id,
                    user: this.selectedUser,
                    role: this.selectedUserRole,
                });
                this.$refs.updateAclUserModal.close();
                this.getAreaManagement();
            } catch (error) {
                console.log(error);
            }
            this.loading = false;
        },

        async updateTotalUser() {
            this.$emit("total_user", {
                area: this.area.area.id,
                num_user: this.listUser.area_rel_user.length,
            });
        },
    },

    watch: {
        division() {
            this.getAreaManagement();
        },
    },
};
</script>

<style></style>
