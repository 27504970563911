<template>
	<Modal
		card-class="w-full h-auto sm:w-5/6 md:w-1/2 lg:5/6 py-4 lg:py-0 rounded-0 overflow-hidden"
		ref="createFilterModal"
	>
		<template #header="{close}">
			<div class="flex justify-end 2xl:p-6 lg:p-5 px-2 items-center leading-none">
				<h1 class="2xl:text-3xl lg:text-2xl mx-auto font-semibold">
          Filtro Ricerca
        </h1>
				<span
					@click.prevent="close"
					@click="resetFilters()"
					class="cursor-pointer text-orange text-2xl"
				>
					<svg
						xmlns="http://www.w3.org/2000/svg"
						class="w-4 h-4"
						viewBox="0 0 20.086 20.086"
					>
						<g
							id="Raggruppa_2175"
							data-name="Raggruppa 2175"
							transform="translate(1.414 1.414)"
						>
							<line
								id="Linea_10"
								data-name="Linea 10"
								x2="17.258"
								y2="17.258"
								fill="none"
								stroke="#000000"
								stroke-linecap="round"
								stroke-width="2"
							/>
							<line
								id="Linea_11"
								data-name="Linea 11"
								x1="17.258"
								y2="17.258"
								fill="none"
								stroke="#000000"
								stroke-linecap="round"
								stroke-width="2"
							/>
						</g>
					</svg>
				</span>
			</div>
		</template>
		<template #body>
			<div class="flex flex-wrap 2xl:mt-8 lg:mt-2 mx-6 justify-center">
				<div class="flex w-full px-4 text-white">
					.
					<span v-if="restError != ''" class="text-red text-xs">
						{{ restError }}
					</span>
				</div>
				<div class="flex w-full px-4 mb-4">
					<input
						class="w-full 2xl:h-10 lg:h-7 rounded-md pl-1 lg:pl-4 text-gray 2xl:text-lg lg:text-sm bg-lightgray focus:outline-none employee-search"
						type="text"
						placeholder="Cerca per titolo e testo"
						v-model="searchByString"
						@keydown.enter="searchTicketByString(searchByString)"
					/>
				</div>
				<div class="w-1/2 flex px-4">
					<select
						class="w-full 2xl:h-10 lg:h-8 rounded-md pl-1 lg:pl-4 text-gray 2xl:text-lg lg:text-sm bg-lightgray focus:outline-none"
						v-model="priority"
					>
						<option class="select-none" value="" selected>
							Priorità
						</option>
						<option
							v-for="priority in priorities"
							:key="priority.id"
							:value="priority.id"
						>
							{{ priority.priority }}
						</option>
					</select>
				</div>
				<div class="w-1/2 flex px-4">
					<select
						class="w-full 2xl:h-10 lg:h-8 rounded-md pl-1 lg:pl-4 text-gray 2xl:text-lg lg:text-sm bg-lightgray focus:outline-none"
						v-model="status"
					>
						<option class="select-none" value="" selected>
							Stato
						</option>
						<option
							v-for="status in statusList"
							:key="status.id"
							:value="status.id"
						>
							{{ status.status }}
						</option>
					</select>
				</div>
			</div>
			<div class="flex mt-4 mx-6 justify-center">
				<!--<div class="w-1/2 flex px-4">
          <select :disabled="user.user_area_coordinator_or_manager.length === 0"
            class="w-full h-10 border-0 rounded-md pl-4 text-gray text-lg bg-lightgray focus:outline-none"
            v-model="processingUser"
          >
            <option class="select-none" value="" selected>
              Operatore assegnato
            </option>
            &lt;!&ndash; manca in optgroup <optgroup class="bg-lightorange text-orange font-medium" v-for="userArea in userAreas" :key="userArea"
            :label="'[' + userArea +']'"&ndash;&gt;
            <option
              class="bg-lightgray text-black"
              v-for="user in users"
              :key="user.id"
              :value="user.id"
            >
              {{ user.user_info.name }}
            </option>
          </select>
        </div>-->
				<div class="w-1/2 flex px-4">
					<div class="w-full h-10 z-20">
						<input
							class="w-full 2xl:h-10 lg:h-8 pl-1 lg:pl-4 rounded-md text-gray 2xl:text-lg lg:text-sm bg-lightgray focus:outline-none"
							type="text"
							placeholder="Operatore assegnato"
							v-model="processingUser"
							@input="searchProcessingUser"
						/>
						<ul v-show="displayProcessingUsers" class="autocomplete-results">
							<li
								v-for="user in filteredProcessingUsers"
								:key="user.id"
								class="autocomplete-result"
								:value="user.id"
								@click="setResult(user.user_info.name, user.id)"
							>
								{{ user.user_info.name }}
							</li>
						</ul>
					</div>
				</div>
				<div class="w-1/2 flex px-4">
					<div class="w-full h-10 z-20">
						<input
							class="w-full 2xl:h-10 lg:h-8 pl-1 lg:pl-4 rounded-md text-gray 2xl:text-lg lg:text-sm bg-lightgray focus:outline-none"
							type="text"
							placeholder="Cerca mittente"
							v-model="searchUser"
							@input="onChange"
						/>
						<ul v-show="isOpen" class="autocomplete-results">
							<li
								v-for="result in filteredResults"
								:key="result.id"
								class="autocomplete-result"
								@click="setResult(result.user_info.name)"
							>
								{{ result.user_info.name }}
							</li>
						</ul>
					</div>
				</div>
			</div>
			<div class="flex mt-4 mx-6 justify-center">
				<div class="w-1/2 flex px-4">
					<date-picker
						class="w-full"
						placeholder="Data apertura ticket"
						v-model="openingDate"
					>
						<template v-slot:icon-calendar>
							<img src="@/assets/icons/calendario.svg" />
						</template>
					</date-picker>
				</div>
				<div class="w-1/2 flex px-4">
					<date-picker
						class="w-full"
						placeholder="Data presa in carico del ticket"
						v-model="processingDate"
					>
						<template v-slot:icon-calendar>
							<img src="@/assets/icons/calendario.svg" />
						</template>
					</date-picker>
				</div>
			</div>
			<div class="flex mt-4 mx-6 justify-center">
				<div class="w-1/2 flex px-4">
					<select
						class="w-full 2xl:h-10 lg:h-8 rounded-md pl-1 lg:pl-4 text-gray 2xl:text-lg lg:text-sm bg-lightgray focus:outline-none"
						v-model="area_id"
						@change="getUserDivisions(area_id)"
					>
						<option class="select-none" value="" selected>
							Seleziona area d'interesse
						</option>
						<option
							v-for="user in user.user_rel_area"
							:key="user.id"
							:value="user.area_id"
						>
							{{ user.area.name }}
						</option>
					</select>
				</div>
				<div class="w-1/2 flex px-4">
					<select
						class="w-full 2xl:h-10 lg:h-8 rounded-md pl-1 lg:pl-4 text-gray 2xl:text-lg lg:text-sm bg-lightgray focus:outline-none"
						v-model="section_id"
						:disabled="!area_id"
					>
						<option class="select-none" value="" selected>
							Seleziona settore d'interesse
						</option>
						<option
							v-for="section in userSections"
							:key="section.sectionId"
							:value="section.sectionId"
						>
							{{ section.sectionName }}
						</option>
					</select>
				</div>
			</div>
			<div class="flex w-1/2 mt-4 mx-6 justify-start">
				<div class="px-4 justify-between w-full">
					<div class="flex flex-shrink-0 w-full md:w-1/2">
						<label class="flex font-medium 2xl:text-base lg:text-sm text-blue whitespace-nowrap mr-4">
							In evidenza
						</label>
						<div
							class="flex flex-shrink-0 w-12 h-6 mr-6 rounded-full p-1
                        cursor-pointer transition"
							:class="
								toggleFavorite.clicked
									? 'bg-orange justify-end'
									: 'bg-lightgray'
							"
							@click="
								(toggleFavorite.clicked = !toggleFavorite.clicked),
									(toggleFavorite.value = 1)
							"
						>
							<div class="bg-white w-4 h-4 rounded-full shadow-lg"></div>
						</div>
					</div>
					<div class="flex w-full mt-3 md:w-1/2">
						<label class="flex font-medium 2xl:text-base lg:text-sm text-blue mr-4">
							Solleciti
						</label>
						<div
							class="flex flex-shrink-0 w-12 h-6 rounded-full p-1
                        cursor-pointer transition duration-500 ease-in-out"
							:class="
								toggleHurry.clicked ? 'bg-orange justify-end' : 'bg-lightgray'
							"
							@click="
								(toggleHurry.clicked = !toggleHurry.clicked),
									(toggleHurry.value = 1)
							"
						>
							<div class="bg-white w-4 h-4 rounded-full shadow-md"></div>
						</div>
					</div>
				</div>
			</div>
			<!-- TODO: filtri personalizzati -->
			<!--<div class="flex mt-4 mx-6 justify-center">
        <div class="flex w-1/2 px-4 h-10 items-center">
          <span class="w-full pl-4 text-lg"> Crea filtro personalizzato</span>
          <div class="flex justify-end w-1/2 my-auto">
            <div
              class="flex w-16 h-6 m-0 rounded-full p-1
                        cursor-pointer transition duration-500 ease-in-out"
              :class="
                toggleSave.clicked ? 'bg-orange justify-end' : 'bg-lightgray'
              "
              @click="toggleSave.clicked = !toggleSave.clicked"
            >
              <div class="bg-white w-4 h-4 rounded-full shadow-lg"></div>
            </div>
          </div>
        </div>
        <div class="flex w-1/2 px-4">
          <div v-if="toggleSave.clicked" class="w-full h-10 z-20">
            <input
              class="w-full h-10 pl-4 rounded-md text-gray text-lg bg-lightgray focus:outline-none"
              type="text"
              placeholder="Inserisci nome filtro"
              v-model="filterName"
            />
          </div>
        </div>
      </div>-->
			<div class="flex justify-end 2xl:my-10 lg:my-4 my-2 mx-10">
				<button
					class="2xl:w-20 2xl:h-10 lg:w-16 lg:h-8 px-2 py-1 lg:p-0 text-blue bg-lightgray rounded-md 2xl:text-lg lg:text-base mr-4 font-medium bg-white focus:outline-none"
					@click="resetFilters()"
				>
					Reset
				</button>
				<button
					:disabled="loading"
					class="2xl:w-28 2xl:h-10 lg:w-24 lg:h-8 px-2 py-1 lg:p-0 bg-orange text-white 2xl:text-lg lg:text-base rounded-md font-medium focus:outline-none"
					@click="filterTickets"
				>
					<!--<span v-if="toggleSave.clicked"> Salva filtro personalizzato </span>-->
					Filtra
				</button>
			</div>
		</template>
	</Modal>
</template>

<script>
import Modal from "./Modal";
import Services from "../services";
import DatePicker from "vue2-datepicker";
import "vue2-datepicker/index.css";
import "vue2-datepicker/locale/it";
import moment from "moment";
import { mapActions, mapState } from "vuex";

export default {
	name: "FilterModal.vue",

	components: {
		Modal,
		DatePicker,
	},

	data: () => ({
		searchByString: "",
		priority: "",
		status: "",
		from_user: "",
		processingUser: "",
		processingUserId: 0,
		sender: "",
		area_id: "",
		section_id: "",
		division_id: [],
		department_id: "",
		openingDate: null,
		processingDate: null,
		userAreas: [],
		users: [],
		loading: false,

		//Variabili associate ai toggle switch
		toggleFavorite: { clicked: false, value: 0 }, //preferiti
		toggleHurry: { clicked: false, value: 0 }, //solleciti
		//toggleSave: { clicked: false, value: 0 }, //toggle per filtro personalizzato
		filterName: "", //nome assegnato dall'utente al filtro personalizzato
		userSections: [],

		//Variabili per autocomplete
		displayProcessingUsers: false,
		isOpen: false,
		searchUser: "",
		userList: [], //Array che uso per fare l'autocomplete - viene popolato in getAllUsers
		results: [],

		restError: "",
	}),

	computed: {
		...mapState("user", { user: "user", areas: "areas" }),
		...mapState("tickets", { priorities: "priorities" }),

		filteredResults: function() {
			return this.users.filter((item) =>
				item.user_info.name
					.toLowerCase()
					.includes(this.searchUser.toLowerCase())
			);
		},

		filteredProcessingUsers: function() {
			return this.users.filter((item) =>
				item.user_info.name
					.toLowerCase()
					.includes(this.processingUser.toLowerCase())
			);
		},
	},

	created() {
		this.getStatus();
	},

	methods: {
		...mapActions({ setTickets: "tickets/setTickets" }),
		...mapActions({ setFilters: "tickets/setFilters" }),
		...mapActions({ setLastPage: "tickets/setLastPage" }),

		open() {
			this.$refs.createFilterModal.open();
		},
		close() {
			this.$refs.createFilterModal.close();
			this.resetFilters();
		},
		resetFilters() {
			this.searchByString = "";
			this.priority = "";
			this.status = "";
			this.from_user = "";
			this.processingUser = "";
			this.sender = "";
			this.area_id = "";
			this.section_id = "";
			this.division_id = [];
			this.openingDate = null;
			this.processingDate = null;
			this.isOpen = false;
			this.searchUser = "";
			this.results = [];
			this.toggleFavorite.clicked = false;
			this.toggleFavorite.value = 0;
			this.toggleHurry.clicked = false;
			this.toggleHurry.value = 0;
			this.restError = "";
			//per filtri personalizzati
			//this.toggleSave.clicked = false;
			//this.toggleSave.value = 0;
		},

		async searchTicketByString(str) {
			this.loading = true;
			try {
				const response = await Services.searchTicket({ text: str, filter: 1 });
				this.setFilters({ text: str });
				this.setLastPage(parseInt(response.data.ticket.last_page));
				this.setTickets(response.data.ticket.data);
				if (this.$route.path !== "/filterResults") {
					this.$router.push("/filterResults/:search");
				}
				this.close();
				this.loading = false;
			} catch (error) {
				console.log(error);
				this.restError = "";
				this.restError = "Impossibile effettuare la ricerca.";
				this.loading = false;
			}
		},

		async getUserDivisions(areaId) {
			this.userSections = [];
			try {
				const response = await Services.getUserDivisions({
					user: this.user.id,
					area: areaId,
				});
				for (let i in response) {
					this.userSections.push({
						sectionId: response[i].id,
						sectionName: response[i].name,
					});
				}
			} catch (error) {
				this.restError = "";
				this.restError =
					"Errore nel caricamento dei settori associati all'area selezionata.";
			}
		},

		populateSectionArray(sectionId) {
			//La rest vuole l'id del settore in un array
			this.division_id = [];
			this.division_id.push(sectionId);
		},

		searchProcessingUser() {
			this.displayProcessingUsers = true;
		},

		onChange() {
			this.isOpen = true;
		},

		setResult(result, id) {
			if (this.displayProcessingUsers && id) {
				this.processingUser = result;
				this.processingUserId = id;
				this.displayProcessingUsers = false;
			} else if (this.isOpen) {
				this.searchUser = result;
				this.from_user = result;
				this.isOpen = false;
			}
		},

		async getStatus() {
			try {
				const response = await Services.getStatus();
				this.statusList = response;
			} catch (error) {
				console.log(error);
				this.restError = "";
				this.restError = "Errore nel caricamento degli stati del ticket.";
			}
		},
		async getAllUsers() {
			try {
				const response = await Services.getAllUsers(
					this.user.user_area_coordinator_or_manager.map((x) => x.area_id)
				);
				for (let i in response.data.user) {
					if (response.data.user[i].user_info)
						this.users.push(response.data.user[i]);
				}
			} catch (error) {
				console.log(error);
				this.restError = "";
				this.restError = "Errore nel caricamento degli utenti.";
			}
		},

		async filterTickets() {
			this.loading = true;
			let request = {
				text: this.searchByString,
				priority: this.priority,
				status: this.status,
				processing_user: this.processingUserId,
				from_user: this.searchUser,
				area: this.area_id,
				//division: this.division_id,
				division: [this.section_id],
				start_date: this.openingDate,
				processing_date: this.processingDate,
				favorite: this.toggleFavorite.value,
				reminder: this.toggleHurry.value,
			};
			//I filtri che non vengono settati non vanno passati
			if (this.searchByString == "") {
				delete request.text;
			}
			if (this.priority === "") {
				delete request.priority;
			}
			if (this.status === "") {
				delete request.status;
			}
			if (this.processingUser === "") {
				delete request.processing_user;
			}
			if (this.searchUser === "") {
				delete request.from_user;
			}
			if (this.area_id === "") {
				delete request.area;
			}
			if (this.section_id.length == 0 || this.section_id == "") {
				delete request.division;
			}
			if (this.openingDate === null) {
				delete request.start_date;
			} else {
				request.start_date = moment(
					this.openingDate,
					"YYYY-MM-DD, HH:mm:ss"
				).format("YYYY-MM-DD");
			}
			if (this.processingDate === null) {
				delete request.processing_date;
			} else {
				request.processing_date = moment(
					this.processingDate,
					"YYYY-MM-DD, HH:mm:ss"
				).format("YYYY-MM-DD");
			}
			if (this.toggleFavorite.clicked == false) {
				delete request.favorite;
			} else {
				this.toggleFavorite.value = 1;
			}
			if (this.toggleHurry.clicked == false) {
				delete request.reminder;
			} else {
				this.toggleHurry.value = 1;
			}
			/* TODO: salvataggio dei filtri
      if (this.toggleSave.clicked) {
        let saveFilter = {
          name: this.filterName,
          priority: this.priority,
          status: this.status,
          processing_user: this.processingUser,
          from_user: this.from_user,
          creation_date: this.openingDate,
          processing_date: this.processingDate,
          to_area: this.area_id,
          favorite: this.toggleFavorite.value,
          remind: this.toggleHurry.value,
        };
        if (this.priority === "") {
          delete saveFilter.priority;
        }
        if (this.status === "") {
          delete saveFilter.status;
        }
        if (this.processingUser === "") {
          delete saveFilter.processing_user;
        }
        if (this.searchUser === "") {
          delete saveFilter.from_user;
        }
        if (this.area_id === "") {
          delete saveFilter.to_area;
        }
        if (this.openingDate === null) {
          delete saveFilter.creation_date;
        } else {
          saveFilter.creation_date = moment(
            this.openingDate,
            "YYYY-MM-DD, HH:mm:ss"
          ).format("YYYY-MM-DD");
        }
        if (this.processingDate === null) {
          delete saveFilter.processing_date;
        } else {
          saveFilter.processing_date = moment(
            this.processingDate,
            "YYYY-MM-DD, HH:mm:ss"
          ).format("YYYY-MM-DD");
        }
        if (this.toggleFavorite.clicked == false) {
          delete saveFilter.favorite;
        } else {
          this.toggleFavorite.value = 1;
        }
        if (this.toggleHurry.clicked == false) {
          delete saveFilter.remind;
        } else {
          this.toggleHurry.value = 1;
        }
        const response = Services.saveFilters(saveFilter);
        console.log(response);
      }*/
			try {
				const response = await Services.searchTicket(request);
				this.setFilters(request);
				this.setLastPage(parseInt(response.data.ticket.last_page));
				this.setTickets(response.data.ticket.data);
				this.close();
				this.loading = false;
			} catch (error) {
				console.log(error);
				this.restError = "";
				this.restError = "Impossibile effettuare la ricerca.";
				this.loading = false;
			}

			if (this.$route.path !== "/filterResults") {
				let type = "filters";
				this.$router.push("/filterResults/" + type);
			}
		},
	},
	watch: {
		$route() {
			if (this.user.user_area_coordinator_or_manager.length > 0) {
				this.users = [];
				this.getAllUsers();
			}
		},
	},
};
</script>

<!--<style lang="scss">
@import "~vue2-datepicker/scss/index.scss";

.calendar-bgColor {
	color: #f3f4f8;
}
</style>-->

<style scoped>
.calendar-bgColor {
    color: #f3f4f8;
}
.autocomplete-results {
	background-color: white;
	padding: 0;
	margin: 0;
	border: 1px solid #eeeeee;
	width: 344px;
	min-height: 1em;
	max-height: 6em;
	overflow: auto;
}

.autocomplete-result {
	background-color: white;
	text-align: left;
	padding: 4px 2px;
	cursor: pointer;
}

.autocomplete-result:hover {
	background-color: #fd7f50;
	color: white;
}
</style>