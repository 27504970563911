<template>
  <div>
    <div>
      <TicketsList class="lg:pb-6 rounded-tr-3xl"></TicketsList>
    </div>
  </div>
</template>

<script>
import TicketsList from "../components/TicketsList";

import {mapActions, mapState} from "vuex";

export default {
  name: "Home",

  created() {
    if (this.ticketStatus == null) {
      this.setTicketStatus(5);
    }
  },

  components: {
    TicketsList
  },

  computed: {
    ...mapState("user", { tickets: "tickets" }),
    ...mapState("user", { ticketStatus: "ticketStatus"})
  },

  methods: {
    ...mapActions({ setTicketStatus: "user/setTicketStatus" }),
  },
};
</script>
