<template>
  <div>
    <div class="inline text-lg px-4 lg:px-0 font-bold text-center uppercase">
      Situazione area -
      <!-- se specifico anche il settore " - settore ...-->
      <span class="text-orange px-4 lg:px-0 flex lg:inline ">
        {{ areaName }}
      </span>
      <span v-if="divisionName != ''"> - </span>
      <span v-if="this.$route.params.sectorId">
        {{ divisionName }}
      </span>
    </div>
    <TicketsList></TicketsList>
    <prompt-modal
        ref="errorModal"
        :title="'Errore'"
        :message="errorMessage"
        :confirm="false"
    >
    </prompt-modal>
  </div>
</template>

<script>
import TicketsList from "@/components/TicketsList";
import Services from "@/services";
import PromptModal from "@/components/PromptModal";
export default {
  name: "MoreDepartmentTickets",
  components: { TicketsList, PromptModal },
  data: () => ({
    areaName: "",
    divisionName: "",
    errorMessage: ""
  }),
  created() {
    this.getAreaManagement();
    this.getDivision();
  },
  methods: {
    async getAreaManagement() {
      try {
        const response = await Services.getAreaManagement({
          area: this.$route.params.id,
        });
        this.areaName = response.name; //Nome dell'area che mostro nel menu - me lo prendo in base al parametro dell'url
      } catch (error) {
        console.log(error);
        this.$refs.errorModal.open();
        this.$refs.errorModal.close();
        this.errorMessage = "";
        this.errorMessage = "Errore nel caricamento dell'area specificata."
      }
    },
    async getDivision() {
      try {
        if (this.$route.params.sectorId) {
          const response = await Services.getDivision(this.$route.params.sectorId);
          this.divisionName = response.data.division[0].name;
        }
      } catch (error) {
        this.$refs.errorModal.open();
        this.$refs.errorModal.close();
        this.errorMessage = "";
        this.errorMessage = "Errore nel caricamento del settore specificato."
        console.log(error);
      }
    },
  },
};
</script>

<style scoped></style>
