<template>
  <div class="bg-white rounded-2xl lg:rounded-lg">
    <div
      class="border-px border-gray-300 "
      :class="showContent ? 'rounded-t-2xl lg:rounded-t-lg' : 'rounded-2xl lg:rounded-lg'"
    >
      <div class="flex w-full h-14 py-3 border-gray-300 items-center">
        <span class="lg:text-xl w-1/2 lg:w-auto ml-2 text-blue uppercase font-bold truncate">
          {{ area.area.name }}
        </span>
        <span
          v-if="total_user !== null && area.area_id == total_user.area"
          class="ml-4 text-orange font-medium text-sm lg:text-base truncate"
        >
          {{ total_user.num_user }} Dipendenti
        </span>
        <span v-else class="ml-4 text-orange font-medium text-sm lg:text-base truncate">
          {{ area.num_user }} Dipendenti
        </span>
        <img
          v-if="showContent"
          @click="showContent = false"
          class="cursor-pointer ml-auto mr-4 h-3"
          :src="require('@/assets/icons/freccia-su.svg')"
        />
        <img
          v-else
          @click="showContent = true"
          class="cursor-pointer ml-auto mr-4 h-3"
          :src="require('@/assets/icons/freccia-giu.svg')"
        />
      </div>
    </div>
    <div v-show="showContent" class="border-px border-t-0 p-5">
      <divisions-card :area="area" @change-division="changeDivision">
      </divisions-card>
      <dependants-card
        :area="area"
        :division="division"
        @total_user="getTotalUser"
      ></dependants-card>
    </div>
  </div>
</template>

<script>
import DivisionsCard from "@/components/DivisionsCard.vue";
import DependantsCard from "@/components/DependantsCard.vue";

export default {
  name: "AreaAccordion",

  components: {
    DivisionsCard,
    DependantsCard,
  },

  props: {
    area: Object,
    open: Boolean,
  },

  data: () => ({
    division: null,
    total_user: null,
  }),

  computed: {
    showContent: {
      get: function() {
        return this.open;
      },
      set: function() {
        this.open = !this.open;
      },
    },
  },

  methods: {
    changeDivision(value) {
      this.division = value;
    },
    getTotalUser(value) {
      this.total_user = value;
    },
  },
};
</script>

<style></style>
