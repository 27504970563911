<template>
	<div>
		<div
			:class="
				message.user.user_info.id == user.user_info.id
					? 'bg-orange bg-opacity-10 rounded-lg p-3 lg:max-w-max ml-auto'
					: 'bg-gray-100 p-3 rounded-lg lg:max-w-max'
			"
		>
			<div class="flex flex-row justify-between">
				<div class="font-bold text-xs sm:text-base">
					{{ message.user.user_info.name }}
				</div>
				<div class="font-medium text-orange text-xs sm:text-base ml-20">
					{{ timeFormatted }}
				</div>
			</div>
			<p class="text-xs sm:text-base mt-1">
				{{ message.text }}
			</p>
			<div
				class="inline-block w-56 truncate mt-2 mr-2 bg-white border-px border-orange rounded-md text-orange py-0.5 sm:py-2 px-2 cursor-pointer"
				v-for="attachment in message.attachments"
				:key="attachment.id"
			>
				<a class="text-xs sm:text-base" :href="attachment.url" target="_blank">
					{{ attachment.filename }}
				</a>
			</div>
		</div>
	</div>
</template>

<script>
import moment from "moment";
import { mapState } from "vuex";

export default {
	name: "ChatMessage",

	props: {
		time: String,
		message: Object,
	},

	computed: {
		...mapState("user", { user: "user" }),

		timeFormatted: function() {
			return moment(this.time, "YYYY/MM/DD, HH:mm:ss").format(
				"DD MMM YYYY - HH:mm"
			);
		},
	},
};
</script>

<style></style>
