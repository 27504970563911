<template>
    <div class="mx-2 lg:mx-0">
        <div class="block ml-auto w-11/12 text-white">
            .
            <span v-if="mandatoryFields === false" class="text-red -ml-2 text-xs">
            * Campo obbligatorio
          </span>
            <span v-else-if="restError != ''" class="text-red -ml-2 text-xs">
            {{ restError }}
          </span>
        </div>
        <input
            class="block w-full lg:w-10/12 h-10 mx-auto rounded-md lg:pt-1 pl-4 lg:text-lg overflow-auto bg-lightgray focus:outline-none"
            :class="!mandatoryTitle ? 'border border-red' : ''"
            type="text"
            placeholder="Titolo"
            v-model="data.title"
            required
        />
        <textarea
            class="block w-full lg:w-10/12 h-20 lg:h-30 mx-auto rounded-md pt-1 pl-4 resize-none lg:text-lg overflow-auto mt-4 lg:pb-20 bg-lightgray focus:outline-none"
            :class="!mandatoryDescription ? 'border border-red' : ''"
            type="text"
            placeholder="Descrizione"
            v-model="data.description"
            required
        />
        <div class="text-center mx-auto">
            <select
                class="w-full lg:w-10/12 inline h-10 rounded-md mt-4 pl-4 mr-1 text-gray lg:text-lg bg-lightgray focus:outline-none"
                :class="!mandatoryArea ? 'border border-red' : ''"
                @change="getAllAreaDivisions(data.area)"
                v-model="data.area"
                required
            >
                <option class="select-none" value="0" disabled selected
                >Seleziona area d'interesse
                </option>
                <option v-for="area in areas" :key="area.id" :value="area.id"
                >{{ area.name }}
                </option>
            </select>

            <select
                :disabled="divisions.length <= 0"
                class="w-full lg:w-7/12 h-10 rounded-md mt-4 pl-4 mr-1 text-gray lg:text-lg bg-lightgray focus:outline-none"
                v-model="selectedDivision"
            >
                <option class="select-none" value="0" selected
                >Seleziona un settore (opzionale)
                </option>
                <option
                    v-for="division in divisions"
                    :key="division.id"
                    :value="division.id"
                >{{ division.name }}
                </option>
            </select>
            <select
                class="w-full lg:w-3/12 h-10 rounded-md mt-4 pl-4 text-gray lg:text-lg bg-lightgray focus:outline-none"
                v-model="data.priority"
                required
            >
                <option value="" hidden selected
                >{{ priorities[1].priority }}
                </option>
                <option value="" disabled>Priorità</option>

                <option
                    v-for="priority in priorities"
                    :key="priority.id"
                    :value="priority.id"
                >
                    {{ priority.priority }}
                </option>
            </select>
        </div>
        <input
            class="hidden w-10/12 h-24"
            ref="input"
            type="file"
            name="image"
            accept="/*"
            multiple
            @change="setAttachment"
        />
        <div
            class="block text-center mx-auto w-full lg:w-10/12 rounded-lg mt-4 border-dashed border-2 border-orange focus:outline-none py-3 lg:py-5"
            @drop="setAttachment"
        >
            <p class="flex place-items-center justify-center">
                <img class="inline w-5 h-5" src="@/assets/icons/allegato.svg" />
                <span class="font-normal text-center text-blue text-md">
              Trascina qui il tuo file
            </span>
            </p>
            <p class="text-orange lg:text-lg text-center">oppure</p>
            <button
                class="mt-2 bg-gray-100 border-px border-gray-300 rounded-md px-3 py-1 text-sm focus:outline-none"
                @click="showFileChooser"
            >
                Seleziona un file
            </button>
        </div>
        <VuePerfectScrollbar style="margin-right: 60px; margin-top: 10px">
            <div
                v-if="files.length > 0"
                class="flex max-h-32 mt-0 lg:mt-4 px-0 lg:px-16 flex-wrap"
            >
            <span
                v-for="(file, index) in files"
                :key="index"
                class="flex place-items-center bg-orange z-20 rounded-md text-xs h-7 mt-1 px-2 mx-1 flex-wrap items-center text-white"
            >
              <svg
                  xmlns="http://www.w3.org/2000/svg"
                  style="cursor: pointer"
                  width="12"
                  height="12"
                  viewBox="0 0 17.309 17.308"
                  class="mr-1 my-auto rounded-full h-2 w-2"
                  @click="removeFile(file)"
                  title="Rimuovi file"
              >
                <path
                    id="Unione_1"
                    data-name="Unione 1"
                    d="M15.089,16.927,8.654,10.492,2.219,16.927A1.3,1.3,0,0,1,.381,15.089L6.816,8.654.382,2.219A1.3,1.3,0,0,1,2.219.382L8.654,6.816,15.089.381a1.3,1.3,0,0,1,1.839,1.839L10.492,8.654l6.435,6.435a1.3,1.3,0,1,1-1.838,1.838Z"
                    fill="#FFFFFF"
                />
              </svg>
              {{ file.name }}
            </span>
            </div>
        </VuePerfectScrollbar>
        <button
            :disabled="loading"
            v-html="loading ? 'Invio in corso...' : 'Invia'"
            class="block mx-auto w-3/12 lg:w-full mb-8 lg:mb-0 lg:w-10/12 h-8 lg:h-10 mt-4 lg:text-xl font-semibold text-white rounded-lg focus:outline-none"
            :class="loading ? 'bg-orangedisabled' : 'bg-orange'"
            @click="openTicket"
        ></button>

        <p class="text-red ml-16 mt-2">{{ error }}</p>
    </div>
</template>

<script>
import Services from "@/services/index";
import VuePerfectScrollbar from "vue-perfect-scrollbar";
import { mapState } from "vuex";

export default {
    name: "OpenTicketContent",

    components: {
        VuePerfectScrollbar,
    },

    data: () => ({
        data: {
            title: "",
            description: "",
            area: 0,
            priority: 2,
        },
        areas:[],
        priorities:[],
        files: [],
        divisions: [],
        selectedDivision: 0,
        mandatoryFields: true,
        mandatoryTitle: true,
        mandatoryDescription: true,
        mandatoryArea: true,
        loading: false,
        error: "",
        restError: "",
    }),

    created() {
        window.addEventListener(
            "dragover",
            function(e) {
                e.preventDefault();
            },
            false
        );
        window.addEventListener(
            "drop",
            function(e) {
                e.preventDefault();
            },
            false
        );

        this.getPriorities();
        this.getAllAreas();
    },

    computed: {
        ...mapState("user", { ticketStatus: "ticketStatus" }),
    },

    methods: {
        async getPriorities() {
            try {
                const response = await Services.getPriorities();
                this.priorities = response;
            } catch (error) {
                console.log(error);
                this.restError = "";
                this.restError = "Errore nel caricamento delle priorità.";
            }
        },

        async getAllAreas() {
            try {
                const response = await Services.getAllAreas();
                this.areas = response;
            } catch (error) {
                console.log(error);
                this.restError = "";
                this.restError = "Errore nel caricamento delle aree.";
            }
        },

        async getAllAreaDivisions(area_id) {
            try {
                const response = await Services.getAllAreaDivisions(area_id);
                this.divisions = response.data.division;
            } catch (error) {
                console.log(error);
                this.restError = "";
                this.restError = "Errore nel caricamento dei settori.";
            }
        },

        resetValues() {
            this.data = {
                title: "",
                description: "",
                area: 0,
                priority: 2,
            }
            this.areas = [];
            this.priorities = [];
            this.files = [];
            this.divisions = [];
            this.selectedDivision = 0;
            this.mandatoryFields = true;
            this.mandatoryTitle = true;
            this.mandatoryDescription = true;
            this.mandatoryArea = true;
        },

        async openTicket() {
            this.loading = true;

            if (
                this.data.title == "" ||
                this.data.description == "" ||
                this.data.area == "" ||
                this.data.priority == ""
            ) {
                this.mandatoryFields = false;
                if (this.data.title == "") {
                    this.mandatoryTitle = false;
                } else {
                    this.mandatoryTitle = true;
                }
                if (this.data.description == "") {
                    this.mandatoryDescription = false;
                } else {
                    this.mandatoryDescription = true;
                }
                if (this.data.area == "") {
                    this.mandatoryArea = false;
                } else {
                    this.mandatoryArea = true;
                }
            } else {
                this.mandatoryFields = true;
                this.mandatoryTitle = true;
                this.mandatoryDescription = true;
                this.mandatoryArea = true;

                let fd = new FormData();

                fd.append("title", this.data.title);
                fd.append("description", this.data.description);
                fd.append("area", this.data.area);
                fd.append("priority", this.data.priority);
                if (this.selectedDivision > 0)
                    fd.append("division[]", this.selectedDivision);
                if (this.files.length > 0)
                    for (let file in this.files) {
                        fd.append("attachments[]", this.files[file]);
                    }

                try {
                    await Services.createTicket(fd);
                    this.$emit('closeModal');
                    this.resetValues();
                    this.$toast.open({
                        message: "Il ticket è stato creato con successo.",
                        type: "success",
                        position: "bottom-right",
                    });
                    if (this.ticketStatus == 1) {
                        this.$router.go();
                    }
                } catch (error) {
                    console.log(error);
                    this.error = error.response.data.message;
                    this.restError = "";
                    this.restError = "Errore nella creazione del ticket, riprovare.";
                }
            }
            this.loading = false;
        },

        showFileChooser() {
            this.$refs.input.click();
        },

        removeFile(file) {
            const index = this.files.indexOf(file);
            this.files.splice(index, 1);
        },

        setAttachment(e) {
            e.preventDefault();
            if (e.dataTransfer) {
                console.log("Data Transfer");
                for (let i = 0; i < e.dataTransfer.files.length; i++) {
                    this.files.push(e.dataTransfer.files[i]);
                }
            } else {
                for (var i = 0; i < e.target.files.length; i++) {
                    this.files.push(e.target.files[i]);
                }
            }
        },
    },
};


</script>