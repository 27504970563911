var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"mt-2 lg:mt-0 pl-1 pr-10 lg:px-0"},[_c('div',{staticClass:"flex 2xl:h-8 lg:h-7 h-8 2xl:my-2 lg:my-1 2xl:text-base lg:text-sm cursor-pointer hover:bg-orangedisabled hover:bg-opacity-50 rounded-l-full lg:rounded-l-none rounded-r-full",class:[
      _vm.status == 1 && this.$route.path != '/sentTickets'
        ? 'bg-orangedisabled bg-opacity-50 font-bold text-blue'
        : 'font-medium text-gray-normal',
      this.$route.name === 'userTickets' ||
      this.$route.name === 'AllTeamMembers'
        ? 'hidden'
        : '' ]},[_c('div',{staticClass:"flex w-full justify-between",on:{"click":function($event){_vm.goToRoot(1);
        _vm.setTicketStatus(1);}}},[_c('span',{staticClass:"my-auto pl-6"},[_vm._v(" Nuovi ")])])]),_c('div',{staticClass:"flex 2xl:h-8 lg:h-7 h-8 2xl:my-2 lg:my-1 2xl:text-base lg:text-sm cursor-pointer hover:bg-orangedisabled hover:bg-opacity-50 rounded-l-full lg:rounded-l-none rounded-r-full",class:_vm.status == 2 && this.$route.path != '/sentTickets'
        ? 'bg-orangedisabled bg-opacity-50 font-bold text-blue'
        : 'font-medium text-gray-normal'},[_c('div',{staticClass:"flex w-full justify-between",on:{"click":function($event){_vm.goToRoot(2);
        _vm.setTicketStatus(2);}}},[_c('span',{staticClass:"my-auto pl-6"},[_vm._v(" Assegnati ")])])]),_c('div',{staticClass:"flex 2xl:h-8 lg:h-7 h-8 2xl:my-2 lg:my-1 2xl:text-base lg:text-sm cursor-pointer hover:bg-orangedisabled hover:bg-opacity-50 rounded-l-full lg:rounded-l-none rounded-r-full",class:_vm.status == 3 && this.$route.path != '/sentTickets'
        ? 'bg-orangedisabled bg-opacity-50 font-bold text-blue'
        : 'font-medium text-gray-normal'},[_c('div',{staticClass:"flex w-full justify-between",on:{"click":function($event){_vm.goToRoot(3);
        _vm.setTicketStatus(3);}}},[_c('span',{staticClass:"my-auto pl-6"},[_vm._v(" In lavorazione ")])])]),_c('div',{staticClass:"flex 2xl:h-8 lg:h-7 h-8 2xl:my-2 lg:my-1 2xl:text-base lg:text-sm cursor-pointer hover:bg-orangedisabled hover:bg-opacity-50 rounded-l-full lg:rounded-l-none rounded-r-full",class:_vm.status == 4 && this.$route.path != '/sentTickets'
        ? 'bg-orangedisabled bg-opacity-50 font-bold text-blue'
        : 'font-medium text-gray-normal'},[_c('div',{staticClass:"flex w-full justify-between",on:{"click":function($event){_vm.goToRoot(4);
        _vm.setTicketStatus(4);}}},[_c('span',{staticClass:" my-auto pl-6"},[_vm._v(" Chiusi ")])])]),(_vm.currentArea === 'I miei' || _vm.currentArea === 'Tutti')?_c('div',{staticClass:"flex 2xl:h-8 lg:h-7 h-8 2xl:my-2 lg:my-1 2xl:text-base lg:text-sm cursor-pointer hover:bg-orangedisabled hover:bg-opacity-50 rounded-l-full lg:rounded-l-none rounded-r-full",class:[
      this.$route.path == '/sentTickets' || _vm.status == 5
        ? 'bg-orangedisabled bg-opacity-50 font-bold text-blue'
        : 'font-medium text-gray-normal' ]},[_c('div',{staticClass:"flex w-full justify-between",on:{"click":function($event){_vm.goToRoot(5);
        _vm.setTicketStatus(5);}}},[_c('span',{staticClass:" my-auto pl-6"},[_vm._v(" Inviati ")])])]):_vm._e()])}
var staticRenderFns = []

export { render, staticRenderFns }