<template>
    <div>
        <div class="flex lg:hidden justify-end">
            <page-navigation
                class="absolute top-14"
                :buttonSize="7"
                :currentPage="currentPage"
                :lastPage="lastPage"
                @next-page="
          currentPage++;
          getTickets();
        "
                @prev-page="
          currentPage--;
          getTickets();
        "
            ></page-navigation>
        </div>
        <div
            v-if="tickets.length > 0 && ticketsLoading == false"
            class="flex flex-col 2xl:h-122 xl:h-100 overflow-auto"
        >
            <VuePerfectScrollbar class="scroll-area">
                <!-- Lista risultati ricerca da lg -->
                <div
                    v-for="(ticket, index) in tickets"
                    :key="ticket.id"
                    :index="index"
                    class="hidden lg:flex flex-col w-full 2xl:h-40 xl:h-36 rounded-lg border border-gray-300 bg-white 2xl:text-base xl:text-sm"
                    :class="index == 0 || index == 9 ? 'my-0' : 'my-5'"
                >
                    <div
                        class="flex lg:space-between w-full h-20 border-b border-gray-300 cursor-pointer"
                        :class="
              ticket.remind && ticket.status.status != 'CHIUSO'
                ? 'bg-remindred'
                : ''
            "
                        @click="$router.push('/ticket/' + ticket.id)"
                    >
                        <div class="w-8 px-2 my-auto">
                            <!--<img class="text-center mx-auto" src="@/assets/icons/flag.svg"/>-->
                            <svg
                                class="cursor-pointer"
                                xmlns="http://www.w3.org/2000/svg"
                                width="18"
                                height="18.233"
                                viewBox="0 0 15.199 18.233"
                                @click.stop="
                  ticket.favorite
                    ? removeFavorites(ticket)
                    : updateFavorites(ticket)
                "
                            >
                                <title>Rimuovi dai preferiti</title>
                                <path
                                    id="Tracciato_1684"
                                    data-name="Tracciato 1684"
                                    d="M.371,18.055c-.411-.172-.484-.486-.2-.845a50.09,50.09,0,0,1,4.35-4.848c.6-.59,1.191-1.195,1.793-1.786.1-.1.116-.155,0-.268-.939-.931-1.861-1.861-2.8-2.792a.5.5,0,0,1,.022-.835,2.17,2.17,0,0,1,2.821-.106c.1.081.152.065.24-.01Q9.1,4.406,11.621,2.253a.151.151,0,0,0,.048-.215A1.629,1.629,0,0,1,12.017.21a.465.465,0,0,1,.631-.031,1.667,1.667,0,0,1,.14.133c1.582,1.582,3.158,3.171,4.751,4.742.439.432.337.756-.13,1.047a1.58,1.58,0,0,1-1.557.116.142.142,0,0,0-.2.043Q13.477,8.8,11.3,11.324c-.064.074-.055.116,0,.187a2.177,2.177,0,0,1-.131,2.885.478.478,0,0,1-.751.015Q9.08,13.078,7.748,11.736c-.1-.1-.154-.116-.268-.006C6.331,12.876,5.2,14.043,4.025,15.158,3,16.129,1.9,17.02.807,17.906a1.958,1.958,0,0,1-.255.147Z"
                                    transform="translate(0 -0.07)"
                                    :fill="ticket.favorite ? '#fd7f50' : '#C6C6C6'"
                                />
                            </svg>
                        </div>
                        <div class="text-orange my-auto mx-6">
                            Ticket
                        </div>
                        <div class="font-medium mx-2 2xl:text-lg xl:text-base text-lg my-auto text-blue">
                            #{{ ticket.id }}
                        </div>
                        <div
                            v-if="ticket.status != null"
                            class="w-44 font-medium mx-2 2xl:text-lg xl:text-base my-auto text-blue"
                        >
                            {{ ticket.status.status }}
                        </div>
                        <div
                            v-if="ticket.remind"
                            class="flex text-center mr-auto my-auto text-red"
                        >
                            <img src="@/assets/icons/sollecita-red.svg" class="w-5 h-5"/>
                            <span class="ml-2">
                Sollecitato
              </span>
                            <span v-if="ticket.remind" class="ml-2">
                in data {{ formatDate(ticket.remind_ticket.created_at) }}
              </span>
                        </div>
                        <div
                            class="flex w-auto ml-auto justify-end pr-6 font-medium my-auto text-orange"
                        >
                            {{ ticket.area.name }}
                            <span v-if="ticket.division.length >=! 1" class="flex pl-1 text-blue">
                -
              </span>
                            <span
                                v-if="ticket.division.length === 1"
                                class="font-medium pl-1  text-blue"
                            >
                {{ ticket.division[0].division.name }}
              </span>
                            <div v-else class="text-blue ml-1">
                <span v-for="(item, index) in ticket.division" :key="index">
                  {{ item.division.name }}
                  <span class="px-0" v-if="index != ticket.division.length - 1">
                    ,
                  </span>
                </span>
                            </div>
                        </div>
                    </div>
                    <!-- Se il ticket viene visualizzato correttamente è per il campo status
                        (la rest torna dati sporchi) es. Ticket n.41581-->
                    <div
                        v-if="ticket.status != null"
                        class="flex justify-items-stretch rounded-b-md"
                        :class="[
              ticket.status.status == 'CHIUSO' ? 'bg-orange' : '',
              ticket.remind && ticket.status.status != 'CHIUSO'
                ? 'bg-remindred'
                : '',
            ]"
                    >
                        <div
                            :style="'background-color:' + ticket.priority.color"
                            class="flex rounded-bl-xl w-2 h-28"
                        ></div>
                        <div class="flex flex-wrap w-full m-2">
                            <div class="w-16 h-2 m-2">
                                <img
                                    v-if="ticket.from_user.user_info.picture_url"
                                    class="rounded-full w-10 h-10"
                                    :src="ticket.from_user.user_info.picture_url"
                                />
                                <div
                                    v-else
                                    class="flex justify-center place-items-center rounded-full w-10 h-10 bg-gray-200 text-white font-medium"
                                >
                                    {{
                                        ticket.from_user.user_info.firstname.slice(0, 1)
                                    }}{{ ticket.from_user.user_info.lastname.slice(0, 1) }}
                                </div>
                            </div>
                            <div
                                class="w-1/6 my-auto"
                                :class="
                  ticket.status.status == 'CHIUSO'
                    ? 'text-white'
                    : 'text-iconsgray'
                "
                            >
                                Mittente
                                <br/>
                                <span
                                    class="font-medium"
                                    :class="
                    ticket.status.status == 'CHIUSO'
                      ? 'text-white'
                      : 'text-blue'
                  "
                                >
                  {{ ticket.from_firstname }} {{ ticket.from_lastname }}
                </span>
                            </div>
                            <div
                                class="w-1/6 xl:mx-2 my-auto"
                                :class="
                  ticket.status.status == 'CHIUSO'
                    ? 'text-white'
                    : 'text-iconsgray'
                "
                            >
                                Assegnato
                                <br/>
                                <span
                                    v-if="ticket.processing_user_firstname != null"
                                    class="font-medium"
                                    :class="
                    ticket.status.status == 'CHIUSO'
                      ? 'text-white'
                      : 'text-blue'
                  "
                                >
                  {{ ticket.processing_user_firstname }}
                  {{ ticket.processing_user_lastname }}
                </span>
                                <span v-else class="font-medium">
                  -
                </span>
                            </div>
                            <div
                                class="w-1/6 xl:mx-2 my-auto"
                                :class="
                  ticket.status.status == 'CHIUSO'
                    ? 'text-white'
                    : 'text-iconsgray'
                "
                            >
                                Data apertura
                                <br/>
                                <span
                                    class="font-medium"
                                    :class="
                    ticket.status.status == 'CHIUSO'
                      ? 'text-white'
                      : 'text-blue'
                  "
                                >
                  {{ formatDate(ticket.creation_date) }}
                </span>
                            </div>
                            <div
                                class="w-1/6 xl:mx-2 my-auto"
                                :class="
                  ticket.status.status == 'CHIUSO'
                    ? 'text-white'
                    : 'text-iconsgray'
                "
                            >
                                Data presa in carico
                                <br/>
                                <span
                                    v-if="ticket.processing_date"
                                    class="font-medium"
                                    :class="
                    ticket.status.status == 'CHIUSO'
                      ? 'text-white'
                      : 'text-blue'
                  "
                                >
                  {{ formatDate(ticket.processing_date) }}
                </span>
                                <span
                                    v-else
                                    class="font-medium"
                                    :class="
                    ticket.status.status == 'CHIUSO'
                      ? 'text-white'
                      : 'text-blue'
                  "
                                >
                  Non definita
                </span>
                            </div>
                            <div
                                class="w-1/6 xl:mx-2 my-auto"
                                :class="
                  ticket.status.status == 'CHIUSO'
                    ? 'text-white'
                    : 'text-iconsgray'
                "
                            >
                                Data chiusura
                                <br/>
                                <span
                                    v-if="ticket.closing_date"
                                    class="font-medium"
                                    :class="
                    ticket.status.status == 'CHIUSO'
                      ? 'text-white'
                      : 'text-blue'
                  "
                                >
                  {{ formatDate(ticket.closing_date) }}
                </span>
                                <span
                                    v-else
                                    class="font-medium"
                                    :class="
                    ticket.status.status == 'CHIUSO'
                      ? 'text-white'
                      : 'text-blue'
                  "
                                >
                  Non definita
                </span>
                            </div>
                            <div
                                class="flex flex-col w-1/2 pl-20 2xl:pt-0 xl:pt-3"
                                :class="
                  ticket.status.status == 'CHIUSO'
                    ? 'text-white'
                    : 'text-iconsgray'
                "
                            >
                                Titolo
                                <div
                                    class="flex flex-col one-row-title font-medium"
                                    :class="
                    ticket.status.status == 'CHIUSO'
                      ? 'text-white'
                      : 'text-blue'
                  "
                                >
                                    {{ ticket.subject }}
                                </div>
                            </div>
                            <div class="flex w-1/2 my-auto pr-2 justify-end 2xl:pt-0 xl:pt-3 text-iconsgray">
                                <button
                                    class="w-32 h-7 mr-2 bg-orange rounded-lg text-white text-sm font-semibold py-auto focus:outline-none"
                                    :class="
                    ticket.status.status == 'CHIUSO'
                      ? 'bg-white text-orange'
                      : 'bg-orange text-white'
                  "
                                    @click="showDescription(ticket.text)"
                                >
                                    Descrizione
                                </button>
                                <button
                                    class="w-22 h-7 bg-orange rounded-lg text-white text-sm font-semibold py-auto focus:outline-none"
                                    :class="
                    ticket.status.status == 'CHIUSO'
                      ? 'bg-white text-orange'
                      : 'bg-orange text-white'
                  "
                                    @click="$router.push('/ticket/' + ticket.id)"
                                >
                                    Vedi
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
            </VuePerfectScrollbar>
            <!-- Lista risultati ricerca fino a lg -->
            <div
                v-for="(ticket, index) in tickets"
                :key="ticket.id"
                :index="index"
                class="flex lg:hidden w-full rounded-lg bg-white"
                :class="index == 0 || index == 9 ? 'my-0' : 'my-5'"
            >
                <div v-if="ticket.status != null"
                     :style="'background-color:' + ticket.priority.color + '; color:' + ticket.priority.color"
                     class="flex w-2 rounded-l-lg"
                >..
                </div>
                <div class="flex flex-col w-full">
                    <div
                        class="flex lg:space-between w-full items-center py-2 border-b border-gray-300 cursor-pointer"
                        :class="ticket.remind && ticket.status.status != 'CHIUSO' ? 'bg-remindred' : ''"
                        @click="$router.push('/ticket/' + ticket.id)"
                    >
                        <div class="w-8 px-2">
                            <!--<img class="text-center mx-auto" src="@/assets/icons/flag.svg"/>-->
                            <svg
                                class="cursor-pointer"
                                xmlns="http://www.w3.org/2000/svg"
                                width="18"
                                height="18.233"
                                viewBox="0 0 15.199 18.233"
                                @click.stop="
                  ticket.favorite
                    ? removeFavorites(ticket)
                    : updateFavorites(ticket)
                "
                            >
                                <title>Rimuovi dai preferiti</title>
                                <path
                                    id="Tracciato_1684"
                                    data-name="Tracciato 1684"
                                    d="M.371,18.055c-.411-.172-.484-.486-.2-.845a50.09,50.09,0,0,1,4.35-4.848c.6-.59,1.191-1.195,1.793-1.786.1-.1.116-.155,0-.268-.939-.931-1.861-1.861-2.8-2.792a.5.5,0,0,1,.022-.835,2.17,2.17,0,0,1,2.821-.106c.1.081.152.065.24-.01Q9.1,4.406,11.621,2.253a.151.151,0,0,0,.048-.215A1.629,1.629,0,0,1,12.017.21a.465.465,0,0,1,.631-.031,1.667,1.667,0,0,1,.14.133c1.582,1.582,3.158,3.171,4.751,4.742.439.432.337.756-.13,1.047a1.58,1.58,0,0,1-1.557.116.142.142,0,0,0-.2.043Q13.477,8.8,11.3,11.324c-.064.074-.055.116,0,.187a2.177,2.177,0,0,1-.131,2.885.478.478,0,0,1-.751.015Q9.08,13.078,7.748,11.736c-.1-.1-.154-.116-.268-.006C6.331,12.876,5.2,14.043,4.025,15.158,3,16.129,1.9,17.02.807,17.906a1.958,1.958,0,0,1-.255.147Z"
                                    transform="translate(0 -0.07)"
                                    :fill="ticket.favorite ? '#fd7f50' : '#C6C6C6'"
                                />
                            </svg>
                        </div>
                        <div class="font-medium mx-2 2xl:text-lg xl:text-base lg:text-lg text-base text-blue">
                            #{{ ticket.id }}
                        </div>
                        <div
                            v-if="ticket.status != null"
                            class="w-auto mx-auto font-medium text-sm text-blue"
                        >
                            {{ ticket.status.status }}
                        </div>
                        <div
                            class="text-sm mr-auto"
                            :class="ticket.status.status == 'CHIUSO' ? 'text-white' : 'text-blue'"
                        >
                            {{ formatDate(ticket.creation_date) }}
                        </div>
                    </div>

                    <div class="flex flex-wrap px-2" :class="[
                                                              ticket.status.status == 'CHIUSO' ? 'bg-orange' : '',
                                                              ticket.remind && ticket.status.status != 'CHIUSO'
                                                                ? 'bg-remindred'
                                                                : '',
                                                            ]">
                        <div
                            class="flex w-full font-bold text-base"
                            :class="
                                        ticket.status.status == 'CHIUSO'
                                          ? 'text-white'
                                          : 'text-blue'
                                      "
                        >
                            {{ ticket.subject }}
                        </div>
                        <div
                            class="text-sm opacity-70"
                            :class="
                                        ticket.status.status == 'CHIUSO'
                                          ? 'text-white'
                                          : 'text-blue'
                                      "
                        >
                            {{ ticket.from_firstname }} {{ ticket.from_lastname }}
                        </div>
                        <div class="flex flex-wrap w-full items-center pr-2">
                            <div class="w-auto mt-3 mb-1 overflow-x-auto h-8">
                                <div class="flex items-center"
                                     :class="ticket.division.length>0 ? 'min-w-max bg-gray-normal bg-opacity-10 rounded-xl' : ''">
                                    <div class="font-semibold text-white bg-orange rounded-xl px-2 mr-2">
                                        {{ ticket.area.name }}
                                    </div>
                                    <div
                                        v-if="ticket.division.length === 1"
                                        class="font-medium text-blue pr-4"
                                        :title="ticket.division[0].division.name"
                                    >
                                        {{ ticket.division[0].division.name }}
                                    </div>
                                    <div v-else class="flex font-medium text-blue pr-4">
                                        <div v-for="(item, index) in ticket.division" :key="index">
                                            {{ item.division.name }}
                                            <div class="px-0" v-if="index != ticket.division.length - 1">
                                                ,
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div
                            v-if="ticket.remind"
                            class="flex text-center mr-auto my-auto text-red mb-2"
                        >
                            <img src="@/assets/icons/sollecita-red.svg" class="w-5 h-5"/>
                            <span class="ml-2">
                                            Sollecitato
                                          </span>
                            <span v-if="ticket.remind" class="ml-2">
                                            in data {{ formatDate(ticket.remind_ticket.created_at) }}
                                          </span>
                        </div>
                    </div>

                </div>
            </div>
            <description-modal
                ref="descriptionModal"
                @dialog-click="showDescription()"
                :active="descriptionModal"
                :description="ticketText"
                :title="'Descrizione'"
            >
                <template v-slot:body>
                    <div class="px-10 py-5">
                        <p class="font-medium text-gray-400">
                            {{ ticketText }}
                        </p>
                    </div>
                </template>
                <template v-slot:actions>
                    <div class="px-10">
                        <!-- <span class="text-blue font-medium mr-10">Immagine-1.png</span>
                        <span class="text-blue font-medium">Immagine-2.png</span> -->
                    </div>
                </template>
            </description-modal>
        </div>

        <div v-else-if="ticketsLoading == true">
            <Spinner class="my-80"></Spinner>
        </div>

        <div
            v-else-if="ticketsLoading == false && tickets.length == 0"
            class="text-center text-lg"
        >
            Nessun ticket trovato
        </div>
        <div class="hidden lg:flex h-12 pt-4 justify-end">
            <page-navigation
                class="absolute bottom-6 right-4"
                :buttonSize="7"
                :currentPage="currentPage"
                :lastPage="lastPage"
                @next-page="
          currentPage++;
          getTickets();
        "
                @prev-page="
          currentPage--;
          getTickets();
        "
            ></page-navigation>
        </div>
        <prompt-modal
            ref="errorModal"
            :title="'Errore'"
            :message="errorMessage"
            :confirm="false"
        >
        </prompt-modal>
    </div>
</template>

<script>
import DescriptionModal from "@/components/DescriptionModal";
import {mapActions, mapState} from "vuex";
import moment from "moment";
import Services from "@/services";
import Spinner from "@/components/Spinner";
import PromptModal from "@/components/PromptModal";
import PageNavigation from "@/components/PageNavigation";
import VuePerfectScrollbar from "vue-perfect-scrollbar";

export default {
    name: "FilteredList.vue",

    components: {
        DescriptionModal,
        PageNavigation,
        PromptModal,
        Spinner,
        VuePerfectScrollbar,
    },

    data: () => ({
        currentPage: 1,
        remindDate: "",
        ticketText: "",
        descriptionModal: false,
        ticketsLoading: false,
        errorMessage: ""
    }),

    props: {
        filter: {
            filter: String,
        },
    },

    computed: {
        ...mapState("user", {user: "user"}),
        ...mapState("tickets", {tickets: "tickets"}),
        ...mapState("tickets", {filters: "filters"}),
        ...mapState("tickets", {lastPage: "lastPage"}),
    },

    methods: {
        ...mapActions({setTickets: "tickets/setTickets"}),
        ...mapActions({setLastPage: "tickets/setLastPage"}),

        async updateFavorites(ticket) {
            try {
                await Services.updateFavorites({id: ticket.id});
                ticket.favorite = true;
                this.$toast.success("Ticket N°" + ticket.id + " messo in evidenza.", {
                    position: "bottom-right",
                });
            } catch (error) {
                console.log(error);
                this.$refs.errorModal.open();
                this.$refs.errorModal.close();
                this.errorMessage = "";
                this.errorMessage = "Impossibile mettere in evidenza il ticket.";
            }
        },

        async removeFavorites(ticket) {
            try {
                await Services.removeFavorites({id: [ticket.id]});
                this.$toast.success(
                    "Il Ticket N°" + ticket.id + " non è più in evidenza.",
                    {
                        position: "bottom-right",
                    }
                );
                ticket.favorite = false;
                //Riaggiorno la lista quando faccio unpin del ticket (?)
                //await this.getTickets();
            } catch (error) {
                console.log(error);
                this.$refs.errorModal.open();
                this.$refs.errorModal.close();
                this.errorMessage = "";
                this.errorMessage = "Impossibile fare unpin del ticket.";
            }
        },

        async getTickets() {
            this.ticketsLoading = true;
            try {
                let response;
                if (this.$route.params.type === 'favorites' || this.$route.params.type === 'reminder' || this.$route.params.type === 'send_reminder') {
                    response = await Services.getAllTickets(
                        this.filters,
                        this.currentPage
                    );
                } else {
                    response = await Services.searchTicket(
                        this.filters,
                        this.currentPage
                    );
                }

                //Se sto su 'in evidenza' e faccio unpin su molti ticket ho necessità di
                // aggiornare l'ultima pagina (la lista si riduce sempre più)
                this.setLastPage(parseInt(response.data.ticket.last_page));
                this.setTickets(response.data.ticket.data);
            } catch (error) {
                this.$refs.errorModal.open();
                this.$refs.errorModal.close();
                this.errorMessage = "";
                this.errorMessage = "Errore nel caricamento dei ticket.";
                console.log(error);
                this.ticketsLoading = false;
            }
            this.ticketsLoading = false;
        },

        formatDate(date) {
            return moment(date, "YYYY/MM/DD HH:mm:ss").format("DD MMM YYYY");
        },

        showDescription(text) {
            this.ticketText = text;
            this.descriptionModal = !this.descriptionModal;
            this.$refs.descriptionModal.open();
        },
    },

    watch: {
        lastPage: function () {
            this.currentPage = 1;
            //this.getTickets();
        },
    },
};
</script>

<style scoped>
.scroll-area {
    @apply pr-0 lg:pr-4;
}

.one-row-title {
    max-height: 50px;
    display: -webkit-box;
    -webkit-line-clamp: 1;
    -webkit-box-orient: vertical;
    overflow: hidden;
}
</style>
