<template>
  <div>
    <VuePerfectScrollbar
      class="overflow-hidden"
      :class="ticket_status != 'CHIUSO' ? 'max-h-96' : 'max-h-98'"
    >
      <div v-if="answers.length > 0" class="pr-3">
        <chat-message
          v-for="answer in answers"
          :key="answer.id"
          class="mb-3"
          :time="answer.answer_timestamp ? answer.answer_timestamp : date"
          :message="answer"
        ></chat-message>
      </div>
      <div class="mb-3 p-5 text-gray-400 w-full" v-else>
        <p class="text-center text-blue font-medium text-3xl">
          Nessun Messaggio
        </p>
      </div>
    </VuePerfectScrollbar>
    <div
      v-if="ticket_status != 'CHIUSO'"
      class="flex flex-row justify-between bg-gray-100 p-3 rounded-md mt-auto"
    >
      <div class="flex flex-col w-full p-2 bg-gray-100">
        <textarea
          :disabled="loading"
          class="flex-grow w-full bg-gray-100 focus:outline-none"
          placeholder="Rispondi..."
          v-model="message.text"
        >
        </textarea>
        <div v-if="message.attachments.length > 0" class="flex flex-row">
          <span
            v-title="attachment.name"
            class="
              whitespace-nowrap
              w-32
              inline-flex
              text-sm
              bg-white
              border-px border-orange
              rounded-lg
              text-orange
              font-bold
              p-1
              mt-2
              mr-3
              place-items-center
            "
            v-for="attachment in message.attachments"
            :key="attachment.id"
            target="_blank"
          >
            <img
              @click="removeAttachment(attachment)"
              class="mx-1 h-2 w-2 cursor-pointer"
              src="@/assets/icons/X.svg"
            />

            <span class="truncate">
              {{ attachment.name }}
            </span>
          </span>
        </div>
      </div>
      <div class="w-px bg-gray-400 mx-5"></div>
      <span class="flex flex-col">
        <input
          class="hidden"
          multiple
          ref="input"
          type="file"
          name="image"
          accept="/*"
          @change="setAttachment"
        />
        <button
          :disabled="loading || ticket_status == 'CHIUSO'"
          @click="$refs.input.click()"
          class="py-1 w-32 mb-1.5 flex justify-between px-4"
          :class="
            ticket_status == 'CHIUSO'
              ? 'ticket-btn-outline-disabled cursor-not-allowed'
              : 'ticket-btn-outline'
          "
          @mouseenter="attachmentHover = true"
          @mouseleave="attachmentHover = false"
        >
          Allega
          <img
            class="h-5"
            :src="
              attachmentHover
                ? require('@/assets/icons/allegato-white.svg')
                : require('@/assets/icons/allegato.svg')
            "
          />
        </button>
        <button
          :disabled="loading || message.text.length <= 0"
          @click="
            message.close = 0;
            createAnswer();
          "
          class="py-1 w-32 mb-1.5 flex justify-between pl-4 pr-2"
          :class="
            loading || message.text.length <= 0
              ? 'ticket-btn-outline-disabled cursor-not-allowed'
              : 'ticket-btn-outline'
          "
          @mouseenter="sendHover = true"
          @mouseleave="sendHover = false"
        >
          Invia
          <img
            class="h-4 mt-0.5"
            :class="message.text.length <= 0 ? 'opacity-50' : ''"
            :src="
              sendHover
                ? require('@/assets/icons/invia-white.svg')
                : require('@/assets/icons/invia.svg')
            "
          />
        </button>
        <button
          :disabled="loading || message.text.length <= 0"
          class="py-1 w-32"
          :class="
            loading || message.text.length <= 0
              ? 'ticket-btn-red-disabled cursor-not-allowed'
              : 'ticket-btn-red'
          "
          @click="$refs.promptModal.open()"
        >
          Invia e Chiudi
        </button>
      </span>
    </div>

    <prompt-modal
      ref="promptModal"
      :title="'Conferma Chiusura'"
      :message="'Sei sicuro di voler chiudere il ticket?'"
      :loading="loading"
      :error="error"
      @action="
        message.close = 1;
        createAnswer();
      "
    ></prompt-modal>
  </div>
</template>

<script>
import { mapState } from "vuex";
import ChatMessage from "./ChatMessage.vue";
import PromptModal from "@/components/PromptModal.vue";
import VuePerfectScrollbar from "vue-perfect-scrollbar";
import Services from "@/services/index";

export default {
  name: "Messages",

  components: { ChatMessage, VuePerfectScrollbar, PromptModal },

  props: {
    area: Number,
    answers: Array,
    ticket_status: String,
  },

  data: () => ({
    loading: false,
    error: "",

    message: {
      text: "",
      ticket_id: "",
      close: 0,
      attachments: [],
      area: 0,
    },

    date: new Date(),

    attachmentHover: false,
    sendHover: false,
  }),

  created() {
    this.message.ticket_id = this.$route.params.id;
    this.message.area = this.area;
  },

  computed: {
    ...mapState("user", { user: "user" }),
  },

  methods: {
    async createAnswer() {
      let fd = new FormData();

      fd.append("message", this.message.text);
      fd.append("id", this.message.ticket_id);
      fd.append("close", this.message.close);
      for (let attachment in this.message.attachments) {
        fd.append("attachments[]", this.message.attachments[attachment]);
      }
      fd.append("area", this.message.area);

      this.loading = true;
      await Services.createAnswer(fd)
        .then((response) => {
          if (this.message.close == 1) {
            this.$refs.promptModal.close();
            this.$router.go();
          }
          this.message = {
            text: "",
            ticket_id: this.$route.params.id,
            area: this.area,
            close: 0,
            attachments: [],
          };
          response.data.answer["user"] = this.user;
          this.answers.push(response.data.answer);
        })
        .catch((err) => {
          this.$toast.open({
            message: "Si è verificato un errore.",
            type: "error",
            position: "bottom",
          });
          this.error = err.response.data.message;
          return err;
        });
      this.loading = false;
    },

    setAttachment(e) {
      e.preventDefault();
      var file;
      if (e.dataTransfer) {
        file = e.dataTransfer.files[0];
        this.message.attachments.push(file);
      } else {
        if (
          this.message.attachments
            .map((a) => a.name)
            .indexOf(e.target.files[0].name) == -1 ||
          this.message.attachments.length == 0
        ) {
          this.message.attachments.push(e.target.files[0]);
          this.$refs.input.value = "";
        }
      }
    },

    removeAttachment(file) {
      const index = this.message.attachments.indexOf(file);
      this.message.attachments.splice(index, 1);
    },
  },
};
</script>
