<template>
  <Modal
    card-class="w-7/12 2xl:p-8 lg:p-5 rounded-0 overflow-hidden"
    ref="createSearchModal"
  >
    <template #header="{close}">
      <div class="flex justify-end px-6 items-center leading-none">
        <div class="2xl:text-3xl lg:text-2xl mx-auto text-blue font-semibold">
          Stato Ticket
          <span class="2xl:text-3xl lg:text-2xl text-orange"> N.{{ ticket.id }}</span>
        </div>
        <span
          @click.prevent="close"
          class="cursor-pointer"
        >
          <svg
            class="2xl:mb-16 lg:mb-10 2xl:w-4 2xl:h-4 lg:w-3 lg:h-3"
            xmlns="http://www.w3.org/2000/svg"
            width="16"
            height="16"
            viewBox="0 0 20.086 20.086"
          >
            <g
              id="Raggruppa_2175"
              data-name="Raggruppa 2175"
              transform="translate(1.414 1.414)"
            >
              <line
                id="Linea_10"
                data-name="Linea 10"
                x2="17.258"
                y2="17.258"
                fill="none"
                stroke="#000000"
                stroke-linecap="round"
                stroke-width="2"
              />
              <line
                id="Linea_11"
                data-name="Linea 11"
                x1="17.258"
                y2="17.258"
                fill="none"
                stroke="#000000"
                stroke-linecap="round"
                stroke-width="2"
              />
            </g>
          </svg>
        </span>
      </div>
    </template>
    <template #body>
      <div class="mx-auto">
        <div class="flex text-left w-auto mt-4 mx-2">
          <div class="flex font-small text-base w-1/5"></div>
          <div
            class="font-medium 2xl:text-base lg:text-xs text-blue m-auto"
            :class="resizeColumns"
          >
            Apertura
          </div>
          <div
            v-if="ticket.status.id === 4"
            class="font-medium 2xl:text-base lg:text-xs text-blue m-auto"
            :class="resizeColumns"
          >
            Chiusura
          </div>
          <div
            v-if="ticket.status.id === 3"
            class="font-medium 2xl:text-base lg:text-xs text-blue m-auto"
            :class="resizeColumns"
          >
            Presa in carico
          </div>
          <div
            v-if="ticket.status.id != 1 && ticket.status.id != 5"
            class="font-medium 2xl:text-base lg:text-xs m-auto text-blue"
            :class="resizeColumns"
          >
            Assegnato
          </div>
          <div
            class="font-medium 2xl:text-base lg:text-xs text-blue m-auto"
            :class="resizeColumns"
          >
            Area
          </div>
          <div
            class="font-medium 2xl:text-base lg:text-xs text-blue m-auto 2xl:px-4 lg:px-0"
            :class="resizeColumns"
          >
            Stato lavorazione
          </div>
        </div>
        <div class="flex text-center w-auto mx-2 my-1">
          <div class="flex w-1/5">
            <img
              v-if="ticket.from_user.user_info.picture_url"
              class="rounded-full"
              :src="ticket.from_user.user_info.picture_url"
            />
            <div
              v-else
              class="flex flex-shrink-0 place-items-center justify-center my-auto rounded-full bg-gray-200 text-white font-medium
               2xl:text-lg lg:text-base 2xl:w-10 2xl:h-10 lg:w-7 lg:h-7"
            >
              {{ ticket.from_user.user_info.firstname.slice(0, 1)
              }}{{ ticket.from_user.user_info.lastname.slice(0, 1) }}
            </div>
            <span class="ml-1 my-auto text-left 2xl:text-base lg:text-xs">
              {{ ticket.from_user.user_info.firstname }}
              {{ ticket.from_user.user_info.lastname }}
            </span>
          </div>
          <span
            class="m-auto text-left 2xl:text-base lg:text-xs"
            :class="resizeColumns"
          >
            {{ formatDate(ticket.creation_date) }}
          </span>
          <span
            v-if="ticket.status.id === 4"
            class="m-auto text-left 2xl:text-base lg:text-xs"
            :class="resizeColumns"
          >
            {{ ticket.closing_date ? formatDate(ticket.closing_date) : 'Non definita'}}
          </span>
          <span
            v-if="ticket.status.id === 3"
            class="m-auto text-left 2xl:text-base lg:text-xs"
            :class="resizeColumns"
            >{{ formatDate(ticket.processing_date) }}</span
          >
          <span
            v-if="ticket.status.id === 2 || ticket.status.id === 3 || ticket.status.id === 4"
            class="m-auto text-left 2xl:text-base lg:text-xs"
            :class="resizeColumns"
          >
            {{ ticket.processing_user.user_info.name }}
          </span>
          <span
            class="m-auto text-left 2xl:text-base lg:text-xs"
            :class="resizeColumns"
          >
            {{ ticket.area.name }}
          </span>
          <span
            class="m-auto text-left 2xl:text-base lg:text-xs 2xl:px-4 lg:px-0 font-medium"
            :class="resizeColumns"
            :style="'color:' + ticket.status.color"
            >{{ ticket.status.status }}</span
          >
          <hr />
        </div>
      </div>
    </template>
  </Modal>
</template>

<script>
import Modal from "@/components/Modal";
import moment from "moment";

export default {
  name: "SearchTicketModal",

  components: {
    Modal,
  },

  data: () => ({
    ticket: {},
  }),

  computed: {
    resizeColumns() {
      return {
        "w-1/4": this.ticket.status.id === 1,
        "w-1/6":
          this.ticket.status.id === 2 ||
          this.ticket.status.id === 3 ||
          this.ticket.status.id === 4||
          this.ticket.status.id === 5,
      };
    },
  },

  methods: {
    open(value) {
      this.ticket = value;
      this.$refs.createSearchModal.open();
    },

    formatDate: function(date) {
      if (this.ticket.status.id === 3 || this.ticket.status.id === 4) {
        return moment(date, "YYYY/MM/DD, HH:mm:ss").format("DD MMM YY");
      }
      return moment(date, "YYYY/MM/DD HH:mm:ss").format("DD MMM YYYY - HH:mm");
    },
  },
};
</script>

<style></style>
