<template>
	<div class="px-2 py-4 lg:p-0">
		<div class="hidden lg:block h-px 2xl:my-2 lg:my-1 bg-gray-300"></div>
		<div class="flex w-full text-sm px-2 2xl:h-12 lg:h-9">
			<div
				:style="'background-color:' + content.priority.color"
				:title="'Priorità: ' + content.priority.priority.toLowerCase()"
				class="w-2"
				:class="'text-' + content.priority.color"
			>
				..
			</div>
			<!--<div class="w-full items-center grid grid-flow-col grid-cols-6">-->
			<div
				class="flex flex-wrap lg:flex-nowrap w-full items-center cursor-pointer px-2"
				@click="$router.push('/ticket/' + content.id)"
			>
				<div
					class="order-2 lg:order-none font-medium ml-2 lg:ml-0 lg:text-center 2xl:text-sm lg:text-xs text-base lg:my-auto"
					:class="
						type === 'INVIATI'
							? 'w-auto lg:w-18 xl:w-20'
							: 'w-1/2 lg:w-18 xl:w-20'
					"
				>
					<span class="lg:hidden inline-block -mr-1">#</span>
					{{ content.id }}
				</div>
				<div
					:class="
						type === 'INVIATI'
							? 'order-1 lg:order-none w-10 lg:w-16 xl:w-20 2xl:w-20'
							: 'order-1 lg:order-none w-10 lg:w-16 xl:w-20'
					"
				>
					<!--<img class="text-center mx-auto" src="@/assets/icons/flag.svg"/>-->
					<button
						v-title="'Metti in evidenza'"
						class="float-left lg:mr-px xl:mr-2 focus:outline-none"
						:disabled="loading"
						@click.stop="
							content.favorite
								? removeFavorites(content)
								: updateFavorites(content)
						"
					>
						<svg
							class="cursor-pointer float-left w-4 h-4"
							xmlns="http://www.w3.org/2000/svg"
							viewBox="0 0 15.199 18.233"
						>
							<path
								id="Tracciato_1684"
								data-name="Tracciato 1684"
								d="M.371,18.055c-.411-.172-.484-.486-.2-.845a50.09,50.09,0,0,1,4.35-4.848c.6-.59,1.191-1.195,1.793-1.786.1-.1.116-.155,0-.268-.939-.931-1.861-1.861-2.8-2.792a.5.5,0,0,1,.022-.835,2.17,2.17,0,0,1,2.821-.106c.1.081.152.065.24-.01Q9.1,4.406,11.621,2.253a.151.151,0,0,0,.048-.215A1.629,1.629,0,0,1,12.017.21a.465.465,0,0,1,.631-.031,1.667,1.667,0,0,1,.14.133c1.582,1.582,3.158,3.171,4.751,4.742.439.432.337.756-.13,1.047a1.58,1.58,0,0,1-1.557.116.142.142,0,0,0-.2.043Q13.477,8.8,11.3,11.324c-.064.074-.055.116,0,.187a2.177,2.177,0,0,1-.131,2.885.478.478,0,0,1-.751.015Q9.08,13.078,7.748,11.736c-.1-.1-.154-.116-.268-.006C6.331,12.876,5.2,14.043,4.025,15.158,3,16.129,1.9,17.02.807,17.906a1.958,1.958,0,0,1-.255.147Z"
								transform="translate(0 -0.07)"
								:fill="content.favorite ? '#fd7f50' : '#C6C6C6'"
							/>
						</svg>
					</button>
					<div v-title="'Sollecita'" class="float-left ml-2 lg:ml-0">
						<svg
							v-if="content.can_remind != 0"
							@click.stop="$refs.remindConfirmModal.open()"
							id="megaphone"
							xmlns="http://www.w3.org/2000/svg"
							class="cursor-pointer fill-current w-4 h-4"
							title="Sollecita"
							viewBox="0 0 23.37 25.799"
						>
							<path
								id="Tracciato_1638"
								data-name="Tracciato 1638"
								d="M378.856,51.11l1.619-1.619a.756.756,0,1,0-1.069-1.069l-1.619,1.619a.756.756,0,1,0,1.069,1.069Z"
								transform="translate(-359.755 -45.772)"
								:fill="content.remind ? '#F91628' : '#C6C6C6'"
							/>
							<path
								id="Tracciato_1639"
								data-name="Tracciato 1639"
								d="M428.141,176.733h-1.619a.756.756,0,0,0,0,1.512h1.619a.756.756,0,1,0,0-1.512Z"
								transform="translate(-405.526 -167.828)"
								:fill="content.remind ? '#F91628' : '#C6C6C6'"
							/>
							<path
								id="Tracciato_1640"
								data-name="Tracciato 1640"
								d="M281.922,3.131a.756.756,0,0,0,.756-.756V.756a.756.756,0,0,0-1.512,0V2.375A.756.756,0,0,0,281.922,3.131Z"
								transform="translate(-268.213)"
								:fill="content.remind ? '#F91628' : '#C6C6C6'"
							/>
							<path
								id="Tracciato_1641"
								data-name="Tracciato 1641"
								d="M24.322,350.059a.756.756,0,0,0,0,1.069l3.238,3.238a.756.756,0,0,0,1.069,0L29.9,353.1l-4.307-4.307Z"
								transform="translate(-24.1 -331.218)"
								:fill="content.remind ? '#F91628' : '#C6C6C6'"
							/>
							<path
								id="Tracciato_1642"
								data-name="Tracciato 1642"
								d="M188.174,386.038l-5.307,2.5,3.628,3.628a.756.756,0,0,0,1.069,0l2.833-2.833a.756.756,0,0,0,0-1.069Z"
								transform="translate(-174.867 -366.586)"
								:fill="content.remind ? '#F91628' : '#C6C6C6'"
							/>
							<path
								id="Tracciato_1643"
								data-name="Tracciato 1643"
								d="M82.48,48.422a.756.756,0,0,0-1.218.213l-6.42,13.643,4.307,4.307,13.643-6.42A.756.756,0,0,0,93,58.947Z"
								transform="translate(-72.284 -45.772)"
								:fill="content.remind ? '#F91628' : '#C6C6C6'"
							/>
						</svg>
					</div>
				</div>

				<div
					v-if="
						(this.$route.name === 'userTickets' && ticketStatus === 1) ||
							(this.$route.name === 'SentTickets' && ticketStatus === 5)
					"
					class="flex font-medium order-3 lg:order-none lg:w-36 xl:w-40 2xl:w-44 mx-auto"
				>
					<div
						class="flex px-2 py-1 rounded-full"
						:class="[
							content.status.status === 'CHIUSO' ? 'bg-cyan' : '',
							content.status.status === 'ASSEGNATO' ? 'bg-lightred' : '',
							content.status.status === 'IN LAVORAZIONE' ? 'bg-lightgreen' : '',
							content.status.status === 'DA ASSEGNARE' ? 'bg-lightorange' : '',
						]"
					>
						<span
							class="w-auto lg:w-16 xl:w-auto font-bold text-xs 2xl:uppercase lg:lowercase lg:truncate"
							style="color: #132546"
						>
							{{ content.status.status }}
						</span>
					</div>
				</div>
				<div
					v-if="type != 'INVIATI'"
					class="order-6 lg:order-none flex w-full lg:w-2/12 font-medium items-center"
				>
					<img
						v-if="content.from_user.user_info.picture_url"
						class="hidden 2xl:block rounded-full 2xl:w-10 2xl:h-10 lg:w-0 lg:h-0 float-left"
						:src="content.from_user.user_info.picture_url"
					/>
					<div
						v-else
						class="2xl:w-10 2xl:h-10 hidden 2xl:flex 2xl:flex-row rounded-full items-center
                        justify-center font-bold bg-gray-300 2xl:text-base text-white"
					>
						{{ content.from_firstname.slice(0, 1)
						}}{{ content.from_lastname.slice(0, 1) }}
					</div>
					<p
						class="2xl:pl-1 text-sm lg:text-xs 2xl:text-sm opacity-70 lg:opacity-100"
					>
						{{ content.from_firstname }} {{ content.from_lastname }}
					</p>
				</div>
				<div
					class="flex items-center order-5 lg:order-none w-full"
					:class="[
						type === 'NUOVI' || type === 'ASSEGNATI'
							? '2xl:w-3/12 lg:w-52'
							: 'lg:w-2/12',
						type === 'INVIATI' ? 'xl:w-56 lg:w-2/12' : '',
						this.$route.name === 'userTickets' && ticketStatus === 1
							? 'xl:w-56 lg:w-2/12'
							: '',
					]"
				>
					<div
						@click="$router.push('/ticket/' + content.id)"
						class="2xl:ml-0 font-bold whitespace-nowrap cursor-pointer overflow-ellipsis truncate 2xl:text-sm lg:text-xs text-base"
						:class="[
							type === 'IN LAVORAZIONE' ||
							type === 'ASSEGNATI' ||
							type === 'INVIATI' ||
							type === 'PREFERITI' ||
							type === 'CHIUSI'
								? 'w-80 lg:w-48'
								: 'w-80',

							type === 'NUOVI' ? 'lg:ml-2 2xl:w-48 lg:w-40' : '',
							type === 'ASSEGNATI' ? 'lg:ml-2 2xl:w-60 lg:w-40' : '',
							type === 'IN LAVORAZIONE' ? 'lg:ml-2 2xl:w-40 lg:w-28' : '',
							type === 'CHIUSI' ? 'lg:ml-2 2xl:w-36 lg:w-28' : '',
							type === 'INVIATI' ? 'xl:w-32 lg:w-24 lg:ml-0 2xl:pl-2' : '',
						]"
						:title="content.subject"
					>
						{{ content.subject }}
					</div>
				</div>
				<!--        <div v-if="showTitle" class="w-auto ml-auto absolute bg-white border rounded shadow-md font-bold cursor-pointer"
                             :class="
                              type === 'IN LAVORAZIONE' ||
                              type === 'INVIATI' ||
                              type === 'PREFERITI' ||
                              type === 'CHIUSI'
                                ? 'w-48'
                                : 'w-80'
                            "
                             @mouseleave="showTitle = false">
                          {{ content.subject }}
                        </div>-->
				<!--        <div
                  v-if="type != 'INVIATI'"

                  class="font-bold cursor-pointer whitespace-nowrap overflow-ellipsis truncate mr-2"
                  v-title="content.subject"
                  :class="
                    type === 'IN LAVORAZIONE' ||
                    type === 'CHIUSI' ||
                    type === 'INVIATI' ||
                    type === 'PREFERITI'
                      ? 'w-2/12'
                      : 'w-4/12'
                  "
                >
                  {{ content.subject }}
                </div>-->
				<div
					class="hidden 2xl:flex 2xl:text-sm lg:text-xs lg:overflow-ellipsis lg:truncate"
					:class="[
						type === 'IN LAVORAZIONE' ||
						type === 'INVIATI' ||
						type === 'PREFERITI'
							? 'w-2/12'
							: 'w-2/12',
						type === 'INVIATI' ? 'xl:w-2/12 lg:w-2/12' : '',
					]"
				>
					{{ creationDateFormatted }}
				</div>
				<div
					class="order-4 lg:order-none text-sm lg:text-xs truncate overflow-ellipsis 2xl:hidden lg:ml-0"
					:class="[
						type === 'IN LAVORAZIONE' ||
						type === 'INVIATI' ||
						type === 'PREFERITI'
							? 'lg:w-2/12'
							: 'lg:w-2/12',
						type === 'INVIATI' ? 'ml-0 xl:w-2/12 lg:w-40' : 'ml-auto',
					]"
				>
					{{ creationDateFormattedMobile }}
				</div>
				<div
					v-if="
						type === 'IN LAVORAZIONE' ||
							type === 'CHIUSI' ||
							type === 'INVIATI' ||
							type === 'PREFERITI'
					"
					class="hidden lg:block font-medium 2xl:w-2/12 2xl:text-sm lg:text-xs lg:overflow-ellipsis lg:truncate"
					:class="
						type === 'IN LAVORAZIONE'
							? 'lg:w-40 lg:pl-2 xl:w-44 xl:pl-0'
							: type === 'CHIUSI' || type === 'INVIATI'
							? 'lg:w-36 lg:pl-2 xl:w-44 xl:pl-0'
							: 'lg:w-44'
					"
				>
					<span v-if="content.processing_date">
						<span class="2xl:hidden">
							{{ processingDateFormattedMobile }}
						</span>
						<span class="hidden 2xl:flex">
							{{ processingDateFormatted }}
						</span>
					</span>
					<span
						v-else
						class="2xl:text-sm lg:text-xs opacity-50 lg:overflow-ellipsis lg:truncate"
					>
						Non definita
					</span>
				</div>
				<div
					v-if="type != 'CHIUSI' && type != 'INVIATI' && type != 'PREFERITI'"
					class="hidden lg:block font-bold lg:w-32 xl:w-44 2xl:text-sm lg:text-xs"
				>
					<span class="font-bold" :style="'color:' + content.priority.color">
						{{ content.priority.priority }}
					</span>
				</div>

				<div
					v-if="type === 'CHIUSI' || type === 'INVIATI' || type === 'PREFERITI'"
					class="hidden lg:block font-medium 2xl:text-sm lg:text-xs"
					:class="
						type === 'INVIATI'
							? 'xl:w-52 lg:w-32'
							: type === 'CHIUSI'
							? 'w-2/12 lg:w-32 xl:w-2/12'
							: 'w-2/12'
					"
				>
					<span v-if="content.closing_date">
						<span class="hidden 2xl:flex">
							{{ closingDateFormatted }}
						</span>
						<span class="2xl:hidden">
							{{ closingDateFormattedMobile }}
						</span>
					</span>
					<span v-else class="opacity-50">
						Non definita
					</span>
				</div>
				<div
					v-if="type === 'INVIATI'"
					class="order-6 lg:order-none w-full lg:w-40 xl:w-44 2xl:text-sm lg:text-xs font-medium lg:overflow-ellipsis lg:truncate"
				>
					<span
						class="opacity-50"
						v-if="
							content.processing_user_firstname == null &&
								content.processing_user_lastname == null
						"
					>
						Non assegnato
					</span>
					<span
						v-else-if="
							content.processing_user_firstname != null &&
								content.processing_user_lastname != null
						"
						class="font-medium w-24"
					>
						{{ content.processing_user_firstname }}
						{{ content.processing_user_lastname }}
					</span>
				</div>
				<div
					class="hidden lg:block leading-none 2xl:text-sm 2xl:mr-0 lg:text-xs"
					:class="type === 'INVIATI' ? '2xl:px-2 xl:w-56 lg:w-36' : 'w-44'"
				>
					<span class="font-semibold text-orange">
						{{ content.area.name }}
					</span>
					<span
						v-if="content.division.length === 1"
						class="font-medium text-blue"
						:title="content.division[0].division.name"
						:class="[
							type === 'INVIATI' ? 'lg:w-20 xl:w-32 truncate block' : 'w-20',
							content.division[0].division.name.length > 40 && type != 'INVIATI'
								? '2xl:inline lg:block'
								: '2xl:block',
							type === 'NUOVI' ? '2xl:inline lg:break-words' : '',
						]"
					>
						- {{ content.division[0].division.name }}
					</span>
					<span
						v-else-if="content.division.length >= 2"
						@mouseenter="displayMoreDivisions(content.division)"
						@mouseout="resetAdditionalDivisions"
						v-title="additionalDivisions"
						class="font-medium text-blue"
					>
						- {{ content.division[0].division.name }} +
						{{ content.division.length - 1 }}
					</span>
				</div>
				<div class="lg:hidden order-7 w-auto mt-2 mb-1 overflow-x-auto h-8">
					<div
						class="py-1"
						:class="
							content.division.length > 0
								? 'min-w-max bg-gray-normal bg-opacity-10 rounded-xl'
								: ''
						"
					>
						<span
							class="font-semibold text-white bg-orange rounded-xl px-2 py-1 mr-2"
						>
							{{ content.area.name }}
						</span>
						<span
							v-if="content.division.length === 1"
							class="font-medium text-blue pr-4 w-full"
							:title="content.division[0].division.name"
						>
							{{ content.division[0].division.name }}
						</span>
						<span
							v-else-if="content.division.length >= 2"
							@mouseenter="displayMoreDivisions(content.division)"
							@mouseout="resetAdditionalDivisions"
							v-title="additionalDivisions"
							class="font-medium text-blue pr-4"
						>
							{{ content.division[0].division.name }} +
							{{ content.division.length - 1 }}
						</span>
					</div>
				</div>

				<!--Valore che deve uscire solo per per ticket inviati -->
				<!--        <div v-if="type === 'PREFERITI'" class="font-medium w-32">
                  {{ content.area.name }}
                </div>-->

				<div
					class="hidden lg:flex h-full justify-end mr-3 2xl:text-sm lg:text-xs"
					:class="[
						type === 'NUOVI' || type === 'ASSEGNATI'
							? '2xl:w-2/12'
							: '2xl:w-48 lg:w-24',
						type == 'INVIATI' ? '2xl:inline-block 2xl:pt-3 lg:hidden ' : '',
					]"
				>
					<div class="flex flex-col my-auto text-center">
						<!--            <span class="block my-1 text-orange text-right font-medium">
                          {{ content.area.name }}
                        </span>-->

						<div
							v-if="
								this.$route.name != 'userTickets' &&
									this.$route.name != 'MoreDepartmentTickets'
							"
							class="flex justify-end"
						>
							<div @click.stop>
								<multiselect
									v-if="
										content.can_assignable > 0 &&
											canAssign &&
											user.user_area_coordinator_or_manager.length > 0 &&
											type === 'NUOVI'
									"
									class="2xl:w-40 lg:w-20 2xl:text-base lg:text-sm border-px border-orange rounded-md text-orange"
									v-model="content.selectedUser"
									:options="usernames"
									placeholder="Assegna a..."
									:hide-selected="true"
									:track-by="'id'"
									:custom-label="
										(usernames) => {
											return usernames.user_info.name;
										}
									"
									:taggable="false"
									:searchable="true"
									:optionHeight="10"
									:maxHeight="300"
									:showLabels="false"
									@input="
										$refs.assignedTicketModal.open(content.selectedUser, 2)
									"
								>
								</multiselect>

								<button
									v-else-if="type === 'NUOVI'"
									:disabled="loading"
									:class="
										loading
											? 'bg-lightorange 2xl:w-32 lg:w-20 rounded-lg text-white font-semibold py-1'
											: 'ticket-btn 2xl:w-32 lg:w-20  py-1 rounded-md'
									"
									@click="$refs.assignedTicketModal.open(user, 2)"
								>
									Assegna a te
								</button>
								<button
									v-else-if="type === 'ASSEGNATI'"
									@click="updateTicket({ id: content.id, status: 3 })"
									:class="
										loading
											? 'bg-lightorange 2xl:w-32 lg:w-16 rounded-lg text-white font-semibold py-1 focus:outline-none'
											: 'ticket-btn 2xl:w-32 lg:w-16 px-2 py-1 rounded-md'
									"
								>
									Gestisci
								</button>
								<button
									v-else-if="type === 'IN LAVORAZIONE'"
									class="bg-orange 2xl:w-32 lg:w-16 rounded-lg text-white font-semibold py-1 focus:outline-none"
									@click="$router.push('/ticket/' + content.id)"
								>
									Riprendi
								</button>
								<button
									v-else
									class="bg-orange 2xl:w-32 lg:w-16 rounded-lg text-white font-semibold py-1 focus:outline-none"
									@click="$router.push('/ticket/' + content.id)"
								>
									Vedi
								</button>
							</div>
						</div>
						<div v-else>
							<button
								class="bg-orange 2xl:w-32 lg:w-16 rounded-lg text-white font-semibold py-1 focus:outline-none"
								@click="$router.push('/ticket/' + content.id)"
							>
								Vedi
							</button>
						</div>
					</div>
				</div>
			</div>
		</div>
		<div class="lg:hidden h-px bg-gray-300 mt-6 mx-4"></div>

		<!--Modale assegna a te-->
		<assigned-ticket-modal
			:user_id="
				this.content.selectedUser ? this.content.selectedUser.id : this.user.id
			"
			:ticket_id="this.content.id"
			:ticket_area="this.content.area.id"
			:ticket_divisions="this.content.division"
			:status="this.status"
			ref="assignedTicketModal"
			@success="showSuccess()"
			@error="showError()"
			@dismiss="this.content.selectedUser = ''"
		></assigned-ticket-modal>

		<!--Modale inoltra-->
		<ForwardTicket
			ref="forwardTicketModal"
			:ticket_id="this.content.id"
			@forwardTicketModal="$refs.forwardTicketModal.open()"
		></ForwardTicket>

		<prompt-modal
			ref="remindConfirmModal"
			:title="content.remind ? 'Annulla Sollecito' : 'Conferma Sollecito'"
			:message="
				content.remind
					? 'Sei sicuro di voler rimuovere il sollecito da questo ticket?'
					: 'Sei sicuro di voler sollecitare questo ticket?'
			"
			@action="
				content.remind ? remindTicket(content, 0) : remindTicket(content, 1)
			"
			:loading="loading"
		>
		</prompt-modal>

		<prompt-modal
			ref="assignSuccessModal"
			:message="'Il ticket N°' + content.id + ' è stato assegnato'"
			:confirm="false"
		>
		</prompt-modal>
		<prompt-modal
			ref="errorModal"
			:title="'Ops...'"
			:message="'Qualcosa è andato storto.'"
			:confirm="false"
		>
		</prompt-modal>
	</div>
</template>

<script>
import moment from "moment";
import Services from "@/services/index";
import { mapState } from "vuex";
import ForwardTicket from "@/components/ForwardTicket";
import AssignedTicketModal from "@/components/AssignedTicketModal";
import PromptModal from "@/components/PromptModal";

export default {
	name: "TicketBox",

	components: {
		ForwardTicket,
		AssignedTicketModal,
		PromptModal,
	},

	props: {
		content: Object,
		type: {
			type: String,
			required: true,
		},
	},

	created() {
		//Per ogni nuovo ticket, controllo se l'utente è coordinatore della rispettiva area (se il ticket è di Area IT
		// e l'utente è coordinatore di Area IT, allora può assegnarlo con la select), altrimenti l'unica azione
		//disponibile è assegna a te, perchè l'utente è semplice dipendente di quell'area (bottone, non select)
		this.canAssign = false;
		if (this.user.user_area_coordinator_or_manager.length > 0) {
			for (let i in this.user.user_area_coordinator_or_manager) {
				if (
					this.user.user_area_coordinator_or_manager[i].area_id ===
					this.content.area.id
				) {
					this.canAssign = true;
				}
			}
			this.getProcessingUser();
		}
	},

	data: () => ({
		loading: false,
		canAssign: false,
		showMoreActions: false,
		additionalDivisions: [],
		assignableUsers: [],
		showTitle: false,
		usernames: [],
		status: 2,
	}),

	methods: {
		showSuccess() {
			this.$refs.assignSuccessModal.open();
			setTimeout(() => {
				this.$router.go();
			}, 2000);
		},

		showError() {
			this.$refs.errorModal.open();
			setTimeout(() => {
				this.$refs.errorModal.close();
			}, 2000);
		},

		async updateFavorites(ticket) {
			this.loading = true;
			try {
				await Services.updateFavorites({ id: ticket.id });
				this.$toast.success("Ticket N°" + ticket.id + " messo in evidenza.", {
					position: "bottom-right",
				});
				ticket.favorite = true;
			} catch (error) {
				console.log(error);
			}
			this.loading = false;
		},

		async removeFavorites(ticket) {
			this.loading = true;
			try {
				await Services.removeFavorites({ id: [ticket.id] });
				this.$toast.success(
					"Il Ticket N°" + ticket.id + " non è più in evidenza.",
					{
						position: "bottom-right",
					}
				);
				ticket.favorite = false;
			} catch (error) {
				console.log(error);
			}
			this.loading = false;
		},

		async updateTicket(request) {
			this.loading = true;
			try {
				await Services.updateTicket(request);
				this.$router.push("/ticket/" + request.id);
			} catch (error) {
				console.log(error.response);
			}
			this.loading = false;
		},

		async remindTicket(ticket, remind) {
			this.loading = true;
			try {
				await Services.remindTicket({
					id: ticket.id,
					remind: remind,
				});
				ticket.remind = remind;
				this.$refs.remindConfirmModal.close();
				if (remind) {
					this.$toast.success(
						"Il ticket N°" + ticket.id + " è stato sollecitato.",
						{ position: "bottom-right" }
					);
				} else {
					this.$toast.success(
						"Il sollecito al ticket N°" + ticket.id + " è stato revocato.",
						{ position: "bottom-right" }
					);
				}
			} catch (error) {
				console.log(error);
			}
			this.loading = false;
		},

		displayMoreDivisions(divisions) {
			for (let i in divisions) {
				this.additionalDivisions.push(divisions[i].division.name);
			}
		},

		resetAdditionalDivisions() {
			this.additionalDivisions = [];
		},

		setMoreActions() {
			if (this.content.reminder || this.content.forward) {
				this.showMoreActions = !this.showMoreActions;
			} else {
				this.showMoreActions = false;
			}
			return this.showMoreActions;
		},

		async getProcessingUser() {
			this.assignableUsers = [];
			const response = await Services.getProcessingUser(this.content.id);
			this.assignableUsers = response.data.user;
			//Sposto l'utente coordinatore in prima posizione tra gli utenti della select
			for (let i in this.assignableUsers) {
				if (this.assignableUsers[i].id == this.user.id) {
					var first = this.assignableUsers[i];
					continue;
				}
				this.usernames.push(this.assignableUsers[i]);
			}
			this.usernames.unshift(first);
		},

		async assignTicket(data) {
			if (data != "") {
				console.log(data);
			}
			try {
				await Services.assignTicket(data);
			} catch (error) {
				alert("'Errore nell'assegnazione del ticket");
			}
		},
	},

	computed: {
		...mapState("user", { user: "user" }),
		...mapState("tickets", { tickets: "tickets" }),
		...mapState("user", { user: "user", ticketStatus: "ticketStatus" }),
		creationDateFormatted: function() {
			return moment(this.content.creation_date, "YYYY/MM/DD, HH:mm:ss").format(
				"DD MMM YYYY - HH:mm"
			);
		},
		creationDateFormattedMobile: function() {
			return moment(this.content.creation_date, "YYYY/MM/DD, HH:mm:ss").format(
				"DD MMM YYYY"
			);
		},

		processingDateFormatted: function() {
			return moment(
				this.content.processing_date,
				"YYYY/MM/DD, HH:mm:ss"
			).format("DD MMM YYYY - HH:mm");
		},
		processingDateFormattedMobile: function() {
			return moment(
				this.content.processing_date,
				"YYYY/MM/DD, HH:mm:ss"
			).format("DD MMM YYYY");
		},

		closingDateFormatted: function() {
			return moment(this.content.closing_date, "YYYY/MM/DD, HH:mm:ss").format(
				"DD MMM YYYY - HH:mm"
			);
		},
		closingDateFormattedMobile: function() {
			return moment(this.content.closing_date, "YYYY/MM/DD, HH:mm:ss").format(
				"DD MMM YYYY"
			);
		},
	},
};
</script>

<style scoped>
.multiselect {
	white-space: nowrap;
}

.multiselect >>> .multiselect__single {
	@apply truncate lg:pl-0 lg:text-xs 2xl:text-base pb-1;
}

.multiselect >>> .multiselect__placeholder {
	@apply lg:text-xs 2xl:text-base;
}

.multiselect >>> .multiselect__input {
	@apply pl-0 min-w-0 lg:max-w-14 lg:text-xs 2xl:text-base 2xl:max-w-32;
}

.multiselect >>> .multiselect__select {
	@apply lg:px-1 lg:w-auto 2xl:w-8 lg:h-8 2xl:h-9;
}

.titleHover {
	position: absolute;
	background-color: #ffffff;
	transition: ease-in-out 0.4s;
}

.titleHover:hover {
	width: 1020px;
	padding-top: 9px;
	padding-bottom: 9px;
}

.titleLeave {
	position: absolute;
	background-color: #ffffff;
	transition: ease-out 0.4s;
}

.multiselect__tag {
	background-color: #fd7f50 !important;
}

.multiselect__tag-icon:hover {
	color: white;
	background: #fd7f50 !important;
}

.multiselect__input {
	display: none !important;
}

.multiselect__option--highlight {
	background: #fd7f50 !important;
}

.bg-cyan {
	background-color: rgba(39, 155, 252, 0.5);
}

.bg-lightred {
	background-color: rgba(249, 22, 40, 0.5);
}

.bg-lightgreen {
	background-color: rgba(114, 222, 131, 0.5);
}

.bg-lightorange {
	background-color: rgba(253, 128, 82, 0.5);
}
</style>
