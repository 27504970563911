<template>
	<Modal
		card-class="p-4 lg:p-0 lg:w-1/4 rounded-0 overflow-hidden"
		ref="assignedTicketModal"
	>
		<template #header="{close}">
			<div class="flex items-center leading-none">
				<h1 class="m-6 text-3xl mx-auto font-semibold text-orange">
					Assegna settore
				</h1>
				<span
					@click.prevent="close"
					class="font-medium items-center text-orange text-2xl"
				>
				</span>
			</div>
		</template>
		<template #body>
			<div class="max-w-max mx-auto">
				<div
					v-for="section in userSections"
					:key="section.sectionId"
					class="text-blue"
				>
					<div>
						<input
							type="checkbox"
							:id="section.sectionName"
							:value="section.sectionId"
							:disabled="
								ticket_divisions
									.map((a) => a.division_id)
									.indexOf(section.sectionId) != -1
									? true
									: false
							"
							v-model="checkedSection"
						/>
						<label class="ml-3">{{ section.sectionName }}</label>
					</div>
				</div>
			</div>

			<div class="flex justify-center my-5">
				<button
					class="w-28 h-9 text-blue text-xl mr-4 font-medium rounded-lg bg-homegray focus:outline-none"
					@click="closeModal"
				>
					Annulla
				</button>

				<button
					@click="assignTicket(user_id, status, checkedSection)"
					:disabled="checkedSection == 0"
					:class="
						checkedSection == 0
							? 'rounded-md text-xl px-4 ticket-btn-disabled cursor-not-allowed'
							: 'rounded-md text-xl px-4 ticket-btn'
					"
				>
					Assegna
				</button>
			</div>
		</template>
	</Modal>
</template>

<script>
import Modal from "@/components/Modal";
import Services from "@/services";
import { mapState } from "vuex";

export default {
	name: "AssignedTicketModal.vue",

	components: {
		Modal,
	},

	props: {
		user_id: Number,
		ticket_id: Number,
		ticket_area: Number,
		ticket_divisions: Array,
		status: Number,
	},

	data: () => ({
		userSections: [],
		checkedSection: [],
	}),

	computed: {
		...mapState("user", { user: "user" }),
	},

	created() {},

	methods: {
		closeModal() {
			this.userSections = [];
			this.$emit("dismiss", null);
			this.$refs.assignedTicketModal.close();
		},

		async assignTicket(user, status, division) {
			let data = {
				id: this.ticket_id,
				user: user,
				status: status,
				division: division,
			};

			try {
				await Services.assignTicket(data);
				this.$emit("success", null);
			} catch (error) {
				this.$emit("error", null);
				console.log(error);
			}
			this.closeModal();
		},

		async open(user, status) {
			this.userSections = [];

			try {
				const response = await Services.getUserDivisions({
					user: user.id,
					area: this.ticket_area,
				});
				for (let i in response) {
					this.userSections.push({
						sectionId: response[i].id,
						sectionName: response[i].name,
						checked: false,
					});
				}
				if (this.userSections.length <= 1) {
					this.assignTicket(user.id, status, [this.userSections[0].sectionId]);
				} else {
					this.checkedSection = this.ticket_divisions.map((a) => a.division_id);
					this.$refs.assignedTicketModal.open();
				}
			} catch (error) {
				console.log(error);
			}
		},
	},
};
</script>
