<template>
  <div>
    <div class="text-xl font-bold uppercase">
      Team members -
      <span class="text-orange"> {{ currentArea }} </span>
      <span v-if="currentSector" class="text-orange">
        - {{ currentSector }}
      </span>
      <span class="text-blue"> - </span>
      <span class="font-medium text-orange"> {{ userData.length }} </span>
    </div>
    <div
        class="flex flex-col w-full  shadow border-t text-base bg-white text-blue rounded-lg
              2xl:text-base lg:text-xs 2xl:h-124 lg:h-104 2xl:mt-4 lg:mt-2"
    >
      <div class="border-b mx-4">
        <div class="flex text-center my-2 w-full">
          <div class="w-14"></div>
          <div class="w-2/12 font-medium m-auto text-left">Nome e cognome</div>
          <div class="lg:w-32 xl:w-1/12 lg:pl-2 xl:pl-0 font-medium m-auto text-left">Ruolo</div>
          <div class="lg:w-0 xl:w-20"></div>
          <div class="flex w-4/12">
            <div class="w-1/3 font-medium m-auto 2xl:mx-6 lg:mx-0">Assegnati</div>
            <div class="w-1/3 font-medium m-auto 2xl:mx-6 lg:mx-0">In lavorazione</div>
            <div class="w-1/3 font-medium m-auto 2xl:mx-6 lg:mx-0">Chiusi</div>
          </div>
          <div class="w-4/12 font-medium m-auto text-center">Altri settori</div>
          <!--<div class="w-2/12 font-medium text-center">Assegna ruolo</div>-->
        </div>
      </div>
      <VuePerfectScrollbar>
        <div class="mx-4">
          <div
              v-for="(user, index) in userData"
              :key="index"
              class="flex text-blue w-full items-center z-30"
              :class="index < userData.length - 1 ? 'border-b' : 'border-b-0'"
          >
            <div class="w-14">
              <img
                  v-if="user.user.user_info.picture_url"
                  class="rounded-full 2xl:w-10 2xl:h-10 lg:w-7 lg:h-7 lg:mx-auto"
                  :src="user.user.user_info.picture_url"
              />
              <div
                  v-else
                  class="flex place-items-center justify-center rounded-full bg-gray-300 text-white text-lg font-bold
                   2xl:w-10 2xl:h-10 lg:w-7 lg:h-7 lg:mx-auto 2xl:text-lg lg:text-base"
              >
                {{user.user.user_info.firstname.slice(0, 1) }}{{ user.user.user_info.lastname.slice(0, 1) }}
              </div>
            </div>
            <div
                v-if="user.user"
                class="w-2/12 cursor-pointer text-left"
                @click="
                setTicketStatus(null),
                  $router.push(
                    '/userTickets/' +
                      user.user_id +
                      '/' +
                      user.user.user_info.name
                  )
              "
            >
              {{ user.user.user_info.name }}
            </div>
            <div v-else class="w-2/12 cursor-pointer text-left">
              Nome utente non trovato
            </div>
            <div class="lg:w-32 xl:w-1/12 lg:pl-2 xl:pl-0 text-left font-medium capitalize text-orange">
              {{ user.acl.role_it }}
            </div>
            <div class="lg:w-0 xl:w-20"></div>
            <div class="flex w-4/12 2xl:py-5 lg:py-3 2xl:text-base lg:text-xs bg-lightorange text-center">
              <button
                  class="flex rounded-lg h-7 mx-auto items-center justify-center cursor-pointer focus:outline-none"
                  :class="user.count_ticket_assigned.toString().length > 2 ?
                  'w-10 bg-pillPeachyOrange' : 'w-7 bg-circlePeachyOrange'"
                  @click="setTicketStatus(2),
                    $router.push('/userTickets/' + user.user_id + '/' + user.user.user_info.name)
                "
              >
                <span class="text-red font-medium items-center 2xl:py-0 lg:py-1">
                  {{ user.count_ticket_assigned }}
                </span>
              </button>
              <div
                  class="flex rounded-full h-7  mx-auto
                items-center justify-center cursor-pointer focus:outline-none"
                  :class="user.count_ticket_in_process.toString().length > 2 ?
                  'w-10 bg-pillPeachyOrange' : 'w-7 bg-circlePeachyOrange'"
                  @click="setTicketStatus(3),
                    $router.push('/userTickets/' +user.user_id + '/' +user.user.user_info.name)"
              >
                <span class="text-green font-medium items-center 2xl:py-0 lg:py-1">
                  {{ user.count_ticket_in_process }}
                </span>
              </div>
              <div
                  class="flex rounded-full h-7 mx-auto
                  items-center justify-center cursor-pointer focus:outline-none"
                  :class="user.count_ticket_closed.toString().length > 2 ?
                  'w-10 bg-pillPeachyOrange' : 'w-7 bg-circlePeachyOrange'"
                  @click="setTicketStatus(4),
                    $router.push('/userTickets/' + user.user_id + '/' + user.user.user_info.name)"
              >
                <span class="text-cyan font-medium items-center 2xl:py-0 lg:py-1">
                  {{ user.count_ticket_closed }}
                </span>
              </div>
            </div>
            <div class="w-4/12 justify-left px-10 font-medium text-orange">
              <span v-for="(sector, index) in user.user.division" :key="index">
                {{ sector.name }}
                <span class="" v-if="index < user.user.division.length - 1"
                >-</span
                >
              </span>
            </div>
            <!--<div class="flex w-2/12 justify-center font-medium text-orange">
              <img class="w-6 h-4 m-2 cursor-pointer" src="@/assets/icons/manager.svg"/>
              <svg class="w-6 h-4 m-2 cursor-pointer" xmlns="http://www.w3.org/2000/svg"
                   viewBox="0 0 20.062 14.043">
                <path id="Icon_awesome-users" data-name="Icon awesome-users"
                      d="M3.009,8.268A2.006,2.006,0,1,0,1,6.262,2.008,2.008,0,0,0,3.009,8.268Zm14.043,0a2.006,2.006,0,1,0-2.006-2.006A2.008,2.008,0,0,0,17.052,8.268Zm1,1H16.049a2,2,0,0,0-1.414.583,4.585,4.585,0,0,1,2.354,3.429h2.069a1,1,0,0,0,1-1v-1A2.008,2.008,0,0,0,18.055,9.272Zm-8.025,0A3.511,3.511,0,1,0,6.52,5.761,3.509,3.509,0,0,0,10.031,9.272Zm2.407,1h-.26a4.847,4.847,0,0,1-4.294,0h-.26a3.612,3.612,0,0,0-3.611,3.611v.9a1.505,1.505,0,0,0,1.5,1.5h9.028a1.505,1.505,0,0,0,1.5-1.5v-.9A3.612,3.612,0,0,0,12.438,10.275Zm-7.012-.42a2,2,0,0,0-1.414-.583H2.006A2.008,2.008,0,0,0,0,11.278v1a1,1,0,0,0,1,1H3.069A4.6,4.6,0,0,1,5.426,9.855Z"
                      transform="translate(0 -2.25)" fill="#959dac"/>
              </svg>
              <svg class="w-6 h-4 m-2 cursor-pointer" xmlns="http://www.w3.org/2000/svg"
                   viewBox="0 0 14.043 14.043">
                <path id="Icon_awesome-user-alt" data-name="Icon awesome-user-alt"
                      d="M7.022,7.9a3.95,3.95,0,1,0-3.95-3.95A3.951,3.951,0,0,0,7.022,7.9Zm3.511.878H9.021a4.775,4.775,0,0,1-4,0H3.511A3.51,3.51,0,0,0,0,12.287v.439a1.317,1.317,0,0,0,1.317,1.317h11.41a1.317,1.317,0,0,0,1.317-1.317v-.439A3.51,3.51,0,0,0,10.532,8.777Z"
                      fill="#959dac"/>
              </svg>
            </div>-->
          </div>
        </div>
      </VuePerfectScrollbar>
      <div v-if="usersLoading == true" class="my-auto">
        <Spinner></Spinner>
      </div>
      <div v-else-if="userData.length == 0"
           class="flex 2xl:text-lg lg:text-base justify-center 2xl:my-auto lg:my-48">
        Nessun utente presente in {{ currentArea }}
        <span class="ml-1" v-if="currentSector">
           - {{ currentSector }}
        </span>
      </div>
    </div>
    <prompt-modal
        ref="errorModal"
        :title="'Errore'"
        :message="errorMessage"
        :confirm="false"
    >
    </prompt-modal>
  </div>
</template>

<script>
import VuePerfectScrollbar from "vue-perfect-scrollbar";
import Services from "@/services";
import Spinner from "@/components/Spinner";
import {mapActions, mapState} from "vuex";
import PromptModal from "@/components/PromptModal";

export default {
  name: "TeamMembers.vue",
  data: () => ({
    userData: [],
    usersLoading: false,
    errorMessage: "",
    //usernames: []
  }),

  created() {
    this.getAreaDetails();
  },
  components: {
    VuePerfectScrollbar,
    PromptModal,
    Spinner,
  },

  computed: {
    ...mapState("user", {user: "user", status: "ticketStatus"}),
    ...mapState("user", {currentArea: "currentArea"}),
    ...mapState("user", {currentSector: "currentSector"}),
  },

  methods: {
    ...mapActions({setTicketStatus: "user/setTicketStatus"}),

    async getAreaDetails() {
      try {
        this.usersLoading = true;
        let request = {};
        if (!this.$route.params.sectorId) {
          request = {area: this.$route.params.id};
        } else {
          request = {
            area: this.$route.params.id,
            division: this.$route.params.sectorId,
          };
        }
        const response = await Services.getAreaDetails(request);
        this.userData = response.area_rel_user;
        this.usersLoading = false;
      } catch (error) {
        console.log(error);
        this.$refs.errorModal.open();
        this.$refs.errorModal.close();
        this.errorMessage = "";
        this.errorMessage = "Errore nel caricamento degli utenti.";
      }
    },
  },
};
</script>

<style scoped>
.bg-circlePeachyOrange :hover {
  width: 25px;
  height: 25px;
  border-radius: 100%;
  background-color: rgba(253, 128, 82, 0.5);
}

.bg-pillPeachyOrange :hover {
  width: 40px;
  height: 25px;
  border-radius: 16px;
  background-color: rgba(253, 128, 82, 0.5);
}
</style>
