<template>
  <div>
    <div class="inline px-4 lg:px-0 text-xl font-bold uppercase text-blue">
      Situazione di -
    <span class="flex lg:inline px-4 lg:px-0 text-xl font-bold uppercase text-orange">
      {{ this.$route.params.userName }}
    </span>
    </div>
    <TicketsList></TicketsList>
  </div>
</template>

<script>
import TicketsList from "@/components/TicketsList";
//import Services from "../services";
export default {
  name: "UserTickets.vue",

  components: {
    TicketsList,
  },

  data: () => ({}),

  methods: {},
};
</script>

<style scoped></style>
