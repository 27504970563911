export default {
  namespaced: true,
  state: {
    selected: '',
  },
  mutations: {
    SET_SELECTED(state, value) {
      state.selected = value;
    },
  },
  actions: {
    setSelected({ commit }, value) {
      commit("SET_SELECTED", value);
    },
  },
};
