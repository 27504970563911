<template>
  <div id="user-menu" :class="isMobile ? 'absolute top-0 flex w-full items-center' : ''">
    <a :class="isMobile ? 'order-3' : 'order-none'"
      :href="
        user.user_area_coordinator_or_manager.length > 0
          ? '/guides/GuidaCoordinatori.pdf'
          : '/guides/GuidaDipendenti.pdf'
      "
      target="_blank"
    >
      <img :class="isMobile ? 'w-7 my-6 ml-8' : 'user-menu-icon h-5'" src="@/assets/icons/guida.svg" />
    </a>
    <img
      v-title="'Rubrica'"
      :class="isMobile ? 'order-2 h-7 w-7 my-6 ml-8' : 'order-none user-menu-icon h-7'"
      @click="$router.push('/colleagues').catch((err) => {}), setSelected(null)"
      src="@/assets/icons/rubrica.svg"
    />
    <img
      v-if="user && user.user_info.picture_url"
      class="cursor-pointer rounded-full h-11 w-11"
      :class="isMobile ? 'ml-4 order-1' : 'order-none mx-auto'"
      @click="$router.push('/profile').catch((err) => {}), setSelected(null)"
      :src="user.user_info.picture_url"
    />
    <div
      v-else-if="user"
      class="flex h-11 w-11 rounded-full items-center justify-center text-xl text-white font-bold cursor-pointer bg-gray-300"
      :class="isMobile ? 'ml-4 order-1' : 'order-none'"
      @click="$router.push('/profile').catch((err) => {}), setSelected(null)"
    >
      {{ user.user_info.firstname.slice(0, 1)
      }}{{ user.user_info.lastname.slice(0, 1) }}
    </div>
  </div>
</template>

<script>
import { mapActions, mapState } from "vuex";

export default {
  name: "UserMenu",
    props: {
        isMobile: {
            type: Boolean,
            required: false,
            default: false
        },
    },
  computed: {
    ...mapState("user", { user: "user" }),
  },
    methods:{
        ...mapActions({setSelected: "mobileMenu/setSelected"}),
    }
};
</script>

<style scoped>
.user-menu-icon {
  cursor: pointer;
  margin: 25px auto;
}
</style>
