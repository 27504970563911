<template>
    <div class="h-screen overflow-hidden">
        <div class="hidden lg:flex flex-col float-left bg-orange w-auto items-center h-full px-10">
            <app-menu class="fixed mt-16"></app-menu>
            <user-menu class="fixed bottom-0 mb-8"></user-menu>
        </div>
        <div class="h-full">
            <div
                class="h-screen lg:rounded-l-main bg-white flex flex-col pt-3.5 lg:pt-10 overflow-hidden"
            >
                <div class="w-full inline-flex justify-between mb-3.5 lg:mb-5">
                      <span
                          class="flex cursor-pointer"
                          @click="
                          setTicketStatus(1);
                          selectArea(), setCurrentSector('');
                          $router.push('/').catch((err) => {});
                        "
                      >
                        <img
                            class="float-left ml-6 lg:ml-14	mr-3 h-5 lg:h-12 lg:w-12"
                            src="./assets/images/icona-ticket.svg"
                        />
                        <p class="hidden lg:block font-medium text-blue text-4xl">Ticket</p>
                      </span>
                    <div class="flex flex-row pr-4 h-5 lg:h-8 my-auto">
                        <!-- <img
                                      class="w-5 h-5 mx-4 my-auto text-blue cursor-pointer"
                                      src="./assets/icons/notifiche.svg"
                                    /> -->
                        <input
                            v-if="!isSearching"
                            class="hidden lg:block ticket-search"
                            type="text"
                            placeholder="Cerca tra i ticket"
                            v-model="search"
                            @keydown.enter="searchTicketByString(search)"
                        />
                        <div
                            v-else
                            class="hidden lg:block bg-gray-100 rounded-full pl-2 w-96 focus:outline-none"
                        >
                            <Spinner class="h-4 w-4"></Spinner>
                        </div>
                        <img
                            class="w-5 h-5 mx-2 my-auto text-blue cursor-pointer"
                            src="./assets/icons/filtra.svg"
                            v-title="'Filtro ricerca'"
                            @click="$refs.createFilterModal.open()"
                        />
                        <img
                            v-if="$route.name != 'Home' && $route.name != 'Department'"
                            class="w-5 h-5 my-auto text-blue cursor-pointer"
                            v-title="'Indietro'"
                            src="./assets/icons/back.svg"
                            @click="toPreviousPage()"
                        />
                    </div>
                </div>
                <div class="main-height rounded-t-main flex bg-homegray">
                    <SideMenu class="hidden lg:block rounded-tl-3xl" v-if="$route.meta.showSideMenu">
                    </SideMenu>
                    <router-view
                        :key="$route.fullPath"
                        class="w-full pt-2 pb-4 overflow-auto"
                        :class="$route.path == '/' ? '' : 'lg:px-4'"
                    />
                </div>
            </div>
            <MobileMenu class="fixed bottom-0 lg:hidden"></MobileMenu>
        </div>
        <filter-modal ref="createFilterModal"></filter-modal>
    </div>
</template>

<script>
import AppMenu from "./components/AppMenu.vue";
import UserMenu from "./components/UserMenu.vue";
import Services from "@/services/index";
import SideMenu from "@/components/SideMenu";
import MobileMenu from "@/components/MobileMenu";
import FilterModal from "./components/FilterModal";
import {mapActions, mapState} from "vuex";
import Spinner from "@/components/Spinner";

export default {
    components: {
        Spinner,
        AppMenu,
        UserMenu,
        FilterModal,
        SideMenu,
        MobileMenu,
    },

    data: () => ({
        isSearching: false,
        search: "",
        userSections: [],
        areaName: "",
        areaId: 0,
        divisionName: "",
        divisionId: 0,
    }),

    computed: {
        ...mapState("user", {user: "user"}),
        ...mapState("tickets", {tickets: "tickets"}),
        ...mapState("user", {ticketStatus: "ticketStatus"}),
        ...mapState("user", {userDivisions: "userDivisions"}),
        ...mapState("user", {currentArea: "currentArea"}),
        userChanged() {
            return this.user;
        },
    },

    methods: {
        ...mapActions({setUser: "user/setUser"}),
        ...mapActions({setTickets: "tickets/setTickets"}),
        ...mapActions({setAreas: "user/setAreas"}),
        ...mapActions({setUserDivisions: "user/setUserDivisions"}),
        ...mapActions({setCurrentArea: "user/setCurrentArea"}),
        ...mapActions({setCurrentAreaId: "user/setCurrentAreaId"}),
        ...mapActions({setCurrentSector: "user/setCurrentSector"}),
        ...mapActions({setCurrentSectorId: "user/setCurrentSectorId"}),
        ...mapActions({setTicketStatus: "user/setTicketStatus"}),
        ...mapActions({setFilters: "tickets/setFilters"}),
        ...mapActions({setLastPage: "tickets/setLastPage"}),
        ...mapActions({setPriorities: "tickets/setPriorities"}),

        async getUserDivisions() {
            try {
                this.setUserDivisions([]); //Se resto inattivo per qualche minuto e ritorno sul browser,
                // mi aggiunge due volte gli stessi settori nei tab in alto sopra la ticketList (esempio: ho infrastruttua e frontend -
                // vengono duplicati infrastruttura frontend infrastruttura frontend)
                const response = await Services.getUserDivisions({
                    user: this.user.id,
                });
                for (let i in response) {
                    this.userSections.push({
                        sectionId: response[i].id,
                        sectionName: response[i].name,
                    });
                    this.userDivisions.push(this.userSections[i]);
                }
            } catch (error) {
                console.log(error);
            }
        },

        async selectArea() {
            if (
                this.$route.name === "MoreDepartmentTickets" ||
                this.$route.name === "userTickets" ||
                this.$route.name === "Department" ||
                this.$route.name === "AllTeamMembers"
            ) {
                if (this.$route.name === "MoreDepartmentTickets") {
                    this.setTicketStatus(1);
                } else if (this.$route.name === "userTickets") {
                    this.setTicketStatus(2);
                }
                this.divisionName = "";
                await this.getAreaManagement();
                this.setCurrentArea(this.areaName);
                this.setCurrentAreaId(this.areaId);
                await this.getDivision();
                this.setCurrentSector(this.divisionName);
                this.setCurrentSectorId(this.divisionId);
            }
            if (this.user.is_admin == 1 && this.$route.name != "Department") {
                this.setCurrentArea("Tutti");
            } else {
                if (
                    this.$route.name != "Department" &&
                    this.$route.name != "MoreDepartmentTickets" &&
                    this.$route.name != "AllTeamMembers"
                ) {
                    this.setCurrentArea("I miei");
                }
            }
        },

        toPreviousPage() {
            if (this.ticketStatus == null) {
                //Nella pagina del coordinatore, se voglio vedere la situazione del dipendente e clicco sul nome, perdo traccia
                //dello status (status=null) nel menù quindi quando torno indietro non sa che status settare e non carica i ticket
                this.setTicketStatus(1);
            }
            this.$router.go(-1);
        },

        async getPriorities() {
            const response = await Services.getPriorities();
            this.setPriorities(response);
        },

        async getAllAreas() {
            const response = await Services.getAllAreas();
            this.setAreas(response);
        },

        async getAreaManagement() {
            try {
                const response = await Services.getAreaManagement({
                    area: this.$route.params.id,
                });
                this.areaName = response.name;
                this.areaId = response.id;
            } catch (error) {
                console.log(error);
            }
        },

        async getDivision() {
            const response = await Services.getDivision(this.$route.params.sectorId);
            this.divisionName = response.data.division[0].name;
            this.divisionId = response.data.division[0].id;
        },

        async searchTicketByString(str) {
            this.isSearching = true;
            try {
                const response = await Services.searchTicket({
                    search: str,
                });
                this.setFilters({search: str});
                this.setLastPage(parseInt(response.data.ticket.last_page));
                this.setTickets(response.data.ticket.data);
                if (this.$route.path !== "/filterResults") {
                    this.$router.push("/filterResults/:search");
                }
            } catch (error) {
                console.log(error);
            }
            this.isSearching = false;
        },
    },
    watch: {
        userChanged() {
            this.getUserDivisions();
            this.getAllAreas();
            this.getPriorities();
            this.selectArea();
        },
    },
};
</script>

<style>
html {
    font-family: "Lato", sans-serif;
}

body {
    background-color: #fd7f50 !important;
}

.main-height {
    height: calc(100vh - 108px);
}
</style>