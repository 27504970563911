<template>
	<Modal
		card-class="w-full sm:w-5/6 md:w-1/2 xl:w-4/12 2xl:w-3/12 rounded-0"
		ref="createUserModal"
	>
		<template #header="{close}">
			<div class="flex flex-col justify-end pt-6 px-6 leading-none">
				<div @click.prevent="close" class="cursor-pointer text-orange text-2xl">
					<svg
						class="float-right"
						xmlns="http://www.w3.org/2000/svg"
						width="20.086"
						height="20.086"
						viewBox="0 0 20.086 20.086"
					>
						<g
							id="Raggruppa_2175"
							data-name="Raggruppa 2175"
							transform="translate(1.414 1.414)"
						>
							<line
								id="Linea_10"
								data-name="Linea 10"
								x2="17.258"
								y2="17.258"
								fill="none"
								stroke="#000000"
								stroke-linecap="round"
								stroke-width="2"
							/>
							<line
								id="Linea_11"
								data-name="Linea 11"
								x1="17.258"
								y2="17.258"
								fill="none"
								stroke="#000000"
								stroke-linecap="round"
								stroke-width="2"
							/>
						</g>
					</svg>
				</div>
				<div class="text-center p-10">
					<img
						v-if="user.user_info.picture_url"
						class="block h-20 w-20 2xl:h-36 2xl:w-36 mx-auto rounded-full shadow-orange"
						:src="user.user_info.picture_url"
					/>
					<div
						v-else
						class="flex items-center justify-center pb-1 2xl:pb-2.5 h-24 w-24 2xl:h-36 2xl:w-36 mx-auto rounded-full shadow-orange bg-gray-200 text-white font-bold text-5xl 2xl:text-7xl"
					>
						{{ user.user_info.firstname.slice(0, 1)
						}}{{ user.user_info.lastname.slice(0, 1) }}
					</div>
					<p v-if="user.user_info" class="text-blue mt-3 text-xl lg:text-3xl font-medium">
						{{ user.user_info.name }}
					</p>
					<p v-else class="text-blue mt-3 text-xl lg:text-3xl font-medium">
						{{ user.areas[0].name }}
					</p>
					<div v-for="area in user.areas" :key="area.id">
						<p class="text-orange lg:text-lg my-3">
							{{ area.name }}
						</p>
					</div>
					<p v-if="user.user_info" class="text-blue lg:text-xl font-medium mb-4 break-all">
						{{ user.user_info.email }}
					</p>
					<p v-else class="text-blue lg:text-xl font-medium mb-4 break-all">
						{{ user.areas[0].email }}
					</p>
				</div>
			</div>
		</template>
		<template #body>
			<vueper-slides
				v-if="user.workstations.length > 0"
				arrows-outside
				bullets-outside
				:infinite="false"
				:touchable="false"
				:gap="10"
				fixed-height="200px"
				class="no-shadow rounded-lg mx-10 lg:mx-14 focus:outline-none"
			>
				<vueper-slide
					v-for="workstation in user.workstations"
					:key="workstation.id"
					class="rounded-lg bg-lightgray flex justify-center place-items-center"
				>
					<template v-slot:content>
						<div>
							<div>
								<img
									class="inline-block mr-5"
									src="@/assets/icons/location.svg"
								/>
								<p class="inline-block text-blue font-medium">
									{{ workstation.headquarter.location }}
								</p>
							</div>
							<div class="mt-5">
								<img class="inline-block mr-5" src="@/assets/icons/piano.svg" />
								<p class="inline-block text-blue font-medium">
									Piano {{ workstation.floor }}
								</p>
							</div>
							<div class="my-5">
								<img
									class="inline-block mr-5"
									src="@/assets/icons/stanza.svg"
								/>
								<p class="inline-block text-blue font-medium">
									Stanza {{ workstation.room }}
								</p>
							</div>
							<div>
								<img
									class="inline-block mr-5"
									src="@/assets/icons/telefono.svg"
								/>
								<p class="inline-block text-blue font-medium">
									{{ workstation.phone }}
								</p>
							</div>
						</div>
					</template>
				</vueper-slide>
			</vueper-slides>
		</template>
	</Modal>
</template>

<script>
import Modal from "@/components/Modal";
import { VueperSlides, VueperSlide } from "vueperslides";
import "vueperslides/dist/vueperslides.css";

export default {
	name: "UserModal",

	data: () => ({
		user: {},
	}),

	components: {
		Modal,
		VueperSlides,
		VueperSlide,
	},

	methods: {
		open(value) {
			this.$refs.createUserModal.open();
			this.user = value;
		},
	},
};
</script>

<style>
.vueperslides__arrow {
	color: #fd7f50;
}

.vueperslides__bullet {
	color: #fd7f50 !important;
}

.vueperslides__arrow:focus {
	outline: none;
}

.vueperslides__bullet:focus {
	outline: none;
}
</style>
