<template>
    <div>
        <div class="px-4 lg:px-0">
           <span
               v-if="ticketStatus != null"
               class="lg:float-left text-xl text-blue font-bold uppercase w-44"
           >
          <div v-if="this.$route.name === 'userTickets'">
            <!-- Se sono sulla pagina 'situazione di ...' - ( component userTickets) -->
            <span v-if="ticketStatus != 1" class="mb-2">
              {{ title }}
            </span>
            <span v-else>
              Tutti i ticket
            </span>
          </div>
          <div v-else class="pl-0">
            <!-- Se sono sulla home-->
            {{ title }}
          </div>
        </span>
            <div class="flex lg:hidden justify-end">
                <page-navigation
                    class="absolute top-14"
                    :buttonSize="7"
                    :currentPage="currentPage"
                    :lastPage="lastPage"
                    @next-page="
          currentPage++;
          getTickets();
        "
                    @prev-page="
          currentPage--;
          getTickets();
        "
                ></page-navigation>
            </div>

        <VuePerfectScrollBar
            class="mt-4 lg:mt-0 pb-4 whitespace-nowrap"
            style="max-width:1400px"
            :settings="{ swipeEasing: true, useBothWheelAxes: true }"
        >
            <div
                v-if="
          this.$route.name != 'MoreDepartmentTickets' &&
            this.$route.name != 'userTickets' &&
            this.$route.name != 'SentTickets'
        " class="2xl:text-base 2xl:py-0 lg:text-sm lg:py-1"
            >
                <button
                    :disabled="selectedSection == 0"
                    class="w-auto px-4 mx-2 border-px font-medium rounded-md
                 hover:bg-orange hover:text-white focus:outline-none"
                    :class="
            selectedSection == 0
              ? 'bg-orange text-white'
              : 'text-orange bg-white'
          "
                    @click="(selectedSection = 0), getTickets()"
                >
          <span class="mx-auto 2xl:text-base lg:text-sm">
            Tutti
          </span>
                </button>

                <button
                    v-for="division in userDivisions"
                    :key="division.sectionId"
                    class="inline-block w-auto px-4 whitespace-nowrap mx-2 border-px font-medium rounded-md
                 hover:bg-orange hover:text-white focus:outline-none"
                    :class="
            selectedSection == division.sectionId
              ? 'bg-orange text-white'
              : 'text-orange bg-white'
          "
                    @click="
            selectedSection = division.sectionId;
            getTickets();
          "
                    :disabled="selectedSection == division.sectionId"
                >
          <span
              class="mx-auto truncate overflow-ellipsis 2xl:text-base lg:text-sm"
          >
            {{ division.sectionName }}
          </span>
                </button>
            </div>
            <div v-else-if="this.$route.name === 'SentTickets'" class="2xl:text-base 2xl:py-0 lg:text-sm lg:py-1">
                <button
                    class="w-auto px-4 mx-2 border-px font-medium rounded-md
                 hover:bg-orange hover:text-white focus:outline-none"
                    :class="
            ticketStatus == 5 ? 'bg-orange text-white' : 'text-orange bg-white'
          "
          :disabled="ticketsLoading || ticketStatus == 5"
          @click="setTicketStatus(5)"
        >
          <span class="mx-auto">
            Tutti
          </span>
                </button>
                <button
                    class="inline-block w-auto px-4 whitespace-nowrap mx-2 border-px font-medium rounded-md
                 hover:bg-orange hover:text-white focus:outline-none"
                    :class="
            ticketStatus == 1 ? 'bg-orange text-white' : 'text-orange bg-white'
          "
          :disabled="ticketsLoading || ticketStatus == 1"
          @click="setTicketStatus(1)"
        >
          <span class="mx-auto">
            Nuovi
          </span>
                </button>
                <button
                    class="inline-block w-auto px-4 whitespace-nowrap mx-2 border-px font-medium rounded-md
                 hover:bg-orange hover:text-white focus:outline-none"
                    :class="
            ticketStatus == 2 ? 'bg-orange text-white' : 'text-orange bg-white'
          "
          :disabled="ticketsLoading || ticketStatus == 2"
          @click="setTicketStatus(2)"
        >
          <span class="mx-auto">
            Assegnati
          </span>
                </button>
                <button
                    class="inline-block w-auto px-4 whitespace-nowrap mx-2 border-px font-medium rounded-md
                 hover:bg-orange hover:text-white focus:outline-none"
                    :class="
            ticketStatus == 3 ? 'bg-orange text-white' : 'text-orange bg-white'
          "
          :disabled="ticketsLoading || ticketStatus == 3"
          @click="setTicketStatus(3)"
        >
          <span class="mx-auto">
            In lavorazione
          </span>
                </button>
                <button
                    class="inline-block w-auto px-4 whitespace-nowrap mx-2 border-px font-medium rounded-md
                 hover:bg-orange hover:text-white focus:outline-none"
                    :class="
            ticketStatus == 4 ? 'bg-orange text-white' : 'text-orange bg-white'
          "
          :disabled="ticketsLoading || ticketStatus == 4"
          @click="setTicketStatus(4)"
        >
          <span class="mx-auto">
            Chiusi
          </span>
                </button>
            </div>
        </VuePerfectScrollBar>
</div>
        <!-- Filtri personalizzati
         <button
            v-for="filter in favoriteFilters"
            :key="filter.id"
            class="flex h-7 w-auto mx-4 truncate border text-orange font-medium items-center rounded-md
                   focus:bg-orange focus:text-white focus:border-none focus:outline-none"
            @click="getTickets()"
          >
            <button
              class="ml-3 focus:outline-none"
              @click="deleteFilter(filter.id)"
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                class="w-3 h-3"
                width="17.309"
                height="17.308"
                viewBox="0 0 17.309 17.308"
              >
                <path
                  id="Unione_1"
                  data-name="Unione 1"
                  d="M15.089,16.927,8.654,10.492,2.219,16.927A1.3,1.3,0,0,1,.381,15.089L6.816,8.654.382,2.219A1.3,1.3,0,0,1,2.219.382L8.654,
              6.816,15.089.381a1.3,1.3,0,0,1,1.839,1.839L10.492,8.654l6.435,6.435a1.3,1.3,0,1,1-1.838,1.838Z"
                  fill="#FD7F50"
                />
              </svg>
            </button>
            <span class="mx-2 text-md">
              {{ filter.name }}
            </span>
          </button>-->

        <div
            v-if="tickets.length > 0 && ticketsLoading == false"
            class="shadow-md h-auto border border-gray-300 rounded-lg bg-white"
            :class="$route.name != 'Home' ? 'mt-4' : 'mt-2'"
        >
            <div class="hidden lg:flex px-2 w-full rounded-lg bg-white pt-2">
                <div class="w-2"></div>
                <div class="flex w-full items-center cursor-pointer">
                    <div
                        class="font-medium text-blue text-center 2xl:text-base lg:text-xs lg:pl-1 xl:pl-0 lg:my-auto"
                        :class="[title === 'INVIATI' ? 'lg:w-24 xl:w-20' : 'lg:w-18 xl:w-20', title === 'IN LAVORAZIONE' || title === 'CHIUSI' ? 'lg:w-20' : '']"
                    >
                        N. Ticket
                    </div>

                    <!-- colonna con icone solleciti/in evidenza -->
                    <div :class="title === 'INVIATI' ? 'lg:w-16 xl:w-24' : 'lg:w-14 xl:w-20'"></div>
                    <div v-if="(this.$route.name === 'userTickets') && ticketStatus === 1
                                || (this.$route.name === 'SentTickets' && ticketStatus === 5)"
                        class="font-medium text-blue 2xl:w-40 lg:w-36 2xl:text-base lg:text-xs">
                        Stato ticket
                    </div>
                    <div
                        v-if="title != 'INVIATI'"
                        class="font-medium text-blue w-2/12 2xl:text-base lg:text-xs lg:my-auto"
                    >
                        Mittente
                    </div>

                    <div
                        class="font-medium text-blue 2xl:text-base lg:text-xs lg:my-auto"
                        :class="[title === 'NUOVI' || title === 'ASSEGNATI' ? '2xl:w-3/12 lg:w-52' : 'w-2/12',
           title === 'INVIATI' ? 'xl:w-56 xl:pl-2 lg:w-40' : '', title === 'IN LAVORAZIONE' || title === 'CHIUSI' ? '2xl:w-2/12 lg:w-40' : '']"
                    >
                        Titolo
                    </div>
                    <div
                        class="font-medium text-blue 2xl:text-base lg:text-xs lg:my-auto"
                        :class="[title === 'Nuovi' || title === 'Assegnati' ? 'w-2/12' : 'w-2/12', title === 'INVIATI' ? '2xl:w-2/12 lg:w-44' : '']"
                    >
                        Data apertura
                    </div>
                    <div
                        v-if="
                                title === 'IN LAVORAZIONE' ||
                                  title === 'CHIUSI' ||
                                  title === 'INVIATI' ||
                                  title === 'PREFERITI'
                              "
                        class="font-medium text-blue overflow-ellipsis truncate 2xl:text-base lg:text-xs lg:my-auto"
                        :class="title === 'IN LAVORAZIONE' ? 'lg:w-40 xl:w-44 2xl:w-2/12 lg:pl-2 xl:pl-0 ' : title === 'CHIUSI' ? 'lg:w-36 xl:w-44 2xl:w-2/12 lg:pl-2 xl:pl-0' : 'lg:w-44 2xl:w-2/12'"
                    >
                      <span>
                        Presa in carico
                      </span>
                    </div>
                    <div
                        v-if="title != 'CHIUSI' && title != 'INVIATI' && title != 'PREFERITI'"
                        class="font-medium text-blue 2xl:text-base lg:text-xs lg:my-auto"
                        :class="title === 'IN LAVORAZIONE' ? 'lg:w-28 xl:w-44' : 'lg:w-32 xl:w-44'"
                    >
                        Priorità
                    </div>
                    <div
                        v-if="
                                title === 'CHIUSI' || title === 'INVIATI' || title === 'PREFERITI'
                              "
                        class="font-medium text-blue 2xl:text-base lg:text-xs lg:my-auto"
                        :class="title === 'INVIATI' ? 'xl:w-52 lg:w-32' : title === 'CHIUSI' ? 'w-2/12 lg:w-32 xl:w-2/12' : 'w-2/12'"
                    >
                        <span class="hidden xl:flex">
                            Data chiusura
                        </span>
                        <span class="xl:hidden">
                            Chiusura
                        </span>
                    </div>
                    <!--<div v-if="title === 'PREFERITI'" class="w-32 font-medium text-blue">
                      Area
                    </div>-->
                    <div v-if="title === 'INVIATI'"
                         class="w-44 font-medium text-blue 2xl:text-base lg:text-xs lg:my-auto">
                        Assegnato
                    </div>
                    <div
                        class="font-medium text-blue 2xl:text-base lg:text-xs lg:my-auto"
                        :class="[title === 'INVIATI' ? 'xl:w-56 lg:w-36' : title === 'IN LAVORAZIONE' || title === 'CHIUSI' ? '2xl:w-48 lg:w-36 2xl:mr-0 lg:mr-4' : '2xl:w-48 lg:w-44 2xl:mr-0 lg:mr-4']"
                    >
                        Area-Settore
                    </div>
                    <div
                        class="font-medium text-right text-blue"
                        :class="[title === 'NUOVI' || title === 'ASSEGNATI' ? '2xl:w-2/12 lg:w-1/12 ' : '2xl:w-48 lg:w-24',
                  title === 'INVIATI' ? '2xl:block lg:hidden ' : '']"
                    >
                    </div>
                </div>
            </div>
            <div class="pb-2 rounded-t-lg lg:rounded-t-none rounded-b-lg bg-white">
                <ticket-box
                    v-for="ticket in tickets"
                    :key="ticket.id"
                    :type="title"
                    :content="ticket"
                ></ticket-box>
            </div>
        </div>

        <div v-if="ticketsLoading" class="2xl:my-72 lg:my-52">
            <Spinner class="inset-0"></Spinner>
        </div>
        <div
            v-else-if="tickets.length === 0"
            class="mt-4 shadow-md text-base border border-gray-300 rounded-lg bg-white"
        >
            <div class="text-center 2xl:text-base lg:text-sm py-4">
                Non sono presenti ticket.
            </div>
        </div>
        <page-navigation
            class="mt-6 hidden lg:flex absolute bottom-6 right-4"
            :buttonSize="7"
            :currentPage="currentPage"
            :lastPage="lastPage"
            @next-page="
        currentPage++;
        getTickets();
      "
            @prev-page="
        currentPage--;
        getTickets();
      "
        ></page-navigation>
        <prompt-modal
            ref="errorModal"
            :title="'Errore'"
            :message="errorMessage"
            :confirm="false"
        >
        </prompt-modal>
    </div>
</template>

<script>
import VuePerfectScrollBar from "vue-perfect-scrollbar";
import TicketBox from "@/components/TicketBox.vue";
import PageNavigation from "@/components/PageNavigation.vue";
import Services from "@/services";
import Spinner from "@/components/Spinner.vue";
import PromptModal from "@/components/PromptModal";
import {mapActions, mapState} from "vuex";

export default {
    name: "TicketsList",

    components: {
        VuePerfectScrollBar,
        TicketBox,
        Spinner,
        PageNavigation,
        PromptModal,
    },

    props: {
        filter: {
            filter: String,
        },
    },

    data: () => ({
        title: "",
        favoriteFilters: [],
        showAllTickets: true,
        showSectorTickets: false,
        userSections: [],
        //I contatori serviranno per aggiornare i valori del component TicketStatus sulla Home
        ticketsCounter: {
            newTickets: 0,
            assignedTickets: 0,
            processingTickets: 0,
            closedTickets: 0,
        },
        ticketsLoading: false,
        selectedSection: 0,
        currentPage: 1,
        lastPage: null,
        errorMessage: "",
    }),

    created() {
        //Se la ticketList viene renderizzata sulla Home
        if (this.$route.path == "/") {
            //this.getFilters(); //Filtri preferiti
            this.getTickets(1); // di default, vengono caricati i ticket nuovi (da assegnare)
        }
        //Se la ticketList viene renderizzata altrove...
        else {
            // ...su my-areas (schermata del coordinatore - component TeamMembers)
            // - click su 'Di più' della tabella tickets - non ho Inviati e Preferiti
            if (this.ticketStatus == null) {
                this.setTicketStatus(1);
            }
            if (this.ticketStatus == 1) {
                this.title = "NUOVI";
            } else if (this.ticketStatus == 2) {
                this.title = "ASSEGNATI";
            } else if (this.ticketStatus == 3) {
                this.title = "IN LAVORAZIONE";
            } else {
                this.title = "CHIUSI";
            }
            this.getTickets();
        }
    },

    computed: {
        ...mapState("user", {user: "user", ticketStatus: "ticketStatus"}),
        ...mapState("tickets", {tickets: "tickets"}),
        ...mapState("user", {currentArea: "currentArea"}),
        ...mapState("user", {currentAreaId: "currentAreaId"}),
        ...mapState("user", {currentSectorId: "currentSectorId"}),
        ...mapState("user", {userDivisions: "userDivisions"}),
    },

    methods: {
        ...mapActions({setTickets: "tickets/setTickets"}),
        ...mapActions({setCurrentArea: "user/setCurrentArea"}),
        ...mapActions({setTicketStatus: "user/setTicketStatus"}),

        /*async getUserDivisions() {
          try {
            const response = await Services.getUserDivisions({
              user: this.user.id,
            });
            for (let i in response) {
              this.userSections.push({
                sectionId: response[i].id,
                sectionName: response[i].name,
              });
            }
          } catch (error) {
            console.log(error);
          }
        },*/

    async getTickets() {
      //Metodo che viene richiamato ogni volta che clicco su una tipologia di ticket nel menu laterale (component TicketStatus)
      try {
        this.ticketsLoading = true;
        let filter = {};
        filter["new"] = 1;
        switch (this.$route.path) {
          case "/":
            filter = {};
            filter["new"] = 1;
            break;
          case "/assignedTickets":
            filter = {};
            filter["assigned"] = 1;
            break;
          case "/processingTickets":
            filter = {};
            filter["in_process"] = 1;
            break;
          case "/closedTickets":
            filter = {};
            filter["closed"] = 1;
            break;
          case "/sentTickets":
            filter = {};
            filter["send"] = 1;
            break;
        }
        if (this.selectedSection != 0) {
          this.showSectorTickets = true;
          this.showAllTickets = false;
          filter["division"] = [this.selectedSection];
        }
        if (
          this.$route.path ===
            "/showAllTickets/" +
              this.$route.params.id +
              "/" +
              this.$route.params.sectorId ||
          this.$route.path === "/showAllTickets/" + this.$route.params.id
        ) {
          //In questo caso devo renderizzare TicketsList sul click 'Di più' di departmentTickets che mi porta a MoreDepartmentTickets
          if (!this.$route.params.sectorId) {
            filter = { status: this.ticketStatus, area: this.$route.params.id };
          } else {
            let division = [this.$route.params.sectorId];
            filter = {
              status: this.ticketStatus,
              area: this.$route.params.id,
              division: division,
            };
          }
        } else if (this.$route.name === "userTickets") {
          //Renderizzo la TicketsList del singolo utente (es. "Situazione di Stefano Lepre") - pagina UserTickets
          if (this.ticketStatus === 2) {
            filter = {
              status: this.ticketStatus,
              processing_user: this.$route.params.id,
              area: this.currentAreaId,
            };
          } else if (this.ticketStatus === 3) {
            filter = {
              status: this.ticketStatus,
              processing_user: this.$route.params.id,
              area: this.currentAreaId,
            };
          } else if (this.ticketStatus === 4) {
            filter = {
              status: 4,
              processing_user: this.$route.params.id,
              area: this.currentAreaId,
            };
          } else {
            if (this.currentSectorId != undefined) {
              let division = [this.currentSectorId];
              filter = {
                processing_user: this.$route.params.id,
                area: this.currentAreaId,
                division: division,
              };
            } else {
              filter = {
                processing_user: this.$route.params.id,
                area: this.currentAreaId,
              };
            }
          }
        } else if (this.$route.name === "SentTickets") {
          if (this.ticketStatus == 5) {
            //ultima condizione dello switch
            filter = {};
            filter["send"] = true;
          } else if (this.ticketStatus == 1) {
            filter = {};
            filter = {
              send: true,
              status: 1,
            };
          } else if (this.ticketStatus == 2) {
            filter = {};
            filter = {
              send: true,
              status: 2,
            };
          } else if (this.ticketStatus == 3) {
            filter = {};
            filter = {
              send: true,
              status: 3,
            };
          } else if (this.ticketStatus == 4){
            filter = {};
            filter = {
              send: true,
              status: 4,
            };
          }
        }
        const response = await Services.getAllTickets(filter, this.currentPage);
        this.lastPage = parseInt(response.data.ticket.last_page);
        this.setTickets(response.data.ticket.data);
        this.setTicketType();
        this.ticketsLoading = false;
      } catch (error) {
        this.$refs.errorModal.open();
        this.$refs.errorModal.close();
        this.errorMessage = "";
        this.errorMessage = "Errore nel caricamento dei ticket.";
        console.log(error);
      }
    },

        setTicketType() {
            /*
              Controllo fatto sul bottone premuto in TicketStatus sulla home (Nuovi, Assegnati, ecc.).
              In base al valore di ticketStatus dello store, setto il valore di title in modo tale da impostare
              le colonne della ticket box in base alla tipologia di ticket selezionata.
              Esempio: la colonna 'Data chiusura' non deve uscire se il title è NUOVI
            */
            if (this.ticketStatus === 1 && this.$route.name != "SentTickets") {
                //this.filter.status
                this.title = "NUOVI";
            } else if (this.ticketStatus === 2 && this.$route.name != "SentTickets") {
                this.title = "ASSEGNATI";
            } else if (this.ticketStatus === 3 && this.$route.name != "SentTickets") {
                this.title = "IN LAVORAZIONE";
            } else if (this.ticketStatus == 4 && this.$route.name != "SentTickets") {
                this.title = "CHIUSI";
            } else if ((this.ticketStatus == 5 || this.$route.name === "SentTickets") && this.$route.name != 'userTickets') {
                this.title = "INVIATI";
            } else {
              this.title = "TUTTI I TICKET";
            }
            return this.title;
        },

        /*async getFilters() {
          const response = await Services.getUserFilters();
          for (let i in response.data.user_filter) {
            this.favoriteFilters.push(response.data.user_filter[i]);
          }
        },*/

        /*async deleteFilter(id) {
          const response = await Services.deleteUserFilter({ id: [id] });
          //Quando rimuovo un filtro (con la 'X') aggiorno la lista dei filtri salvati
          this.favoriteFilters = [];
          await this.getFilters();
          return response;
        },*/
    },

  watch: {
    ticketStatus: function() {
      this.currentPage = 1;
      if (
        this.$route.name === "MoreDepartmentTickets" ||
        this.$route.name === "userTickets" ||
        this.$route.name === "SentTickets"
      ) {
        this.getTickets();
      }
    },

        selectedSection: function () {
            this.currentPage = 1;
            //this.getTickets();
        },
    },
};
</script>
