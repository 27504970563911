<template>
  <div class="h-full lg:flex flex-col">
    <ticket-detail
      ref="ticketDetail"
      :ticketProp="ticket"
      @status_change="getTicket($route.params.id)"
    ></ticket-detail>
    <div
      class="hidden lg:flex flex-col flex-grow"
      v-if="
        ticket.status.status == 'ASSEGNATO' ||
          ticket.status.status == 'IN LAVORAZIONE' ||
          ticket.status.status == 'CHIUSO'
      "
    >
      <h3 class="font-medium text-blue text-2xl mt-2">MESSAGGI</h3>
      <messages
        :area="ticket.area.id"
        :answers="ticket.answers"
        :ticket_status="ticket.status.status"
        class="hidden lg:flex mt-2 flex-col h-full bg-white border border-gray-300 rounded-lg p-3"
      ></messages>
    </div>
    <div
      v-else
      class="hidden lg:flex flex-col flex-grow bg-white rounded-md border-px border-gray-300 mt-10 py-5"
    >
      <p class="text-blue text-center text-3xl font-bold mb-10">Descrizione</p>
      <p
        class="flex-grow text-blue font-medium p-5 bg-gray-100 mx-8 rounded-lg"
      >
        {{ ticket.text }}
      </p>
      <div
        v-if="ticket.attachments.length > 0"
        class="flex flex-col-reverse mt-6"
      >
        <div class="shadow-inner rounded-md bg-gray-100 mx-8 px-10 mt-4">
          <VuePerfectScrollbar
            class="py-10"
            :settings="{ swipeEasing: true, useBothWheelAxes: true }"
          >
            <div class="whitespace-nowrap">
              <a
                v-for="attachment in ticket.attachments"
                :key="attachment.id"
                :href="attachment.url"
                target="_blank"
                rel="noopener"
                class="text-blue border-px border-blue mr-10 cursor-pointer bg-gray-50 p-2 rounded-lg text-sm"
                >{{ attachment.filename }}</a
              >
            </div>
          </VuePerfectScrollbar>
        </div>

        <div>
          <img
            class="block float-left h-9 w-9 ml-6"
            src="@/assets/icons/allegato.svg"
          />
          <p class="text-blue text-3xl font-bold px-5">Allegati</p>
        </div>
      </div>
    </div>

    <prompt-modal
      ref="confirmChangesModal"
      :title="'Conferma Modifiche'"
      :message="'Sono state apportate delle modifiche. Vuoi salvarle?'"
      :dismiss_label="'Continua senza Salvare'"
      @dismiss="
        dismiss = true;
        $router.push(nextRoute);
      "
      @action="
        $refs.confirmChangesModal.close();
        $refs.ticketDetail.updateTicket(nextRoute);
      "
    >
    </prompt-modal>

    <div
      class="lg:hidden fixed flex place-items-center justify-center bg-orange rounded-full cursor-pointer right-7 bottom-20 shadow-xl"
      style="height: 58.77px; width: 58.77px"
      @click="mobile_messages = true"
    >
      <img
        height="31.44px"
        width="32.25px"
        src="@/assets/icons/mobile/messages.svg"
      />
    </div>

    <div
      class="absolute mobile-height-calc w-full px-2 bg-white shadow-mobile-popup rounded-t-3xl bottom-0 mb-14 overflow-auto"
      :class="mobile_messages ? 'lg:hidden' : 'hidden'"
    >
      <div
        class="flex justify-between place-items-center mt-5 leading-none px-4"
      >
        <div id="nav">
          <span
            id="messaggi_menu"
            class="p-1 selected text-xl font-semibold cursor-pointer"
            @click="
              toggleSelected('messaggi_menu');
              content = 'messages';
            "
            >Messaggi</span
          >
          <span
            id="descrizione_menu"
            class="p-1 text-xl font-semibold ml-4 cursor-pointer"
            @click="
              toggleSelected('descrizione_menu');
              content = 'description';
            "
            >Descrizione</span
          >
        </div>

        <span @click="mobile_messages = false" class="cursor-pointer text-2xl">
          <img src="@/assets/icons/X.svg" />
        </span>
      </div>

      <div id="content" class="inner-mobile-height-calc flex flex-col mx-5">
        <div v-if="content == 'messages'" class="flex-grow">
          <div
            v-if="ticket.answers.length == 0"
            class="text-blue font-medium mt-4 text-center"
          >
            Non ci sono messaggi
          </div>
          <div v-else class="overflow-auto">
            <chat-message
              v-for="answer in ticket.answers"
              :key="answer.id"
              :message="answer"
              :time="answer.answer_timestamp ? answer.answer_timestamp : date"
              class="my-5"
            />
          </div>
        </div>
        <div v-if="content == 'messages'" class="flex flex-row bg-gray-100">
          <input
            type="text"
            v-model="message.text"
            class="bg-gray-100 p-1"
            placeholder="Rispondi..."
            size="30"
          />
          <div class="bg-gray-500 w-px"></div>
          <div class="flex flex-row justify-between w-full p-2.5">
            <img src="@/assets/icons/allegato.svg" />
            <img src="@/assets/icons/invia.svg" @click="createAnswer()" />
          </div>
        </div>

        <div class="px-5 py-3" v-if="content == 'description'">
          <p class="text-blue font-medium p-5 bg-gray-100 rounded-lg my-5">
            {{ ticket.text }}
          </p>
          <div v-if="ticket.attachments.length > 0" id="attachments">
            <div class="flex place-items-center">
              <img
                class="block float-left h-6 w-6"
                src="@/assets/icons/allegato.svg"
              />
              <p class="text-blue font-bold pb-0.5 ml-1">Allegati</p>
            </div>
            <div
              class="flex flex-col bg-gray-100 border-px border-gray-200 rounded-lg my-2.5 p-3 text-gray-normal font-medium"
            >
              <p class="text-lg">{{ description }}</p>

              <a
                v-for="attachment in ticket.attachments"
                :key="attachment.id"
                :href="attachment.url"
                target="_blank"
                class="truncate cursor-pointer whitespace-nowrap bg-white py-1.5 px-3 rounded-md border-px border-orange text-orange mr-2 mb-2"
              >
                {{ attachment.filename }}
              </a>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import VuePerfectScrollbar from "vue-perfect-scrollbar";
import TicketDetail from "@/components/TicketDetail.vue";
import Messages from "../components/Messages.vue";
import ChatMessage from "../components/ChatMessage.vue";
import Services from "@/services/index.js";
import PromptModal from "@/components/PromptModal.vue";
import { mapActions, mapState } from "vuex";

export default {
  name: "Ticket",

  data: () => ({
    ticket: {},
    message: {
      ticket_id: 0,
      text: "",
      close: 0,
      attachments: [],
      area: 0,
    },
    dismiss: false,
    nextRoute: "",
    prevRouteName: "",
    mobile_messages: false,
    content: "messages",
  }),

  computed: {
    ...mapState("user", { user: "user", ticketStatus: "ticketStatus" }),
  },

  components: {
    VuePerfectScrollbar,
    TicketDetail,
    Messages,
    PromptModal,
    ChatMessage,
  },

  mounted() {
    this.getTicket(this.$route.params.id);
  },

  async beforeRouteLeave(to, from, next) {
    this.nextRoute = to.fullPath;
    if (this.$refs.ticketDetail.modified == true && !this.dismiss) {
      this.$refs.confirmChangesModal.open();
      next(false);
    } else {
      next();
    }
  },

  beforeRouteEnter(to, from, next) {
    next((vm) => {
      vm.prevRouteName = from.name;
    });
  },

  methods: {
    ...mapActions({ setTicketStatus: "user/setTicketStatus" }),

    toggleSelected(el) {
      let element = document.getElementById(el);
      document
        .querySelectorAll("span")
        .forEach((all) => all.classList.remove("selected"));
      element.classList.add("selected");
    },

    async createAnswer() {
      this.message.ticket_id = this.$route.params.id;
      this.message.area = this.ticket.area.id;

      let fd = new FormData();

      fd.append("message", this.message.text);
      fd.append("id", this.message.ticket_id);
      fd.append("close", this.message.close);
      for (let attachment in this.message.attachments) {
        fd.append("attachments[]", this.message.attachments[attachment]);
      }
      fd.append("area", this.message.area);

      this.loading = true;
      await Services.createAnswer(fd)
        .then((response) => {
          if (this.message.close == 1) {
            this.$refs.promptModal.close();
            this.$router.go();
          }
          this.message = {
            text: "",
            ticket_id: this.$route.params.id,
            area: this.area,
            close: 0,
            attachments: [],
          };
          response.data.answer["user"] = this.user;
          this.ticket.answers.push(response.data.answer);
          this.getTicket(this.$route.params.id);
        })
        .catch((err) => {
          this.$toast.open({
            message: "Si è verificato un errore.",
            type: "error",
            position: "bottom",
          });
          this.error = err.response.data.message;
          return err;
        });
      this.loading = false;
    },

    async getTicket(id) {
      try {
        const response = await Services.getTicket(id);
        this.ticket = response.data.ticket;
        if (this.ticketStatus != 1) {
          //i ticket nuovi non hanno processing_user -> l'if successivo produrrebbe errore
          if (
            this.prevRouteName === "userTickets" ||
            this.ticket.status.id === 1 ||
            this.ticket.processing_user.user_info.name ===
              this.user.user_info.name
          ) {
            this.setTicketStatus(this.ticket.status.id);
          }
        }
      } catch (error) {
        console.log(error);
      }
    },
  },
};
</script>

<style scoped>
.mobile-height-calc {
  height: calc(100vh - 4.375rem);
}

.inner-mobile-height-calc {
  height: calc(100vh - 9.2rem);
}

#nav > .selected {
  border-bottom: solid 2px;
  border-bottom-color: #fd7f50;
}
</style>
