<template>
    <div>
        <div class="text-lg mt-4 font-bold ml-4 lg:ml-0 lg:text-center uppercase">
      <span class="text-blue">
        Situazione area -
      </span>
            <!-- se specifico anche il settore " - settore ...-->
            <span class="text-orange">
        {{ currentArea }}
      </span>
            <span v-if="currentSector != ''" class="text-blue"> - </span>
            <span v-if="this.$route.params.sectorId" class="text-blue">
        {{ currentSector }}
      </span>
        </div>

        <!-- Da qui, mostrare tabelle diverse a seconda dell'utente in sessione -->
        <div class="flex lg:hidden mt-2 ml-4 lg:ml-0">
            <div class="flex mr-6"
                 :class="selectedTab === 'tickets' ? 'font-semibold border-b-2 border-orange' : 'opacity-70'"
                 @click="selectedTab = 'tickets'">
                Tickets
            </div>
            <div class="flex"
                 :class="selectedTab === 'teamMembers' ? 'font-semibold border-b-2 border-orange' : 'opacity-70'"
                 @click="selectedTab = 'teamMembers'">
                Team Members
            </div>
        </div>
        <div class="flex">
            <DepartmentTickets :class="selectedTab === 'tickets' ? 'block' : 'hidden lg:block '" :filter="pressedBtn"/>
            <TeamMembers :class="selectedTab === 'teamMembers' ? 'block' : 'hidden lg:block'"></TeamMembers>
        </div>
    </div>
</template>

<script>
import TeamMembers from "./TeamMembers";
import DepartmentTickets from "@/components/DepartmentTickets";
import {mapActions, mapState} from "vuex";

export default {
    name: "DepartmentDetails",

    components: {
        TeamMembers,
        DepartmentTickets,
    },

    async created() {
        if (this.ticketStatus === null && this.$route.name == 'Department') {
            this.setTicketStatus(1);
        }
    },

    data: () => ({
        pressedBtn: "new",
        selectedTab: "tickets"
    }),

    computed: {
        ...mapState("user", {currentArea: "currentArea"}),
        ...mapState("user", {currentSector: "currentSector"}),
    },

    methods: {
        ...mapActions({setTicketStatus: "user/setTicketStatus"}),
    },
};
</script>

<style scoped></style>
