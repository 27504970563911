<template>
  <div class="lg:flex-1 w-full lg:w-auto">
    <p class="uppercase font-medium text-blue text-xl mb-3">
      Le mie postazioni
    </p>
    <div v-if="workstations.length > 0">
      <div
        v-for="(workstation, i) in workstations"
        :key="workstation.id"
        class="inline-block relative font-medium rounded-t-md px-3 py-2 cursor-pointer"
        @click="selectedTab = i"
        :class="
          selectedTab === i
            ? 'bg-ticket-grey text-blue'
            : 'bg-orange text-white'
        "
      >
        Sede {{ i + 1 }}
      </div>
      <div
        class="inline-block font-medium rounded-t-md px-3 py-2 cursor-pointer"
        @click="selectedTab = workstations.length"
        :class="
          selectedTab === workstations.length
            ? 'bg-ticket-grey text-blue'
            : 'bg-orange text-white'
        "
      >
        Aggiungi
      </div>
    </div>
    <div
      v-if="workstations.length > 0 && selectedTab !== workstations.length"
      class="bg-ticket-grey rounded-lg rounded-tl-none px-14 py-14 xl:py-12 h-auto"
    >
      <div v-if="editMode != false">
        <div class="flex mb-4">
          <img
            v-if="workstations[selectedTab].headquarter"
            class="h-6 w-2/12 mt-2"
            src="@/assets/icons/location.svg"
          />
          <select
            v-model="workstations[selectedTab].headquarter.id"
            class="ticket-text-input-white py-2 w-10/12"
          >
            <option value="" disabled>Sede</option>
            <option
              v-for="headquarter in headquarters"
              :key="headquarter.id"
              :value="headquarter.id"
              class="text-blue"
              >{{ headquarter.location }}
            </option>
          </select>
        </div>
        <div class="flex mb-4">
          <img class="h-6 w-2/12 mt-2" src="@/assets/icons/piano.svg" />
          <input
            class="ticket-text-input-white py-2 w-10/12"
            type="text"
            placeholder="Piano"
            v-model="workstations[selectedTab].floor"
          />
        </div>
        <div class="flex mb-4">
          <img class="h-6 w-2/12 mt-2" src="@/assets/icons/stanza.svg" />
          <input
            class="ticket-text-input-white py-2 w-10/12"
            type="text"
            placeholder="Stanza"
            v-model="workstations[selectedTab].room"
          />
        </div>
        <div class="flex mb-4">
          <img class="h-6 w-2/12 mt-2" src="@/assets/icons/telefono.svg" />
          <input
            class="ticket-text-input-white py-2 w-10/12"
            type="text"
            placeholder="Telefono"
            v-model="workstations[selectedTab].phone"
          />
        </div>
        <div class="text-right">
          <button
            class="ticket-btn text-blue py-1 px-6 rounded-md"
            @click="editWorkstation(workstations[selectedTab])"
          >
            Salva
          </button>
        </div>
      </div>
      <div v-else class="text-center">
        <div class="flex">
          <img
            v-if="workstations[selectedTab].headquarter"
            class="h-6 w-2/12 mt-2"
            src="@/assets/icons/location.svg"
          />
          <div
            v-if="workstations[selectedTab].headquarter"
            class="inline-block text-blue py-2 mb-4 w-4/5 text-left text-sm xl:text-base"
          >
            {{ workstations[selectedTab].headquarter.location }}
          </div>
        </div>
        <div class="flex">
          <img class="h-6 w-2/12 mt-2" src="@/assets/icons/piano.svg" />
          <div class="inline-block text-blue py-2 mb-4 w-4/5 text-left text-sm xl:text-base">
            {{ "Piano " + workstations[selectedTab].floor }}
          </div>
        </div>
        <div class="flex">
          <img class="h-6 w-2/12 mt-2" src="@/assets/icons/stanza.svg" />
          <div class="inline-block text-blue py-2 mb-4 w-4/5 text-left text-sm xl:text-base">
            {{ "Stanza " + workstations[selectedTab].room }}
          </div>
        </div>
        <div class="flex">
          <img class="h-6 w-2/12 mt-2" src="@/assets/icons/telefono.svg" />
          <div class="inline-block text-blue py-2 mb-4 w-4/5 text-left text-sm xl:text-base">
            {{ workstations[selectedTab].phone }}
          </div>
        </div>
        <div class="text-right">
          <button class="inline-block mr-6">
            <img
              v-if="!deleteIcon"
              src="@/assets/icons/elimina.svg"
              class="h-5 focus:outline-none"
              @mouseenter="toggleDeleteIcon"
              @click="$refs.promptModal.open()"
            />
            <img
              v-else
              src="@/assets/icons/elimina-orange.svg"
              class="h-5 focus:outline-none"
              @mouseleave="toggleDeleteIcon"
              @click="$refs.promptModal.open()"
            />
          </button>
          <button class="inline-block">
            <img
              v-if="!editIcon"
              src="@/assets/icons/modifica.svg"
              class="h-5 focus:outline-none"
              @mouseenter="toggleEditIcon"
              @click="edit(true)"
            />
            <img
              v-else
              src="@/assets/icons/modifica_orange.svg"
              class="h-5 focus:outline-none"
              @mouseleave="toggleEditIcon"
              @click="edit(true)"
            />
          </button>
        </div>
      </div>
      <prompt-modal
        ref="promptModal"
        :title="'Vuoi eliminare la postazione?'"
        :message="
          'Se elimini la postazione, non sarà più possibile recuperarne i dati'
        "
        @action="deleteWorkstation(workstations[selectedTab])"
      >
      </prompt-modal>
    </div>
    <div
      v-else
      class="bg-ticket-grey rounded-lg rounded-tl-none px-14 py-6"
      :class="workstations.length > 0 ? 'h-86' : 'h-96'"
    >
      <p
        class="text-center font-medium text-blue text-xl mb-4"
        v-if="workstations.length > 0"
      >
        Aggiungi Postazione
      </p>
      <p class="text-center font-medium text-blue text-xl mb-4" v-else>
        Crea Postazione
      </p>
      <div class="flex mb-4">
        <img class="h-6 w-2/12 mt-2" src="@/assets/icons/location-gray.svg" />
        <select
          v-model="data.headquarter"
          class="ticket-text-input-white py-2 w-full"
        >
          <option value="0" disabled>Selezionare una sede</option>
          <option
            v-for="headquarter in headquarters"
            :key="headquarter.id"
            :value="headquarter.id"
            >{{ headquarter.location }}</option
          >
        </select>
      </div>
      <div class="flex mb-4">
        <img class="h-6 w-2/12 mt-2" src="@/assets/icons/piano-gray.svg" />
        <div class="w-full">
          <input
            id="floor"
            class="ticket-text-input-white py-2 w-full"
            type="text"
            placeholder="Piano"
            v-model="data.floor"
          />
          <p id="floor-error" class="text-red"></p>
        </div>
      </div>

      <div class="flex mb-4">
        <img class="h-6 w-2/12 mt-2" src="@/assets/icons/stanza-gray.svg" />
        <div class="w-full">
          <input
            id="room"
            class="ticket-text-input-white py-2 w-full"
            type="text"
            placeholder="Stanza"
            v-model="data.room"
          />
          <p id="room-error" class="text-red"></p>
        </div>
      </div>

      <div class="flex mb-4">
        <img class="h-6 w-2/12 mt-2" src="@/assets/icons/telefono-gray.svg" />
        <div class="w-full">
          <input
            id="phone"
            class="ticket-text-input-white py-2 w-full"
            type="text"
            placeholder="Telefono"
            v-model="data.phone"
          />
          <p id="phone-error" class="text-red"></p>
        </div>
      </div>

      <div class="text-right">
        <button
          class="ticket-btn py-1 px-6 rounded-md"
          @click="addWorkstation()"
        >
          Aggiungi
        </button>
      </div>
    </div>
  </div>
</template>

<script>
import Services from "@/services/index";
import PromptModal from "./PromptModal";

export default {
  name: "Workstations",
  components: { PromptModal },
  data: () => ({
    data: {
      id: "",
      headquarter: "0",
      floor: "",
      room: "",
      phone: "",
    },
    headquarters: [],
    workstations: [],
    deleteIcon: false,
    editIcon: false,
    selectedTab: 0,
    editMode: false,
  }),

  created() {
    this.getHeadquarters();
    this.getWorkstation();
  },

  methods: {
    async getHeadquarters() {
      this.headquarters = await Services.getHeadquarters();
    },

    async getWorkstation() {
      this.workstations = await Services.getWorkstation();
    },

    async addWorkstation() {
      try {
        const response = await Services.addWorkstation(this.data);
        this.data = { headquarter: "0" };
        this.workstations.push(response.data.workstation);
        this.$toast.success("Postazione Aggiunta", {
          position: "bottom-right",
        });
      } catch (error) {
        console.log(error.response);
        if (isNaN(this.data.phone) || this.data.phone == "") {
          document
            .getElementById("phone")
            .classList.add("border-px", "border-red");
          document.getElementById("phone-error").innerHTML =
            error.response.data.data.phone[0];
        }
        if (isNaN(this.data.room) || this.data.room == "") {
          document
            .getElementById("room")
            .classList.add("border-px", "border-red");
          document.getElementById("room-error").innerHTML =
            error.response.data.data.room[0];
        }
        if (isNaN(this.data.floor) || this.data.floor == "") {
          document
            .getElementById("floor")
            .classList.add("border-px", "border-red");
          document.getElementById("floor-error").innerHTML =
            error.response.data.data.floor[0];
        }
      }
    },

    async editWorkstation(workstation) {
      var update_workstation = {
        floor: workstation.floor,
        headquarter: workstation.headquarter_id,
        room: workstation.room,
        phone: workstation.phone,
      };
      try {
        await Services.editWorkstation(workstation.id, update_workstation);
        this.edit(false);
        this.data = { headquarter: "0" };
        this.getWorkstation();
        this.$toast.success("Postazione Aggiornata", {
          position: "bottom-right",
        });
      } catch (error) {
        console.log(error);
      }
    },

    async deleteWorkstation(workstation) {
      try {
        await Services.deleteWorkstation({ id: [workstation.id] });
        this.$refs.promptModal.close();
        this.data = { headquarter: "0" };
        const index = this.workstations.indexOf(workstation.id);
        this.workstations.splice(index, 1);
        this.selectedTab > 0 ? this.selectedTab-- : (this.selectedTab = 0);
        this.$toast.success("Postazione Rimossa", {
          position: "bottom-right",
        });
      } catch (error) {
        console.log(error);
      }
    },

    async edit(edit) {
      this.editMode = edit;
    },

    toggleDeleteIcon() {
      this.deleteIcon = !this.deleteIcon;
    },

    toggleEditIcon() {
      this.editIcon = !this.editIcon;
    },
  },
};
</script>

<style>
.shadow-tab {
  -webkit-box-shadow: 10px 0px 13px -7px #000000,
    5px 5px 15px 5px rgba(0, 0, 0, 0);
  box-shadow: 10px 0px 13px -7px #000000, 5px 5px 15px 5px rgba(0, 0, 0, 0);
}
</style>
