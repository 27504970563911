<template>
  <Modal @close="resetValues" ref="forwardTicketModal">
    <template #header="{close}">
      <div class="flex justify-end px-8 py-5 items-center leading-none">
        <h1 class="text-3xl mx-auto font-semibold">Inoltra ticket</h1>
        <span
          @click.prevent="close"
          class="cursor-pointer text-orange text-2xl"
        >
          <img src="@/assets/icons/X.svg" />
        </span>
      </div>
    </template>
    <template #body>
      <div class="flex flex-col p-2 pb-5 px-20">
        <select
          @change="
            getAreaDivision();
            getAllUsers();
          "
          class="w-full h-10 mx-auto rounded-md pl-4 text-xs sm:text-sm lg:text-lg overflow-auto bg-lightgray focus:outline-none"
          type="text"
          v-model="forwardData.area"
          id="area"
        >
          <option class="select-none" disabled selected value="0"
            >Seleziona area d'interesse *</option
          >
          <option v-for="area in areas" :key="area.id" :value="area.id">{{
            area.name
          }}</option>
        </select>
        <select
          @change="getDivision()"
          :disabled="forwardData.area == 0"
          class="w-full h-10 mx-auto rounded-md my-2 pl-4 text-xs sm:text-sm lg:text-lg overflow-auto bg-lightgray focus:outline-none"
          type="text"
          v-model="forwardData.division"
        >
          <option
            class="select-none"
            selected
            value="0"
            v-html="
              forwardData.user == 0
                ? 'Seleziona Settore (Opzionale)'
                : 'Seleziona Settore (Obbligatorio)'
            "
          ></option>
          <option
            v-for="division in divisions"
            :key="division.id"
            :value="division.id"
            >{{ division.name }}</option
          >
        </select>
        <select
          @change="
            forwardData.user == 0 ? getAreaDivision() : getUserDivisions()
          "
          :disabled="!forwardData.area"
          class="w-full h-10 mx-auto rounded-md pl-4 text-xs sm:text-sm lg:text-lg overflow-auto bg-lightgray focus:outline-none"
          type="text"
          v-model="forwardData.user"
        >
          <option class="select-none" selected value="0"
            >Operatore (Opzionale)</option
          >
          <option v-for="user in users" :key="user.id" :value="user.id">
            {{ user.user_info.name }}
          </option>
        </select>
        <input
          type="text"
          class="w-full h-10 mx-auto my-2 pl-4 rounded-md text-xs sm:text-sm lg:text-lg overflow-auto bg-lightgray focus:outline-none"
          placeholder="Messaggio *"
          v-model="forwardData.message"
          id="message"
        />
        <button
          :disabled="loading"
          @click="forwardTicket"
          :class="
            loading
              ? 'ticket-btn-disabled rounded-md py-1.5 focus:outline-none'
              : 'ticket-btn rounded-md py-1.5 focus:outline-none'
          "
          v-html="loading ? 'Inoltro...' : 'Inoltra'"
        ></button>

        <p id="error" class="text-red mt-2"></p>
      </div>
    </template>
  </Modal>
</template>

<script>
import { mapState } from "vuex";
import Services from "@/services";
import Modal from "./Modal";

export default {
  name: "ForwardTicket.vue",

  components: {
    Modal,
  },

  props: {
    ticket_id: Number,
  },

  created() {
    this.forwardData.id = this.ticket_id;
  },

  data: () => ({
    users: [],
    divisions: [],
    forwardData: {
      id: 0,
      user: 0,
      area: 0,
      division: 0,
      message: "",
    },

    loading: false,
  }),

  computed: {
    ...mapState("user", { areas: "areas" }),
  },

  methods: {
    resetValues() {
      this.forwardData = {
        id: 0,
        user: 0,
        area: 0,
        division: 0,
        message: "",
      };
    },

    async getAllUsers() {
      try {
        const response = await Services.getAllUsers({
          area: this.forwardData.area,
          division: this.forwardData.division,
        });
        this.users = response.data.user;
      } catch (error) {
        console.log(error);
      }
    },

    async getDivision() {
      try {
        const response = await Services.getDivision(this.forwardData.division);
        this.users = response.data.division[0].users;
      } catch (error) {
        console.log(error);
      }
    },

    async getUserDivisions() {
      try {
        const response = await Services.getUserDivisions({
          area: this.forwardData.area,
          user: this.forwardData.user,
        });
        this.divisions = response;
      } catch (error) {
        console.log(error);
      }
    },

    async getAreaDivision() {
      try {
        this.forwardData.division = 0;
        const response = await Services.getAreaDivision({
          id: this.forwardData.area,
        });
        this.divisions = response.data.division;
      } catch (error) {
        console.log(error);
      }
    },

    forwardTicketModal() {
      this.$emit("forwardTicketModal", true);
    },

    async forwardTicket() {
      this.loading = true;
      try {
        await Services.forwardTicket(this.forwardData);
        this.$refs.forwardTicketModal.close();
        this.resetValues();
        this.$router.replace("/");
      } catch (error) {
        if (this.forwardData.area == 0) {
          document
            .getElementById("area")
            .classList.add("border-px", "border-red");
        }
        if (this.forwardData.message == 0) {
          document
            .getElementById("message")
            .classList.add("border-px", "border-red");
        }
        document.getElementById("error").innerHTML =
          error.response.data.message;
      }
      this.loading = false;
    },

    open() {
      this.$refs.forwardTicketModal.open();
    },
  },
};
</script>

<style scoped></style>
