<template>
    <div class="px-4 lg:px-0">
            <div class="text-lg font-bold lg:text-center text-blue uppercase">
      <span
          v-if="
          $route.params.type === 'filters' || $route.params.type === ':search'
        "
      >
        Risultato della ricerca
      </span>
                <span v-if="$route.params.type === 'favorites'">
        In evidenza
      </span>
                <span v-if="$route.params.type === 'send_reminder' || $route.params.type === 'reminder'">
        Ticket sollecitati
        <span v-if="$route.params.type === 'send_reminder'" class="hidden lg:inline-block text-orange">
          inviati
        </span>
        <span v-else class="hidden lg:inline-block text-orange">
          ricevuti
        </span>
      </span>
            </div>
            <div v-if="$route.params.type === 'send_reminder' || $route.params.type === 'reminder'"
                 class="flex mt-2 lg:hidden">
                <div v-if="this.user && this.user.is_admin == 0" class="flex mr-6"
                     :class="$route.params.type === 'reminder' ? 'font-semibold border-b-2 border-orange' : 'opacity-70'"
                     @click="showRemind('reminder')">
                    Ricevuti
                </div>
                <div
                    v-if="this.user && (this.user.user_area_coordinator_or_manager.length > 0 || this.user.is_admin == 1)"
                    class="flex"
                    :class="$route.params.type === 'send_reminder' ? 'font-semibold border-b-2 border-orange' : 'opacity-70'"
                    @click="showRemind('send_reminder')">
                    Inviati
                </div>
            </div>
        <div class="mt-6">
            <FilteredList :list="tickets"></FilteredList>
        </div>

    </div>

</template>

<script>
import FilteredList from "@/components/FilteredList";
import {mapState, mapActions} from "vuex";
import Services from "@/services";

export default {
    name: "FilterResults.vue",
    components: {FilteredList},

    computed: {
        ...mapState("user", {tickets: "tickets"}),
        ...mapState("user", {user: "user"}),

    },
    methods: {
        ...mapActions({setTickets: "tickets/setTickets"}),
        ...mapActions({setFilters: "tickets/setFilters"}),
        ...mapActions({setLastPage: "tickets/setLastPage"}),
        ...mapActions({setTicketStatus: "user/setTicketStatus"}),
        async showRemind(remindType) {
            let request = {};
            let type = "";

            if (remindType === 'reminder') {
                request = {reminder: 1};
                type = "reminder";
            } else {
                request = {send_reminder: 1};
                type = "send_reminder";
            }

            try {
                const response = await Services.getAllTickets(request);
                this.setFilters(request);
                this.setLastPage(parseInt(response.data.ticket.last_page));
                this.setTickets(response.data.ticket.data);
                if (this.$route.path !== "/filterResults") {
                    this.$router.push("/filterResults/" + type);
                }
                this.setTicketStatus(null);
            } catch (error) {
                this.$refs.errorModal.open();
                this.$refs.errorModal.close();
                this.errorMessage = "";
                this.errorMessage = 'Errore nel caricamento dei ticket sollecitati.'
                console.log(error);
            }
        },
    }
};
</script>

<style scoped></style>
