import jwtDecode from "jwt-decode";
import cookies from "vue-cookies";

import { TicketRequest, AuthRequest } from "@/services/request";

class Services {
  static tokenExpired() {
    return !cookies.get("pegaso_token");
  }

  static untilSecondsFromNow(seconds) {
    const date = new Date(seconds * 1000);
    return date.toUTCString();
  }

  static async signin(username, password) {
    const response = await AuthRequest.post({
      path: "oauth/token",
      data: {
        username,
        password,
        grant_type: "password",
        client_id: 4,
        client_secret: "x4KFuQnc95bjsmFPV9CbgvEtKEKRFkMGvkdpRNrC",
        scope: "*",
      },
      withCredentials: true,
    });
    const decoded = jwtDecode(response.access_token);
    cookies.set(
      "mv_token",
      response.access_token,
      this.untilSecondsFromNow(decoded.exp),
      "",
      ".multiversity.click"
    );
    return response;
  }

  static async refreshAuthToken() {
    const response = await AuthRequest.post({
      path: "oauth/token",
      data: {
        grant_type: "refresh_token",
        client_id: 4,
        client_secret: "x4KFuQnc95bjsmFPV9CbgvEtKEKRFkMGvkdpRNrC",
        scope: "*",
      },
      withCredentials: true,
    });
    const decoded = jwtDecode(response.access_token);
    cookies.set(
      "mv_token",
      response.access_token,
      this.untilSecondsFromNow(decoded.exp),
      "",
      ".multiversity.click"
    );
    return response;
  }

  static async signOut() {
    //FIXME
    /*await AuthRequest.get({
            path: "oauth/pegaso/logout",
            headers: {Authorization: "Bearer " + cookies.get("mv_token")},
            withCredentials: true,
        });
        cookies.remove("mv_token", null, ".multiversity.click");

        store.dispatch("user/clearUser");
        router.push("/signin");*/
  }

  /* START example functions */

  /* static getUserMenu() {
        return TicketRequest.get({
            path: "student/menu",
        });
    }

    static getHeadquarters(data = null) {
        return TicketRequest.post({
            path: `student/locations`,
            data,
        });
    }

    static async uploadProfilePhoto(file, coords) {
        const fd = new FormData();
        fd.append("profile_img", file);
        fd.append("scaleX", coords.scaleX);
        fd.append("scaleY", coords.scaleY);
        fd.append("x", parseInt(coords.x));
        fd.append("y", parseInt(coords.y));
        fd.append("h", parseInt(coords.height));
        fd.append("w", parseInt(coords.width));

        return TicketRequest.post({
            path: `update-image-profile`,
            data: fd,
        });
    }*/

  /* END functions */

  static async getUserSession() {
    const response = await TicketRequest.get({
      path: "admin/get-user",
    });
    return response.data.user;
  }

  static async getUserDetails(id) {
    const response = await TicketRequest.get({
      path: "admin/get-user-detail/" + id,
    });
    return response.data.user;
  }

  static async getPriorities() {
    const response = await TicketRequest.get({
      path: "admin/get-priority",
    });
    return response.data.priority;
  }

  static async getStatus() {
    const response = await TicketRequest.get({
      path: "admin/get-status",
    });
    return response.data.status;
  }

  static async getHeadquarters() {
    const response = await TicketRequest.get({
      path: "admin/get-headquarters",
    });
    return response.data.headquarters;
  }

  static async getWorkstation() {
    const response = await TicketRequest.get({
      path: "admin/get-workstation",
    });
    return response.data.workstation;
  }

  static async addWorkstation(request) {
    const response = await TicketRequest.post({
      path: "admin/add-workstation",
      data: request,
    });
    return response;
  }

  static async editWorkstation(id, request) {
    const response = await TicketRequest.post({
      path: "admin/edit-workstation/" + id,
      data: request,
    });
    return response;
  }

  static async deleteWorkstation(request) {
    const response = await TicketRequest.delete({
      path: "admin/delete-workstation",
      data: request,
    });
    return response;
  }

  static async getAllAreas() {
    const response = await TicketRequest.get({
      path: "admin/get-all-area",
    });
    return response.data.area;
  }

  static async getAllTickets(request, page = 1) {
    const response = await TicketRequest.post({
      path: "admin/get-all-ticket?page=" + page,
      data: request,
    });
    return response;
  }

  static async searchTicket(request, page = 1) {
    const response = await TicketRequest.post({
      path: "admin/search-ticket?page=" + page,
      data: request,
    });
    return response;
  }

  static async searchTicketId(request) {
    const response = await TicketRequest.get({
      path: "admin/search-ticket-id/" + request,
    });
    return response;
  }

  static async saveFilters(request) {
    const response = await TicketRequest.post({
      path: "admin/create-user-filter",
      data: request,
    });
    return response;
  }

  static async getUserFilters() {
    const response = await TicketRequest.get({
      path: "admin/get-user-filter",
    });
    return response;
  }

  static async deleteUserFilter(request) {
    const response = await TicketRequest.delete({
      path: "admin/delete-user-filter",
      data: request,
    });
    return response;
  }

  static async getTicket(id) {
    const response = await TicketRequest.get({
      path: "admin/get-ticket/" + id,
    });
    return response;
  }

  static async createTicket(request) {
    const response = await TicketRequest.post({
      path: "admin/create-ticket",
      data: request,
    });
    return response;
  }

  static async getListArea() {
    const response = await TicketRequest.get({
      path: "admin/get-all-area-detail",
    });
    return response;
  }

  static async getUserDivisions(request) {
    const response = await TicketRequest.post({
      path: "admin/get-user-division",
      data: request,
    });
    return response.data.division;
  }

  //Ricerca Utenti

  static async getAllUsers(request) {
    const response = await TicketRequest.post({
      path: "admin/get-all-user",
      data: request,
    });
    return response;
  }

  static async getListUser(request) {
    const response = await TicketRequest.post({
      path: "admin/get-list-user",
      data: request,
    });
    return response;
  }

  //Aggiungi/Rimuovi Ticket Preferiti

  static async updateFavorites(request) {
    const response = await TicketRequest.post({
      path: "admin/update-ticket-favorite",
      data: request,
    });
    return response;
  }

  //Sollecita Ticket

  static async remindTicket(request) {
    const response = await TicketRequest.post({
      path: "admin/reminder-ticket",
      data: request,
    });
    return response;
  }

  //Invia Messaggio in Ticket

  static async createAnswer(request) {
    const response = await TicketRequest.post({
      path: "admin/create-answer",
      data: request,
    });
    return response;
  }

  //Assegna Ticket

  static async assignTicket(request) {
    const response = await TicketRequest.post({
      path: "admin/update-processing-user",
      data: request,
    });
    return response;
  }

  //Inoltra Ticket

  static async forwardTicket(request) {
    const response = await TicketRequest.post({
      path: "admin/forward-ticket",
      data: request,
    });
    return response;
  }

  //Restituisce la lista dei critici

  static async getCritical() {
    const response = await TicketRequest.get({
      path: "admin/get-ticket-critical",
    });
    return response;
  }

  //Restituisce i solleciti

  static async getPriority() {
    const response = await TicketRequest.get({
      path: "admin/get-ticket-priority",
    });
    return response;
  }

  //Inserimento Utente in Settore

  static async addUserToDivision(request) {
    const response = await TicketRequest.post({
      path: "admin/create-user-division",
      data: request,
    });
    return response;
  }

  //Eliminazione utente da un'area

  static async deleteUserArea(request) {
    const response = await TicketRequest.delete({
      path: "admin/delete-user-area",
      data: request,
    });
    return response;
  }

  //Eliminazione utente da un settore

  static async deleteUserDivision(request) {
    const response = await TicketRequest.delete({
      path: "admin/delete-user-division",
      data: request,
    });
    return response;
  }

  //Modifica Ruolo Utente

  static async updateUserAcl(request) {
    const response = await TicketRequest.post({
      path: "admin/update-user-acl",
      data: request,
    });
    return response;
  }

  static async getAreaDivision(request) {
    const response = await TicketRequest.get({
      path: "admin/get-area-division/" + request.id,
    });
    return response;
  }

  //Restituisce le divisioni di un'area

  static async getAllAreaDivisions(id) {
    const response = await TicketRequest.get({
      path: "admin/get-area-division/" + id,
    });
    return response;
  }

  //Restituisce la divisione

  static async getDivision(id) {
    const response = await TicketRequest.get({
      path: "admin/get-division/" + id,
    });
    return response;
  }

  //Crea un'area

  static async createArea(request) {
    const response = await TicketRequest.post({
      path: "admin/create-area",
      data: request,
    });
    return response;
  }

  //Modifica un'area

  static async updateArea(request) {
    return await TicketRequest.post({
      path: "admin/update-area",
      data: request,
    });
  }

  //Cancella un'area

  static async deleteArea(id) {
    const response = await TicketRequest.delete({
      path: "admin/delete-area",
      data: { id: id },
    });
    return response;
  }

  //Restituisce i dettagli di un'area

  static async getAreaManagement(request) {
    const response = await TicketRequest.post({
      path: "admin/get-area-management",
      data: request,
    });
    return response.data.area;
  }

  static async getAreaDetails(request) {
    const response = await TicketRequest.post({
      path: "admin/get-area-detail",
      data: request,
    });
    return response.data.area;
  }

  static async addDivision(request) {
    const response = await TicketRequest.post({
      path: "admin/create-division",
      data: request,
    });
    return response;
  }

  //Rimuove i Ticket dai preferiti

  static async removeFavorites(request) {
    const response = await TicketRequest.delete({
      path: "admin/delete-ticket-favorite",
      data: request,
    });
    return response;
  }

  //Modifica il ticket

  static async updateTicket(request) {
    await TicketRequest.post({
      path: "admin/update-ticket",
      data: request,
    });
  }

  //Restituisce la lista di utenti ai quali è possibile assegnare un ticket

  static async getProcessingUser(id) {
    const response = await TicketRequest.get({
      path: "admin/get-processing-user/" + id,
    });
    return response;
  }

  //Elimina il settore

  static async deleteDivision(id) {
    await TicketRequest.delete({
      path: "admin/delete-division",
      data: id,
    });
  }

  //Modifica il settore

  static async updateDivision(request) {
    await TicketRequest.post({
      path: "admin/update-division",
      data: request,
    });
  }
}

export default Services;