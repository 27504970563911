<template>
  <Modal ref="newManagerModal">
    <template #header="{close}">
      <div class="flex p-8 justify-end ">
        <h1 class="text-xl lg:text-3xl mx-auto font-semibold">Aggiungi dipendente</h1>
        <span
          @click.prevent="close"
          class="cursor-pointer text-orange text-2xl"
        >
          <img src="@/assets/icons/X.svg" />
        </span>
      </div>
    </template>
    <template #body>
      <div class="px-16 pb-5">
        <div class="rounded-lg bg-ticket-grey">
          <input
            class="block mb-3 focus:outline-none h-10 w-full employee-search pl-4 text-lg overflow-auto bg-ticket-grey"
            type="text"
            placeholder="Nome dipendente"
            v-model="searchUser"
            @keydown.enter="getAllUser()"
          />
          <div v-if="listSearchUser.data" class="overflow-auto h-60">
            <div
              v-for="user in listSearchUser.data.user"
              :key="user.id"
              class="block font-medium p-3 cursor-pointer"
              @mouseenter="hoverUser = true"
              @mouseleave="hoverUser = false"
              @click="
                selectedRow = user.id;
                selectedUser = user.id;
              "
              :class="[
                hoverUser && selectedRow == false ? 'bg-hover-ticket' : '',
                selectedRow == user.id ? 'bg-ticket-orange' : '',
              ]"
            >
              <img
                class="h-14 inline-block rounded-full"
                :src="
                  user.user_info.picture_url !== null
                    ? user.user_info.picture_url
                    : require('@/assets/images/default-profile.jpg')
                "
              />
              <div class="inline-block pl-4">
                <p :class="selectedRow == user.id ? 'text-white' : 'text-blue'">
                  {{ user.user_info.firstname + " " + user.user_info.lastname }}
                </p>
              </div>
            </div>
          </div>
        </div>
        <button
          :disabled="!selectedRow"
          v-if="listSearchUser.data"
          @click="addUserToDivision()"
          @click.prevent="close"
          class="mt-5 py-2 focus:outline-none w-full ticket-btn text-xl rounded-lg"
          :class="selectedRow ? 'ticket-btn' : 'ticket-btn-disabled'"
        >
          Aggiungi
        </button>
        <button
          v-else
          @click="getAllUser()"
          class="py-2 focus:outline-none w-full ticket-btn rounded-lg text-xl"
          :class="searchUser.length > 0 ? 'ticket-btn' : 'ticket-btn-disabled'"
        >
          Cerca
        </button>
      </div>
    </template>
  </Modal>
</template>

<script>
import Services from "../services";
import Modal from "./Modal";

export default {
  name: "ModalCreateDependant",
  components: {
    Modal,
  },
  props: {
    area: Number,
    division: Number,
    type: String,
  },
  data() {
    return {
      listSearchUser: [],
      searchUser: "",
      hoverUser: false,
      selectedRow: false,
      selectedUser: 0,
    };
  },

  methods: {
    async getAllUser() {
      this.listSearchUser = await Services.getAllUsers({
        name: this.searchUser,
      });
    },

    async addUserToDivision() {
      try {
        const response = await Services.addUserToDivision({
          user: this.selectedUser,
          division: this.division,
        });
        this.$emit("user-create", response.data.user_division.user);
        this.$toast.success("Utente aggiunto al settore", {
          position: "bottom-right",
        });
      } catch (error) {
        console.log(error);
      }
    },

    reset() {
      this.listSearchUser = [];
      this.searchUser = "";
      this.selectedRow = false;
      this.selectedUser = 0;
      this.hoverUser = false;
    },
    open() {
      this.$refs.newManagerModal.open();
    },
    close() {
      this.$refs.newManagerModal.close();
    },
  },
};
</script>

<style scoped></style>
