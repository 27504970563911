var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"relative border border-gray-300 rounded-lg"},[(_vm.expand)?_c('img',{staticClass:"absolute right-3 top-2 h-4 w-4 cursor-pointer",attrs:{"src":require("@/assets/icons/espandi.svg")},on:{"click":function($event){return _vm.getUserDetails(_vm.user.user.id)}}}):_vm._e(),_c('div',{staticClass:"flex flex-row p-5 pb-2"},[_c('img',{staticClass:"h-12 w-12 2xl:h-14 2xl:w-14 rounded-full shadow-orange",attrs:{"src":_vm.user.user.user_info.picture_url
          ? _vm.user.user.user_info.picture_url
          : require('@/assets/images/default-profile.jpg')}}),_c('div',{staticClass:"ml-5"},[_c('p',{staticClass:"font-medium text-blue sm:text-lg 2xl:text-xl whitespace-nowrap "},[_vm._v(" "+_vm._s(_vm.user.user.user_info.name)+" ")]),_c('p',{staticClass:"font-bold text-orange whitespace-nowrap"},[_vm._v(" "+_vm._s(_vm.user.acl.role_it)+" ")])])]),_c('div',{staticClass:"text-center"},[_c('p',{staticClass:"sm:text-sm md:text-md 2xl:text-lg text-blue font-medium"},[_vm._v(" "+_vm._s(_vm.user.user.user_info.email)+" ")])]),_c('div',{staticClass:"text-center"},[_c('div',{staticClass:"inline-flex my-3"},[_c('img',{directives:[{name:"title",rawName:"v-title",value:('Rimuovi dal settore'),expression:"'Rimuovi dal settore'"}],staticClass:"cursor-pointer h-5 w-5 mr-4",attrs:{"src":require("@/assets/icons/elimina.svg")},on:{"click":function($event){return _vm.$refs.promptModal.open()}}}),_c('img',{directives:[{name:"title",rawName:"v-title",value:('Manager'),expression:"'Manager'"}],staticClass:"cursor-pointer h-5 w-5 mx-4",attrs:{"src":_vm.user.acl.id == 4
            ? require('@/assets/icons/manager.svg')
            : require('@/assets/icons/manager_gray.svg')},on:{"click":function($event){return _vm.updateUserAcl('manager')}}}),_c('img',{directives:[{name:"title",rawName:"v-title",value:('Coordinatore'),expression:"'Coordinatore'"}],staticClass:"cursor-pointer h-5 mx-4",attrs:{"src":_vm.user.acl.id == 2
            ? require('@/assets/icons/coordinatore.svg')
            : require('@/assets/icons/coordinatore-gray.svg')},on:{"click":function($event){return _vm.updateUserAcl('coordinator')}}}),_c('img',{directives:[{name:"title",rawName:"v-title",value:('Dipendente'),expression:"'Dipendente'"}],staticClass:"cursor-pointer h-5 w-5 ml-4",attrs:{"src":_vm.user.acl.id == 3
            ? require('@/assets/icons/dipendente.svg')
            : require('@/assets/icons/dipendente-gray.svg')},on:{"click":function($event){return _vm.updateUserAcl('employee')}}})])]),_c('prompt-modal',{ref:"promptModal",attrs:{"title":'Conferma Eliminazione',"message":'Sei sicuro di voler rimuovre l\'utente selezionato dall\'area?'},on:{"action":_vm.deleteUserArea}}),_c('user-modal',{ref:"userModal"})],1)}
var staticRenderFns = []

export { render, staticRenderFns }