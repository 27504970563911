<template>
	<div>
		<div class="p-3 gradient-orange-vertical rounded-md">
			<div v-if="loading">
				<Spinner></Spinner>
				<p class="font-bold text-blue text-center mt-2">
					Recupero Dati Area...
				</p>
			</div>
			<div v-else-if="!loading && divisions.length <= 0" class="text-center">
				<p class="text-blue font-medium text-center">
					Non ci sono settori in quest'area.
				</p>
				<button
					class="ticket-btn px-5 py-2 mt-2 rounded-md"
					@click="$refs.modalCreateDivision.open()"
				>
					Aggiungi Un Settore
				</button>
			</div>
			<div v-else-if="divisions.length > 0" class="flex place-items-center">
				<span
					class="ml-10 font-medium text-2xl text-blue mr-5 whitespace-nowrap pb-4"
					>{{ areaName }}</span
				>
				<VuePerfectScrollbar
					class="flex overflow-hidden pb-4"
					:settings="{ swipeEasing: true, useBothWheelAxes: true }"
				>
					<button
						class="text-sm font-medium text-orange bg-white p-0.5 rounded-sm mr-3 focus:outline-none whitespace-nowrap"
						@click="$refs.modalCreateDivision.open()"
					>
						+ Aggiungi Settore
					</button>
					<div
						v-for="division in divisions"
						:key="division.id"
						:disabled="selected.id == division.id"
						class="flex ticket-btn-gray-outline rounded-sm mr-3 cursor-pointer whitespace-nowrap"
						:class="selected.id == division.id ? 'active pr-5' : 'px-5'"
						@click="
							selected = division;
							getAreaManagement(selected.id);
						"
					>
						<div
							v-if="selected.id == division.id"
							class="flex-shrink-0 inline-block hover:bg-white cursor-pointer ml-0 pl-2 mr-1 pt-0.5"
							@mouseleave="divisionEditHover = false"
							@mouseenter="divisionEditHover = true"
							v-title="'Modifica Settore'"
						>
							<img
								class="h-4 w-4 mr-5 xl:mr-1"
								:src="
									divisionEditHover
										? require('@/assets/icons/edit-orange.svg')
										: require('@/assets/icons/edit.svg')
								"
								@click.stop="$refs.createEditDivision.open()"
							/>
						</div>
						{{ division.name }}
					</div>
				</VuePerfectScrollbar>
			</div>
			<div
				v-if="divisions.length > 0"
				class="grid grid-cols-5 mt-2 px-3 2xl:px-0"
			>
				<div class="text-center">
					<p class="font-medium text-blue text-sm 2xl:text-lg">MANAGER</p>
					<img
						:src="
							managers.length > 0
								? managers[0].user.user_info.picture_url
									? managers[0].user.user_info.picture_url
									: require('@/assets/images/default-profile.jpg')
								: ''
						"
						class="block rounded-full mx-auto mt-2 h-14 w-14 2xl:h-20 2xl:w-20"
					/>
					<p
						v-for="manager in managers"
						:key="manager.id"
						class="text-blue font-medium mt-1 text-sm 2xl:text-md"
					>
						{{ manager.user.user_info.name }}
					</p>
				</div>
				<div class="relative text-center leading-5">
					<p class="font-medium text-blue text-sm 2xl:text-lg">
						COORDINATORI
					</p>
					<div class="inline-flex ml-5 mt-2">
						<div
							v-if="coordinators.length > 0"
							class="flex-shrink-0 border-2 border-white rounded-full h-14 w-14 2xl:h-20 2xl:w-20"
						>
							<img
								class="rounded-full"
								:src="
									coordinators[0].user.user_info.picture_url
										? coordinators[0].user.user_info.picture_url
										: require('@/assets/images/default-profile.jpg')
								"
							/>
						</div>
						<div
							v-if="coordinators.length > 1"
							class="border-2 border-white rounded-full transform -translate-x-6 flex-shrink-0 h-14 w-14 2xl:h-20 2xl:w-20"
						>
							<img
								class="rounded-full"
								:src="
									coordinators[1].user.user_info.picture_url
										? coordinators[1].user.user_info.picture_url
										: require('@/assets/images/default-profile.jpg')
								"
							/>
						</div>
					</div>

					<div v-if="coordinators.length > 0">
						<span class="inline-block mx-auto text-left">
							<p
								v-for="coordinator in coordinators.slice(0, 2)"
								:key="coordinator.user.id"
								class="text-blue font-medium text-sm 2xl:text-md"
							>
								{{ coordinator.user.user_info.name }}
							</p>

							<span
								v-if="coordinators.length > 2"
								@click="$refs.userListModal.open()"
								class="text-orange cursor-pointer hover:underline text-sm 2xl:text-md"
							>
								Mostra tutti >
							</span>
						</span>
					</div>
					<div
						class="absolute w-0.5 rounded-sm bg-orange h-full right-0 top-0"
					></div>
				</div>
				<div class="text-center">
					<p class="font-medium text-blue text-sm 2xl:text-lg">
						DIPENDENTI SETTORE
					</p>
					<div
						class="flex place-items-center bg-orange h-14 w-14 2xl:h-20 2xl:w-20 text-white text-3xl 2xl:text-5xl font-medium rounded-full mx-auto mt-2"
					>
						<p class="w-full text-center">{{ users.length }}</p>
					</div>
				</div>
				<div class="text-center whitespace-nowrap">
					<p class="font-medium text-blue text-sm 2xl:text-lg">
						AGGIUNGI DIPENDENTE
					</p>
					<img
						@mouseenter="hover = true"
						@mouseleave="hover = false"
						@click="$refs.modalCreateDependant.open()"
						class="block rounded-full h-14 w-14 2xl:h-20 2xl:w-20 mx-auto mt-2 cursor-pointer"
						:src="
							hover
								? require('@/assets/icons/nuovoTicketFilled.svg')
								: require('@/assets/icons/nuovoTicket.svg')
						"
					/>
				</div>
				<div class="text-center">
					<p
						class="font-medium text-blue text-sm whitespace-nowrap 2xl:text-lg"
					>
						SITUAZIONE TICKET SETTORE
					</p>
					<div class="block mx-auto text-left text-blue px-10 font-medium mt-2">
						<p class="flex justify-between">
							<span class="text-sm 2xl:text-lg">Nuovi</span>
							<span class="text-orange font-medium text-sm 2xl:text-lg">{{
								stats.to_assign
							}}</span>
						</p>
						<p class="flex justify-between">
							<span class="text-sm 2xl:text-lg">Assegnati</span>
							<span class="text-green font-medium text-sm 2xl:text-lg">{{
								stats.assigned
							}}</span>
						</p>
						<p class="flex justify-between">
							<span class="whitespace-nowrap text-sm 2xl:text-lg"
								>In Lavorazione</span
							>
							<span class="text-red font-medium text-sm 2xl:text-lg">{{
								stats.in_process
							}}</span>
						</p>
						<p class="flex justify-between">
							<span class="text-sm 2xl:text-lg">Chiusi</span>
							<span class="text-blue font-medium text-sm 2xl:text-lg">{{
								stats.closed
							}}</span>
						</p>
					</div>
				</div>
			</div>
		</div>
		<div
			class="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 xl:grid-cols-3 2xl:grid-cols-4 gap-x-10 gap-y-8 mt-4"
		>
			<div v-for="user in users" :key="user.id">
				<dependant-card
					:user="user"
					:expand="true"
					:area="$route.params.id"
					@user-delete="removeDependant"
				></dependant-card>
			</div>
		</div>

		<modal-create-division
			ref="modalCreateDivision"
			:area="this.$route.params.id"
			@division-create="addDivision"
		></modal-create-division>

		<modal-create-dependant
			ref="modalCreateDependant"
			:area="this.$route.params.id"
			:division="selected.id"
			@user-create="getAreaManagement(selected.id)"
		></modal-create-dependant>

		<users-list-dialog
			ref="userListModal"
			:title="'Coordinatori'"
			:content="coordinators"
		></users-list-dialog>

		<edit-division-modal
			ref="createEditDivision"
			:division="selected"
			@delete="getAllAreaDivisions"
		>
		</edit-division-modal>
	</div>
</template>

<script>
import UsersListDialog from "@/components/UsersListDialog.vue";
import ModalCreateDependant from "@/components/ModalCreateDependant.vue";
import ModalCreateDivision from "@/components/ModalCreateDivision.vue";
import EditDivisionModal from "@/components/EditDivisionModal.vue";
import DependantCard from "@/components/DependantCard.vue";
import Services from "@/services/index";
import Spinner from "@/components/Spinner.vue";
import VuePerfectScrollbar from "vue-perfect-scrollbar";

export default {
	components: {
		DependantCard,
		ModalCreateDivision,
		ModalCreateDependant,
		UsersListDialog,
		EditDivisionModal,
		Spinner,
		VuePerfectScrollbar,
	},

	data: () => ({
		UsersList: false,
		areaName: "",
		users: [],
		hover: false,
		divisions: [],
		selected: {},
		divisionEditHover: false,
		loading: false,
		stats: {},
	}),

	computed: {
		managers: function() {
			return this.users.filter((a) => a.acl.role == "Manager");
		},

		coordinators: function() {
			return this.users.filter((a) => a.acl.role == "Area Manager");
		},

		dependants: function() {
			return this.users.filter((a) => a.acl.role == "User");
		},
	},

	mounted() {
		this.getAllAreaDivisions();
	},

	methods: {
		async getAreaDetails(division_id) {
			try {
				const response = await Services.getAreaDetails({
					area: this.$route.params.id,
					division: division_id,
				});
				this.stats = response;
			} catch (error) {
				console.log(error);
			}
		},

		async getAreaManagement(division_id) {
			try {
				const response = await Services.getAreaManagement({
					area: this.$route.params.id,
					division: division_id,
				});
				this.areaName = response.name;
				this.users = response.area_rel_user;
				this.getAreaDetails(division_id);
			} catch (error) {
				console.log(error);
			}
		},

		async getAllAreaDivisions() {
			this.loading = true;
			try {
				const response = await Services.getAllAreaDivisions(
					this.$route.params.id
				);
				this.divisions = response.data.division;
				if (this.divisions.length > 0) {
					this.selected = this.divisions[0];
				}
				this.getAreaManagement(this.selected.id);
			} catch (error) {
				console.log(error);
			}
			this.loading = false;
		},

		addDivision(division) {
			this.divisions.push(division);
		},

		removeDependant(dependant) {
			const index = this.users.indexOf(dependant);
			this.users.splice(index, 1);
		},
	},
};
</script>

<style></style>
