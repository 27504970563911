<template>
  <div class="flex">
    <button
      class="my-auto rounded-md text-blue text-medium font-bold justify-center focus:outline-none"
      :class="[
        'h-' + buttonSize,
        'w-' + buttonSize,
        page === 1 ? 'hover:bg-transparent' : 'hover:bg-orangedisabled',
      ]"
      @click="prevPage()"
      :disabled="page === 1"
    >
      <img
        class="h-2 mx-auto transform rotate-90"
        :src="require('@/assets/icons/freccia-giu.svg')"
      />
    </button>
    <div class="float-right text-blue font-semibold mx-2 my-auto">
        <span class="hidden lg:inline-block mr-1">Pagina</span>{{ page }} di {{ lastPage }}
    </div>
    <button
      class="w-6 h-6 my-auto rounded-md text-blue text-medium font-bold justify-center focus:outline-none"
      :class="[
        'h-' + buttonSize,
        'w-' + buttonSize,
        page === lastPage ? 'hover:bg-transparent' : 'hover:bg-orangedisabled',
      ]"
      @click="nextPage()"
    >
      <img
        class="h-2 mx-auto transform rotate-90"
        :src="require('@/assets/icons/freccia-su.svg')"
      />
    </button>
  </div>
</template>

<script>
export default {
  name: "PageNavigation",

  props: {
    currentPage: Number,
    lastPage: Number,
    buttonSize: Number,
  },

  computed: {
    page: {
      get() {
        return this.currentPage;
      },
      set() {
        return 1;
      },
    },
  },

  methods: {
    nextPage() {
      if (this.page < this.lastPage) {
        this.page++;
        this.$emit("next-page", this.page);
      }
    },

    resetNextPage() {
      this.page = 1;
      this.$emit("next-page", this.page);
    },

    prevPage() {
      if (this.page > 1) {
        this.page--;
        this.$emit("prev-page", this.page);
      }
    },
  },
};
</script>
