import Vue from "vue";
import App from "./App.vue";
import router from "./router";
import store from "./store";
import moment from "moment";
import vTitle from "vuejs-title";
import VueToast from "vue-toast-notification";
import Multiselect from "vue-multiselect";
import "vue-toast-notification/dist/theme-default.css";

import "@/assets/styles/index.css";

Vue.use(moment.locale("it"));
Vue.use(vTitle);
Vue.use(VueToast);
Vue.component("multiselect", Multiselect);

Vue.config.productionTip = false;

new Vue({
  router,
  store,
  render: (h) => h(App),
}).$mount("#app");
