export default {
  namespaced: true,
  state: {
    user: null,
    areas: null,
    menuStatus: true,
    userDivisions: [],
    currentArea: "",
    currentAreaId: "",
    currentSector: "",
    currentSectorId: null,
    ticketStatus: null,
  },
  mutations: {
    SET_USER(state, value) {
      state.user = value;
    },
    SET_AREAS(state, value) {
      state.areas = value;
    },
    SET_USER_PHOTO(state, value) {
      state.user.multiversity_user = {
        ...state.user.multiversity_user,
        picture_url: value,
      };
    },
    SET_USER_DOC_PHOTO(state, value) {
      state.user.user = { ...state.user.user, doc_photo: value };
    },
    SET_USER_DOC_FRONT(state, value) {
      state.user.user = { ...state.user.user, doc_front: value };
    },
    SET_USER_DOC_REAR(state, value) {
      state.user.user = { ...state.user.user, doc_rear: value };
    },
    SET_MENU_STATUS(state, value) {
      state.menuStatus = value;
    },
    SET_USER_DIVISIONS(state, value) {
      state.userDivisions = value;
    },
    SET_CURRENT_AREA(state, value) {
      state.currentArea = value;
    },
    SET_CURRENT_AREA_ID(state, value) {
      state.currentAreaId = value;
    },
    SET_CURRENT_SECTOR(state, value) {
      state.currentSector = value;
    },
    SET_CURRENT_SECTOR_ID(state, value) {
      state.currentSectorId = value;
    },
    SET_TICKET_STATUS(state, value) {
      state.ticketStatus = value;
    },
  },
  actions: {
    setAreas({ commit }, value) {
      commit("SET_AREAS", value);
    },
    async setUser({ commit }, value) {
      commit("SET_USER", value);
    },
    clearUser({ commit }) {
      commit("SET_USER", null);
    },
    setUserPhoto({ commit }, value) {
      commit("SET_USER_PHOTO", value);
    },
    setUserDocPhoto({ commit }, value) {
      commit("SET_USER_DOC_PHOTO", value);
    },
    setUserDocFront({ commit }, value) {
      commit("SET_USER_DOC_FRONT", value);
    },
    setUserDocRear({ commit }, value) {
      commit("SET_USER_DOC_REAR", value);
    },
    openMenu({ commit }) {
      commit("SET_MENU_STATUS", true);
    },
    setUserDivisions({ commit }, value) {
      commit("SET_USER_DIVISIONS", value);
    },
    setCurrentArea({ commit }, value) {
      commit("SET_CURRENT_AREA", value);
    },
    setCurrentAreaId({ commit }, value) {
      commit("SET_CURRENT_AREA_ID", value);
    },
    setCurrentSector({ commit }, value) {
      commit("SET_CURRENT_SECTOR", value);
    },
    setCurrentSectorId({ commit }, value) {
      commit("SET_CURRENT_SECTOR_ID", value);
    },
    closeMenu({ commit }) {
      commit("SET_MENU_STATUS", false);
    },
    setTicketStatus({ commit }, value) {
      commit("SET_TICKET_STATUS", value);
    },
  },
};
