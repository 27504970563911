<template>
  <div class="relative border border-gray-300 rounded-lg">
    <img
      v-if="expand"
      class="absolute right-3 top-2 h-4 w-4 cursor-pointer"
      src="@/assets/icons/espandi.svg"
      @click="getUserDetails(user.user.id)"
    />
    <div class="flex flex-row p-5 pb-2">
      <img
        class="h-12 w-12 2xl:h-14 2xl:w-14 rounded-full shadow-orange"
        :src="
          user.user.user_info.picture_url
            ? user.user.user_info.picture_url
            : require('@/assets/images/default-profile.jpg')
        "
      />
      <div class="ml-5">
        <p
          class="font-medium text-blue sm:text-lg 2xl:text-xl whitespace-nowrap "
        >
          {{ user.user.user_info.name }}
        </p>
        <p class="font-bold text-orange whitespace-nowrap">
          {{ user.acl.role_it }}
        </p>
      </div>
    </div>
    <div class="text-center">
      <p class="sm:text-sm md:text-md 2xl:text-lg text-blue font-medium">
        {{ user.user.user_info.email }}
      </p>
    </div>
    <div class="text-center">
      <div class="inline-flex my-3">
        <img
          v-title="'Rimuovi dal settore'"
          @click="$refs.promptModal.open()"
          class="cursor-pointer h-5 w-5 mr-4"
          src="@/assets/icons/elimina.svg"
        />
        <img
          v-title="'Manager'"
          class="cursor-pointer h-5 w-5 mx-4"
          :src="
            user.acl.id == 4
              ? require('@/assets/icons/manager.svg')
              : require('@/assets/icons/manager_gray.svg')
          "
          @click="updateUserAcl('manager')"
        />
        <img
          v-title="'Coordinatore'"
          class="cursor-pointer h-5 mx-4"
          :src="
            user.acl.id == 2
              ? require('@/assets/icons/coordinatore.svg')
              : require('@/assets/icons/coordinatore-gray.svg')
          "
          @click="updateUserAcl('coordinator')"
        />
        <img
          v-title="'Dipendente'"
          class="cursor-pointer h-5 w-5 ml-4"
          :src="
            user.acl.id == 3
              ? require('@/assets/icons/dipendente.svg')
              : require('@/assets/icons/dipendente-gray.svg')
          "
          @click="updateUserAcl('employee')"
        />
      </div>
    </div>
    <prompt-modal
      ref="promptModal"
      :title="'Conferma Eliminazione'"
      :message="
        'Sei sicuro di voler rimuovre l\'utente selezionato dall\'area?'
      "
      @action="deleteUserArea"
    ></prompt-modal>
    <user-modal ref="userModal"></user-modal>
  </div>
</template>

<script>
import UserModal from "@/components/UserModal.vue";
import PromptModal from "@/components/PromptModal.vue";
import Services from "@/services/index";

export default {
  name: "DependantCard",

  components: {
    PromptModal,
    UserModal,
  },

  props: {
    user: Object,
    area: String,
    expand: Boolean,
  },

  methods: {
    async getUserDetails(id) {
      const response = await Services.getUserDetails(id);
      this.$refs.userModal.open(response);
    },

    async deleteUserArea() {
      try {
        await Services.deleteUserArea({
          id: this.area,
          user: this.user.user.id,
        });
        this.$emit("user-delete", this.user);
        this.$toast.success("Utente rimosso.", { position: "bottom-right" });
      } catch (error) {
        console.log(error);
      }
    },

    async updateUserAcl(role) {
      try {
        const response = await Services.updateUserAcl({
          area: this.area,
          user: this.user.user.id,
          role: role,
        });
        this.user.acl = response.data.acl;
      } catch (error) {
        console.log(error);
      }
    },
  },
};
</script>

<style></style>
