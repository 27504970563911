<template>
  <div>
    <div
      class="cursor-pointer bg-white rounded-md shadow-center h-52 whitespace-nowrap text-sm xl:text-md"
      @click.stop="$router.push('/areas/' + area.id)"
    >
      <div class="flex justify-between px-5 border-b-px border-gray-300 py-2">
        <span class="text-blue font-medium">{{ area.name }}</span>
        <span>
          <img
            @click.stop="$refs.createEditAreaModal.open()"
            class="inline-block cursor-pointer mr-5"
            @mouseover="editHover = true"
            @mouseleave="editHover = false"
            :src="
              editHover
                ? require('@/assets/icons/edit-orange.svg')
                : require('@/assets/icons/edit-gray.svg')
            "
          />
          <img
            @click.stop="$refs.createPromptModal.open()"
            class="inline-block cursor-pointer"
            @mouseover="deleteHover = true"
            @mouseleave="deleteHover = false"
            :src="
              deleteHover
                ? require('@/assets/icons/elimina-orange.svg')
                : require('@/assets/icons/elimina.svg')
            "
          />
        </span>
      </div>
      <div class="flex flex-col p-5">
        <div class="flex">
          <img class="ml-1 mr-3" src="@/assets/icons/manager.svg" />
          <div v-if="managers.length > 0">
            <p class="ml-1 inline-block">
              {{ managers[0].user.user_info.name }}
            </p>
            <p v-if="managers.length > 1" class="ml-1 text-gray-400">
              Altri {{ managers.length - 1 }}
            </p>
          </div>
          <div v-else>
            <p class="ml-1 text-gray-400">
              Nessun Manager Assegnato
            </p>
          </div>
        </div>
        <div class="flex my-3">
          <img class="mr-3" src="@/assets/icons/coordinatore.svg" />
          <div v-if="coordinators.length > 0">
            <p class="mr- 1 inline-block">
              {{ coordinators[0].user.user_info.name }}
            </p>
            <p
              v-if="coordinators.length > 1"
              class="text-gray-400"
              :v-title="coordinators"
            >
              Altri {{ coordinators.length - 1 }}
            </p>
          </div>
          <div v-else>
            <p class="text-gray-400">Nessun Coordinatore Assegnato</p>
          </div>
        </div>
        <div class="flex">
          <img class="ml-0.5 mr-3" src="@/assets/icons/dipendente.svg" />

          <div v-if="dependants.length > 0" class="relative flex">
            <img
              class="h-7 w-7 rounded-full"
              :class="dependants.length > 1 ? '-mr-2' : ''"
              v-for="dependant in dependants.slice(0, 4)"
              :key="dependant.user.id"
              :src="
                dependant.user.user_info.picture_url
                  ? dependant.user.user_info.picture_url
                  : require('@/assets/images/default-profile.jpg')
              "
            />
            <div
              v-if="dependants.length > 4"
              class="flex bg-orange h-7 w-7 rounded-full text-white place-items-center justify-center text-sm"
            >
              <span class="text-xs">+{{ dependants.slice(4).length }}</span>
            </div>
          </div>

          <div v-else>
            <p class="ml-1 text-gray-400">Non ci sono dipendenti</p>
          </div>
        </div>
      </div>
    </div>
    <prompt-modal
      ref="createPromptModal"
      :title="'Conferma Eliminazione'"
      :message="'Sei sicuro di voler rimuovere quest\'area?'"
      @action="deleteArea(area.id)"
    ></prompt-modal>

    <edit-area-modal ref="createEditAreaModal" :area="area"></edit-area-modal>
  </div>
</template>

<script>
import PromptModal from "@/components/PromptModal.vue";
import EditAreaModal from "@/components/EditAreaModal.vue";
import Services from "@/services/index";

export default {
  name: "AreaCard",

  components: {
    PromptModal,
    EditAreaModal,
  },

  props: {
    area: Object,
  },

  data: () => ({
    editHover: false,
    deleteHover: false,
    users: [],
    managers: [],
    coordinators: [],
    dependants: [],
  }),

  created() {
    this.users = this.area.area_rel_user;
    this.managers = this.users.filter((a) => a.user && a.acl.role == "Manager");
    this.coordinators = this.users.filter(
      (a) => a.user && a.acl.role == "Area Manager"
    );
    this.dependants = this.users.filter((a) => a.user && a.acl.role == "User");
  },

  methods: {
    async deleteArea(id) {
      var idArr = [];
      idArr.push(id);
      try {
        await Services.deleteArea(idArr);
        this.$refs.createPromptModal.close();
        this.$emit("update", id);
      } catch (error) {
        console.log(error);
      }
    },
  },
};
</script>
