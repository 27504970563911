<template>
  <Modal card-class="p-5" ref="createListModal">
    <template #header="{close}">
      <div class="flex justify-end">
        <h1 class="text-3xl mx-auto font-semibold mb-5">{{ title }}</h1>
        <span
          @click.prevent="close"
          class="cursor-pointer text-orange text-2xl"
        >
          <img src="@/assets/icons/X.svg" />
        </span>
      </div>
    </template>
    <template #body>
      <div class="px-10">
        <div
          v-for="user in content"
          :key="user.id"
          class="flex flex-row text-xl text-blue  bg-gray-100 my-4 p-2 pr-10 rounded-md"
        >
          <img
            class="h-10 rounded-full mr-5"
            :src="
              user.user.user_info.picture_url
                ? user.user.user_info.picture_url
                : require('@/assets/images/default-profile.jpg')
            "
          />
          <p class="font-medium">{{ user.user.user_info.name }}</p>
        </div>
      </div>
    </template>
  </Modal>
</template>

<script>
import Modal from "@/components/Modal.vue";

export default {
  name: "UsersListDialog",

  components: {
    Modal,
  },

  props: {
    title: String,
    content: Array,
  },

  methods: {
    open() {
      this.$refs.createListModal.open();
    },

    close() {
      this.$refs.createListModal.close();
    },
  },
};
</script>
