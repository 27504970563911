<template>
  <Modal card-class="lg:w-2/6 p-5" ref="createPromptModal">
    <template #header="{close}">
      <div class="flex justify-end items-center leading-none">
        <div
          class="text-xl lg:text-3xl mx-auto text-blue font-semibold whitespace-nowrap"
        >
          {{ title }}
        </div>
        <span
          @click.prevent="close"
          class="hidden lg:inline-block flex-shrink-0 cursor-pointer text-orange text-2xl"
        >
          <img src="@/assets/icons/X.svg" />
        </span>
      </div>
    </template>
    <template #body>
      <span
        v-if="!loading"
        class="block text-lg text-center text-gray-400 font-semibold my-4"
        >{{ message }}</span
      >
      <div class="my-4" v-else>
        <Spinner></Spinner>
      </div>
    </template>
    <template v-if="confirm" #actions="{close}">
      <div class="flex flex-nowrap justify-center">
        <button
          @click.prevent="
            $emit('dismiss');
            close();
          "
          class="text-blue bg-gray-100 font-medium p-2  rounded-md focus:outline-none"
        >
          {{ dismiss_label }}
        </button>
        <button
          :disabled="loading"
          @click="$emit('action')"
          :class="loading ? 'ticket-btn-disabled' : 'ticket-btn'"
          class="p-2 rounded-md ml-3"
        >
          {{ confirm_label }}
        </button>
      </div>
      <p class="text-red mt-2">{{ error }}</p>
    </template>
  </Modal>
</template>

<script>
import Modal from "@/components/Modal.vue";
import Spinner from "@/components/Spinner.vue";

export default {
  name: "PromptModal",

  components: {
    Modal,
    Spinner,
  },

  props: {
    title: String,
    message: String,
    error: String,
    action: Function,
    loading: Boolean,
    confirm_label: { type: String, default: "Conferma" },
    dismiss_label: { type: String, default: "Annulla" },
    confirm: { type: Boolean, default: true },
  },

  methods: {
    open() {
      this.$refs.createPromptModal.open();
    },

    close() {
      this.$refs.createPromptModal.close();
    },
  },
};
</script>
