<template>
  <div>
    <!-- Bottone 'apri ticket' e 'cerca ticket' in versione "base"-->
    <div class="2xl:inline-block hidden">
      <div class="text-center" @click="openTicketModal">
        <img
          v-if="!createTicketIcon"
          class="mx-auto cursor-pointer"
          src="@/assets/icons/nuovo ticket outline.svg"
          @mouseenter="toggleTicketIcon"
        />
        <img
          v-else
          class="mx-auto transition ease-linear cursor-pointer"
          src="@/assets/icons/nuovoTicketFilled.svg"
          @mouseleave="toggleTicketIcon"
        />
      </div>
      <div class="my-1 font-semibold text-center uppercase">
        APRI TICKET
      </div>
      <div class="text-center text-base">
        <input
          class="w-3/4 border-gray-300 border-px border-current rounded-md focus:outline-none p-1 mt-2"
          placeholder="N. Ticket"
          type="text"
          title="searchTicket"
          v-model="id"
          @keydown.enter="getTicket"
        />
        <button
          :class="
            loading || id == ''
              ? 'ticket-btn-disabled mt-2 p-1 w-3/4 rounded-md text-center cursor-not-allowed'
              : 'ticket-btn mt-2 p-1 w-3/4 rounded-md text-center'
          "
          @click="getTicket"
          :disabled="loading || id == ''"
        >
          Cerca Ticket
        </button>
      </div>
    </div>
    <!-- Bottone 'apri ticket' e 'cerca ticket' - Responsive (versione "ridotta")-->
    <div class="2xl:hidden 2xl:float-left 2xl:my-auto inline-block">
      <div class="flex-col text-center text-base">
<!--        <button
            class="w-3/4 h-8 bg-orange text-white rounded-md uppercase font-semibold"
            @click="openTicketModal"
        >
          <span class="font-bold">
            +
          </span>
            Apri ticket
        </button>-->
        <div class="text-center mb-4" @click="openTicketModal">

          <!--          http://jsfiddle.net/Lg81swrx/1/-->
          <img
              v-if="!createTicketIcon"
              class="mx-auto lg:w-14 lg:h-14 cursor-pointer"
              src="@/assets/icons/nuovo ticket outline.svg"
              @mouseenter="toggleTicketIcon"
          />
          <img
              v-else
              class="mx-auto lg:w-14 lg:h-14 transition ease-linear cursor-pointer"
              src="@/assets/icons/nuovoTicketFilled.svg"
              @mouseleave="toggleTicketIcon"
          />
        </div>
        <div class="flex w-5/6 mx-auto">
          <input
              class="w-full h-7 p-0 rounded-l-md pl-1 focus:outline-none text-sm border-gray-300 border border-current
              border-t-1 border-l-1 border-b-1 border-r-0"
              placeholder="Cerca ticket per ID"
              type="text"
              src="../icons/cerca.svg"
              title="searchTicket"
              v-model="id"
              @keydown.enter="getTicket"
          />
          <svg class="w-11 h-7 py-1 rounded-r-md m-auto cursor-pointer border-gray-300
               border-t-px border-r-px border-b-px border-l-0 border-current"
               :class="loading || id == ''? 'ticket-btn-disabled cursor-not-allowed': 'ticket-btn'"
               @click="getTicket"
              xmlns="http://www.w3.org/2000/svg" width="16.9" height="16.904" viewBox="0 0 16.9 16.904">
            <path id="Icon_ionic-ios-search" data-name="Icon ionic-ios-search" d="M21.2,20.174l-4.7-4.744a6.7,6.7,0,1,0-1.017,1.03l4.669,4.713a.723.723,0,0,0,1.021.026A.728.728,0,0,0,21.2,20.174Zm-9.964-3.657a5.289,5.289,0,1,1,3.741-1.549A5.256,5.256,0,0,1,11.238,16.516Z" transform="translate(-4.5 -4.493)" fill="#FFFFFF"/>
          </svg>
        </div>

      </div>
    </div>
    <open-ticket-modal ref="createTicketModal"></open-ticket-modal>
    <search-ticket-modal ref="createSearchTicketModal"></search-ticket-modal>
  </div>
</template>

<script>
import Services from "@/services/index";
import OpenTicketModal from "./OpenTicketModal.vue";
import SearchTicketModal from "./SearchTicketModal.vue";

export default {
  components: { OpenTicketModal, SearchTicketModal },
  name: "OpenTicket.vue",

  data() {
    return {
      createTicketIcon: false,
      id: "",
      ticket: {},
      loading: false,
    };
  },
  methods: {
    toggleTicketIcon() {
      this.createTicketIcon = !this.createTicketIcon;
    },
    openTicketModal() {
      this.$refs.createTicketModal.open();
    },
    openSearchModal(value) {
      this.$refs.createSearchTicketModal.open(value);
    },
    openTicket() {
      Services.openTicket(this.data);
    },

    async getTicket() {
      if (this.id == "" || this.id == null) return;
      this.loading = true;
      try {
        const response = await Services.searchTicketId(this.id);
        if (response.data.ticket != null) {
          this.ticket = response.data.ticket;
          this.openSearchModal(this.ticket);
          this.id = "";
        } else {
          this.$toast.warning("Nessun Ticket Trovato", {
            position: "bottom-right",
          });
        }
      } catch (error) {
        console.log(error);
      }
      this.loading = false;
    },
  },
};
</script>

<style scoped></style>
