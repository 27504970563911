<template>
  <Modal
    card-class="w-full p-5 sm:w-5/6 md:w-1/2 xl:w-5/12 rounded-0 overflow-hidden"
    ref="createEditAreaModal"
  >
    <template #header="{close}">
      <div>
        <div class="text-right">
          <img
            class="inline-block cursor-pointer mr-10"
            src="@/assets/icons/X.svg"
            @click.prevent="close"
          />
        </div>
        <h2 class="text-center text-4xl font-medium px-3 py-1">
          Modifica Area
        </h2>
      </div>
    </template>

    <template #body>
      <div class="flex flex-col px-10 my-5">
        <input
          class="ticket-text-input py-2"
          id="name"
          type="text"
          placeholder="Nome"
          v-model="area.name"
        />

        <input
          class="ticket-text-input my-5 py-2"
          id="email"
          type="text"
          placeholder="Email"
          v-model="area.email"
        />

        <input
          class="ticket-text-input mb-5 py-2"
          type="text"
          placeholder="Descrizione (max 100 caratteri)"
          v-model="area.description"
        />

        <select
          class="ticket-text-input mb-5 py-2"
          v-model="area.headquarter_id"
        >
          <option value="0" disabled>
            Sede
          </option>
          <option
            v-for="headquarter in headquarters"
            :key="headquarter.id"
            :value="headquarter.id"
          >
            {{ headquarter.location }}
          </option>
        </select>
        <button
          :disabled="loading"
          v-html="loading ? 'Modifica in corso...' : 'Modifica'"
          @click="updateArea()"
          class="ticket-btn py-2 text-lg rounded-md max-w-max px-5 place-self-end"
          :class="loading ? 'ticket-btn-disabled' : ''"
        >
          Modifica
        </button>
      </div>
    </template>
  </Modal>
</template>

<script>
import Modal from "@/components/Modal.vue";
import Services from "@/services/index";

export default {
  name: "NewArea",

  components: {
    Modal,
  },

  props: {
    area: Object,
  },

  data: () => ({
    headquarters: [],
    loading: false,
  }),

  created() {
    this.getHeadquarters();
  },

  methods: {
    async getHeadquarters() {
      try {
        const response = await Services.getHeadquarters();
        this.headquarters = response;
      } catch (error) {
        console.log(error);
      }
    },

    async updateArea() {
      let data = {
        id: this.area.id,
        name: this.area.name,
        email: this.area.email,
        headquarter: this.area.headquarter_id,
      };

      this.loading = true;
      try {
        const response = await Services.updateArea(data);
        this.close();
        this.$emit("area-updated", response.data.area);
        this.$toast.success("Area Modificata", { position: "bottom-right" });
      } catch (error) {
        console.log(error.response);
      }
      this.loading = false;
    },

    open() {
      this.$refs.createEditAreaModal.open();
    },

    close() {
      this.$refs.createEditAreaModal.close();
    },
  },
};
</script>
