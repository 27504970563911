<template>
  <div class="lg:flex-1 w-full lg:w-auto">
    <p class="uppercase font-medium text-xl text-blue mb-3">I miei ruoli</p>
    <div
      v-if="user.user_rel_area.length"
      class="grid grid-cols-2 overflow-auto place-content-start bg-ticket-grey rounded-md py-6 px-3 2xl:px-6 h-96"
    >
      <div class="2xl:mx-auto">
        <div class="text-left">
          <table class="border-collapse border-transparent">
            <tr>
              <th class="text-left font-family-lato font-bold">Ruolo</th>
            </tr>
            <tr v-for="url in user.user_rel_area" :key="url.id">
              <td class="text-center font-bold">
                <img
                  v-if="url.acl.role_it === 'Dipendente'"
                  v-title="url.acl.role_it"
                  class="inline-block h-4 mr-5"
                  src="@/assets/icons/dipendente.svg"
                />
                <img
                  v-else-if="url.acl.role_it === 'Capo Area'"
                  v-title="url.acl.role_it"
                  class="inline-block h-4 mr-5"
                  src="@/assets/icons/manager.svg"
                />
                <img
                  v-else-if="url.acl.role_it === 'Coordinatore Area'"
                  v-title="url.acl.role_it"
                  class="inline-block h-4 mr-5"
                  src="@/assets/icons/coordinatore.svg"
                />
              </td>
              <td class="text-sm xl:text-base">{{ url.acl.role_it }}</td>
            </tr>
          </table>
        </div>
      </div>
      <div>
        <table class="border-collapse border-transparent">
          <tr>
            <th class="text-left font-family-lato font-bold">Area - Settore</th>
          </tr>
          <tr v-for="url in user.user_rel_area" :key="url.id">
            <td class="font-family-lato font-bold text-orange text-sm xl:text-base">
              {{ url.area.name }} -
              <span class="text-blue font-medium text-sm xl:text-base">{{
                url.area.divisions[0].name
              }}</span>
              <span
                v-title="url.area.divisions.slice(1).map((a) => a.name)"
                class="text-blue font-medium cursor-pointer text-sm xl:text-base"
                v-if="url.area.divisions.length > 1"
              >
                , Altri {{ url.area.divisions.length - 1 }}
              </span>
            </td>
          </tr>
        </table>
      </div>
    </div>
    <div
      v-else
      class="grid grid-cols-1 overflow-auto place-content-start place-items-center bg-ticket-grey rounded-md p-6 h-96"
    >
      <div>
        <div class="text-left">
          <table class="border-collapse border-transparent">
            <tr>
              <td class="text-center">Nessun ruolo assegnato.</td>
            </tr>
          </table>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "Roles",

  props: {
    user: Object,
  },
};
</script>

<style></style>