<template>
    <div class="relative" :class="sideMenuClasses">
        <!--    <div class="flex items-center justify-center absolute z-0 -ml-12 w-12 h-20 bg-homegray rounded-l-full">-->
        <!--        <div class="flex absolute z-0 -ml-5">
                    <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="24"
                        height="58.77"
                        viewBox="0 0 21 73.77"
                    >
                        <path
                            id="Sottrazione_5"
                            data-name="Sottrazione 5"
                            d="M21,83.31h0c-1.521-.151-3.078-1.2-4.627-3.125l-.121-.15-.008-.01-.952-1.181-.006-.006-.016-.02A148.989,148.989,0,0,1,6.043,66.648,32.881,32.881,0,0,1,1.288,55.615a33.428,33.428,0,0,1,0-18.38A32.87,32.87,0,0,1,6.043,26.2a148.988,148.988,0,0,1,9.226-12.17l.016-.02.006-.006.2-.244.019-.023.865-1.073C17.921,10.743,19.478,9.691,21,9.54Z"
                            transform="translate(0 -9.54)"
                            fill="#f8f8f8"
                        />
                    </svg>
                    <img
                        v-if="menuStatus"
                        class="cursor-pointer -ml-4 my-auto h-3"
                        @click="closeMenu()"
                        :src="require('@/assets/icons/X.svg')"
                    />

                    <img
                        v-else
                        class="cursor-pointer -ml-4 my-auto"
                        @click="openMenu()"
                        :src="require('@/assets/icons/freccia-menu-laterale.svg')"
                    />
                </div>-->

        <!--    </div>-->
        <div v-if="menuStatus" class="flex flex-col" :class="isMobile ? 'w-full px-4' : 'w-48 lg:w-48 xl:w-56'">
            <!--<TicketStatus></TicketStatus>-->

            <div class="flex flex-col py-3" :class="isMobile ? 'w-10/12 justify-center mx-auto' : ''">
                <div
                    class="flex lg:justify-between text-blue text-2xl lg:text-base 2xl:text-lg font-medium uppercase"
                    :class="isMobile ? 'pl-0' : 'pl-6'"
                >
                    {{ currentArea }}

                    <div
                        v-if="
              user.is_admin > 0 ||
                user.user_area_coordinator_or_manager.length > 0
            "
                        class="flex w-10 ml-auto"
                    >
                        <img
                            v-if="showContent"
                            class="cursor-pointer my-auto mr-2 xl:mr-5 h-2.5 lg:h-2"
                            :src="require('@/assets/icons/freccia-su.svg')"
                            @click="showContent = !showContent"
                        />
                        <img
                            v-else
                            class="cursor-pointer my-auto mr-1 xl:mr-5 h-2.5 lg:h-2"
                            :src="require('@/assets/icons/freccia-giu.svg')"
                            @click="isMobile ? (getAreas(),showContent = !showContent) : showContent = !showContent"
                        />
                    </div>
                </div>
                <div
                    v-if="currentSector != 'Tutti'"
                    class="font-medium text-orange" :class="isMobile ? 'pl-0' : 'pl-6'"
                >
                    {{ currentSector }}
                </div>
                <div v-show="showContent" class="flex flex-col pt-2">
                    <div class="flex flex-col">
                        <div
                            v-if="
                user.is_admin > 0 ||
                  user.user_area_coordinator_or_manager.length > 0
              "
                            class="flex justify-between w-56 lg:w-40 space-x-4 px-1 cursor-pointer text-lg lg:text-sm border bg-white focus:outline-none rounded-sm"
                            :class="isMobile ? '' : 'ml-6 mr-2'"
                            @click="
                (enableAreaSelect = !enableAreaSelect),
                  (enableSectorSelect = false)
              "
                        >
              <span>
                {{ currentArea }}
              </span>
                            <div class="flex my-auto">
                                <img
                                    v-if="enableAreaSelect"
                                    class="cursor-pointer h-2"
                                    src="@/assets/icons/freccia-su.svg"
                                />
                                <img
                                    v-else
                                    class="cursor-pointer h-2"
                                    src="@/assets/icons/freccia-giu.svg"
                                />
                            </div>
                        </div>
                        <div
                            v-if="enableAreaSelect"
                            class="absolute mt-7 lg:mt-6 w-56 lg:w-40 p-1 shadow-md border z-20 rounded-sm bg-white cursor-pointer"
                            :class="isMobile ? 'ml-0' : 'ml-6'"
                        >
                            <div v-if="user.is_admin === 1"
                                 class="hover:bg-orangedisabled rounded-sm text-lg lg:text-sm focus:bg-orangedisabled"
                                 @click="showAllTickets"
                            >
                                Tutti
                            </div>
                            <div
                                v-if="user.is_admin != 1"
                                class="hover:bg-orangedisabled rounded-sm text-lg lg:text-sm focus:bg-orangedisabled"
                                @click="showUserTickets"
                            >
                                I miei
                            </div>
                            <div
                                v-for="area in areas"
                                :key="area.areaId"
                                class="hover:bg-orangedisabled rounded-sm text-lg lg:text-sm focus:bg-orange"
                                @click="switchRoute(area.areaId, area.areaName, null, null)"
                            >
                                {{ area.areaName }}
                            </div>
                        </div>
                    </div>
                </div>

                <div v-show="showContent" class="flex flex-col pt-2">
                    <div class="flex flex-col">
                        <div
                            v-if="user.is_admin > 0 ||
                                  user.user_area_coordinator_or_manager.length > 0"
                            class="flex justify-between w-56 lg:w-40 space-x-4 px-1 cursor-pointer text-lg lg:text-sm border bg-white focus:outline-none rounded-sm"
                            :class="isMobile ? '' : 'ml-6 mr-2'"
                            @click="enableSectorSelect = !enableSectorSelect"
                        >
              <span
                  v-if="currentSector == '' && currentDivision == ''"
                  :class="
                  currentArea &&
                  currentArea != 'I miei' &&
                  currentArea != 'Tutti'
                    ? ''
                    : 'text-iconsgray pointer-events-none'
                "
              >Seleziona settore
              </span>
                            <span v-else-if="currentSector != ''">
                {{ currentSector }}
              </span>
                            <span v-else-if="
                  currentDivision != '' &&
                    currentSector == '' &&
                    currentArea != 'I miei' &&
                    currentArea != 'Tutti'">
                Tutti
              </span>
                            <span v-else class="text-iconsgray"> Seleziona settore</span>
                            <div class="my-auto">
                                <div class="flex my-auto">
                                    <img v-if="
                          enableSectorSelect &&
                          currentArea &&
                          currentArea != 'I miei' &&
                          currentArea != 'Tutti'"
                                         class="cursor-pointer h-2"
                                         src="@/assets/icons/freccia-su.svg"/>
                                    <svg
                                        v-else
                                        xmlns="http://www.w3.org/2000/svg"
                                        width="13.98"
                                        height="8"
                                        viewBox="0 0 18.81 10.754"
                                    >
                                        <path
                                            id="Icon_ionic-ios-arrow-back"
                                            data-name="Icon ionic-ios-arrow-back"
                                            d="M3.242,9.4l7.117-7.112a1.344,1.344,0,0,0-1.9-1.9L.392,8.451A1.342,1.342,0,0,0,.352,10.3l8.1,8.114a1.344,1.344,0,0,0,1.9-1.9Z"
                                            transform="translate(0 10.754) rotate(-90)"
                                            :fill="
                                          currentArea &&
                                          currentArea != 'I miei' &&
                                          currentArea != 'Tutti'
                                            ? '#132546'
                                            : '#959dac'
                                          "
                                        />
                                    </svg>
                                    <!--<img
                                        v-else
                                        class="cursor-pointer h-2"
                                        src="@/assets/icons/freccia-giu.svg"
                                      />-->
                                </div>
                            </div>
                        </div>
                    </div>

                    <div v-if="
                        enableSectorSelect &&
                        currentArea != 'I miei' &&
                        currentArea != 'Tutti' &&
                        !enableAreaSelect"
                         class="absolute mt-7 lg:mt-6 w-56 lg:w-40 p-1 shadow-md text-lg lg:text-sm border z-10 rounded-sm bg-white cursor-pointer"
                         :class="isMobile ? 'ml-0' : 'ml-6'">
                        <div
                            class="relative hover:bg-orangedisabled rounded-sm focus:bg-orangedisabled"
                            @click="
                            switchRoute(currentAreaId, currentArea, null, null),
                            (currentDivision = 'Tutti'),
                            (enableSectorSelect = false)
                            "
                        >
                            Tutti
                        </div>
                        <div
                            v-for="division in divisions"
                            :key="division.id"
                            class="relative hover:bg-orangedisabled focus:bg-orange"
                            @click="
                switchRoute(
                  currentAreaId,
                  currentArea,
                  division.id,
                  division.name
                )
              "
                        >
                            {{ division.name }}
                        </div>
                    </div>

                    <!--<select class="flex flex-col w-40 my-2 h-6 ml-6 rounded-sm border text-gray focus:outline-none">
                      <option class="select-none" value="" selected disabled>
                        Seleziona settore
                      </option>
                      <option>
                        area name2
                      </option>
                    </select>-->
                </div>
            </div>

            <TicketStatus></TicketStatus>

            <div class="py-3" :class="isMobile ? 'hidden' : 'flex flex-col'">
        <span class="text-blue pl-6 2xl:text-lg lg:text-base font-medium uppercase">
          Altro
        </span>
                <div
                    v-if="
            user.user_area_coordinator_or_manager.length > 0 ||
              user.is_admin > 0
          "
                    class="flex pl-6 2xl:h-8 lg:h-7 2xl:my-1 cursor-pointer 2xl:text-base lg:text-sm hover:bg-orangedisabled hover:bg-opacity-50 rounded-r-full"
                    :class="[
             this.$route.name === 'EmployeeManagement' || this.$route.name === 'AreaManagement'
              ? 'bg-orangedisabled bg-opacity-50'
              : '',
          ]"
                    @mouseover="
            hover_manage_employee = true;
            hover_manage_areas = true;
          "
                    @mouseleave="
            hover_manage_employee = false;
            hover_manage_areas = false;
          "
                    @click="redirect(), (favorites = false), (reminders = false)">
                    <!--<img
                        v-if="user.is_admin <= 0 && (this.$route.name === 'EmployeeManagement' || hover_manage_employee)"
                        class="h-5 w-5 my-auto"
                        src="@/assets/icons/gestione-dipendenti-black.svg"
                    />-->
                    <img v-if="user.user_area_coordinator_or_manager.length > 0 && user.is_admin <= 0"
                         class="h-5 w-5 my-auto" src="@/assets/icons/gestione-dipendenti.svg"
                    />
                    <!--<img v-if="user.is_admin > 0 && this.$route.path === 'AreaManagement' || !hover_manage_areas"
                             class="h-5 w-5 my-auto"
                             src="@/assets/icons/gestione aree.svg"
                             />
                             <img v-else-if="user.is_admin > 0 && this.$route.path === 'AreaManagement' || hover_manage_areas"
                                  class="h-5 w-5 my-auto"
                                  src="@/assets/icons/gestione-aree-blue.svg"
                                  />-->
                    <svg v-else class="my-auto"
                         xmlns="http://www.w3.org/2000/svg" width="19.953" height="19.953" viewBox="0 0 19.953 19.953">
                        <g id="Raggruppa_10080" data-name="Raggruppa 10080" transform="translate(419 9522)">
                            <path
                                fill="#fd7f50"
                                id="Tracciato_1677" data-name="Tracciato 1677"
                                d="M12.112,19.953h-2.8V15.877a3.067,3.067,0,1,0-6.133,0v4.076H1.165A1.165,1.165,0,0,1,0,18.789V1.165A1.165,1.165,0,0,1,1.165,0l9.867,1.558a1.64,1.64,0,0,1,.773.311.823.823,0,0,1,.226.295,1,1,0,0,1,.082.415V19.953ZM7.764,9.239a1.009,1.009,0,0,0,0,2.019H9.55a1.009,1.009,0,0,0,0-2.019Zm-5.357,0a1.009,1.009,0,0,0,0,2.019H4.193a1.009,1.009,0,0,0,0-2.019ZM7.764,6.134a1.009,1.009,0,0,0,0,2.019H9.55a1.009,1.009,0,0,0,0-2.019Zm-5.357,0a1.009,1.009,0,0,0,0,2.019H4.193a1.009,1.009,0,0,0,0-2.019ZM7.764,3.028a1.009,1.009,0,0,0,0,2.019H9.55a1.009,1.009,0,0,0,0-2.019Zm-5.357,0a1.009,1.009,0,0,0,0,2.019H4.193a1.009,1.009,0,0,0,0-2.019Z"
                                transform="translate(-419 -9522)"/>
                            <path fill="#fd7f50"
                                  id="Tracciato_1676" data-name="Tracciato 1676"
                                  d="M5.512,11.025H0V0H5.512a1.629,1.629,0,0,1,1.63,1.63V9.394a1.629,1.629,0,0,1-1.63,1.63ZM4.658,7.376a.7.7,0,1,0,0,1.4H5.745a.7.7,0,0,0,0-1.4Zm-3.183,0a.7.7,0,0,0,0,1.4H2.562a.7.7,0,1,0,0-1.4Zm3.183-2.95a.7.7,0,1,0,0,1.4H5.745a.7.7,0,0,0,0-1.4Zm-3.183,0a.7.7,0,0,0,0,1.4H2.562a.7.7,0,1,0,0-1.4Zm3.183-2.95a.7.7,0,1,0,0,1.4H5.745a.7.7,0,0,0,0-1.4Zm-3.183,0a.7.7,0,0,0,0,1.4H2.562a.7.7,0,0,0,0-1.4Z"
                                  transform="translate(-406.19 -9513.071)"/>
                        </g>
                    </svg>

                    <div
                        v-if="user.is_admin > 0"
                        class="text-blue font-medium pl-4 my-auto"
                    >
                        Gestione aree
                    </div>
                    <span
                        v-else-if="user.user_area_coordinator_or_manager.length > 0"
                        class="text-blue font-medium pl-4 my-auto "
                        @click="setTicketStatus(null)"
                    >
            Gestione dipendenti
          </span>
                </div>
                <div
                    class="flex pl-6 2xl:my-1 2xl:h-8 lg:h-7 cursor-pointer 2xl:text-base lg:text-sm hover:bg-orangedisabled hover:bg-opacity-50 rounded-r-full"
                    @mouseover="hover_favorite = true"
                    @mouseleave="hover_favorite = false"
                    :class="
             this.$route.name === 'FilterResults' && this.$route.params.type === 'favorites'
              ? 'bg-orangedisabled bg-opacity-50'
              : ''
          "
                    @click="
                    showFavorites(),
                      (favorites = true),
                      (reminders = false)
          "
                >
                    <svg
                        class="my-auto"
                        xmlns="http://www.w3.org/2000/svg"
                        width="21"
                        height="21"
                        viewBox="0 0 17.818 17.985"
                    >
                        <g
                            id="Livello_2"
                            data-name="Livello 2"
                            transform="translate(0 7.413)"
                        >
                            <g
                                id="Livello_1"
                                data-name="Livello 1"
                                transform="translate(0 -7.413)"
                            >
                                <path
                                    id="Tracciato_1684"
                                    data-name="Tracciato 1684"
                                    d="M.371,18.055c-.411-.172-.484-.486-.2-.845a50.09,50.09,0,0,1,4.35-4.848c.6-.59,1.191-1.195,1.793-1.786.1-.1.116-.155,0-.268-.939-.931-1.861-1.861-2.8-2.792a.5.5,0,0,1,.022-.835,2.17,2.17,0,0,1,2.821-.106c.1.081.152.065.24-.01Q9.1,4.406,11.621,2.253a.151.151,0,0,0,.048-.215A1.629,1.629,0,0,1,12.017.21a.465.465,0,0,1,.631-.031,1.667,1.667,0,0,1,.14.133c1.582,1.582,3.158,3.171,4.751,4.742.439.432.337.756-.13,1.047a1.58,1.58,0,0,1-1.557.116.142.142,0,0,0-.2.043Q13.477,8.8,11.3,11.324c-.064.074-.055.116,0,.187a2.177,2.177,0,0,1-.131,2.885.478.478,0,0,1-.751.015Q9.08,13.078,7.748,11.736c-.1-.1-.154-.116-.268-.006C6.331,12.876,5.2,14.043,4.025,15.158,3,16.129,1.9,17.02.807,17.906a1.958,1.958,0,0,1-.255.147Z"
                                    transform="translate(0 -0.07)"
                                    fill="#fd7f50"
                                />
                                <path
                                    id="Tracciato_1684"
                                    data-name="Tracciato 1684"
                                    d="M.371,18.055c-.411-.172-.484-.486-.2-.845a50.09,50.09,0,0,1,4.35-4.848c.6-.59,1.191-1.195,1.793-1.786.1-.1.116-.155,0-.268-.939-.931-1.861-1.861-2.8-2.792a.5.5,0,0,1,.022-.835,2.17,2.17,0,0,1,2.821-.106c.1.081.152.065.24-.01Q9.1,4.406,11.621,2.253a.151.151,0,0,0,.048-.215A1.629,1.629,0,0,1,12.017.21a.465.465,0,0,1,.631-.031,1.667,1.667,0,0,1,.14.133c1.582,1.582,3.158,3.171,4.751,4.742.439.432.337.756-.13,1.047a1.58,1.58,0,0,1-1.557.116.142.142,0,0,0-.2.043Q13.477,8.8,11.3,11.324c-.064.074-.055.116,0,.187a2.177,2.177,0,0,1-.131,2.885.478.478,0,0,1-.751.015Q9.08,13.078,7.748,11.736c-.1-.1-.154-.116-.268-.006C6.331,12.876,5.2,14.043,4.025,15.158,3,16.129,1.9,17.02.807,17.906a1.958,1.958,0,0,1-.255.147Z"
                                    transform="translate(0 -0.07)"
                                    fill="#fd7f50"
                                />
                            </g>
                        </g>
                    </svg>
                    <span class="text-blue font-medium pl-4 my-auto">
                      In evidenza
                    </span>
                </div>
                <div
                    v-if="user.user_area_coordinator_or_manager.length > 0"
                    class="flex pl-6 2xl:my-1 2xl:h-8 lg:h-7 2xl:text-base lg:text-sm cursor-pointer mb-2"
                >
                    <img src="@/assets/icons/sollecita-red.svg" class="my-auto h-5 w-5"/>
                    <div
                        class="flex text-blue font-medium pl-4 my-auto cursor-pointer"
                        @click="enableRemindMenu = !enableRemindMenu"
                    >
                        Solleciti
                        <div class="flex justify-end pl-16 xl:pl-20">
                            <img
                                v-if="enableRemindMenu "
                                class="cursor-pointer my-auto h-2 rotate-90"
                                :src="require('@/assets/icons/freccia-su.svg')"
                            />
                            <img
                                v-else
                                class=" cursor-pointer my-auto h-2 rotate-180"
                                :src="require('@/assets/icons/freccia-giu.svg')"
                            />
                        </div>
                    </div>
                </div>
                <div
                    v-if="user.user_area_coordinator_or_manager.length > 0 && enableRemindMenu"
                    class="flex flex-col justify-start"
                >
                    <div
                        v-if="enableRemindMenu || this.$route.name === 'FilterResults' &&
                (this.$route.params.type === 'send_reminder' || this.$route.params.type === 'reminder')"
                        class="font-medium pl-16 my-auto cursor-pointer mb-2 hover:bg-orangedisabled hover:bg-opacity-50
                         focus:bg-orangedisabled focus:bg-opacity-50 rounded-r-full 2xl:text-base lg:text-sm"
                        :class="
              sent || this.$route.name === 'FilterResults' && this.$route.params.type === 'send_reminder'
                ? 'text-blue font-bold bg-orangedisabled bg-opacity-50'
                : 'text-gray-normal'
            "
                        @click="(sent = true), (received = false), showRemind()"
                    >
                        Inviati
                    </div>
                    <div
                        v-if="enableRemindMenu || this.$route.name === 'FilterResults' &&
                (this.$route.params.type === 'send_reminder' || this.$route.params.type === 'reminder')"
                        class="font-medium pl-16 my-auto cursor-pointer hover:bg-orangedisabled hover:bg-opacity-50 rounded-r-full
                        2xl:text-base lg:text-sm"
                        :class="
              received || this.$route.name === 'FilterResults' && this.$route.params.type === 'reminder'
                ? 'text-blue font-bold bg-orangedisabled bg-opacity-50'
                : 'text-gray-normal'
            "
                        @click="(sent = false), (received = true), showRemind()"
                    >
                        Ricevuti
                    </div>
                </div>
                <div
                    v-if="
            user.user_area_coordinator_or_manager.length == 0 ||
              user.is_admin > 0
          "
                    class="flex pl-6 my-1 h-8 cursor-pointer hover:bg-orangedisabled hover:bg-opacity-50 rounded-r-full"
                    :class="
            this.$route.name === 'FilterResults' &&
            (this.$route.params.type === 'reminder' || this.$route.params.type === 'send_reminder' )
              ? 'bg-orangedisabled bg-opacity-50'
              : ''
          "
                    @click="
            showRemind(),
              (reminders = true),
              (favorites = false)
          "
                    @mouseover="hover_reminder = true"
                    @mouseleave="hover_reminder = false"
                >
                    <!--                    <img
                                            v-if="this.$route.name === 'FilterResults'
                                            && (this.$route.params.type === 'reminder' || this.$route.params.type === 'send_reminder') || hover_reminder"
                                            src="@/assets/icons/sollecita-black.svg"
                                        />-->
                    <img src="@/assets/icons/sollecita-red.svg"/>
                    <span class="text-blue font-medium pl-4 my-auto">
            Solleciti
          </span>
                </div>
            </div>
            <OpenTicket class="2xl:bottom-10 2xl:inset-y-3/4 lg:bottom-8 mx-auto"
                        :class="isMobile ? 'hidden' : 'lg:absolute 2xl:absolute'"></OpenTicket>
        </div>
        <prompt-modal
            ref="errorModal"
            :title="'Errore'"
            :message="errorMessage"
            :confirm="false"
        >
        </prompt-modal>
    </div>
</template>

<script>
import OpenTicket from "../components/OpenTicket";
import TicketStatus from "@/components/TicketStatus";
import {mapState, mapActions} from "vuex";
import PromptModal from "@/components/PromptModal";
import Services from "@/services";

export default {
    name: "SideMenu.vue",

    props: {
        isMobile: {
            type: Boolean,
            required: false,
            default: false
        },
    },
    components: {
        TicketStatus,
        OpenTicket,
        PromptModal
    },
    data: () => ({
        areas: [],
        //areaSelected: false,
        divisions: [],
        ticketsCounter: {
            newTickets: 0,
            assignedTickets: 0,
            processingTickets: 0,
            closedTickets: 0,
        },
        enableAreaSelect: false, //Variabile per nascondere/mostrare il div che sarebbe il menu con le aree
        enableSectorSelect: false, //... stessa cosa per i settori
        showContent: true,
        pressedBtn: "",
        //Ticket sollecitati - inviati e ricevuti
        enableRemindMenu: false,
        sent: false,
        received: false,
        currentDivision: "",
        //Variabili per gestire il focus sulle voci del menu 'Altro'
        favorites: false,
        reminders: false,
        hover_favorite: false,
        hover_reminder: false,
        hover_manage_employee: false,
        hover_manage_areas: false,

        errorMessage: ""
    }),

    async created() {
        //Se faccio il refresh della pagina ho ticketStatus = null - lo metto a 1 altrimenti
        // la rest per avere i ticket va in errore
        if (this.ticketStatus == null && this.$route.path === "/") {
            this.setTicketStatus(1);
        }
        if (this.$route.name === 'FilterResults' && this.$route.params.type === 'favorites') {
            await this.showFavorites();
        }

        await this.getAreas(); //In base all'utente (coordinatore o admin), popolo la prima select in alto con le varie aree
        if (this.currentAreaId != 0) {
            this.getAreaDivisions(this.currentAreaId);
        }

        //Controlli fatti per il refresh della pagina (preservo lo stato del menu e richiamo le rispettive rest)
        //Esempio: sono su in evidenza - ricarico - resta il focus sulla voce di menu e mi richiamo la rest per avere i t. in evidenza

        if (this.user.user_area_coordinator_or_manager.length > 0) {
            if (this.$route.name === 'FilterResults' && this.$route.params.type === 'send_reminder') {
                this.enableRemindMenu = true;
                this.sent = true;
                this.showRemind();
            } else if (this.$route.params.type === 'reminder') {
                this.enableRemindMenu = true;
                this.received = true;
                this.showRemind();
            }
        } else if (this.user.user_area_coordinator_or_manager === 0 && this.$route.name === 'FilterResults') {
            if (this.$route.params.type === 'reminder') {
                //Come utente normale ricarico direttamente i solleciti ricevuti
                this.enableRemindMenu = true;
                this.received = true;
                this.showRemind();
            } else {
                //Come admin ricarico i solleciti inviati
                this.enableRemindMenu = true;
                this.sent = true;
                this.showRemind();
            }
        }


    },

    computed: {
        ...mapState("user", {user: "user"}),
        ...mapState("user", {menuStatus: "menuStatus"}),
        ...mapState("user", {ticketStatus: "ticketStatus"}),
        ...mapState("user", {currentArea: "currentArea"}),
        ...mapState("user", {currentAreaId: "currentAreaId"}),
        ...mapState("user", {currentSector: "currentSector"}),
        ...mapState("user", {currentSectorId: "currentSectorId"}),
        sideMenuClasses() {
            return {
                'gray-bg': !this.menuStatus,
                'mobile-class': this.isMobile
            }
        }
    },

    methods: {
        ...mapActions({setUser: "user/setUser"}),
        ...mapActions({openMenu: "user/openMenu"}),
        ...mapActions({closeMenu: "user/closeMenu"}),
        ...mapActions({setTickets: "tickets/setTickets"}),
        ...mapActions({setFilters: "tickets/setFilters"}),
        ...mapActions({setLastPage: "tickets/setLastPage"}),
        ...mapActions({setCurrentArea: "user/setCurrentArea"}),
        ...mapActions({setTicketStatus: "user/setTicketStatus"}),
        ...mapActions({setCurrentAreaId: "user/setCurrentAreaId"}),
        ...mapActions({setCurrentSector: "user/setCurrentSector"}),
        ...mapActions({setCurrentSectorId: "user/setCurrentSectorId"}),
        ...mapActions({setSelected: "mobileMenu/setSelected"}),


        redirect() {
            //Se l'utente è admin (la voce di menu è Gestione aree) vado su /areas
            //se l'utente è coordinatore (voce di menu Gestionde dipendenti) vado su /employeeManagement
            if (this.user.is_admin) {
                this.$router.push("/areas");
            } else if (this.user.user_area_coordinator_or_manager.length > 0) {
                this.$router.push("/employeeManagement");
                //Comprimo anche il sottomenu con le relative voci se l'utente è coordinatore
                this.enableRemindMenu = false;
                this.sent = false;
                this.received = false;

            }
        },

        async getAreas() {
            try {
                this.areas = []
                if (this.user.user_area_coordinator_or_manager.length > 0) {
                    for (let i in this.user.user_area_coordinator_or_manager) {
                        this.areas.push({
                            areaId: this.user.user_area_coordinator_or_manager[i].area.id,
                            areaName: this.user.user_area_coordinator_or_manager[i].area.name,
                            clicked: false,
                        });
                        await this.getUserAreas(
                            this.user.user_area_coordinator_or_manager[i].area.id
                        );
                    }
                } else if (this.user.is_admin > 0) { //Se l'utente è admin vede tutte le aree
                    const response = await Services.getAllAreas();
                    for (let i in response) {
                        this.areas.push({
                            areaId: response[i].id,
                            areaName: response[i].name,
                            clicked: false,
                        });
                    }
                }
            } catch (error) {
                console.log(error);
            }
        },

        async getUserAreas(id) {
            const response = await Services.getAllAreaDivisions(id);
            this.areas.find((area) => area.areaId === id).divisions =
                response.data.division;
        },

        async getAreaDivisions(areaId) {
            try {
                const response = await Services.getAreaDivision({id: areaId, status: 1});
                for (let i in response.data.division) {
                    this.divisions.push(response.data.division[i]);
                }
            } catch (error) {
                this.$refs.errorModal.open();
                this.$refs.errorModal.close();
                this.errorMessage = "";
                this.errorMessage = "Errore nel caricamento dei settori associati all'area selezionata."
                console.log(error);
            }
        },

        showAllTickets() {
            this.showUserTickets();
            this.enableAreaSelect = false;
            this.setCurrentArea("Tutti");
        },

        showUserTickets() {
            if (this.$route.name != "Home") {
                this.$router.push("/");
            }
            this.setTicketStatus(1);
            this.enableAreaSelect = false;
            //this.currentArea = "I miei";
            this.setCurrentArea("I miei");
            this.setCurrentSector("");
        },

        async showFavorites() {
            this.setSelected('favorites')
            let request = {favorite: 1};
            try {
                const response = await Services.getAllTickets(request);
                this.setFilters(request);
                this.setLastPage(parseInt(response.data.ticket.last_page));
                this.setTickets(response.data.ticket.data);
                if (this.$route.path !== "/filterResults/") {
                    let type = "favorites";
                    this.$router.push("/filterResults/" + type);
                }
                this.setTicketStatus(null);
                //Comprimo anche il sottomenu con le relative voci se l'utente è coordinatore
                if (this.user.user_area_coordinator_or_manager.length > 0) {
                    this.enableRemindMenu = false;
                    this.sent = false;
                    this.received = false;
                }
            } catch (error) {
                this.$refs.errorModal.open();
                this.$refs.errorModal.close();
                this.errorMessage = "";
                this.errorMessage = 'Errore nel caricamento dei ticket in evidenza.'
                console.log(error);
            }
        },

        async showRemind() {
            this.setSelected('reminds')
            let request = {};
            let type = "";
            //Controllo: se l'utente è coordinatore, può vedere solleciti inviati e ricevuti
            //Dipendente - vede solo ricevuti / admin - vede solo inviati
            //Reminder - ticket sollecitati ricevuti / send_reminder - ... inviati
            if (this.user.user_area_coordinator_or_manager.length > 0) {
                if (this.sent) {
                    request = {send_reminder: 1};
                    type = "send_reminder";
                } else if (this.received) {
                    request = {reminder: 1};
                    type = "reminder";
                }
            } else if (
                this.user.user_area_coordinator_or_manager.length == 0 &&
                this.user.is_admin == 0
            ) {
                request = {reminder: 1};
                type = "reminder";
            } else if (this.user.is_admin > 0) {
                request = {send_reminder: 1};
                type = "send_reminder";
            }
            try {
                const response = await Services.getAllTickets(request);
                this.setFilters(request);
                this.setLastPage(parseInt(response.data.ticket.last_page));
                this.setTickets(response.data.ticket.data);
                if (this.$route.path !== "/filterResults") {
                    this.$router.push("/filterResults/" + type);
                }
                this.setTicketStatus(null);
            } catch (error) {
                this.$refs.errorModal.open();
                this.$refs.errorModal.close();
                this.errorMessage = "";
                this.errorMessage = 'Errore nel caricamento dei ticket sollecitati.'
                console.log(error);
            }
        },

        switchRoute(areaId, areaName, divisionId, divisionName) {
            //Se invoco questo metodo significa che almeno l'area l'ho selezionata nella prima select;
            //se entro nell'else significa che ho selezionato anche uno dei settori associato all'area, quindi mi porto
            //l'id nell'url
            //this.areaSelected = true;
            if (divisionId == null && divisionName == null) {
                this.$router.push("/my-areas/" + areaId);
            } else {
                this.$router.push("/my-areas/" + areaId + "/" + divisionId);
            }

            //La voce di menù 'inviati' (status = 5)  deve essere solo sulla home e non sulla schermata del
            //coordinatore (DepartmentDetails) Se sto sulla home tenendo selezionato inviati e mi sposto su un'area,
            // resetto lo status a 1 (nuovi)
            if (this.ticketStatus == null || this.ticketStatus == 5) {
                this.setTicketStatus(1);
            }

            //Nel momento in cui seleziono un'area carico i relativi settori e abilito la rispettiva select
            this.divisions = [];
            this.getAreaDivisions(areaId);
            for (let i in this.areas) {
                if (
                    this.areas[i].areaId === areaId &&
                    this.areas[i].clicked === false
                ) {
                    this.areas[i].clicked = true;
                } else {
                    this.areas[i].clicked = false;
                }
            }

            //this.currentArea = areaName;
            this.setCurrentArea(areaName);
            this.setCurrentAreaId(areaId);
            if (divisionName != null) {
                this.setCurrentSector(divisionName);
                this.setCurrentSectorId(divisionId);
                this.enableSectorSelect = false;
            } else {
                this.setCurrentSectorId();
                this.setCurrentSector("");
            }
            this.enableAreaSelect = false;
        },
    },

    watch: {
        //Watch per deselezionare le voci del menu 'Altro': se torno a selezionare una voce dal menu TicketStatus
        //(nuovi, assegnati, ..), non avrò più che ticketStatus è settato a null, perchè l'utente ha ripreso a interagire
        //con la parte alta del menu
        ticketStatus: function () {
            if (this.ticketStatus != null) {
                this.favorites = false;
                this.reminders = false;
                if (this.user.user_area_coordinator_or_manager.length > 0) {
                    this.enableRemindMenu = false;
                    this.sent = false;
                    this.received = false;
                }
            }
        },
    },
};
</script>

<style scoped>
.gray-bg {
    @apply bg-homegray;
}

.mobile-class {
    @apply bg-white w-full justify-center mt-2
}
</style>