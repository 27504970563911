<template>
  <Modal ref="newDivision">
    <template #header="{close}">
      <div class="flex p-8 justify-end ">
        <h1 class="text-3xl mx-auto font-semibold">Crea settore</h1>
        <span
          @click.prevent="close"
          class="cursor-pointer text-orange text-2xl"
        >
          <img src="@/assets/icons/X.svg" />
        </span>
      </div>
    </template>
    <template #body>
      <div class="pb-8 px-16">
        <input
          class="block mb-3 focus:outline-none h-10 w-full pl-4 text-lg overflow-auto bg-ticket-grey"
          v-model="data.name"
          placeholder="Nome"
        />
        <button
          :disabled="data.name == ''"
          @click="addDivision()"
          @click.prevent="close"
          :class="
            data.name.length == 0
              ? 'cursor-not-allowed ticket-btn-disabled w-full h-11 rounded-lg mt-5 font-bold text-xl focus:outline-none'
              : 'mt-5 focus:outline-none w-full h-11 bg-orange text-xl font-bold text-white rounded-lg'
          "
        >
          Crea
        </button>
      </div>
    </template>
  </Modal>
</template>

<script>
import Modal from "./Modal";
import Services from "../services";

export default {
  name: "ModalCreateDivision",
  props: {
    area: Number,
  },
  components: {
    Modal,
  },
  data: () => ({
    data: {
      name: "",
    },
  }),
  methods: {
    open() {
      this.$refs.newDivision.open();
    },
    async addDivision() {
      try {
        const response = await Services.addDivision({
          area: this.area,
          name: this.data.name,
        });
        this.reset();
        this.$emit("division-create", response.data.division);
        this.$toast.success("Settore creato.", { position: "bottom-right" });
      } catch (error) {
        console.log(error);
      }
    },
    close() {
      this.$refs.newDivision.close();
    },
    reset() {
      this.name = "";
    },
  },
};
</script>

<style scoped></style>
