var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('div',{staticClass:"text-xl font-bold uppercase"},[_vm._v(" Team members - "),_c('span',{staticClass:"text-orange"},[_vm._v(" "+_vm._s(_vm.currentArea)+" ")]),(_vm.currentSector)?_c('span',{staticClass:"text-orange"},[_vm._v(" - "+_vm._s(_vm.currentSector)+" ")]):_vm._e(),_c('span',{staticClass:"text-blue"},[_vm._v(" - ")]),_c('span',{staticClass:"font-medium text-orange"},[_vm._v(" "+_vm._s(_vm.userData.length)+" ")])]),_c('div',{staticClass:"flex flex-col w-full  shadow border-t text-base bg-white text-blue rounded-lg\n            2xl:text-base lg:text-xs 2xl:h-124 lg:h-104 2xl:mt-4 lg:mt-2"},[_vm._m(0),_c('VuePerfectScrollbar',[_c('div',{staticClass:"mx-4"},_vm._l((_vm.userData),function(user,index){return _c('div',{key:index,staticClass:"flex text-blue w-full items-center z-30",class:index < _vm.userData.length - 1 ? 'border-b' : 'border-b-0'},[_c('div',{staticClass:"w-14"},[(user.user.user_info.picture_url)?_c('img',{staticClass:"rounded-full 2xl:w-10 2xl:h-10 lg:w-7 lg:h-7 lg:mx-auto",attrs:{"src":user.user.user_info.picture_url}}):_c('div',{staticClass:"flex place-items-center justify-center rounded-full bg-gray-300 text-white text-lg font-bold\n                 2xl:w-10 2xl:h-10 lg:w-7 lg:h-7 lg:mx-auto 2xl:text-lg lg:text-base"},[_vm._v(" "+_vm._s(user.user.user_info.firstname.slice(0, 1))+_vm._s(user.user.user_info.lastname.slice(0, 1))+" ")])]),(user.user)?_c('div',{staticClass:"w-2/12 cursor-pointer text-left",on:{"click":function($event){_vm.setTicketStatus(null),
                _vm.$router.push(
                  '/userTickets/' +
                    user.user_id +
                    '/' +
                    user.user.user_info.name
                )}}},[_vm._v(" "+_vm._s(user.user.user_info.name)+" ")]):_c('div',{staticClass:"w-2/12 cursor-pointer text-left"},[_vm._v(" Nome utente non trovato ")]),_c('div',{staticClass:"lg:w-32 xl:w-1/12 lg:pl-2 xl:pl-0 text-left font-medium capitalize text-orange"},[_vm._v(" "+_vm._s(user.acl.role_it)+" ")]),_c('div',{staticClass:"lg:w-0 xl:w-20"}),_c('div',{staticClass:"flex w-4/12 2xl:py-5 lg:py-3 2xl:text-base lg:text-xs bg-lightorange text-center"},[_c('button',{staticClass:"flex rounded-lg h-7 mx-auto items-center justify-center cursor-pointer focus:outline-none",class:user.count_ticket_assigned.toString().length > 2 ?
                'w-10 bg-pillPeachyOrange' : 'w-7 bg-circlePeachyOrange',on:{"click":function($event){_vm.setTicketStatus(2),
                  _vm.$router.push('/userTickets/' + user.user_id + '/' + user.user.user_info.name)}}},[_c('span',{staticClass:"text-red font-medium items-center 2xl:py-0 lg:py-1"},[_vm._v(" "+_vm._s(user.count_ticket_assigned)+" ")])]),_c('div',{staticClass:"flex rounded-full h-7  mx-auto\n              items-center justify-center cursor-pointer focus:outline-none",class:user.count_ticket_in_process.toString().length > 2 ?
                'w-10 bg-pillPeachyOrange' : 'w-7 bg-circlePeachyOrange',on:{"click":function($event){_vm.setTicketStatus(3),
                  _vm.$router.push('/userTickets/' +user.user_id + '/' +user.user.user_info.name)}}},[_c('span',{staticClass:"text-green font-medium items-center 2xl:py-0 lg:py-1"},[_vm._v(" "+_vm._s(user.count_ticket_in_process)+" ")])]),_c('div',{staticClass:"flex rounded-full h-7 mx-auto\n                items-center justify-center cursor-pointer focus:outline-none",class:user.count_ticket_closed.toString().length > 2 ?
                'w-10 bg-pillPeachyOrange' : 'w-7 bg-circlePeachyOrange',on:{"click":function($event){_vm.setTicketStatus(4),
                  _vm.$router.push('/userTickets/' + user.user_id + '/' + user.user.user_info.name)}}},[_c('span',{staticClass:"text-cyan font-medium items-center 2xl:py-0 lg:py-1"},[_vm._v(" "+_vm._s(user.count_ticket_closed)+" ")])])]),_c('div',{staticClass:"w-4/12 justify-left px-10 font-medium text-orange"},_vm._l((user.user.division),function(sector,index){return _c('span',{key:index},[_vm._v(" "+_vm._s(sector.name)+" "),(index < user.user.division.length - 1)?_c('span',{},[_vm._v("-")]):_vm._e()])}),0)])}),0)]),(_vm.usersLoading == true)?_c('div',{staticClass:"my-auto"},[_c('Spinner')],1):(_vm.userData.length == 0)?_c('div',{staticClass:"flex 2xl:text-lg lg:text-base justify-center 2xl:my-auto lg:my-48"},[_vm._v(" Nessun utente presente in "+_vm._s(_vm.currentArea)+" "),(_vm.currentSector)?_c('span',{staticClass:"ml-1"},[_vm._v(" - "+_vm._s(_vm.currentSector)+" ")]):_vm._e()]):_vm._e()],1),_c('prompt-modal',{ref:"errorModal",attrs:{"title":'Errore',"message":_vm.errorMessage,"confirm":false}})],1)}
var staticRenderFns = [function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"border-b mx-4"},[_c('div',{staticClass:"flex text-center my-2 w-full"},[_c('div',{staticClass:"w-14"}),_c('div',{staticClass:"w-2/12 font-medium m-auto text-left"},[_vm._v("Nome e cognome")]),_c('div',{staticClass:"lg:w-32 xl:w-1/12 lg:pl-2 xl:pl-0 font-medium m-auto text-left"},[_vm._v("Ruolo")]),_c('div',{staticClass:"lg:w-0 xl:w-20"}),_c('div',{staticClass:"flex w-4/12"},[_c('div',{staticClass:"w-1/3 font-medium m-auto 2xl:mx-6 lg:mx-0"},[_vm._v("Assegnati")]),_c('div',{staticClass:"w-1/3 font-medium m-auto 2xl:mx-6 lg:mx-0"},[_vm._v("In lavorazione")]),_c('div',{staticClass:"w-1/3 font-medium m-auto 2xl:mx-6 lg:mx-0"},[_vm._v("Chiusi")])]),_c('div',{staticClass:"w-4/12 font-medium m-auto text-center"},[_vm._v("Altri settori")])])])}]

export { render, staticRenderFns }