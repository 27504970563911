<template>
  <div class="bg-white">
    <p class="text-center uppercase text-blue text-xl font-medium">Profilo</p>
    <div class="flex flex-row justify-center items-center lg:items-start mt-8" v-if="user">
      <img
        v-if="user.user_info.picture_url"
        class="rounded-full h-28 w-28 shadow-orange"
        :src="user.user_info.picture_url"
      />
      <div
        v-else
        class="flex h-28 w-28 rounded-full items-center justify-center bg-gray-300 text-white font-bold text-7xl pb-3"
      >
        <p>
          {{ user.user_info.firstname.slice(0, 1)
          }}{{ user.user_info.lastname.slice(0, 1) }}
        </p>
      </div>
      <div class="ml-4 lg:ml-10 lg:mt-3">
        <p class="text-blue font-medium text-2xl">{{ user.user_info.name }}</p>
        <p class="text-orange font-medium my-2">
          {{ user.user_info.email }}
        </p>
        <button
          class="ticket-btn rounded-md px-3 py-1 mt-2 mr-5"
          @click="logout()"
        >
          Logout
        </button>
        <button
          class="ticket-btn rounded-md px-3 py-1 mt-2"
          @click="goToMultiversity"
        >
          Modifica
        </button>
      </div>
    </div>
    <div class="flex flex-row flex-wrap lg:flex-nowrap lg:gap-20 2xl:gap-40 mx-4 lg:mx-20 2xl:mx-44 mt-14">
      <roles :user="user"></roles>
      <workstations class="mt-8 lg:mt-0"></workstations>
    </div>
  </div>
</template>

<script>
import Roles from "@/components/Roles.vue";
import Workstations from "@/components/Workstations.vue";
import { mapState } from "vuex";
import cookies from "vue-cookies";

export default {
  components: {
    Roles,
    Workstations,
  },

  computed: {
    ...mapState("user", {
      user: "user",
    }),
  },

  methods: {
    goToMultiversity() {
      window.location.href = "https://signin.multiversity.click";
    },

    removeCookies() {
      return new Promise((resolve) => {
        cookies.remove("mv_token", null, ".multiversity.click");
        resolve("cookie removed");
      });
    },

    async logout() {
      await this.removeCookies();
      this.$router.go();
    },
  },
};
</script>
