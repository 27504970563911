<template>
  <div v-if="user" class="pt-5">
    <area-accordion
      v-for="area in user.user_area_coordinator_or_manager"
      :key="area.id"
      :area="area"
      :open="open"
      class="mb-4"
    ></area-accordion>
  </div>
</template>

<script>
import {mapActions, mapState} from "vuex";
import AreaAccordion from "@/components/AreaAccordion.vue";

export default {
  name: "EmployeeManagement",

  components: {
    AreaAccordion,
  },

  data: () => ({
    open: false,
  }),

  created() {
      this.setSelected('dependantsOrAreas')
      this.user.user_area_coordinator_or_manager.length <= 1
      ? (this.open = true)
      : (this.open = false);
  },

  computed: {
    ...mapState("user", {
      user: "user",
    }),
  },
    methods: {
        ...mapActions({setSelected: "mobileMenu/setSelected"}),
    }
};
</script>
