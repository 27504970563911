<template>
    <div class="w-full lg:w-6/12 bg-white lg:bg-transparent mt-2 lg:mt-0 rounded-t-3xl lg:rounded-t-none">
        <div class="flex items-center mt-4 mx-4 lg:mx-0">
            <div class="2xl:text-lg lg:text-base font-bold uppercase">
                <span class="hidden lg:inline-block">Ticket</span>
                <span v-if="ticketStatus == 1"> nuovi </span>
                <span v-if="ticketStatus == 2"> assegnati </span>
                <span v-if="ticketStatus == 3"> in lavorazione </span>
                <span v-if="ticketStatus == 4"> chiusi </span>
            </div>
            <div class="ml-auto text-right w-4/12">
                <span
                    class="font-medium text-blue 2xl:text-base lg:text-sm cursor-pointer"
                    @click="showMore()"
                >
                  Di più >
                </span>
            </div>
        </div>
        <div
            class="flex relative flex-col mt-4 2xl:h-120 lg:h-100 shadow-md border-t text-base rounded-lg bg-white"
        >
            <div class="hidden lg:block text-blue 2xl:text-base lg:text-xs">
                <div class="flex border-b py-2 mx-4">
                    <div
                        class="font-medium"
                        :class="ticketStatus == 1 ? 'lg:w-20 xl:w-36 2xl:w-56' : resizeColumns"
                    >
                        N. Ticket
                    </div>
                    <div class="font-medium"
                         :class="ticketStatus == 1 || ticketStatus == 3 || ticketStatus == 4 ? 'w-1/2' : resizeColumns">
                        Mittente
                    </div>
                    <div
                        class="font-medium 2xl:flex hidden"
                        :class="ticketStatus == 4 ? 'w-52' : resizeColumns"
                    >
                        Data apertura
                    </div>
                    <div
                        class="font-medium 2xl:hidden lg:visible"
                        :class="ticketStatus == 4 ? 'w-52' : resizeColumns"
                    >
                        D. apertura
                    </div>
                    <div
                        v-if="ticketStatus == 3"
                        class="font-medium ml-2"
                        :class="resizeColumns"
                    >
                        P. in carico
                    </div>
                    <div
                        v-if="ticketStatus == 4"
                        class="font-medium ml-2 2xl:flex lg:hidden"
                        :class="resizeColumns"
                    >
                        Data chiusura
                    </div>
                    <div
                        v-if="ticketStatus == 4"
                        class="font-medium ml-2 2xl:hidden lg:flex"
                        :class="resizeColumns"
                    >
                        D. chiusura
                    </div>
                    <div
                        v-if="ticketStatus != 1"
                        class="font-medium ml-2"
                        :class="ticketStatus == 3 || ticketStatus == 4 ? 'w-1/2' : resizeColumns"
                    >
                        Assegnato
                    </div>
                </div>
            </div>
            <!--      <VuePerfectScrollbar class="myAreas-scrollbar-height">-->
            <div v-if="ticketStatus == 1 && !ticketsLoading" class="mb-10 lg:mb-0 2xl:text-base lg:text-xs">
                <div
                    v-for="(newTicket, index) in tickets"
                    :key="newTicket.id"
                    class="flex lg:block mx-4 py-4 cursor-pointer"
                    :class="index < tickets.length - 1 ? 'border-b' : 'border-b-0'"
                    @click="$router.push('/ticket/' + newTicket.id)"
                >
                    <div class="lg:hidden w-1.5"
                         :style="'background-color:' + newTicket.priority.color">
                    </div>
                    <div class="flex flex-wrap lg:flex-nowrap w-full ml-2 lg:ml-0">
                        <div class="lg:hidden text-blue font-semibold">Ticket #</div>
                        <span
                            class="text-blue lg:text-orange font-semibold w-20"
                            :class="ticketStatus == 1 ? 'lg:w-20 xl:w-36 2xl:w-56' : resizeColumns"
                        >{{ newTicket.id }}</span>
                        <span :class="ticketStatus == 1 ? 'lg:w-1/2' : resizeColumns"
                              class="w-full order-3 lg:order-1 opacity-70 lg:opacity-100"
                              :title="newTicket.from_firstname + ' ' + newTicket.from_lastname"
                        >{{ newTicket.from_firstname + ' ' + newTicket.from_lastname }}</span>
                        <span class="ml-auto opacity-70 lg:hidden order-1">{{
                                formatDateMobile(newTicket.creation_date)
                            }}</span>
                        <span :class="ticketStatus == 1 ? 'w-1/3' : resizeColumns"
                              class="hidden lg:block order-2">{{ formatDate(newTicket.creation_date) }}</span>
                        <div class="w-full block lg:hidden order-2 text-lg font-semibold break-all">{{ newTicket.subject }}</div>
                    </div>

                </div>
            </div>
            <div v-else-if="ticketsLoading" class="2xl:my-64 lg:my-48">
                <Spinner></Spinner>
            </div>

            <div v-else-if="ticketStatus == 2" class="mb-10 lg:mb-0 2xl:text-base lg:text-xs">
                <div
                    v-for="(assignedTicket, index) in tickets"
                    :key="assignedTicket.id"
                    class="flex lg:block mx-4 2xl:py-4 lg:py-2 py-4 cursor-pointer"
                    :class="index < tickets.length - 1 ? 'border-b' : 'border-b-0'"
                    @click="$router.push('/ticket/' + assignedTicket.id)"
                >
                    <div class="lg:hidden w-1.5"
                         :style="'background-color:' + assignedTicket.priority.color">
                    </div>
                    <div class="flex flex-wrap lg:flex-nowrap w-full ml-2 lg:ml-0">
                        <div class="lg:hidden text-blue font-semibold">Ticket #</div>
                        <span class="text-blue lg:text-orange font-semibold lg:h-6 2xl:py-0 lg:py-1 w-20"
                              :class="resizeColumns">
                          {{ assignedTicket.id }}
                        </span>
                        <span
                            class="w-full order-3 lg:order-1 opacity-70 lg:opacity-100 lg:h-6 2xl:py-0 lg:py-1 lg:truncate lg:overflow-ellipsis"
                            :class="resizeColumns"
                            :title="assignedTicket.from_firstname + ' ' + assignedTicket.from_lastname">
                          {{ assignedTicket.from_firstname + ' ' + assignedTicket.from_lastname }}
                        </span>
                        <span class="lg:h-6 2xl:py-0 lg:py-1 hidden 2xl:block order-2" :class="resizeColumns">
                          {{ formatDate(assignedTicket.creation_date) }}
                        </span>
                        <span
                            class="ml-auto lg:ml-0 opacity-70 lg:opacity-100 order-1 lg:order-2 lg:h-6 2xl:py-0 lg:py-1 2xl:hidden"
                            :class="resizeColumns">
                          {{ formatDateMobile(assignedTicket.creation_date) }}
                        </span>
                        <span
                            class="hidden lg:block order-3 font-semibold ml-2 text-red lg:h-6 2xl:py-0 lg:py-1 lg:truncate lg:overflow-ellipsis"
                            :class="resizeColumns"
                        >{{
                                assignedTicket.processing_user_firstname + ' ' + assignedTicket.processing_user_lastname
                            }}</span>
                        <div class="w-full block lg:hidden order-2 text-lg font-semibold break-all">{{ assignedTicket.subject }}
                        </div>
                    </div>
                </div>
            </div>
            <div v-else-if="ticketStatus == 3" class="mb-10 lg:mb-0 2xl:text-base lg:text-xs">
                <div
                    v-for="(processingTicket, index) in tickets"
                    :key="processingTicket.id"
                    class="flex lg:block mx-4 2xl:py-4 lg:py-3 py-4 cursor-pointer"
                    :class="index < tickets.length - 1 ? 'border-b' : 'border-b-0'"
                    @click="$router.push('/ticket/' + processingTicket.id)"
                >
                    <div class="lg:hidden w-1.5"
                         :style="'background-color:' + processingTicket.priority.color">
                    </div>
                    <div class="flex flex-wrap lg:flex-nowrap w-full ml-2 lg:ml-0">
                        <div class="lg:hidden text-blue font-semibold">Ticket #</div>
                        <span class="text-blue lg:text-orange font-semibold lg:h-6 2xl:py-0 lg:py-1 w-20"
                              :class="resizeColumns">
                          {{ processingTicket.id }}
                        </span>
                        <span
                            class="w-full lg:w-1/2 order-3 lg:order-1 opacity-70 lg:opacity-100 lg:h-6 2xl:py-0 lg:py-1 lg:truncate lg:overflow-ellipsis"
                            :title="processingTicket.from_firstname + ' ' + processingTicket.from_lastname">
                            {{ processingTicket.from_firstname + ' ' + processingTicket.from_lastname }}
                        </span>
                        <span class="hidden lg:block order-2 lg:h-6 2xl:py-0 lg:py-1" :class="resizeColumns">{{
                                formatDate(processingTicket.creation_date)
                            }}</span>
                        <span class="lg:hidden ml-auto opacity-70 order-1" :class="resizeColumns">{{
                                formatDateMobile(processingTicket.creation_date)
                            }}</span>
                        <span
                            class="hidden lg:block order-3 font-semibold ml-2 text-green lg:h-6 2xl:py-0 lg:py-1"
                            :class="resizeColumns"
                        >
                          {{ formatDate(processingTicket.processing_date) }}
                        </span>
                        <span
                            class="hidden lg:block order-4 w-1/2 font-semibold ml-2 text-red lg:h-6 2xl:py-0 lg:py-1 lg:truncate lg:overflow-ellipsis"
                        >{{
                                processingTicket.processing_user_firstname + ' ' + processingTicket.processing_user_lastname
                            }}
                        </span>
                        <div class="w-full block lg:hidden order-2 text-lg font-semibold break-all">{{ processingTicket.subject }}
                        </div>
                    </div>
                </div>
            </div>
            <div v-else-if="ticketStatus == 4" class="mb-10 lg:mb-0 2xl:text-base lg:text-xs">
                <div
                    v-for="(closedTicket, index) in tickets"
                    :key="closedTicket.id"
                    class="flex lg:block mx-4 2xl:py-4 lg:py-3 py-4 cursor-pointer"
                    :class="index < tickets.length - 1 ? 'border-b' : 'border-b-0'"
                    @click="$router.push('/ticket/' + closedTicket.id)"
                >
                    <div class="lg:hidden w-1.5"
                         :style="'background-color:' + closedTicket.priority.color">
                    </div>
                    <div class="flex flex-wrap lg:flex-nowrap w-full ml-2 lg:ml-0">
                        <div class="lg:hidden text-blue font-semibold">Ticket #</div>
                        <span class="text-blue lg:text-orange font-semibold lg:h-6 2xl:py-0 lg:py-1 w-20" :class="resizeColumns">
                          {{ closedTicket.id }}
                        </span>
                        <span class="w-full lg:w-1/2 order-3 lg:order-1 opacity-70 lg:opacity-100 lg:h-6 2xl:py-0 lg:py-1 lg:truncate lg:overflow-ellipsis"
                              :title="closedTicket.from_firstname + ' ' + closedTicket.from_lastname">
                          {{ closedTicket.from_firstname + ' ' + closedTicket.from_lastname }}
                        </span>
                        <span class="hidden lg:block w-52 order-2 lg:h-6 2xl:py-0 lg:py-1">{{
                                formatDate(closedTicket.creation_date)
                            }}</span>
                        <span class="lg:hidden ml-auto opacity-70 order-1">{{
                                formatDateMobile(closedTicket.creation_date)
                            }}</span>
                        <span
                            v-if="closedTicket.closing_date"
                            class="hidden lg:block order-3 font-semibold ml-2 text-cyan lg:h-6 2xl:py-0 lg:py-1"
                            :class="resizeColumns"
                        >{{ formatDate(closedTicket.closing_date) }}</span>
                        <span
                            v-else
                            class="hidden lg:block order-3 font-semibold ml-2 text-cyan lg:h-6 2xl:py-0 lg:py-1"
                            :class="resizeColumns">
                          Non definita
                        </span>
                        <span
                            class="hidden lg:block order-4 w-1/2 font-semibold ml-2 text-red lg:h-6 2xl:py-0 lg:py-1 lg:truncate lg:overflow-ellipsis"
                        >{{ closedTicket.processing_user_firstname + ' ' + closedTicket.processing_user_lastname }}</span>
                        <div class="w-11/12 block lg:hidden order-2 text-lg font-semibold break-all">{{ closedTicket.subject }}
                        </div>
                    </div>
                </div>
            </div>
            <div v-if="tickets.length == 0" class="flex 2xl:text-base lg:text-xs justify-center 2xl:my-64 lg:my-40">
                Non sono presenti ticket.
            </div>
            <!--      </VuePerfectScrollbar>-->
            <page-navigation
                class="flex absolute w-full bottom-2 rounded-lg justify-center"
                style="background: rgba(255, 255, 255, 0.75)"
                :currentPage="currentPage"
                :lastPage="lastPage"
                @next-page="
          currentPage++;
          getTickets();
        "
                @prev-page="
          currentPage--;
          getTickets();
        "
                :buttonSize="6"
            ></page-navigation>
            <prompt-modal
                ref="errorModal"
                :title="'Errore'"
                :message="errorMessage"
                :confirm="false"
            >

            </prompt-modal>
        </div>
    </div>
</template>

<script>
//import VuePerfectScrollbar from "vue-perfect-scrollbar";
import PageNavigation from "@/components/PageNavigation.vue";
import Services from "../services";
import Spinner from "@/components/Spinner";
import PromptModal from "@/components/PromptModal";
import moment from "moment";
import {mapActions, mapState} from "vuex";

export default {
    name: "DepartmentTickets",
    components: {
        //VuePerfectScrollbar,
        PageNavigation,
        Spinner,
        PromptModal
    },
    props: {
        area: Number,
        filter: {
            filter: String,
        },
    },

    created() {
        this.getTickets();
    },

    data: () => ({
        //tickets: [],
        currentPage: 1,
        lastPage: null,
        ticketsLoading: false,
        errorMessage: ""
    }),

    computed: {
        resizeColumns() {
            return {
                "lg:w-1/3": this.filter === "new",
                "lg:w-1/4": this.filter === "assigned",
                "lg:w-40": this.filter === "processing" || this.filter === "closed",
            };
        },
        ...mapState("tickets", {tickets: "tickets"}),
        ...mapState("user", {ticketStatus: "ticketStatus"}),
    },
    methods: {
        ...mapActions({setTickets: "tickets/setTickets"}),
        nextPage() {
            if (this.currentPage < this.lastPage) {
                this.currentPage++;
                this.getTickets();
            }
        },
        prevPage() {
            if (this.currentPage > 1) {
                this.currentPage--;
                this.getTickets();
            }
        },
        showMore() {
            if (this.$route.params.sectorId) {
                this.$router.push(
                    '/showAllTickets/' +
                    this.$route.params.id +
                    '/' +
                    this.$route.params.sectorId
                )
            } else {
                this.$router.push(
                    '/showAllTickets/' +
                    this.$route.params.id)
            }
        },
        async getTickets() {
            this.ticketsLoading = true;
            let filter = {};
            let division = [];
            if (!this.$route.params.sectorId) {
                if (this.ticketStatus == null) {
                    this.setTicketStatus(1);
                }
                filter = {status: this.ticketStatus, area: this.$route.params.id};
            } else {
                if (this.ticketStatus == null) {
                    this.setTicketStatus(1);
                }
                division = [this.$route.params.sectorId];
                filter = {
                    status: this.ticketStatus,
                    area: this.$route.params.id,
                    division: division,
                };
            }
            try {
                const response = await Services.getAllTickets(filter, this.currentPage);
                this.lastPage = parseInt(response.data.ticket.last_page);
                this.setTickets(response.data.ticket.data);
                this.ticketsLoading = false;
            } catch (error) {
                this.$refs.errorModal.open();
                this.$refs.errorModal.close();
                this.errorMessage = "";
                this.errorMessage = "Errore nel caricamento dei ticket."
                this.ticketsLoading = false;
                console.log(error);
            }
        },
        formatDate(date) {
            if (this.ticketStatus == 3 || this.ticketStatus == 4) {
                return moment(date, "YYYY/MM/DD, HH:mm:ss").format("DD MMM YY");
            }
            return moment(date, "YYYY/MM/DD, HH:mm:ss").format("DD MMM YY - HH:MM");
        },
        formatDateMobile(date) {
            return moment(date, "YYYY/MM/DD, HH:mm:ss").format("DD MMM YY");
        },
    },

    watch: {
        ticketStatus: function () {
            this.currentPage = 1;
            this.getTickets();
        },
        lastPage: function () {
            this.currentPage = 1;
        },
    },
};
</script>

<style scoped>
.scrollbar-height {
    height: 300px;
}
</style>
