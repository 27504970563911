<template>
  <Modal
    card-class="w-full p-5 sm:w-5/6 md:w-1/2 xl:w-5/12 rounded-0 overflow-hidden"
    ref="createAreaModal"
  >
    <template #header="{close}">
      <div>
        <div class="text-right">
          <img
            class="inline-block cursor-pointer mr-10"
            src="@/assets/icons/X.svg"
            @click.prevent="close"
          />
        </div>
        <h2 class="text-center text-4xl font-medium px-3 py-1">
          Crea Area
        </h2>
      </div>
    </template>

    <template #body>
      <div class="flex flex-col px-10 my-5">
        <input
          class="ticket-text-input py-2"
          id="name"
          type="text"
          placeholder="Nome"
          v-model="data.name"
        />

        <input
          class="ticket-text-input mt-5 py-2"
          id="division"
          type="text"
          placeholder="Aggiungi Settore"
          v-model="data.division"
        />

        <input
          class="ticket-text-input my-5 py-2"
          id="email"
          type="text"
          placeholder="Email"
          v-model="email"
        />

        <input
          class="ticket-text-input mb-5 py-2"
          type="text"
          placeholder="Descrizione (max 100 caratteri)"
          v-model="description"
        />

        <select class="ticket-text-input mb-5 py-2" v-model="data.headquarter">
          <option value="0" selected disabled>
            Sede
          </option>
          <option
            v-for="headquarter in headquarters"
            :key="headquarter.id"
            :value="headquarter.id"
          >
            {{ headquarter.location }}
          </option>
        </select>

        <button
          :disabled="loading"
          v-html="loading ? 'Creazione in corso...' : 'Crea'"
          @click="createArea(data)"
          class="ticket-btn py-2 text-lg rounded-md"
          :class="loading ? 'ticket-btn-disabled' : 'ticket-btn'"
        >
          Crea
        </button>
      </div>
    </template>
  </Modal>
</template>

<script>
import Modal from "@/components/Modal.vue";
import Services from "@/services/index";

export default {
  name: "NewArea",

  components: {
    Modal,
  },

  data: () => ({
    headquarters: [],
    loading: false,

    description: "",
    email: "",

    data: {
      name: "",
      division: "",
      headquarter: 0,
    },
  }),

  mounted() {
    this.getHeadquarters();
  },
  methods: {
    async getHeadquarters() {
      const response = await Services.getHeadquarters();
      this.headquarters = response;
    },

    async createArea(data) {
      this.description.length > 0
        ? (this.data["description"] = this.description)
        : null;
      this.email.length > 0 ? (this.data["email"] = this.email) : null;

      this.loading = true;
      try {
        const response = await Services.createArea(data);
        this.close();
        this.data = { headquarter: 0 };
        this.email = "";
        this.division = "";
        this.$emit("area-created", response.data.area);
      } catch (error) {
        console.log(error.response);
        if (this.data.name.length <= 0) {
          document
            .getElementById("name")
            .classList.add("border-px", "border-red");
        }
        if (this.email.length <= 0) {
          document
            .getElementById("email")
            .classList.add("border-px", "border-red");
        }
        if (this.data.division.length <= 0) {
          document
            .getElementById("division")
            .classList.add("border-px", "border-red");
        }
      }
      this.loading = false;
    },

    open() {
      this.$refs.createAreaModal.open();
    },

    close() {
      this.$refs.createAreaModal.close();
    },
  },
};
</script>
