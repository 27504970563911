var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('Modal',{ref:"newManagerModal",scopedSlots:_vm._u([{key:"header",fn:function(ref){
var close = ref.close;
return [_c('div',{staticClass:"flex p-8 justify-end "},[_c('h1',{staticClass:"text-xl lg:text-3xl mx-auto font-semibold"},[_vm._v("Aggiungi dipendente")]),_c('span',{staticClass:"cursor-pointer text-orange text-2xl",on:{"click":function($event){$event.preventDefault();return close.apply(null, arguments)}}},[_c('img',{attrs:{"src":require("@/assets/icons/X.svg")}})])])]}},{key:"body",fn:function(){return [_c('div',{staticClass:"px-16 pb-5"},[_c('div',{staticClass:"rounded-lg bg-ticket-grey"},[_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.searchUser),expression:"searchUser"}],staticClass:"block mb-3 focus:outline-none h-10 w-full employee-search pl-4 text-lg overflow-auto bg-ticket-grey",attrs:{"type":"text","placeholder":"Nome dipendente"},domProps:{"value":(_vm.searchUser)},on:{"keydown":function($event){if(!$event.type.indexOf('key')&&_vm._k($event.keyCode,"enter",13,$event.key,"Enter")){ return null; }return _vm.getAllUser()},"input":function($event){if($event.target.composing){ return; }_vm.searchUser=$event.target.value}}}),(_vm.listSearchUser.data)?_c('div',{staticClass:"overflow-auto h-60"},_vm._l((_vm.listSearchUser.data.user),function(user){return _c('div',{key:user.id,staticClass:"block font-medium p-3 cursor-pointer",class:[
              _vm.hoverUser && _vm.selectedRow == false ? 'bg-hover-ticket' : '',
              _vm.selectedRow == user.id ? 'bg-ticket-orange' : '' ],on:{"mouseenter":function($event){_vm.hoverUser = true},"mouseleave":function($event){_vm.hoverUser = false},"click":function($event){_vm.selectedRow = user.id;
              _vm.selectedUser = user.id;}}},[_c('img',{staticClass:"h-14 inline-block rounded-full",attrs:{"src":user.user_info.picture_url !== null
                  ? user.user_info.picture_url
                  : require('@/assets/images/default-profile.jpg')}}),_c('div',{staticClass:"inline-block pl-4"},[_c('p',{class:_vm.selectedRow == user.id ? 'text-white' : 'text-blue'},[_vm._v(" "+_vm._s(user.user_info.firstname + " " + user.user_info.lastname)+" ")])])])}),0):_vm._e()]),(_vm.listSearchUser.data)?_c('button',{staticClass:"mt-5 py-2 focus:outline-none w-full ticket-btn text-xl rounded-lg",class:_vm.selectedRow ? 'ticket-btn' : 'ticket-btn-disabled',attrs:{"disabled":!_vm.selectedRow},on:{"click":[function($event){return _vm.addUserToDivision()},function($event){$event.preventDefault();return _vm.close.apply(null, arguments)}]}},[_vm._v(" Aggiungi ")]):_c('button',{staticClass:"py-2 focus:outline-none w-full ticket-btn rounded-lg text-xl",class:_vm.searchUser.length > 0 ? 'ticket-btn' : 'ticket-btn-disabled',on:{"click":function($event){return _vm.getAllUser()}}},[_vm._v(" Cerca ")])])]},proxy:true}])})}
var staticRenderFns = []

export { render, staticRenderFns }