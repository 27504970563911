var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('Modal',{ref:"assignedTicketModal",attrs:{"card-class":"p-4 lg:p-0 lg:w-1/4 rounded-0 overflow-hidden"},scopedSlots:_vm._u([{key:"header",fn:function(ref){
var close = ref.close;
return [_c('div',{staticClass:"flex items-center leading-none"},[_c('h1',{staticClass:"m-6 text-3xl mx-auto font-semibold text-orange"},[_vm._v(" Assegna settore ")]),_c('span',{staticClass:"font-medium items-center text-orange text-2xl",on:{"click":function($event){$event.preventDefault();return close.apply(null, arguments)}}})])]}},{key:"body",fn:function(){return [_c('div',{staticClass:"max-w-max mx-auto"},_vm._l((_vm.userSections),function(section){return _c('div',{key:section.sectionId,staticClass:"text-blue"},[_c('div',[_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.checkedSection),expression:"checkedSection"}],attrs:{"type":"checkbox","id":section.sectionName,"disabled":_vm.ticket_divisions
								.map(function (a) { return a.division_id; })
								.indexOf(section.sectionId) != -1
								? true
								: false},domProps:{"value":section.sectionId,"checked":Array.isArray(_vm.checkedSection)?_vm._i(_vm.checkedSection,section.sectionId)>-1:(_vm.checkedSection)},on:{"change":function($event){var $$a=_vm.checkedSection,$$el=$event.target,$$c=$$el.checked?(true):(false);if(Array.isArray($$a)){var $$v=section.sectionId,$$i=_vm._i($$a,$$v);if($$el.checked){$$i<0&&(_vm.checkedSection=$$a.concat([$$v]))}else{$$i>-1&&(_vm.checkedSection=$$a.slice(0,$$i).concat($$a.slice($$i+1)))}}else{_vm.checkedSection=$$c}}}}),_c('label',{staticClass:"ml-3"},[_vm._v(_vm._s(section.sectionName))])])])}),0),_c('div',{staticClass:"flex justify-center my-5"},[_c('button',{staticClass:"w-28 h-9 text-blue text-xl mr-4 font-medium rounded-lg bg-homegray focus:outline-none",on:{"click":_vm.closeModal}},[_vm._v(" Annulla ")]),_c('button',{class:_vm.checkedSection == 0
						? 'rounded-md text-xl px-4 ticket-btn-disabled cursor-not-allowed'
						: 'rounded-md text-xl px-4 ticket-btn',attrs:{"disabled":_vm.checkedSection == 0},on:{"click":function($event){return _vm.assignTicket(_vm.user_id, _vm.status, _vm.checkedSection)}}},[_vm._v(" Assegna ")])])]},proxy:true}])})}
var staticRenderFns = []

export { render, staticRenderFns }