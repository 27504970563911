<template>
  <transition
    enter-active-class="transition-all duration-300 ease-out-quad"
    leave-active-class="transition-all duration-300 ease-out-quad"
    enter-class="opacity-0"
    enter-to-class="opacity-100"
    leave-class="opacity-100"
    leave-to-class="opacity-0"
    @after-enter="isBody = true"
  >
    <div
      class="fixed flex inset-0 bg-black-special bg-opacity-25 z-50"
      v-if="isOpen"
      @click="$emit('dialog-click')"
    >
      <transition
        enter-active-class="transition-all duration-300 ease-out-quad"
        leave-active-class="transition-all duration-300 ease-out-quad"
        enter-class="scale-50 opacity-0"
        enter-to-class="scale-100 opacity-100"
        leave-class="scale-100 opacity-100"
        leave-to-class="scale-50 opacity-0"
        @after-leave="isOpen = false"
      >
        <div
          class="absolute left-1/2 -translate-x-1/2 card top-1/2 -translate-y-1/2 h-auto transform"
          :class="cardClass"
          role="dialog"
          :aria-labelledby="title"
          v-if="isBody"
        >
          <slot name="header" :close="close">
            <h2
              v-if="title"
              class="text-lg font-bold uppercase border-b border-gray-300 px-3 py-1"
            >
              {{ title }}
            </h2>
          </slot>
          <!--<VuePerfectScrollbar ref="modalScrollbar"> -->
          <slot name="body" :close="close"></slot>
          <slot name="actions" :close="close"></slot>
          <!--</VuePerfectScrollbar>&ndash;&gt;-->
        </div>
      </transition>
    </div>
  </transition>
</template>

<script>
/*import VuePerfectScrollbar from "vue-perfect-scrollbar";*/

export default {
  data: () => ({
    isOpen: false,
    isBody: false,
  }),
  props: {
    title: {
      type: String,
      required: false,
    },
    active: {
      type: Boolean,
      required: false,
      default: false,
    },
    cardClass: {
      type: String,
      required: false,
      default: "w-full md:w-2/3 lg:w-1/2",
    },
  },
  /*components: {VuePerfectScrollbar},*/
  mounted() {
    this.isOpen = this.active;
    this.isBody = this.active;
  },
  methods: {
    close() {
      this.$emit("close", null);
      this.isBody = false;
    },
    open() {
      this.isOpen = true;
    },
  },
  watch: {
    active(value) {
      this.isOpen = value;
    },
  },
};
</script>

<style lang="postcss">
.card {
  @apply shadow-lg rounded-lg bg-white;
}
</style>
