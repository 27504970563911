<template>
    <div class="w-full">
        <div class="rounded-t-xl shadow-mobile-menu-bar flex w-full items-center bg-homegray h-16 z-40">
            <div class="flex w-1/5 justify-center">
                <svg xmlns="http://www.w3.org/2000/svg" class="h-6 w-6 fill-current"
                     :class="selected === 'menu' ? 'text-orange' : 'text-blue'"
                     viewBox="0 0 24 24" @click="selected === 'menu' ? setSelected(null) : setSelected('menu')">
                    <g id="menu_hamburger" data-name="menu hamburger" transform="translate(-2292 194)">
                        <g id="Raggruppa_10808" data-name="Raggruppa 10808" transform="translate(-112.665 -30.996)">
                            <g id="Raggruppa_10807" data-name="Raggruppa 10807" transform="translate(2404.665 -159)">
                                <rect id="Rettangolo_2188" data-name="Rettangolo 2188" width="24" height="3" rx="1.5"
                                      transform="translate(0 -0.004)"/>
                                <rect id="Rettangolo_2189" data-name="Rettangolo 2189" width="24" height="3" rx="1.5"
                                      transform="translate(0 6.996)"/>
                                <rect id="Rettangolo_2190" data-name="Rettangolo 2190" width="24" height="3" rx="1.5"
                                      transform="translate(0 13.996)"/>
                            </g>
                        </g>
                    </g>
                </svg>
            </div>
            <div class="flex w-1/5 justify-center">
                <svg xmlns="http://www.w3.org/2000/svg" class="h-6 w-6 fill-current"
                     :class="selected === 'favorites' ? 'text-orange' : 'text-blue'"
                     viewBox="0 0 24 24"
                     @click="showFavorites()">
                    <g id="in_evidenza" data-name="in evidenza" transform="translate(-2363 223)">
                        <g id="Livello_2" data-name="Livello 2" transform="translate(2365 -213.587)">
                            <g id="Livello_1" data-name="Livello 1" transform="translate(0 -7.413)">
                                <path id="Tracciato_1684" data-name="Tracciato 1684"
                                      d="M.413,20.07c-.457-.191-.538-.541-.225-.939A55.7,55.7,0,0,1,5.025,13.74c.668-.656,1.325-1.329,1.994-1.986.116-.115.129-.172,0-.3-1.044-1.035-2.07-2.07-3.113-3.1a.553.553,0,0,1,.025-.929,2.413,2.413,0,0,1,3.137-.118c.115.091.169.072.266-.012q2.79-2.4,5.589-4.8a.168.168,0,0,0,.053-.239A1.811,1.811,0,0,1,13.364.226a.517.517,0,0,1,.7-.035,1.854,1.854,0,0,1,.155.147C15.98,2.1,17.733,3.865,19.5,5.611c.488.48.375.841-.145,1.164a1.757,1.757,0,0,1-1.731.129.158.158,0,0,0-.226.048q-2.414,2.82-4.836,5.633c-.071.083-.061.129,0,.208A2.42,2.42,0,0,1,12.42,16a.531.531,0,0,1-.836.017Q10.1,14.536,8.616,13.043c-.115-.115-.171-.129-.3-.006-1.278,1.274-2.532,2.572-3.842,3.812C3.334,17.928,2.118,18.919.9,19.9a2.177,2.177,0,0,1-.283.163Z"
                                      transform="translate(0 -0.07)"/>
                            </g>
                        </g>
                    </g>
                </svg>
            </div>
            <div class="flex w-1/5 justify-center">
                <svg xmlns="http://www.w3.org/2000/svg" class="h-6 w-6 fill-current"
                     :class="selected === 'add' ? 'text-orange' : 'text-blue'"
                     viewBox="0 0 24 24" @click="selected === 'add' ? setSelected(null) : setSelected('add')">
                    <g id="Piu" transform="translate(-2519 158)">
                        <g id="Icon_feather-plus" data-name="Icon feather-plus" transform="translate(2523 -154)">
                            <path id="Tracciato_1619" data-name="Tracciato 1619"
                                  d="M17.429,25.5A1.429,1.429,0,0,1,16,24.071V6.929a1.429,1.429,0,1,1,2.857,0V24.071A1.429,1.429,0,0,1,17.429,25.5Z"
                                  transform="translate(-9.429 -7.5)"/>
                            <path id="Tracciato_1620" data-name="Tracciato 1620"
                                  d="M24.071,18.857H6.929a1.429,1.429,0,1,1,0-2.857H24.071a1.429,1.429,0,1,1,0,2.857Z"
                                  transform="translate(-7.5 -9.429)"/>
                        </g>
                    </g>
                </svg>
            </div>
            <div class="flex w-1/5 justify-center ciao">
                <svg xmlns="http://www.w3.org/2000/svg" class="h-6 w-6 fill-current"
                     :class="selected === 'reminds' ? 'text-orange' : 'text-blue'"
                     viewBox="0 0 24 24" @click="showRemind()">
                    <g id="solleciti" transform="translate(-2363 194)">
                        <g id="Raggruppa_10802" data-name="Raggruppa 10802" transform="translate(3312.079 -10590.872)">
                            <g id="megaphone" transform="translate(-946.079 10398.872)">
                                <path id="Tracciato_1638" data-name="Tracciato 1638"
                                      d="M378.566,50.456l1.255-1.255a.586.586,0,0,0-.829-.829l-1.255,1.255a.586.586,0,1,0,.829.829Z"
                                      transform="translate(-363.758 -46.318)"/>
                                <path id="Tracciato_1639" data-name="Tracciato 1639"
                                      d="M427.607,176.733h-1.255a.586.586,0,0,0,0,1.172h1.255a.586.586,0,1,0,0-1.172Z"
                                      transform="translate(-410.076 -169.829)"/>
                                <path id="Tracciato_1640" data-name="Tracciato 1640"
                                      d="M281.752,2.427a.586.586,0,0,0,.586-.586V.586a.586.586,0,0,0-1.172,0V1.841A.586.586,0,0,0,281.752,2.427Z"
                                      transform="translate(-271.124 0)"/>
                                <path id="Tracciato_1641" data-name="Tracciato 1641"
                                      d="M24.272,349.775a.586.586,0,0,0,0,.829l2.51,2.51a.586.586,0,0,0,.829,0l.982-.982-3.339-3.339Z"
                                      transform="translate(-24.1 -335.168)"/>
                                <path id="Tracciato_1642" data-name="Tracciato 1642"
                                      d="M186.981,386.038l-4.114,1.936,2.813,2.813a.586.586,0,0,0,.829,0l2.2-2.2a.586.586,0,0,0,0-.829Z"
                                      transform="translate(-176.665 -370.958)"/>
                                <path id="Tracciato_1643" data-name="Tracciato 1643"
                                      d="M80.763,48.372a.586.586,0,0,0-.944.165L74.841,59.114l3.339,3.339,10.577-4.977a.586.586,0,0,0,.165-.944Z"
                                      transform="translate(-72.859 -46.318)"/>
                            </g>
                        </g>
                    </g>
                </svg>
            </div>
            <div class="flex w-1/5 justify-center">
                <svg xmlns="http://www.w3.org/2000/svg" class="h-6 w-6 fill-current"
                     :class="selected === 'dependantsOrAreas' ? 'text-orange' : 'text-blue'"
                     viewBox="0 0 24 24"
                     @click="showDependantsOrAreas()">
                    <g id="dipendenti" transform="translate(-2519 190)">
                        <g id="Raggruppa_10810" data-name="Raggruppa 10810" transform="translate(2519 -223.89)">
                            <path id="Tracciato_1670" data-name="Tracciato 1670"
                                  d="M23.1,122H14.96a.019.019,0,0,0-.019.017l-.3,2.447a.62.62,0,1,1-.008,1.231H9.074a.62.62,0,1,1-.008-1.231L8.761,122H.608a.608.608,0,0,0-.608.608v14.8a.608.608,0,0,0,.608.608H23.1a.608.608,0,0,0,.608-.608v-14.8A.608.608,0,0,0,23.1,122Zm-10.177,7.135h6.742c.319,0,.579.168.579.376s-.259.376-.579.376H12.919c-.319,0-.579-.168-.579-.376S12.6,129.135,12.919,129.135ZM8.776,134.57a3.729,3.729,0,0,1-6.345-2.244,3.6,3.6,0,0,1,0-.786,3.729,3.729,0,1,1,6.345,3.03Zm3.564-2.678c0-.208.259-.376.579-.376h6.742c.319,0,.579.168.579.376s-.259.376-.579.376H12.919C12.6,132.268,12.341,132.1,12.341,131.892Zm7.564,2.839H13.162c-.319,0-.579-.169-.579-.376s.259-.376.579-.376H19.9c.319,0,.579.168.579.376S20.224,134.731,19.9,134.731Z"
                                  transform="translate(0 -82.123)"/>
                            <path id="Tracciato_1671" data-name="Tracciato 1671"
                                  d="M208.2,39.877l.38,2.778h2.778l.38-2.778.461-3.368c.045-.33-.277-.619-.69-.619h-3.08c-.414,0-.736.288-.69.619Z"
                                  transform="translate(-198.12)"/>
                            <path id="Tracciato_1672" data-name="Tracciato 1672"
                                  d="M64.023,267.636a3.227,3.227,0,0,0-.311,4.553c.379-.15,1.01-.373,1.434-.5.04-.013.047-.013.047-.192a.994.994,0,0,0-.068-.423,2.159,2.159,0,0,1-.165-.567,1.274,1.274,0,0,1-.244-.59.774.774,0,0,1,.007-.495c0-.011.007-.02.011-.032a3.244,3.244,0,0,0-.056-.7,1.274,1.274,0,0,1,.269-.92,1.369,1.369,0,0,1,1.04-.5H66.3a1.372,1.372,0,0,1,1.049.5,1.28,1.28,0,0,1,.267.92,3.243,3.243,0,0,0-.056.7.288.288,0,0,0,.011.031.787.787,0,0,1,.007.495,1.263,1.263,0,0,1-.244.59,2.085,2.085,0,0,1-.165.567,1.024,1.024,0,0,0-.119.418c0,.176.006.18.049.192.411.122,1.069.349,1.477.5h0a3.227,3.227,0,0,0-4.556-4.55Z"
                                  transform="translate(-60.004 -220.259)"/>
                        </g>
                    </g>
                </svg>
            </div>

        </div>
        <div
            class="absolute mobile-height-calc w-full px-2 bg-white shadow-mobile-popup rounded-t-3xl bottom-0 mb-14 overflow-auto"
            :class="selected === 'add' ? 'flex' : 'hidden'">
            <div class="flex flex-col">

                <div class="flex justify-end mt-5 items-center leading-none px-4">
                    <h1 class="text-2xl mx-auto font-semibold">Apri ticket</h1>
                    <span
                        @click="setSelected(null)"
                        class="cursor-pointer text-2xl"
                    >
                        <img src="@/assets/icons/X.svg"/>
                    </span>
                </div>
                <OpenTicketContent></OpenTicketContent>
            </div>

        </div>
        <div class="absolute h-104-real w-full bg-orange shadow-mobile-popup rounded-t-3xl bottom-0 mb-14"
             :class="selected === 'menu' ? 'flex' : 'hidden'">
            <user-menu :isMobile=true></user-menu>

            <div class="absolute h-86 w-full bg-white px-2 bottom-0 rounded-t-3xl">
                <SideMenu :isMobile=true></SideMenu>
            </div>
        </div>
    </div>
</template>

<script>

import {mapActions, mapState} from "vuex";
import SideMenu from "@/components/SideMenu";
import OpenTicketContent from "@/components/OpenTicketContent";
import Services from "@/services";
import UserMenu from "@/components/UserMenu.vue";



export default {
    name: "MobileMenu.vue",
    components: {SideMenu, OpenTicketContent,UserMenu},
    data: () => ({}),
    methods: {
        ...mapActions({setSelected: "mobileMenu/setSelected"}),
        ...mapActions({setTickets: "tickets/setTickets"}),
        ...mapActions({setFilters: "tickets/setFilters"}),
        ...mapActions({setLastPage: "tickets/setLastPage"}),
        ...mapActions({setTicketStatus: "user/setTicketStatus"}),
        async showFavorites() {
            this.setSelected('favorites')
            let request = {favorite: 1};
            try {
                const response = await Services.getAllTickets(request);
                this.setFilters(request);
                this.setLastPage(parseInt(response.data.ticket.last_page));
                this.setTickets(response.data.ticket.data);
                if (this.$route.path !== "/filterResults/") {
                    let type = "favorites";
                    this.$router.push("/filterResults/" + type);
                }
                this.setTicketStatus(null);
            } catch (error) {
                this.$refs.errorModal.open();
                this.$refs.errorModal.close();
                this.errorMessage = "";
                this.errorMessage = 'Errore nel caricamento dei ticket in evidenza.'
                console.log(error);
            }
        },
        async showRemind() {
            this.setSelected('reminds')
            let request = {};
            let type = "";
            //Controllo: se l'utente è coordinatore, può vedere solleciti inviati e ricevuti
            //Dipendente - vede solo ricevuti / admin - vede solo inviati
            //Reminder - ticket sollecitati ricevuti / send_reminder - ... inviati
            if(this.user && this.user.is_admin > 0){
                request = {send_reminder: 1};
                type = "send_reminder";
            }else{
                request = {reminder: 1};
                type = "reminder";
            }
            try {
                const response = await Services.getAllTickets(request);
                this.setFilters(request);
                this.setLastPage(parseInt(response.data.ticket.last_page));
                this.setTickets(response.data.ticket.data);
                if (this.$route.path !== "/filterResults") {
                    this.$router.push("/filterResults/" + type);
                }
                this.setTicketStatus(null);
            } catch (error) {
                this.$refs.errorModal.open();
                this.$refs.errorModal.close();
                this.errorMessage = "";
                this.errorMessage = 'Errore nel caricamento dei ticket sollecitati.'
                console.log(error);
            }
        },
        showDependantsOrAreas(){
            this.setSelected('dependantsOrAreas')
            if (this.user && this.user.is_admin) {
                this.$router.push("/areas");
            } else if (this.user && this.user.user_area_coordinator_or_manager.length > 0) {
                this.$router.push("/employeeManagement");
            }
        }
    },
    computed: {
        ...mapState("user", {user: "user"}),
        ...mapState("mobileMenu", {selected: "selected"}),
    }
};

</script>

<style scoped>
.mobile-height-calc {
    height: calc(100vh - 4.375rem);
}
</style>